import React from "react";
import ReactDOM from "react-dom";
//import * as serviceWorker from "./serviceWorker";
import { GetTokenKey } from "./push-notification";

import HttpsRedirect from "react-https-redirect";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import App from "./App";
import reducers from "./reducers";

const store = createStore(reducers, applyMiddleware(thunk));
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then(function (registration) {
      //  console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      //   console.log("Service worker registration failed, error:", err);
    });
}

ReactDOM.render(
  <Provider store={store}>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </Provider>,
  document.getElementById("root")
);

GetTokenKey();
//serviceWorker.register();
