import React, { useEffect, useState } from "react";
import Parse from "parse";
import axios from "axios";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import ImageUpload from "../CustomeWidget/ImageUpload";

function LoyaltySchemeReport() {
  const [Customers, setCustomers] = useState([]);
  const [_newSelected, set_newSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(0);
  const [selected, setSelected] = useState({});
  const [offerType, setOfferType] = useState("");
  const [offerTitle, setOfferTitle] = useState("");
  const [ByName, setByName] = useState("");
  const [ByMobile, setByMobile] = useState("");
  const [message, setMessage] = useState("");
  const [ByType, setByType] = useState("");
  const [ByNumber, setByNumber] = useState("");
  const [offerData, setOfferData] = useState([]);
  const [notification, setNotification] = useState(false);
  const [image, setImage] = useState("");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [fileType] = useState(
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  );
  const [fileExtension] = useState(".xlsx");

  const columns = [
    {
      displayName: "Name",
      columnName: "Name",
    },
    {
      displayName: "Customer Mobile No",
      columnName: "Costomer_Mobile_no",
    },
    {
      displayName: "Vehicle No.",
      columnName: "Vehicle_No",
    },
    {
      displayName: "Last Sale",
      columnName: "LastSaleAmt",
    },
    {
      displayName: "Last Sale Date",
      columnName: "LastSaleDate",
    },
  ];
  let imageUrl = "";
  const toggleSelectAll = (Customers) => {
    let newSelected = {};
    set_newSelected([]);
    if (selectAll === 0) {
      Customers.forEach((x) => {
        newSelected[x.objectId] = true;
        set_newSelected((_newSelected) => _newSelected.concat(x));
      });
    }

    setSelected(newSelected);
    setSelectAll(selectAll === 0 ? 1 : 0);
  };

  const toggleRow = (_object) => {
    // set_newSelected([]);
    const newSelected = Object.assign({}, selected);
    newSelected[_object["objectId"]] = !selected[_object["objectId"]];
    setSelected(newSelected);
    setSelectAll(2);
    set_newSelected((_newSelected) => _newSelected.concat(_object));
  };

  const GetCustomersData = async () => {
    try {
      Parse.serverURL = localStorage.getItem("baseUrl");
      Parse.initialize(localStorage.getItem("parseAppId"));
      const Omar = Parse.Object.extend("loyaltyscheme_Inactivedayslimit");
      const query = new Parse.Query(Omar);
      query.descending("createdAt");
      await query.first().then(
        async (results) => {
          if (results) {
            let res = results.toJSON();
            let date = new Date();
            let daysToDeletion = res.Inactive_Days_Limit;
            let deletionDate = new Date(
              date.setDate(date.getDate() - daysToDeletion)
            );
            let d = new Date(deletionDate);
            let Todate = new Date(
              Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59)
            ).toISOString();
            /* let date1 =
              today.getFullYear() +
              "-" +
              ("0" + (today.getMonth() + 1)).slice(-2) + 
              "-" +
              ("0" + today.getDate()).slice(-2); */
            const Report = Parse.Object.extend("loyaltyscheme_Customer");
            const query2 = new Parse.Query(Report);
            query2.equalTo("LastSaleDate", null);
            const query1 = new Parse.Query(Report);
            query1.equalTo("LastSaleDate", { $lte: Todate });

            const composedQuery = Parse.Query.or(query2, query1);
            await composedQuery.find().then(
              (results) => {
                if (results) {
                  let data = JSON.stringify(results);
                  let data_parse = JSON.parse(data);
                  setCustomers(data_parse);
                }
                console.log("Report found", results);
              },
              (error) => {
                console.error("Error while fetching Report", error);
              }
            );
          }
        },
        (error) => {
          console.error("Error while fetching Omar", error);
        }
      );
    } catch (error) {}
  };

  useEffect(() => {
    GetCustomersData();
  }, []);

  const handleNotification = async (e) => {
    
    e.preventDefault();
    try {
      let url = `${localStorage.getItem(
        "baseUrl"
      )}functions/fn_sendNotification`;
      Parse.serverURL = localStorage.getItem("baseUrl");
      Parse.initialize(localStorage.getItem("parseAppId"));
      const currentUser = Parse.User.current();
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
        sessionToken: localStorage.getItem("accesstoken"),
      };
      let formData = {
        notificationText: notificationMessage,
        m: currentUser.id,
        image: image ? image : imageUrl,
      };
      if (_newSelected && _newSelected.length === 0) {
        alert("Please Select Customers");
        return;
      }
      
      let obj = {
        formData: formData,
        data: _newSelected,
      };
      await axios
        .post(url, obj, { headers: headers })
        .then((response) => {
          
          if (response) {
            alert(response.data.result.message && response.data.result.message);
          }
        })
        .catch((error) => {
          
          alert(error.message);
        });
    } catch (error) {}
  };
  // console.log(_newSelected)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let url = `${localStorage.getItem("baseUrl")}functions/fn_sendoffer`;
      Parse.serverURL = localStorage.getItem("baseUrl");
      Parse.initialize(localStorage.getItem("parseAppId"));
      const currentUser = Parse.User.current();
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
        sessionToken: localStorage.getItem("accesstoken"),
      };
      let formData = {
        Offer_Type: offerType,
        m: currentUser.id,
        Offer_Title: offerTitle,
      };
      if (_newSelected && _newSelected.length === 0) {
        alert("Please Select Customers");
        return;
      }
      let obj = {
        formData: formData,
        data: _newSelected,
      };
      await axios
        .post(url, obj, { headers: headers })
        .then((response) => {
          try {
            if (response) {              
              alert(`${response.data.result.message && response.data.result.message}`);
            }
          } catch (error) {
            alert(error.message);
          }
        })
        .catch((error) => alert(error.message));
    } catch (error) {}
  };

  // const onSearchButton
  const onSearchButton = async (e) => {
    try {
      e.preventDefault();
      let SearchQuery = "";
      if (ByName !== "") {
        let js = { Name: { $regex: ByName, $options: "i" } };
        let str = JSON.stringify(js);
        str = str.substring(1);
        str = str.substring(0, str.length - 1);
        if (SearchQuery === "") {
          SearchQuery = str;
        } else {
          SearchQuery = SearchQuery + "," + str;
        }
      }
      if (ByType !== "") {
        let js = { Vehicle_Type: { $regex: ByType, $options: "i" } };
        let str = JSON.stringify(js);
        str = str.substring(1);
        str = str.substring(0, str.length - 1);
        if (SearchQuery === "") {
          SearchQuery = str;
        } else {
          SearchQuery = SearchQuery + "," + str;
        }
      }
      if (ByNumber !== "") {
        let js = { Vehicle_No: { $regex: ByNumber, $options: "i" } };
        let str = JSON.stringify(js);
        str = str.substring(1);
        str = str.substring(0, str.length - 1);
        if (SearchQuery === "") {
          SearchQuery = str;
        } else {
          SearchQuery = SearchQuery + "," + str;
        }
      }
      if (ByMobile !== "") {
        let js = { Costomer_Mobile_no: { $regex: ByMobile, $options: "i" } };
        let str = JSON.stringify(js);
        str = str.substring(1);
        str = str.substring(0, str.length - 1);
        if (SearchQuery === "") {
          SearchQuery = str;
        } else {
          SearchQuery = SearchQuery + "," + str;
        }
      }

      let url = `${localStorage.getItem(
        "baseUrl"
      )}classes/loyaltyscheme_Customer?where={${SearchQuery}}&keys=Name,Costomer_Mobile_no,Vehicle_No,Vehicle_Type,Branch,LastSaleAmt,LastSaleDate`;

      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
        sessionToken: localStorage.getItem("accesstoken"),
      };

      await axios.get(url, { headers: headers }).then((response) => {
        try {
          if (response) {
            setCustomers(response.data.results);
          }
        } catch (error) {}
      });
    } catch (error) {}
  };

  //Send SMS
  const SendMessage = async (e) => {
    try {
      e.preventDefault();
      let url = `${localStorage.getItem("baseUrl")}functions/SendSms`;
      // alert(url);
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
        sessionToken: localStorage.getItem("accesstoken"),
      };
      let obj = {
        object: {
          message: message,
          data: _newSelected,
        },
      };
      await axios.post(url, obj, { headers: headers }).then((response) => {
        if (response.data.result.message === "Success") {
          alert("SMS Send.");
        }
      });
    } catch (error) {}
  };

  // Export to excel
  const exportToCSVState = (data, col) => {
    let mainData = [];
    try {
      for (let i = 0; i < data.length; i++) {
        let RowData = {};
        for (let j = 0; j < col.length; j++) {
          let tt = col[j].columnName;
          let t = "";
          var nameSplit = tt.split(".");
          if (nameSplit.length === 1) {
            try {
              t = data[i][nameSplit[0]];
            } catch (error) {}
          } else if (nameSplit.length === 2) {
            try {
              t = data[i][nameSplit[0]][nameSplit[1]];
            } catch (error) {}
          } else if (nameSplit.length === 3) {
            try {
              t = data[i][nameSplit[0]][nameSplit[1]][nameSplit[2]];
            } catch (error) {}
          }
          RowData[col[j].displayName] = t;
          //  let d =`${col[j].displayName}:${t}`
        }
        mainData.push(RowData);
      }

      data = mainData;
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data1 = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data1, `Inactiive Customers${fileExtension}`);
    } catch (e) {}
  };

  return (
    <React.Fragment>
      <div className="card card-topline-aqua">
        <div className="card-head">
          <header>Inactive Customers</header>
          <input
            type="image"
            id="image"
            tooltip="Export to Excel"
            alt="Export"
            style={{ padding: "4px" }}
            onClick={(e) => exportToCSVState(Customers, columns)}
            src="./assets/img/excel.png"
            className="pull-right"
          ></input>
          <a
            className={`btn btn-success btn-xs pull-right`}
            title="Send SMS"
            data-toggle="modal"
            data-target="#sendSMS"
            style={{ padding: "4px", margin: "1px" }}
            onClick={() => {
              // this.getForm(l.componentId);
            }}
          >
            <i className="fa fa-envelope" aria-hidden="true"></i>
          </a>
          <button
            type="button"
            data-toggle="modal"
            data-target="#exampleModal"
            onClick={(e) => {
              e.preventDefault();
              setNotification(false);
            }}
            className="btn btn-xs btn-info"
            style={{ float: "right", margin: "4px" }}
          >
            Send Offer
          </button>
          <button
            type="button"
            data-toggle="modal"
            data-target="#exampleModal"
            onClick={(e) => {
              e.preventDefault();
              setNotification(true);
            }}
            className="btn btn-xs btn-danger"
            style={{ float: "right", margin: "4px" }}
          >
            Send Notification
          </button>
        </div>
        <div className="card-body ">
          <div className="row">
            <div className="col-md-3 col-lg-3 col-12">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={ByName}
                  onChange={(e) => setByName(e.target.value)}
                  placeholder="Search by name"
                  id="name"
                />
              </div>
            </div>
            <div className="col-md-3 col-lg-3 col-12">
              <label htmlFor="Bymobile">Mobile No</label>
              <input
                type="text"
                className="form-control"
                value={ByMobile}
                onChange={(e) => setByMobile(e.target.value)}
                placeholder="Search by mobile no."
                id="mobile"
              />
            </div>
            <div className="col-md-3 col-lg-3 col-12">
              <label htmlFor="Bymobile">Vehicle Type</label>
              <select
                className="form-control"
                value={ByType}
                onChange={(e) => setByType(e.target.value)}
              >
                <option>Select</option>
                <option value="Truck">Truck</option>
                <option value="Bus">Bus</option>
                <option value="Container">Container</option>
                <option value="Tractor">Tractor</option>
                <option value="Car">Car</option>
              </select>
            </div>
            <div className="col-md-3 col-lg-3 col-12">
              <label htmlFor="Bymobile">Vehicle No</label>
              <input
                type="text"
                className="form-control"
                value={ByNumber}
                onChange={(e) => setByNumber(e.target.value)}
                placeholder="Search by vehicle no."
                id="vnum"
              />
            </div>
            <button
              type="button"
              className="btn btn-info"
              style={{ margin: "14px" }}
              onClick={(e) => onSearchButton(e)}
            >
              Search
            </button>
          </div>

          <div className="table-scrollable">
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={selectAll === 1}
                      ref={(input) => {
                        if (input) {
                          input.indeterminate = selectAll === 2;
                        }
                      }}
                      onChange={() => toggleSelectAll(Customers)}
                    />
                  </th>
                  <th>Name</th>
                  <th>Customer Mobile No</th>
                  <th>Vehicle No.</th>
                  <th>Vehicle Type</th>
                  <th>Last Sale </th>
                  <th>Last Sale Date</th>
                </tr>
              </thead>
              <tbody>
                {Customers &&
                  Customers.map((x, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          <td key={i + "h"}>
                            <input
                              type="checkbox"
                              className="checkbox"
                              value={x.objectId}
                              checked={selected[x.objectId]}
                              onChange={() => toggleRow(x)}
                            />
                          </td>
                        </td>
                        <td>{x.Name}</td>
                        <td>{x.Costomer_Mobile_no}</td>
                        <td>{x.Vehicle_No}</td>
                        <td>{x.Vehicle_Type}</td>
                        <td>{x.LastSaleAmt}</td>
                        <td>{x.LastSaleDate}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {notification ? "Send Notification" : "Send Offers"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {notification ? (
                <form onSubmit={handleNotification}>
                  <div className="form-group">
                    <label htmlFor="notification">Notification Message:</label>
                    <textarea
                      className="form-control"
                      rows="2"
                      id="notification"
                      name="notification"
                      value={notificationMessage}
                      onChange={(e) => setNotificationMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <ImageUpload
                    schema={{
                      title: "Image",
                      type: "string",
                      filetypes: ["JPEG", "PNG", "JPG"],
                      fileSize: 12240,
                    }}
                    onChange={(val) => {
                      imageUrl = val;
                      setImage(val);
                    }}
                  />
                  <br />
                  <br />
                  <button type="submit" className="btn btn-primary">
                    Send Notification
                  </button>
                </form>
              ) : (
                <form>
                  <div className="form-group">
                    <label htmlFor="email">Offer Type</label>
                    <select
                      id="type"
                      value={offerType}
                      className="form-control"
                      onChange={async (e) => {
                        try {
                          setOfferType(e.target.value);
                          Parse.serverURL = localStorage.getItem("baseUrl");
                          Parse.initialize(localStorage.getItem("parseAppId"));
                          const Agent = Parse.Object.extend(
                            "loyaltyscheme_AddOffer"
                          );
                          const query = new Parse.Query(Agent);
                          query.equalTo(
                            "Is_Special",
                            e.target.value === "true" ? true : false
                          );
                          await query.find().then(
                            (results) => {
                              if (results) {
                                let _data = JSON.stringify(results);
                                let _dataparse = JSON.parse(_data);
                                setOfferData(_dataparse);
                              }
                            },
                            (error) => {
                              console.error(
                                "Error while fetching Agent",
                                error
                              );
                            }
                          );
                        } catch (error) {}
                      }}
                    >
                      <option value="">Select</option>
                      <option value="false">Normal</option>
                      <option value="true">Special</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="pwd">Offer Title:</label>
                    <select
                      id="title"
                      value={offerTitle}
                      className="form-control"
                      onChange={(e) => setOfferTitle(e.target.value)}
                    >
                      <option value="">Select</option>

                      {offerData &&
                        offerData.map((x) => (
                          <option key={x.objectId} value={x.objectId}>
                            {x.Offer_Title}
                          </option>
                        ))}
                    </select>
                  </div>

                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="btn btn-primary"
                  >
                    Send offer
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>

      <div id="sendSMS" className="modal fade" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={SendMessage}>
                <div className="form-group">
                  <label htmlFor="smsbody">Message Body</label>
                  <textarea
                    className="form-control"
                    id="smsbody"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows="3"
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-info">
                  Send SMS
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LoyaltySchemeReport;
