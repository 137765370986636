import React, { useState, useEffect } from "react";
import "../CustomeWidget/image.css";
import axios from "axios";
import parse from "html-react-parser";
import { SaveFileSize } from "components/SaveFileSize/saveFileSize";
import Hls from "hls.js";
import Parse from "parse";
const ReactS3Uploader = require("react-s3-uploader");

const $ = window.$;

const FileUpload = (props) => {
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const [_fileupload, setFileUpload] = useState("");
  const [fileload, setfileload] = useState(false);
  const [isS3Upload, setisS3Upload] = useState(
    props.schema.uploadtype && props.schema.uploadtype
  );
  const [Message, setMessage] = useState(false);
  const [percentage, setpercentage] = useState(0);

  const REQUIRED_FIELD_SYMBOL = "*";
  const url = parseBaseUrl.match(/server2/g);
  const tenant = localStorage.getItem("TenetId");

  let image_url;
  if (url !== null) {
    image_url = parseBaseUrl.replace("/app", "");
  } else {
    image_url = "https://root.qik.ai/";
  }
  let imgSize;
  useEffect(() => {
    if (!props.formData) {
      setFileUpload([]);
      $("#hashfile").val("");
      props.onChange(undefined);
    }

    // eslint-disable-next-line
  }, [props.formData]);
  const onChange = (e) => {
    try {
      let files = e.target.files;
      /*  if (window.app) {
        window.app.getFileConfig(
          `{"fileSize": ${props.schema.maxfilesizeKB},"exactSize": {"width": ${props.schema.exactSize.width},"height": ${props.schema.exactSize.height}},"width": ${props.schema.width}}`
        );
      } */
      if (typeof files[0] !== "undefined") {
        if (props.schema.filetypes && props.schema.filetypes.length > 0) {
          var fileName = files[0].name;
          var fileNameExt = fileName
            .substr(fileName.lastIndexOf(".") + 1)
            .toLowerCase();
          let Extensions = props.schema.filetypes.map((x) => x.toLowerCase());
          let arr = Extensions.filter((x) => x === fileNameExt);
          if (arr.length > 0) {
          } else {
            alert(
              "Only these file types are accepted : " + Extensions.join(", ")
            );
            $("#hashfile").val("");
            return;
          }
        }
        if (props.schema.maxfilesizeKB && props.schema.maxfilesizeKB !== "") {
          console.log(Math.round(files[0].size / 1024));
          if (
            Math.round(Number(files[0].size) / 1024) <=
            props.schema.maxfilesizeKB
          ) {
          } else {
            alert(
              `The selected file size is too large. Please select a file less than ${Math.round(
                props.schema.maxfilesizeKB / 1024
              )} MB`
            );
            $("#hashfile").val("");
            return;
          }
        }
        // console.log("fileupload",files[0])
        const url = parseBaseUrl.match(/server2/g);

        if (url !== null) {
          fileUpload(files[0]);
        } else {
         handleFileUpload(files[0]);
        }
      } else {
        alert("Please select file.");
        $("#hashfile").val("");
        return false;
      }
    } catch (error) {
      alert(error.message);
      $("#hashfile").val("");
      return false;
    }
  };

  const handleFileUpload = async (file) => {
    Parse.serverURL = parseBaseUrl;
    Parse.initialize(parseAppId);
    const size = file.size;
    // console.log("file ", file)
    setfileload(true);
    const tenant = localStorage.getItem("TenetId");
    const pdfFile = file;
    const parseFile = new Parse.File(pdfFile.name, pdfFile);

    try {
      const response = await parseFile.save();

      setFileUpload(response.url());
      props.onChange(response.url());
      setfileload(false);
      // The response object will contain information about the uploaded file
      console.log("File uploaded:", response);

      // You can access the URL of the uploaded file using response.url()
      console.log("File URL:", response.url());
      if(response.url()){
        SaveFileSize(size, response.url(), url, tenant);

        return response.url()
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const onPreprocess = (file, next) => {
    // console.log("on process",file)
    if (file) {
      imgSize = file.size;
      try {
        let fileName = file.name;
        let fileNameExt = fileName
          .substr(fileName.lastIndexOf(".") + 1)
          .toLowerCase();
        let Extensions = props.schema.filetypes.map((x) => x.toLowerCase());
        let arr = Extensions.filter((x) => x === fileNameExt);
        if (arr.length > 0) {
        } else {
          alert(
            "Only these file types are accepted : " + Extensions.join(", ")
          );
          return;
        }
        if (props.schema.maxfilesizeKB && props.schema.maxfilesizeKB !== "") {
          if (
            Math.round(Number(file.size) / 1024) <= props.schema.maxfilesizeKB
          ) {
          } else {
            alert(
              `The selected file size is too large. Please select a file less than ${Math.round(
                props.schema.maxfilesizeKB / 1024
              )} MB`
            );
            return;
          }
        }
      } catch (error) {
        return;
      }
      next(file);
    }
  };

  const onJWChange = (e) => {
    try {
      let files = e.target.files;
      if (typeof files[0] !== "undefined") {
        if (props.schema.filetypes && props.schema.filetypes.length > 0) {
          var fileName = files[0].name;
          var fileNameExt = fileName
            .substr(fileName.lastIndexOf(".") + 1)
            .toLowerCase();
          let Extensions = props.schema.filetypes.map((x) => x.toLowerCase());
          let arr = Extensions.filter((x) => x === fileNameExt);
          if (arr.length > 0) {
          } else {
            alert(
              "Only these file types are accepted : " + Extensions.join(", ")
            );
            $("#hashfile").val("");
            return;
          }
        }
        if (props.schema.maxfilesizeKB && props.schema.maxfilesizeKB !== "") {
          console.log(Math.round(files[0].size / 1024));
          if (
            Math.round(Number(files[0].size) / 1024) <=
            props.schema.maxfilesizeKB
          ) {
          } else {
            alert(
              `The selected file size is too large. Please select a file less than ${Math.round(
                props.schema.maxfilesizeKB / 1024
              )} MB`
            );
            $("#hashfile").val("");
            return;
          }
        }
        JWfileUpload(files[0]);
      } else {
        alert("Please select file.");
        $("#hashfile").val("");
        return false;
      }
    } catch (error) {
      alert(`${error.message}`);
      $("#hashfile").val("");
    }
  };

  const JWfileUpload = async (file) => {
    setfileload(true);
    try {
      const url = `${parseBaseUrl}functions/JWplayVideoCreate`;
      let config = {
        headers: {
          sessiontoken: localStorage.getItem("accesstoken"),
          "X-Parse-Application-Id": parseAppId,
        },
      };
      const formData = new FormData();
      formData.append("file", file);
      let JWBody = { appname: localStorage.getItem("_appName") };
      await axios
        .post(url, JWBody, config)
        .then(async (res) => {
          if (res) {
            let result = res.data.result;
            let sendUrl = "",
              videoUrl = "";
            if (result.length > 0) {
              result.forEach((x) => {
                if (x.sendUrl) {
                  sendUrl = x.sendUrl;
                }
                if (x.videoURL) {
                  videoUrl = x.videoURL;
                }
              });
              if (sendUrl && videoUrl) {
                let config = {
                  headers: {
                    "content-type": file.type,
                  },
                  onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setpercentage(percentCompleted);
                  },
                };
                // let sendurl = x.sendUrl;

                await axios
                  .put(sendUrl, file, config)
                  .then((response) => {
                    console.log(response);
                    setFileUpload(videoUrl);
                    props.onChange(videoUrl);
                    alert("file updated successfully");
                    setfileload(false);
                    setMessage(true);
                    setpercentage(0);
                  })
                  .catch((err) => {
                    console.log(err);
                    alert(`${err.message}`);
                    setfileload(false);
                    setpercentage(0);
                    $("#hashfile").val("");
                  });
              }
            }
          }
        })
        .catch((err) => {
          alert(`${err.message}`);
          setfileload(false);
          setpercentage(0);
          $("#hashfile").val("");
        });
    } catch (error) {
      alert(`${error.message}`);
      setfileload(false);
      setpercentage(0);
      $("#hashfile").val("");
    }
  };

  //function for upload file
  const fileUpload = async (file) => {
    if (file) {
      const size = file.size;

      // console.log("size",size)
      setfileload(true);

      const url = `${image_url}image_upload`;
      const formData = new FormData();
      formData.append("file", file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          "X-Parse-Application-Id": parseAppId,
          tenantId: tenant && tenant,
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setpercentage(percentCompleted);
        },
      };

      try {
        const response = await axios
          .post(url, formData, config)
          .then((res) => {
            if (res.data.status === "Error") {
              alert(res.data.message);
            }
            setFileUpload(res.data.imageUrl);
            props.onChange(res.data.imageUrl);
            setfileload(false);
            setpercentage(0);
            return res;
          })
          .catch((err) => {
            alert(`${err.message}`);
            setfileload(false);
            setpercentage(0);
            $("#hashfile").val("");
          });
        //after getting successful response save image's size on client db class "partners_TenantCredits".
        if (response.data.status === "Success") {
          //function for save image's size on client db class "partners_TenantCredits".
          SaveFileSize(size, response.data.imageUrl, url, tenant);
        }
      } catch (error) {
        alert(error.message);
        setfileload(false);
        setpercentage(0);
        $("#hashfile").val("");
      }
    }
  };

  const onUploadProgress = (per) => {
    if (percentage === 0) {
      setfileload(true);
    }
    setpercentage(per);
  };

  const onUploadError = (err) => {
    console.log(err);
    alert(err.message);
    setpercentage(0);
    setfileload(false);
  };

  const onUploadFinish = (e) => {
    let imgUrl = `https://qikvideos.ams3.digitaloceanspaces.com/${e.filename}`;
    setFileUpload(imgUrl);
    props.onChange(imgUrl);
    setfileload(false);
    setpercentage(0);

    if (imgSize) {
      let size = imgSize;
      //function for save image's size on client db class "partners_TenantCredits".
      SaveFileSize(size, imgUrl, url, tenant);
    }
  };

  const headers = {
    "Content-Type": "application/json",
    "X-Parse-Application-Id": "shbjnnhfcp",
  };

  let imageView =
    props.formData && props.schema.uploadtype === "s3viajw"
      ? props.formData && (
          <a
            onClick={(e) => {
              e.preventDefault();
              // var hls = new Hls()
              let url = `https://cdn.jwplayer.com/manifests/${
                props.formData ? props.formData : _fileupload
              }.m3u8`;
              if (Hls.isSupported()) {
                var video = document.getElementById("video");
                var hls = new Hls();
                hls.detachMedia();
                hls.loadSource(url);
                hls.attachMedia(video);
                hls.on(Hls.Events.MANIFEST_PARSED, function () {
                  video.play();
                });
                hls.on(Hls.Events.ERROR, function (event, data) {
                  if (data.fatal) {
                    switch (data.type) {
                      case Hls.ErrorTypes.NETWORK_ERROR:
                        // when try to recover network error
                        console.log(
                          "fatal network error encountered, try to recover"
                        );
                        hls.startLoad();
                        break;
                      case Hls.ErrorTypes.MEDIA_ERROR:
                        console.log(
                          "fatal media error encountered, try to recover"
                        );
                        hls.recoverMediaError();
                        break;
                      default:
                        // when cannot recover
                        hls.destroy();
                        break;
                    }
                  }
                });
              }
              $("video").show();
              $("#VideoModal").show();
            }}
          >
            <img
              /* src={`https://nxgcreator.s3.amazonaws.com/orange-video-icon-png-3.png`} */
              src={
                Message
                  ? `https://nxgcreator.s3.amazonaws.com/orange-video-icon-png-3.png`
                  : `https://cdn.jwplayer.com/v2/media/${props.formData}/poster.jpg`
              }
              className=""
              alt=""
              height="30px"
            />
          </a>
        )
      : _fileupload && (
          <img
            src={`https://nxgcreator.s3.amazonaws.com/orange-video-icon-png-3.png`}
            /* src={`https://cdn.jwplayer.com/v2/media/${_fileupload}/poster.jpg`} */
            className=""
            alt=""
            height="30px"
          />
        );

  let fileView =
    props.formData &&
    props.schema.uploadtype === "s3viajw" ? null : props.formData &&
      props.formData ? (
      <React.Fragment>
        <a
          href={props.formData}
          title={props.formData}
          style={{ paddingBottom: "10px" }}
        >
          Download
        </a>
      </React.Fragment>
    ) : fileload === false ? null : (
      <React.Fragment>
        <a
          href={_fileupload}
          title={_fileupload}
          style={{ paddingBottom: "10px" }}
        >
          Download
        </a>
        <br />
      </React.Fragment>
    );

  /*  if (props.formData && props.schema.uploadtype === "s3viajw") {
    $("#hashfile").val(props.formData);
  } */
  return (
    <React.Fragment>
      <div style={{ display: "inline-block" }}>
        <label htmlFor={props.name}>
          {props.schema.title}
          {props.required && (
            <span className="required">{REQUIRED_FIELD_SYMBOL}</span>
          )}
          {fileload ? (
            <div className="progress pull-right">
              <div
                className="progress__bar"
                style={{ width: `${percentage}%` }}
              ></div>
              <span className="progress__value">{percentage}%</span>
            </div>
          ) : (
            Message && (
              <span style={{ fontSize: "9px", marginLeft: "4px" }}>
                Processing will take 5-10 mins....
              </span>
            )
          )}
          {props.schema.helpbody ? (
            <div className="dropdown pull-right">
              <i
                className="far fa-question-circle dropdown-toggle hovereffect"
                aria-hidden="true"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  fontSize: "12px",
                  color: "purple",
                  cursor: "pointer !important",
                  position: "relative",
                  bottom: "0px",
                  left: "0px",
                  paddingBottom: "4px",
                  paddingLeft: "4px",
                }}
              ></i>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{
                  marginleft: "-121px",
                  margintop: "-14px",
                  position: "absolute",
                  padding: "10px",
                  top: "102px!important",
                }}
              >
                {parse(`
             ${props.schema.helpbody}
           `)}
                <br />
                {props.schema.helplink ? (
                  <a
                    href={props.schema.helplink}
                    target="_blank"
                    className="btn btn-xs btn-primary"
                  >
                    Read more..
                  </a>
                ) : null}
              </div>
            </div>
          ) : null}
          <span style={{ marginLeft: "10px" }}>{fileView}</span>
        </label>
      </div>

      {isS3Upload && isS3Upload === "directtos3" ? (
        <ReactS3Uploader
          signingUrl="s3/sign"
          signingUrlMethod="GET"
          preprocess={onPreprocess}
          onProgress={onUploadProgress}
          onFinish={onUploadFinish}
          onError={onUploadError}
          signingUrlHeaders={headers}
          uploadRequestHeaders={{
            "x-amz-acl": "public-read",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          }}
          contentDisposition="auto"
          scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, "")}
          server={
            url !== null ? "https://server2.qik.ai/" : "https://root.qik.ai/"
          }
        />
      ) : isS3Upload === "s3viajw" ? (
        <div className="input-group mb-5">
          <input
            type="file"
            id="hashfile"
            className="form-control"
            onChange={onJWChange}
          />
          <div className="input-group-append">
            <span className="input-group-text" height="45px">
              {imageView}
            </span>
          </div>
        </div>
      ) : (
        <input
          type="file"
          id="hashfile"
          style={{
            border: "1px solid gray",
            color: "gray",
            backgroundColor: "white",
            padding: "5px 10px",
            borderRadius: "4px",
            fontSize: "14px",
            width: "100%",
            fontWeight: "bold",
          }}
          onChange={onChange}
        />
      )}

      <div className="modal" id="VideoModal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body" width="100%" style={{ padding: "0px" }}>
              <button
                className="pull-right"
                style={{ marginTop: "0px", width: "auto", marginRight: "4px" }}
                onClick={(e) => {
                  e.preventDefault();
                  var video = document.getElementById("video");
                  video.pause();
                  $("#video").hide();
                  $("#VideoModal").hide();
                }}
              >
                <span
                  aria-hidden="true"
                  style={{
                    color: "black",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  &times;
                </span>
              </button>
              <video id="video" width="100%" controls></video>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FileUpload;
