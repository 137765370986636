import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LabelField from "../LevelDropDown/LabelField";

const MobileNumber = (props) => {
  const [phone, setphone] = useState("");

  return (
    <React.Fragment>
      <LabelField
        Title={props.schema.title}
        Name={props.name}
        Required={props.required}
        HelpBody={props.schema.helpbody}
        HelpLink={props.schema.helplink}
      />
      <br />
      <PhoneInput
        country={
          props.schema.defaultcountry && props.schema.defaultcountry
            ? props.schema.defaultcountry
            : "us"
        }
        value={props.formData && props.formData ? props.formData : phone}
        onChange={(phone) => {
          setphone(phone);
          props.onChange(phone);
        }}
        inputProps={{
          name: props.name,
          required: true,
          autoFocus: false,
          disabled: props.uiSchema["ui:disabled"]
        }}
      />
    </React.Fragment>
  );
};

export default MobileNumber;
