import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Parse from "parse";
import { SaveFileSize } from "./SaveFileSize/saveFileSize";

function UserProfile() {
  let UserProfile = JSON.parse(localStorage.getItem("UserInformation"));
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const [editmode, setEditMode] = useState(false);
  const [name, SetName] = useState(localStorage.getItem("username"));
  const [Phone, SetPhone] = useState(UserProfile && UserProfile.phone);
  const [Image, setImage] = useState(localStorage.getItem("profileImg"));
  const [isLoader, setIsLoader] = useState(false);
  Parse.serverURL = parseBaseUrl;
  Parse.initialize(parseAppId);
  //save user's data
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoader(true);
    const value = parseBaseUrl.match(/server2/g);
    let phn = Phone;
    // if (value === null) {
    //   console.log("here")
    //   phn = parseInt(Phone);
    // }
    // console.log("value ", value);
    try {
      const tour = Parse.Object.extend("_User");
      const query = new Parse.Query(tour);

      await query.get(UserProfile.objectId).then((object) => {
        object.set("name", name);
        object.set("ProfilePic", Image);
        object.set("phone", phn);
        object.save().then(
          async (response) => {
            if (response) {
              let res = response.toJSON();
              let rr = JSON.stringify(res);
              localStorage.setItem("UserInformation", rr);
              SetName(res.name);
              SetPhone(res.phone);
              setImage(res.ProfilePic);
              localStorage.setItem("username", res.name);
              if (value === null) {
                //   console.log("here")
                await updateExtUser({ Name: res.name, Phone: res.phone });
              }
              alert("Profile updated successfully.");
              setEditMode(false);
              // window.location.reload();
            }
          },
          (error) => {
            alert("Something went wrong.");
            console.error("Error while updating tour", error);
            setIsLoader(false);
          }
        );
      });
    } catch (error) {}
  };

  //  `updateExtUser` is used to update user details in extended class
  const updateExtUser = async (obj) => {
    const extClass = localStorage.getItem("extended_class");
    const extData = JSON.parse(localStorage.getItem("Extand_Class"));
    const ExtUserId = extData[0].objectId;
    // console.log("extData ", extData);
    // console.log("UserId ", ExtUserId);

    const extQuery = new Parse.Query(extClass);
    const update = await extQuery.get(ExtUserId);
    // console.log("update ", update);

    update.set("Phone", obj.Phone);
    update.set("Name", obj.Name);
    const updateRes = await update.save();

    const json = JSON.parse(JSON.stringify([updateRes]));
    const extRes = JSON.stringify(json);

    localStorage.setItem("Extand_Class", extRes);
    // console.log("updateRes ", updateRes);
  };
  // file upload function
  const fileUpload = async (file) => {
    if (file) {
      //get image's size
      const size = file.size;
      const tenant = localStorage.getItem("TenetId");
      const value = parseBaseUrl.match(/server2/g);
      let image_url;

      if (value !== null) {
        image_url = parseBaseUrl.replace("/app", "");

        //save  image
        const url = `${image_url}image_upload`;
        const formData = new FormData();

        formData.append("file", file);

        const config = {
          headers: {
            "content-type": "multipart/form-data",
            "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
            sessionToken: localStorage.getItem("accesstoken"),
            tenantId: tenant && tenant,
          },
        };
        try {
          const response = await axios
            .post(url, formData, config)
            .then((res) => {
              if (res.data.status === "Error") {
                alert(res.data.message);
              }
              setImage(res.data.imageUrl);
              localStorage.setItem("profileImg", res.data.imageUrl);
              return res;
            });
          // console.log("response url", response);
          //   after getting successful response save image's size on client db class "partners_TenantCredits".
          if (response.data.status === "Success") {
            SaveFileSize(size, response.data.imageUrl, value, tenant);
          }
        } catch (error) {
          alert("Please select Image");
        }
      } else {
        image_url = "https://root.qik.ai/";
        await handleFileUpload(file, value);
      }
    }
  };

  const handleFileUpload = async (file, url) => {
    const size = file.size;

    const tenant = localStorage.getItem("TenetId");
    const pdfFile = file;
    const parseFile = new Parse.File(pdfFile.name, pdfFile);

    try {
      const response = await parseFile.save();
      // // The response object will contain information about the uploaded file
      // console.log("File uploaded:", response);

      // You can access the URL of the uploaded file using response.url()
      // console.log("File URL:", response.url());
      if (response.url()) {
        setImage(response.url());
        localStorage.setItem("profileImg", response.url());
        SaveFileSize(size, response.url(), url, tenant);
        return response.url();
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  if (
    localStorage.getItem("accesstoken") === null &&
    localStorage.getItem("pageType") === null
  ) {
    let _redirect = `/`;
    return <Redirect to={_redirect} />;
  }

  return (
    <React.Fragment>
      {isLoader ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontSize: "45px",
              color: "#3dd3e0",
            }}
            className="loader-37"
          ></div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-12">
            <div
              className="profile-sidebar"
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="card card-topline-aqua">
                <div className="card-body no-padding height-9">
                  <div className="row">
                    <div className="profile-userpic">
                      <img
                        src={Image === "" ? "./assets/img/dp.png" : Image}
                        className="img-responsive"
                        alt="no"
                      />{" "}
                      {editmode ? (
                        <input
                          type="file"
                          style={{ marginLeft: "100px", padding: "4px" }}
                          onChange={(e) => {
                            let files = e.target.files;
                            fileUpload(files[0]);
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="profile-usertitle">
                    <div className="profile-usertitle-name">
                      {editmode ? (
                        <input
                          type="text"
                          value={name}
                          style={{
                            padding: "4px",
                            textAlign: "Center",
                            border: "1px solid #a4cdce",
                            borderRadius: "4px",
                          }}
                          onChange={(e) => SetName(e.target.value)}
                        />
                      ) : (
                        localStorage.getItem("username")
                      )}
                    </div>
                    <div className="profile-usertitle-job">
                      {" "}
                      {localStorage.getItem("_user_role")}{" "}
                    </div>
                  </div>
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <b>Email</b>{" "}
                      <a className="pull-right">
                        {UserProfile && UserProfile.email}
                      </a>
                    </li>
                    <li className="list-group-item">
                      <b>Phone</b>{" "}
                      <a className="pull-right">
                        {editmode ? (
                          <input
                            type="text"
                            style={{
                              padding: "4px",
                              textAlign: "Center",
                              border: "1px solid #a4cdce",
                              borderRadius: "4px",
                            }}
                            onChange={(e) => SetPhone(e.target.value)}
                            value={Phone}
                          />
                        ) : (
                          UserProfile && UserProfile.phone
                        )}
                      </a>
                    </li>
                    <li className="list-group-item">
                      <b>Is email verified</b>{" "}
                      <a className="pull-right">
                        {UserProfile && UserProfile.emailVerified
                          ? "Verified"
                          : "Not verified"}
                      </a>
                    </li>
                  </ul>

                  <div className="profile-userbuttons">
                    <button
                      type="button"
                      onClick={() => {
                        window.location.hash = "/changepassword";
                      }}
                      className="btn btn-circle blue btn-sm"
                    >
                      Change Password
                    </button>
                    {editmode ? (
                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="btn btn-circle Viridian btn-sm"
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          setEditMode(true);
                        }}
                        className="btn btn-circle red btn-sm"
                      >
                        Edit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default UserProfile;
