import React, { useEffect, useState } from "react";
import Parse from "parse";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  Redirect,
} from "react-router-dom";
import FormBuilder from "../components/FormBuilder";
import Dashboard from "../components/Dashboard";
import ReportData from "../components/ReportData";

function CustomeHorizantal(props) {
  const [menuList, setmenuList] = useState([]);
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const [pageType, setPageType] = useState("");
  const [objectId, setObjejectId] = useState("");
  const getMenuList = async (id) => {
    try {
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      var sideMenu = Parse.Object.extend("w_menu");
      var query = new Parse.Query(sideMenu);
      query.equalTo("objectId", id);
      //	query.equalTo("objectId", "46X9z6lAv2");
      const results = await query.first();
      const resultjson = results.toJSON();
      
      let result = resultjson;
      setmenuList(result.menuItems);
    } catch (e) {
      console.error("Problem", e);
    }
  };
  useEffect(() => {
    getMenuList(props.match.params.id);

    // eslint-disable-next-line
  }, [props.match.params.id]);
  useEffect(() => {
    renderDefault();
    // eslint-disable-next-line
  }, [menuList]);
  const renderDefault = () => {
    if (
      menuList.length > 0 &&
      menuList[0].pageType !== undefined &&
      menuList[0].objectId !== undefined
    ) {
      if (menuList[0].children === undefined) {
        setPageType(menuList[0].pageType);
        setObjejectId(menuList[0].objectId);
      }else{
        setPageType(menuList[0].children[0].pageType);
        setObjejectId(menuList[0].children[0].objectId);
      }
    }
  };
  return (
    <React.Fragment>
      <nav id="menu">
        <input type="checkbox" />
        <label>
          &#8801;<span>Navigation</span>
        </label>
        <ul>
          {menuList &&
            menuList.map((item, i) =>
              item.children === undefined ? (
                <li>
                  <NavLink
                    to={`${props.match.url}/${item.pageType}/${item.objectId}`}
                  >
                    {item.title}
                  </NavLink>
                </li>
              ) : (
                <li>
                  <a className="prett" onClick={(e) => e.preventDefault()}>
                    {item.title}
                  </a>
                  <ul className="menus">
                    {item.children.map((sub, i) =>
                      sub.children === undefined ? (
                        <NavLink
                          to={`${props.match.url}/${sub.pageType}/${sub.objectId}`}
                        >
                          {sub.title}
                        </NavLink>
                      ) : null
                    )}
                  </ul>
                </li>
              )
            )}
        </ul>
      </nav>
      <br />
      <Switch>
        <Route path={`${props.match.path}/form/:id`} component={FormBuilder} />
        <Route path={`${props.match.path}/report/:id`} component={ReportData} />
        <Route
          path={`${props.match.path}/dashboard/:id`}
          component={Dashboard}
        />
        <Route
          path={`${props.match.path}/h/:id`}
          component={CustomeHorizantal}
        />
      </Switch>
      {menuList.length > 0 ? (
        <Redirect to={`${props.match.url}/${pageType}/${objectId}`} />
      ) : null}
    </React.Fragment>
  );
}

export default CustomeHorizantal;
