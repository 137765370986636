import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import "../Spinner/loader.css";
import Parse from "parse";
import axios from "axios";

const ConnectorAppComponent = (props) => {
  const [pagetype, setpagetype] = useState("");
  const [pageid, setpageid] = useState("");
  const [sessionId] = useState(props.match.params.sessionId);
  const [isloading, setIsloading] = useState(false);
  const [org, setOrgId] = useState("");

  let hosturl = window.location.host;
  // const hosturl = "elearning.qik.ai";
  // let hosturl = "crm-defaultssty.qik.ai";
  // let hosturl = "elearning-defaultssty.qik.ai";
  // let hosturl = "crm.qik.ai";
  // let hosturl =  "salesforceexams.qik.ai";
  // let hosturl = "cloudacademy.qik.ai"
  // let hosturl = "sign-defaultssty.qik.ai";
  // let hosturl = "sign.nxglabs.in"; //  "sign.qik.ai";

  // const hosturl = "elearning-austriadxsq.qik.ai";
  const splitUrl = hosturl.split(".")[0];
  const qikDomain = hosturl.split(".")[1];
  const urlSubName = splitUrl.split("-")[0];
  const orgId = splitUrl.split("-")[1];

  useEffect(() => {
    if (qikDomain !== "qik") {
      getOrgId();
    }
  }, [qikDomain]);

  // `getOrgId` is used to fetch orgId in suddomain not equal to qik
  async function getOrgId() {
    const subDo = window.location.host; // "https://sign.nxglabs.in";
    const surl = subDo.split(".");
    surl.shift();
    let domainName = surl.join(".");
    if (domainName.indexOf("/") !== -1) {
      domainName = surl.join(".").split("/")[0];
    }
    const queryParam = JSON.stringify({ domain: domainName });
    const res = await axios.get(
      `https://root.qik.ai/app/classes/domain?where=${queryParam}`,
      {
        headers: {
          "X-Parse-Application-Id": "aprjnnhfcp",
        },
      }
    );
    const orgRes = res.data.results[0].appId;
    // console.log("org ", orgRes);
    setOrgId(orgRes);
  }
  try {
    // var urlSubName = hosturl.split(".")[0];
    // let qikDomain = hosturl.split(".")[1];
    if (orgId) {
      localStorage.setItem("baseUrl", `https://server3.qik.ai/${orgId}/`);
      localStorage.setItem("parseAppId", orgId);
      localStorage.setItem("domain", urlSubName);
    } else {
      if (qikDomain === "uat") {
        localStorage.setItem("baseUrl", "https://server3.qik.ai/thirdapp/");
        localStorage.setItem("parseAppId", "qbKGrKRiIDFxqrYhcIkY");
      } else if (qikDomain === "dev") {
        localStorage.setItem("baseUrl", "https://dev-server.qik.ai/app/");
        localStorage.setItem("parseAppId", "qik-dev-app");
      } else if (qikDomain === "qik") {
        localStorage.setItem("baseUrl", "https://server2.qik.ai/app/");
        localStorage.setItem("parseAppId", "shbjnnhfcp");
      } else {
        // console.log("orgId ", orgId);
        localStorage.setItem("baseUrl", `https://server3.qik.ai/${org}/`);
        localStorage.setItem("parseAppId", org);
        localStorage.setItem("domain", urlSubName);
      }
      localStorage.setItem("domain", urlSubName);
    }
  } catch (error) {}

  const getCurrentUser = async () => {
    setIsloading(true);
    Parse.serverURL = localStorage.getItem("baseUrl");
    Parse.initialize(localStorage.getItem("parseAppId"));
    Parse.User.become(localStorage.getItem("accesstoken")).then(async (x) => {
      if (x) {
        let json = x.toJSON();
        localStorage.setItem("username", json.name);
        localStorage.setItem("accesstoken", json.sessionToken);
        let response = "";
        try {
          var AppInfoWeb = Parse.Object.extend("w_appinfo");
          var query = new Parse.Query(AppInfoWeb);
          query.equalTo("domain", localStorage.getItem("domain"));
          let results = await query.first();
          if (results) {
            response = results.toJSON();
            let _base = response.baseurl.charAt(response.baseurl.length - 1);
            localStorage.removeItem("baseUrl");
            localStorage.setItem("_appName", response.appname);
            const res = response.baseurl.split("/")[3];
            if (res === "app") {
              if (_base === "/") {
                localStorage.setItem("baseUrl", response.baseurl);
              } else {
                localStorage.setItem("baseUrl", `${response.baseurl}/`);
              }
            } else {
              localStorage.setItem(
                "baseUrl",
                `${response.baseurl}/${urlSubName}/`
              );
            }
            localStorage.setItem("appLogo", response.applogo);
            localStorage.setItem(
              "userSettings",
              JSON.stringify(response.settings)
            );

            let url = `${localStorage.getItem("baseUrl")}functions/UserGroups`;
            const headers = {
              "Content-Type": "application/json",
              "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
              sessionToken: json.sessionToken,
            };

            let body = {
              appname: localStorage.getItem("_appName"),
            };
            await axios
              .post(url, JSON.stringify(body), { headers: headers })
              .then((roles) => {
                let userRoles = roles.data.result;
                let _currentRole = "";
                if (userRoles.length > 1) {
                  if (
                    userRoles[0] ===
                    `${localStorage.getItem("_appName")}_appeditor`
                  ) {
                    _currentRole = userRoles[1];
                  } else {
                    _currentRole = userRoles[0];
                  }
                } else {
                  _currentRole = userRoles[0];
                }
                let SettingsUser = response.settings;
                SettingsUser.forEach(async (element) => {
                  if (element.role === _currentRole) {
                    localStorage.setItem(
                      "extended_class",
                      element.extended_class
                    );
                  }
                });
                setpagetype(props.match.params.pagetype);
                setpageid(props.match.params.pageid);
                setIsloading(false);
              })
              .catch((err) => {
                setIsloading(false);
              });
          } else {
            var query1 = new Parse.Query(AppInfoWeb);
            query1.equalTo("appname", localStorage.getItem("domain"));
            let result = await query1.first();
            if (result) {
              response = result.toJSON();
              let _base = response.baseurl.charAt(response.baseurl.length - 1);
              localStorage.removeItem("baseUrl");
              localStorage.setItem("_appName", response.appname);
              if (_base === "/") {
                localStorage.setItem("baseUrl", response.baseurl);
              } else {
                localStorage.setItem("baseUrl", `${response.baseurl}/`);
              }
              localStorage.setItem("appLogo", response.applogo);
              localStorage.setItem(
                "userSettings",
                JSON.stringify(response.settings)
              );
              let url = `${localStorage.getItem(
                "baseUrl"
              )}functions/UserGroups`;
              const headers = {
                "Content-Type": "application/json",
                "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
                sessionToken: json.sessionToken,
              };

              let body = {
                appname: localStorage.getItem("_appName"),
              };
              await axios
                .post(url, JSON.stringify(body), { headers: headers })
                .then((roles) => {
                  let userRoles = roles.data.result;
                  let _currentRole = "";
                  if (userRoles.length > 1) {
                    if (
                      userRoles[0] ===
                      `${localStorage.getItem("_appName")}_appeditor`
                    ) {
                      _currentRole = userRoles[1];
                    } else {
                      _currentRole = userRoles[0];
                    }
                  } else {
                    _currentRole = userRoles[0];
                  }
                  let SettingsUser = response.settings;
                  SettingsUser.forEach(async (element) => {
                    if (element.role === _currentRole) {
                      localStorage.setItem(
                        "extended_class",
                        element.extended_class
                      );
                    }
                  });
                  setpagetype(props.match.params.pagetype);
                  setpageid(props.match.params.pageid);
                  setIsloading(false);
                })
                .catch((err) => {
                  setIsloading(false);
                });
            } else {
              setIsloading(false);
            }
          }
        } catch (err) {
          console.log(err);
          setIsloading(false);
        }
      } else {
        setIsloading(false);
      }
    });
  };

  useEffect(() => {
    if (window.localStorage) {
      if (
        localStorage.getItem("accesstoken") &&
        localStorage.getItem("accesstoken") === sessionId
      ) {
        setpagetype(props.match.params.pagetype);
        setpageid(props.match.params.pageid);
      } else {
        localStorage.setItem("AppUser", true);
        localStorage.setItem("accesstoken", sessionId);
        getCurrentUser();
      }
    } else {
      localStorage.setItem("AppUser", true);
      localStorage.setItem("accesstoken", sessionId);
      getCurrentUser();
    }

    // eslint-disable-next-line
  }, [""]);

  if (pagetype && pageid) {
    let _redirect = `/${pagetype}/${pageid}`;
    return <Redirect to={_redirect} />;
  } else {
    return (
      <div style={{ height: "300px" }}>
        <div
          style={{
            marginLeft: "45%",
            marginTop: "150px",
            fontSize: "45px",
            color: "#3dd3e0",
          }}
          className="loader-37"
        ></div>
      </div>
    );
  }
};

export default ConnectorAppComponent;
