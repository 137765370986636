import React from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import LabelField from "./LabelField";
import axios from "axios";
import { SaveFileSize } from "components/SaveFileSize/saveFileSize";
import Parse from "parse";

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise(async (resolve, reject) => {
          const tenant = localStorage.getItem("TenetId");
          let parseBaseUrl = localStorage.getItem("baseUrl");
          let parseAppId = localStorage.getItem("parseAppId");
          const size = file.size;
          const value = parseBaseUrl.match(/server2/g);
          let image_url;
          if (file) {
            if (value !== null) {
              image_url = parseBaseUrl.replace("/app", "");

              const url = `${image_url}image_upload`;

              const formData = new FormData();
              formData.append("file", file);
              const config = {
                headers: {
                  "content-type": "multipart/form-data",
                  "X-Parse-Application-Id": parseAppId,
                  tenantId: tenant && tenant,
                },
              };
              const response = await axios
                .post(url, formData, config)
                .then((res) => {
                  if (res.data.status === "Error") {
                    alert(res.data.message);
                    reject(res);
                  }
                  resolve({
                    default: res.data.imageUrl,
                  });
                  return res;
                });
              //after getting successful response save image's size on client db class "partners_TenantCredits".
              if (response.data.status === "Success") {
                SaveFileSize(size, response.data.imageUrl, value, tenant);
              }
            }
          } else {
            image_url = "https://root.qik.ai/";
            Parse.serverURL = parseBaseUrl;
            Parse.initialize(parseAppId);

            const pdfFile = file;
            const parseFile = new Parse.File(pdfFile.name, pdfFile);

            try {
              const response = await parseFile.save();

              // You can access the URL of the uploaded file using response.url()
              // console.log("File URL:", response.url());
              if (response.url()) {
                SaveFileSize(size, response.url(), value, tenant);
                resolve({
                  default: response.url(),
                });
                return response.url();
              }
            } catch (error) {
              console.error("Error uploading file:", error);
              alert(error);
              reject(error)
            }
          }
        })
    );
  }
}
function HtmlEditor(props) {
  return (
    <React.Fragment>
      <LabelField
        Title={props.schema.title}
        Name={props.name}
        Required={props.required}
        HelpBody={props.schema.helpbody}
        HelpLink={props.schema.helplink}
      />
      <CKEditor
        editor={ClassicEditor}
        data={`${props.formData ? props.formData : ""}`}
        config={{
          alignment: {
            options: ["left", "right"],
          },
          fontSize: {
            options: [10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 22, 24],
            supportAllValues: true,
          },
          fontFamily: {
            options: [
              "default",
              "Ubuntu, Arial, sans-serif",
              "Ubuntu Mono, Courier New, Courier, monospace",
            ],
            supportAllValues: true,
          },
          toolbar: {
            items: [
              "specialCharacters",
              "undo",
              "redo",
              "fontFamily",
              "fontSize",
              "|",
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "blockquote",
              "removeFormat",
              "|",
              "alignment",
              "numberedList",
              "bulletedList",
              "|",
              "indent",
              "outdent",
              "|",
              "imageupload",
              "link",
              "mediaembed",
              "heading",
              "insertTable",
              "todoList",
              "horizontalLine",
            ],
          },

          heading: {
            options: [
              {
                model: "heading1",
                view: "h1",
                title: "Heading 1",
                class: "ck-heading_heading1",
              },
              {
                model: "heading2",
                view: "h2",
                title: "Heading 2",
                class: "ck-heading_heading2",
              },
              /*  {
                model: "paragraph",
                title: "Paragraph",
                class: "ck-heading_paragraph",
              }, */
              {
                model: "heading3",
                view: "h3",
                title: "Heading 3",
                class: "ck-heading_heading3",
              },
              {
                model: "heading4",
                view: "h4",
                title: "Heading 4",
                class: "ck-heading_heading4",
              },
              {
                model: "heading5",
                view: "h5",
                title: "Heading 5",
                class: "ck-heading_heading5",
              },
              {
                model: "heading6",
                view: "h6",
                title: "Heading 6",
                class: "ck-heading_heading6",
              },
            ],
          },
          image: {
            toolbar: [
              "imageStyle:alignLeft",
              "imageStyle:alignCenter",
              "imageStyle:alignRight",
              "|",
              "imageResize",
              "|",
              "imageTextAlternative",
            ],
            styles: [
              // This option is equal to a situation where no style is applied.
              "full",
              "side",
              // This represents an image aligned to the left.
              "alignLeft",
              "alignCenter",
              // This represents an image aligned to the right.
              "alignRight",
            ],
            resizeUnit: "px",
            resizeOptions: [
              {
                name: "imageResize:original",
                label: "Original",
                value: null,
              },
              {
                name: "imageResize:50",
                label: "50%",
                value: "50",
              },
              {
                name: "imageResize:75",
                label: "75%",
                value: "75",
              },
            ],
          },
          table: {
            contentToolbar: [
              "tableColumn",
              "tableRow",
              "mergeTableCells",
              "tableProperties",
              "tableCellProperties",
            ],
          },

          // This value must be kept in sync with the language defined in webpack.config.js.
          language: "de",
        }}
        onInit={(editor) => {
          editor.plugins.get("FileRepository").createUploadAdapter = (
            loader
          ) => {
            return new MyUploadAdapter(loader);
          };
        }}
        onChange={(event, editor) => {
          let data = editor.getData();
          props.onChange(data);
        }}
        disabled={props.uiSchema["ui:disabled"]}
      />
    </React.Fragment>
  );
}

export default HtmlEditor;
