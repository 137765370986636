import React, { Component } from "react";
import Parse from "parse";
import "./toast.css";
import "../Spinner/loader.css";
import { connect } from "react-redux";
import { fetchAppInfo, setEnableCart, showTenantName } from "./../actions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./Login.css";
// import GoogleLogin from "react-google-login";
// import FacebookLogin from "react-facebook-login";
import axios from "axios";
import { Redirect } from "react-router-dom";
import Title from "./Title";
import GoogleSignInBtn from "./LoginGoogle";
// import jwtDecode from "jwt-decode";
import LoginFacebook from "./LoginFacebook";
const $ = window.$;

class Login extends Component {
  state = {
    email: "",
    toastColor: "#5cb85c",
    toastDescription: "",
    password: "",
    mobile: "",
    phone: "",
    OTP: "",
    hideNav: "",
    scanResult: "",
    baseUrl: localStorage.getItem("baseUrl"),
    parseAppId: localStorage.getItem("parseAppId"),
    loading: false,
    thirdpartyLoader: false,
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleTenant = (e, IncludedApps) => {
    e.preventDefault();
    localStorage.removeItem("showpopup");
    let newTenant = IncludedApps.filter((x) => {
      return x.tenentId === this.state.AppURL;
    });

    let extendedClass = JSON.parse(localStorage.getItem("Extand_Class"));
    let newExtendedCls =
      extendedClass.length > 1 &&
      extendedClass.filter((x) => {
        return x.TenantId.objectId === this.state.AppURL;
      });
    if (newExtendedCls.length) {
      localStorage.setItem("Extand_Class", JSON.stringify(newExtendedCls));
    }
    this.props.showTenantName(newTenant[0].tenentName);
    localStorage.setItem("TenetId", this.state.AppURL);
    localStorage.setItem("TenantName", newTenant[0].tenentName || "");
    $("#myModaluvs").modal("hide");
    let _appurl = `/${localStorage.getItem("pageType")}/${localStorage.getItem(
      "PageLanding"
    )}`;
    window.location.hash = _appurl;
  };

  resize = () => {
    let currentHideNav = window.innerWidth <= 760;
    if (currentHideNav !== this.state.hideNav) {
      this.setState({ hideNav: currentHideNav });
    }
  };

  setLocalIframe = (iframeUrl) => {
    try {
      let data = {
        accesstoken: localStorage.getItem("accesstoken"),
        baseUrl: localStorage.getItem("baseUrl"),
        parseAppId: localStorage.getItem("parseAppId"),
        extended_class: localStorage.getItem("extended_class"),
        extend_details: localStorage.getItem("Extand_Class"),
        userSettings: localStorage.getItem("userSettings"),
        username: localStorage.getItem("username"),
        _appName: localStorage.getItem("_appName"),
        // tenantId: localStorage.getItem("TenetId"),
        TenetId: localStorage.getItem("TenetId"),
      };
      let storage = JSON.stringify({
        key: "storage",
        method: "set",
        data: data,
      });
      var iframe = document.getElementById("def_iframe");
      iframe.contentWindow.postMessage(storage, "*");
      setTimeout(() => {
        window.location.hash = `/microapp/${iframeUrl}`;
      }, 4000);
    } catch (error) {
      console.log(error);
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    if (email && password) {
      try {
        this.setState({ loading: true });
        let baseUrl = localStorage.getItem("baseUrl");
        let parseAppId = localStorage.getItem("parseAppId");
        localStorage.setItem("appLogo", this.props.appInfo.applogo);
        localStorage.setItem("appName", this.props.appInfo.appname);
        Parse.serverURL = localStorage.getItem("baseUrl");
        Parse.initialize(localStorage.getItem("parseAppId"));
        // Pass the username and password to logIn function
        await Parse.User.logIn(email, password)
          .then(async (user) => {
            if (user) {
              let _user = user.toJSON();
              localStorage.setItem("UserInformation", JSON.stringify(_user));
              localStorage.setItem("userEmail", email);
              localStorage.setItem("username", _user.name);
              localStorage.setItem("accesstoken", _user.sessionToken);
              localStorage.setItem("scriptId", true);
              if (_user.ProfilePic) {
                localStorage.setItem("profileImg", _user.ProfilePic);
              } else {
                localStorage.setItem("profileImg", "");
              }

              // Check extended class user role and tenentId
              try {
                let userRoles = [];
                if (this.props.appInfo.settings) {
                  let userSettings = this.props.appInfo.settings;

                  //Get Current user roles
                  let url = `${baseUrl}functions/UserGroups`;
                  const headers = {
                    "Content-Type": "application/json",
                    "X-Parse-Application-Id": parseAppId,
                    sessionToken: _user.sessionToken,
                  };

                  let body = {
                    appname: localStorage.getItem("_appName"),
                  };
                  await axios
                    .post(url, JSON.stringify(body), { headers: headers })
                    .then((roles) => {
                      if (roles) {
                        userRoles = roles.data.result;
                        let _currentRole = "";
                        if (userRoles.length > 1) {
                          if (
                            userRoles[0] ===
                            `${localStorage.getItem("_appName")}_appeditor`
                          ) {
                            _currentRole = userRoles[1];
                          } else {
                            _currentRole = userRoles[0];
                          }
                        } else {
                          _currentRole = userRoles[0];
                        }
                        if (
                          _currentRole !==
                          `${localStorage.getItem("_appName")}_appeditor`
                        ) {
                          userSettings.forEach(async (element) => {
                            if (element.role === _currentRole) {
                              let _role = _currentRole.replace(
                                `${localStorage.getItem("_appName")}_`,
                                ""
                              );
                              localStorage.setItem("_user_role", _role);

                              if (element.enableCart) {
                                localStorage.setItem(
                                  "EnableCart",
                                  element.enableCart
                                );
                                this.props.setEnableCart(element.enableCart);
                              } else {
                                localStorage.removeItem("EnableCart");
                              }
                              // debugger;
                              // Get TenentID from Extendend Class
                              localStorage.setItem(
                                "extended_class",
                                element.extended_class
                              );
                              localStorage.setItem(
                                "userpointer",
                                element.userpointer
                              );

                              const extendedClass = Parse.Object.extend(
                                element.extended_class
                              );
                              let query = new Parse.Query(extendedClass);
                              query.equalTo("UserId", Parse.User.current());
                              query.include("TenantId");
                              await query.find().then(
                                (results) => {
                                  let tenentInfo = [];
                                  if (results) {
                                    let extendedInfo_stringify =
                                      JSON.stringify(results);
                                    debugger;
                                    localStorage.setItem(
                                      "Extand_Class",
                                      extendedInfo_stringify
                                    );
                                    let extendedInfo = JSON.parse(
                                      extendedInfo_stringify
                                    );
                                    if (extendedInfo.length > 1) {
                                      extendedInfo.forEach((x) => {
                                        if (x.TenantId) {
                                          let obj = {
                                            tenentId: x.TenantId.objectId,
                                            tenentName:
                                              x.TenantId.TenantName || "",
                                          };
                                          tenentInfo.push(obj);
                                        }
                                      });
                                      if (tenentInfo.length) {
                                        this.props.showTenantName(
                                          tenentInfo[0].tenentName || ""
                                        );
                                        localStorage.setItem(
                                          "TenantName",
                                          tenentInfo[0].tenentName || ""
                                        );
                                      }

                                      localStorage.setItem("showpopup", true);
                                      localStorage.setItem(
                                        "IncludedApps",
                                        JSON.stringify(tenentInfo)
                                      );

                                      localStorage.setItem(
                                        "PageLanding",
                                        element.pageId
                                      );
                                      localStorage.setItem(
                                        "defaultmenuid",
                                        element.menuId
                                      );
                                      localStorage.setItem(
                                        "pageType",
                                        element.pageType
                                      );
                                      this.setState({ loading: false });
                                      window.location.hash = `/login`;
                                    } else {
                                      extendedInfo.forEach((x) => {
                                        if (x.TenantId) {
                                          let obj = {
                                            tenentId: x.TenantId.objectId,
                                            tenentName:
                                              x.TenantId.TenantName || "",
                                          };
                                          localStorage.setItem(
                                            "TenetId",
                                            x.TenantId.objectId
                                          );
                                          tenentInfo.push(obj);
                                        }
                                      });
                                      if (tenentInfo.length) {
                                        this.props.showTenantName(
                                          tenentInfo[0].tenentName || ""
                                        );
                                        localStorage.setItem(
                                          "TenantName",
                                          tenentInfo[0].tenentName || ""
                                        );
                                      }
                                      localStorage.setItem(
                                        "PageLanding",
                                        element.pageId
                                      );
                                      localStorage.setItem(
                                        "defaultmenuid",
                                        element.menuId
                                      );
                                      localStorage.setItem(
                                        "pageType",
                                        element.pageType
                                      );
                                      this.setState({ loading: false });
                                      if (element.pageType === "microapp") {
                                        this.setLocalIframe(element.pageId);
                                      } else {
                                        window.location.hash = `/${element.pageType}/${element.pageId}`;
                                      }
                                    }
                                  } else {
                                    localStorage.setItem(
                                      "PageLanding",
                                      element.pageId
                                    );
                                    localStorage.setItem(
                                      "defaultmenuid",
                                      element.menuId
                                    );
                                    localStorage.setItem(
                                      "pageType",
                                      element.pageType
                                    );
                                    this.setState({ loading: false });
                                    if (element.pageType === "microapp") {
                                      this.setLocalIframe(element.pageId);
                                    } else {
                                      window.location.hash = `/${element.pageType}/${element.pageId}`;
                                    }
                                  }
                                },
                                (error) => {
                                  /*   alert(
                                  "You dont have access to this application."
                                ); */
                                  this.setState(
                                    {
                                      loading: false,
                                      toastColor: "#d9534f",
                                      toastDescription:
                                        "You dont have access to this application.",
                                    },
                                    () => {
                                      var x =
                                        document.getElementById("snackbar");
                                      x.className = "show";
                                      setTimeout(function () {
                                        x.className = x.className.replace(
                                          "show",
                                          ""
                                        );
                                      }, 5000);
                                      localStorage.setItem("accesstoken", null);
                                    }
                                  );

                                  console.error(
                                    "Error while fetching Follow",
                                    error
                                  );
                                }
                              );
                            }
                          });
                        } else {
                          this.setState(
                            {
                              loading: false,
                              toastColor: "#d9534f",
                              toastDescription: "User Role Not Found.",
                            },
                            () => {
                              var x = document.getElementById("snackbar");
                              x.className = "show";
                              setTimeout(function () {
                                x.className = x.className.replace("show", "");
                              }, 5000);
                            }
                          );
                        }
                      } else {
                        this.setState(
                          {
                            loading: false,
                            toastColor: "#d9534f",
                            toastDescription: "User Role Not Found.",
                          },
                          () => {
                            var x = document.getElementById("snackbar");
                            x.className = "show";
                            setTimeout(function () {
                              x.className = x.className.replace("show", "");
                            }, 5000);
                          }
                        );
                      }
                    })
                    .catch((err) => {
                      this.setState(
                        {
                          loading: false,
                          toastColor: "#d9534f",
                          toastDescription: `Does not have permissions to access this application.`,
                        },
                        () => {
                          var x = document.getElementById("snackbar");
                          x.className = "show";
                          setTimeout(function () {
                            x.className = x.className.replace("show", "");
                          }, 5000);
                        }
                      );
                    });
                }
              } catch (error) {
                // alert(`${error.message}`);
                this.setState(
                  {
                    loading: false,
                    toastColor: "#d9534f",
                    toastDescription: `${error.message}`,
                  },
                  () => {
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () {
                      x.className = x.className.replace("show", "");
                    }, 5000);
                  }
                );

                console.log(error);
              }
            }
          })
          .catch((error) => {
            // alert("Login failed: Invalid username or password.");

            this.setState(
              {
                loading: false,
                toastColor: "#d9534f",
                toastDescription: "Login failed: Invalid username or password.",
              },
              () => {
                var x = document.getElementById("snackbar");
                x.className = "show";
                setTimeout(function () {
                  x.className = x.className.replace("show", "");
                }, 5000);
              }
            );
            console.error("Error while logging in user", error);
          });
      } catch (error) {
        console.log(error.message);
        this.setState({ loading: false });
      }
    }
  };

  responseFacebook = async (response) => {
    let baseUrl = localStorage.getItem("baseUrl");
    let parseAppId = localStorage.getItem("parseAppId");
    Parse.serverURL = `${baseUrl}users`;
    Parse.initialize(parseAppId);
    var body = {
      username: response.name,
      authData: {
        facebook: {
          id: response.userID,
          access_token: response.accessToken,
          /*  "expiration_date": response.data_access_expiration_time */
        },
      },
    };
    let url = `${baseUrl}users`;
    const headers = {
      "Content-Type": "application/json",
      "X-Parse-Application-Id": parseAppId,
    };
    await axios.post(url, body, { headers: headers }).then((response1) => {
      localStorage.setItem("username", response1.data.name);
      localStorage.setItem("accesstoken", response1.data.sessionToken);
      localStorage.setItem("scriptId", true);
      if (localStorage.getItem("_appName") === "invoicetracker") {
        window.location.hash = "/knbn";
      } else {
        window.location.hash = "/dashboard";
      }
      //  window.location.hash = "/dashboard";
    });
  };

  setThirdpartyLoader = (value) => {
    this.setState({ thirdpartyLoader: value });
  };
  thirdpartyLoginfn = async (sessionToken) => {
    const baseUrl = localStorage.getItem("BaseUrl12");
    const parseAppId = localStorage.getItem("AppID12");
    const res = await axios.get(baseUrl + "users/me", {
      headers: {
        "X-Parse-Session-Token": sessionToken,
        "X-Parse-Application-Id": parseAppId,
      },
    });
    await Parse.User.become(sessionToken).then(() => {
      window.localStorage.setItem("accesstoken", sessionToken);
    });
    // console.log("me res ", res);
    if (res.data) {
      let _user = res.data;
      localStorage.setItem("UserInformation", JSON.stringify(_user));
      localStorage.setItem("userEmail", _user.email);
      localStorage.setItem("username", _user.name);
      localStorage.setItem("accesstoken", _user.sessionToken);
      localStorage.setItem("scriptId", true);
      if (_user.ProfilePic) {
        localStorage.setItem("profileImg", _user.ProfilePic);
      } else {
        localStorage.setItem("profileImg", "");
      }
      // Check extended class user role and tenentId
      try {
        let userRoles = [];
        if (this.props.appInfo.settings) {
          let userSettings = this.props.appInfo.settings;

          //Get Current user roles
          let url = `${baseUrl}functions/UserGroups`;
          const headers = {
            "Content-Type": "application/json",
            "X-Parse-Application-Id": parseAppId,
            sessionToken: _user.sessionToken,
          };

          let body = {
            appname: localStorage.getItem("_appName"),
          };
          await axios
            .post(url, JSON.stringify(body), { headers: headers })
            .then((roles) => {
              if (roles) {
                userRoles = roles.data.result;
                let _currentRole = "";
                if (userRoles.length > 1) {
                  if (
                    userRoles[0] ===
                    `${localStorage.getItem("_appName")}_appeditor`
                  ) {
                    _currentRole = userRoles[1];
                  } else {
                    _currentRole = userRoles[0];
                  }
                } else {
                  _currentRole = userRoles[0];
                }
                if (
                  _currentRole !==
                  `${localStorage.getItem("_appName")}_appeditor`
                ) {
                  userSettings.forEach(async (element) => {
                    if (element.role === _currentRole) {
                      let _role = _currentRole.replace(
                        `${localStorage.getItem("_appName")}_`,
                        ""
                      );
                      localStorage.setItem("_user_role", _role);

                      if (element.enableCart) {
                        localStorage.setItem("EnableCart", element.enableCart);
                        this.props.setEnableCart(element.enableCart);
                      } else {
                        localStorage.removeItem("EnableCart");
                      }
                      // debugger;
                      // Get TenentID from Extendend Class
                      localStorage.setItem(
                        "extended_class",
                        element.extended_class
                      );
                      localStorage.setItem("userpointer", element.userpointer);

                      const extendedClass = Parse.Object.extend(
                        element.extended_class
                      );
                      let query = new Parse.Query(extendedClass);
                      query.equalTo("UserId", Parse.User.current());
                      query.include("TenantId");
                      await query.find().then(
                        (results) => {
                          let tenentInfo = [];
                          if (results) {
                            let extendedInfo_stringify =
                              JSON.stringify(results);
                            debugger;
                            localStorage.setItem(
                              "Extand_Class",
                              extendedInfo_stringify
                            );
                            let extendedInfo = JSON.parse(
                              extendedInfo_stringify
                            );
                            if (extendedInfo.length > 1) {
                              extendedInfo.forEach((x) => {
                                if (x.TenantId) {
                                  let obj = {
                                    tenentId: x.TenantId.objectId,
                                    tenentName: x.TenantId.TenantName || "",
                                  };
                                  tenentInfo.push(obj);
                                }
                              });
                              if (tenentInfo.length) {
                                this.props.showTenantName(
                                  tenentInfo[0].tenentName || ""
                                );
                                localStorage.setItem(
                                  "TenantName",
                                  tenentInfo[0].tenentName || ""
                                );
                              }

                              localStorage.setItem("showpopup", true);
                              localStorage.setItem(
                                "IncludedApps",
                                JSON.stringify(tenentInfo)
                              );

                              localStorage.setItem(
                                "PageLanding",
                                element.pageId
                              );
                              localStorage.setItem(
                                "defaultmenuid",
                                element.menuId
                              );
                              localStorage.setItem(
                                "pageType",
                                element.pageType
                              );
                              this.setThirdpartyLoader(false)
                              this.setState({ loading: false });
                              window.location.hash = `/login`;
                            } else {
                              extendedInfo.forEach((x) => {
                                if (x.TenantId) {
                                  let obj = {
                                    tenentId: x.TenantId.objectId,
                                    tenentName: x.TenantId.TenantName || "",
                                  };
                                  localStorage.setItem(
                                    "TenetId",
                                    x.TenantId.objectId
                                  );
                                  tenentInfo.push(obj);
                                }
                              });
                              if (tenentInfo.length) {
                                this.props.showTenantName(
                                  tenentInfo[0].tenentName || ""
                                );
                                localStorage.setItem(
                                  "TenantName",
                                  tenentInfo[0].tenentName || ""
                                );
                              }
                              localStorage.setItem(
                                "PageLanding",
                                element.pageId
                              );
                              localStorage.setItem(
                                "defaultmenuid",
                                element.menuId
                              );
                              localStorage.setItem(
                                "pageType",
                                element.pageType
                              );
                              this.setThirdpartyLoader(false)

                              this.setState({ loading: false });
                              if (element.pageType === "microapp") {
                                this.setLocalIframe(element.pageId);
                              } else {
                                window.location.hash = `/${element.pageType}/${element.pageId}`;
                              }
                            }
                          } else {
                            localStorage.setItem("PageLanding", element.pageId);
                            localStorage.setItem(
                              "defaultmenuid",
                              element.menuId
                            );
                            localStorage.setItem("pageType", element.pageType);
                            this.setState({ loading: false });
                            this.setThirdpartyLoader(false)

                            if (element.pageType === "microapp") {
                              this.setLocalIframe(element.pageId);
                            } else {
                              window.location.hash = `/${element.pageType}/${element.pageId}`;
                            }
                          }
                        },
                        (error) => {
                          /*   alert(
                          "You dont have access to this application."
                        ); */
                                this.setThirdpartyLoader(false)

                          this.setState(
                            {
                              loading: false,
                              toastColor: "#d9534f",
                              toastDescription:
                                "You dont have access to this application.",
                            },
                            () => {
                              var x = document.getElementById("snackbar");
                              x.className = "show";
                              setTimeout(function () {
                                x.className = x.className.replace("show", "");
                              }, 5000);
                              localStorage.setItem("accesstoken", null);
                            }
                          );

                          console.error("Error while fetching Follow", error);
                        }
                      );
                    }
                  });
                } else {
                  this.setThirdpartyLoader(false)
                  this.setState(
                    {
                      loading: false,
                      toastColor: "#d9534f",
                      toastDescription: "User Role Not Found.",
                    },
                    () => {
                      var x = document.getElementById("snackbar");
                      x.className = "show";
                      setTimeout(function () {
                        x.className = x.className.replace("show", "");
                      }, 5000);
                    }
                  );
                }
              } else {
                this.setThirdpartyLoader(false)
                this.setState(
                  {
                    loading: false,
                    toastColor: "#d9534f",
                    toastDescription: "User Role Not Found.",
                  },
                  () => {
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () {
                      x.className = x.className.replace("show", "");
                    }, 5000);
                  }
                );
              }
            })
            .catch((err) => {
              this.setThirdpartyLoader(false)
              this.setState(
                {
                  loading: false,
                  toastColor: "#d9534f",
                  toastDescription: `Does not have permissions to access this application.`,
                },
                () => {
                  var x = document.getElementById("snackbar");
                  x.className = "show";
                  setTimeout(function () {
                    x.className = x.className.replace("show", "");
                  }, 5000);
                }
              );
            });
        }
      } catch (error) {
        // alert(`${error.message}`);
        this.setThirdpartyLoader(false)
        this.setState(
          {
            loading: false,
            toastColor: "#d9534f",
            toastDescription: `${error.message}`,
          },
          () => {
            var x = document.getElementById("snackbar");
            x.className = "show";
            setTimeout(function () {
              x.className = x.className.replace("show", "");
            }, 5000);
          }
        );

        console.log(error);
      }
    }
  };
  // responseGoogle = async (response) => {
  //   // localStorage.setItem('profileImg', response.profileObj.imageUrl)
  //   let baseUrl = localStorage.getItem("baseUrl");
  //   let parseAppId = localStorage.getItem("parseAppId");
  //   Parse.serverURL = `${baseUrl}`;
  //   Parse.initialize(parseAppId);
  //   console.log("response.credential ", response);

  //   const params = {
  //     Id: response.googleId,
  //     tokenId: response.tokenObj.id_token,
  //     Gmail: response.profileObj.email,
  //   };

  //   const userRes = await Parse.Cloud.run("googlesign", { params: params });
  //   console.log("userRes ", userRes);

  // };
  // responseGoogle = async (response) => {
  //   // localStorage.setItem('profileImg', response.profileObj.imageUrl)
  //   let baseUrl = localStorage.getItem("baseUrl");
  //   let parseAppId = localStorage.getItem("parseAppId");
  //   Parse.serverURL = `${baseUrl}users`;
  //   Parse.initialize(parseAppId);

  //   var body = {
  //     username: response.profileObj.email,
  //     authData: {
  //       google: {
  //         id: response.googleId,
  //         id_token: response.tokenObj.id_token,
  //       },
  //     },
  //   };
  //   let url = `${baseUrl}users`;
  //   const headers = {
  //     "Content-Type": "application/json",
  //     "X-Parse-Application-Id": parseAppId,
  //   };
  //   await axios.post(url, body, { headers: headers }).then((response) => {
  //     localStorage.setItem("username", response.data.name);
  //     localStorage.setItem("accesstoken", response.data.sessionToken);
  //     localStorage.setItem("scriptId", true);
  //     if (localStorage.getItem("_appName") === "invoicetracker") {
  //       window.location.hash = "/knbn";
  //     } else {
  //       window.location.hash = "/dashboard";
  //     }
  //     //window.location.hash = "/dashboard";
  //   });
  // };

  GetLoginData = async () => {
    try {
      Parse.serverURL = localStorage.getItem("baseUrl");
      Parse.initialize(localStorage.getItem("parseAppId"));
      await Parse.User.become(localStorage.getItem("accesstoken")).then(
        async (user) => {
          let _usss = user.toJSON();
          localStorage.setItem("UserInformation", JSON.stringify(_usss));
          localStorage.setItem("username", _usss.name);
          localStorage.setItem("accesstoken", _usss.sessionToken);
          localStorage.setItem("scriptId", true);
          if (_usss.ProfilePic) {
            localStorage.setItem("profileImg", _usss.ProfilePic);
          } else {
            localStorage.setItem("profileImg", "");
          }
          try {
            let userRoles = [];

            if (localStorage.getItem("userSettings")) {
              let userSettings = localStorage.getItem("userSettings");

              //Get Current user roles
              let url = `${localStorage.getItem(
                "baseUrl"
              )}functions/UserGroups`;
              const headers1 = {
                "Content-Type": "application/json",
                "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
                sessionToken: _usss.sessionToken,
              };

              let body = {
                appname: localStorage.getItem("_appName"),
              };

              await axios
                .post(url, JSON.stringify(body), { headers: headers1 })
                .then((roles) => {
                  if (roles) {
                    userRoles = roles.data.result;
                    let _currentRole = "";
                    if (userRoles.length > 1) {
                      if (
                        userRoles[0] ===
                        `${localStorage.getItem("_appName")}_appeditor`
                      ) {
                        _currentRole = userRoles[1];
                      } else {
                        _currentRole = userRoles[0];
                      }
                    } else {
                      _currentRole = userRoles[0];
                    }
                    let SettingsUser = JSON.parse(userSettings);
                    SettingsUser.forEach(async (element) => {
                      if (element.role === _currentRole) {
                        let _role = _currentRole.replace(
                          `${localStorage.getItem("_appName")}_`,
                          ""
                        );
                        localStorage.setItem("_user_role", _role);

                        if (element.enableCart) {
                          localStorage.setItem(
                            "EnableCart",
                            element.enableCart
                          );
                          this.props.setEnableCart(element.enableCart);
                        } else {
                          localStorage.removeItem("EnableCart");
                        }
                        // Get TenentID from Extendend Class
                        localStorage.setItem(
                          "extended_class",
                          element.extended_class
                        );

                        const extendedClass = Parse.Object.extend(
                          element.extended_class
                        );
                        let query = new Parse.Query(extendedClass);
                        query.equalTo("UserId", Parse.User.current());
                        query.include("TenantId");
                        await query.find().then(
                          (results) => {
                            let tenentInfo = [];
                            if (results) {
                              let extendedInfo_stringify =
                                JSON.stringify(results);
                              let extendedInfo = JSON.parse(
                                extendedInfo_stringify
                              );
                              if (extendedInfo.length > 1) {
                                extendedInfo.forEach((x) => {
                                  if (x.TenantId) {
                                    let obj = {
                                      tenentId: x.TenantId.objectId,
                                      tenentName: x.TenantId.TenantName || "",
                                    };
                                    tenentInfo.push(obj);
                                  }
                                });

                                localStorage.setItem("showpopup", true);
                                localStorage.setItem(
                                  "IncludedApps",
                                  JSON.stringify(tenentInfo)
                                );

                                localStorage.setItem(
                                  "PageLanding",
                                  element.pageId
                                );
                                localStorage.setItem(
                                  "defaultmenuid",
                                  element.menuId
                                );
                                localStorage.setItem(
                                  "pageType",
                                  element.pageType
                                );
                                window.location.hash = `/login`;
                              } else {
                                extendedInfo.forEach((x) => {
                                  if (x.TenantId) {
                                    let obj = {
                                      tenentId: x.TenantId.objectId,
                                      tenentName: x.TenantId.TenantName || "",
                                    };
                                    localStorage.setItem(
                                      "TenetId",
                                      x.TenantId.objectId
                                    );
                                    tenentInfo.push(obj);
                                  }
                                });
                                localStorage.setItem(
                                  "PageLanding",
                                  element.pageId
                                );
                                localStorage.setItem(
                                  "defaultmenuid",
                                  element.menuId
                                );
                                localStorage.setItem(
                                  "pageType",
                                  element.pageType
                                );
                                localStorage.removeItem("SwitcherApp");
                                if (element.pageType === "microapp") {
                                  this.setLocalIframe(element.pageId);
                                } else {
                                  window.location.hash = `/${element.pageType}/${element.pageId}`;
                                }
                              }
                            } else {
                              localStorage.setItem(
                                "PageLanding",
                                element.pageId
                              );
                              localStorage.setItem(
                                "defaultmenuid",
                                element.menuId
                              );
                              localStorage.setItem(
                                "pageType",
                                element.pageType
                              );
                              localStorage.removeItem("SwitcherApp");
                              if (element.pageType === "microapp") {
                                this.setLocalIframe(element.pageId);
                              } else {
                                window.location.hash = `/${element.pageType}/${element.pageId}`;
                              }
                            }
                          },
                          (error) => {
                            //  alert("You dont have access to this application.");
                            this.setState(
                              {
                                loading: false,
                                toastColor: "#d9534f",
                                toastDescription:
                                  "You don`t have access to this application.",
                              },
                              () => {
                                var x = document.getElementById("snackbar");
                                x.className = "show";
                                setTimeout(function () {
                                  x.className = x.className.replace("show", "");
                                }, 5000);
                                localStorage.setItem("accesstoken", null);
                                localStorage.removeItem("SwitcherApp");
                              }
                            );

                            console.error("Error while fetching Follow", error);
                          }
                        );
                      }
                    });
                  } else {
                    console.log("User Role Not Found.");
                    localStorage.removeItem("SwitcherApp");
                  }
                })
                .catch((err) => {
                  $("#RoleRequestModal").modal("show");
                  localStorage.removeItem("SwitcherApp");
                });
            }
          } catch (error) {
            localStorage.removeItem("SwitcherApp");
          }
        }
      );
    } catch (error) {}
  };

  sendAddRoleRequest = (e) => {
    try {
      e.preventDefault();
      let _user = JSON.parse(localStorage.getItem("UserInformation"));
      Parse.serverURL = localStorage.getItem("baseUrl");
      Parse.initialize(localStorage.getItem("parseAppId"));
      const role_req = Parse.Object.extend("partners_Request");
      const myNewObject = new role_req();
      myNewObject.set("AppName", localStorage.getItem("_appName"));
      myNewObject.set("Status", "Pending");
      myNewObject.set("UserId", {
        __type: "Pointer",
        className: "_User",
        objectId: _user.objectId,
      });
      myNewObject.set("TenetId", {
        __type: "Pointer",
        className: "partners_Tenant",
        objectId: localStorage.getItem("TenetId"),
      });
      /*  myNewObject.set("AppId", {
        __type: "Pointer",
        className: "elearning_BuildConfig",
        objectId: localStorage.getItem("TenetId")
      }); */
      myNewObject
        .save()
        .then((result) => {
          if (result) {
            alert("Request sended successfully.");
            $("#RoleRequestModal").modal("hide");
          }
        })
        .catch((err) => {
          alert(err.message);
          $("#RoleRequestModal").modal("hide");
        });
    } catch (error) {
      alert(error.message);
      $("#RoleRequestModal").modal("hide");
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    if (
      localStorage.getItem("accesstoken") &&
      localStorage.getItem("SwitcherApp")
    ) {
      this.GetLoginData();
    }
    this.props.fetchAppInfo(
      localStorage.getItem("domain"),
      localStorage.getItem("BaseUrl12"),
      localStorage.getItem("AppID12")
    );
    this.resize();
  }

  //send OTP function
  SendOtp = async (e) => {
    e.preventDefault();

    this.setState({ loading: true, mobile: this.state.phone });
    try {
      const { baseUrl, parseAppId, mobile } = this.state;
      let url = `${localStorage.getItem("baseUrl")}functions/SendOTP/`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
      };
      let body = {
        phone: this.state.phone.toString(),
        appId: {
          __type: "Pointer",
          className: "w_appinfo",
          objectId: localStorage.getItem("_app_objectId"),
        },
      };
      let Otp = await axios.post(url, body, { headers: headers });

      if (Otp) {
        this.setState({
          ["ReqOtp"]: Otp.data.result,
          loading: false,
          ["EnterOTP"]: true,
          ["VisibleOTP"]: true,
        });
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  //verify OTP
  VerifyOTP = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { baseUrl, parseAppId, mobile, OTP } = this.state;
    if (Number(OTP) === Number(this.state["ReqOtp"])) {
      try {
        let url = `${localStorage.getItem("baseUrl")}functions/Auth/`;
        const headers = {
          "Content-Type": "application/json",
          "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
        };
        let body = {
          phone: mobile.toString(),
        };
        let user = await axios.post(url, body, { headers: headers });
        if (user) {
          let _user = user.data.result;

          Parse.User.become(_user.sessionToken)
            .then(async (user) => {
              let _usss = user.toJSON();

              localStorage.setItem("UserInformation", JSON.stringify(_usss));
              // localStorage.setItem("userEmail", email);
              localStorage.setItem("username", _usss.name);
              localStorage.setItem("accesstoken", _usss.sessionToken);
              localStorage.setItem("scriptId", true);

              if (_user.ProfilePic) {
                localStorage.setItem("profileImg", _usss.ProfilePic);
              } else {
                localStorage.setItem("profileImg", "");
              }

              // Check extended class user role and tenentId
              try {
                let userRoles = [];
                if (this.props.appInfo.settings) {
                  let userSettings = this.props.appInfo.settings;

                  //Get Current user roles
                  let url = `${localStorage.getItem(
                    "baseUrl"
                  )}functions/UserGroups`;
                  const headers1 = {
                    "Content-Type": "application/json",
                    "X-Parse-Application-Id":
                      localStorage.getItem("parseAppId"),
                    sessionToken: _user.sessionToken,
                  };

                  let body = {
                    appname: localStorage.getItem("_appName"),
                  };
                  await axios
                    .post(url, JSON.stringify(body), { headers: headers1 })
                    .then((roles) => {
                      if (roles) {
                        userRoles = roles.data.result;
                        let _currentRole = "";
                        if (userRoles.length > 1) {
                          if (
                            userRoles[0] ===
                            `${localStorage.getItem("_appName")}_appeditor`
                          ) {
                            _currentRole = userRoles[1];
                          } else {
                            _currentRole = userRoles[0];
                          }
                        } else {
                          _currentRole = userRoles[0];
                        }

                        userSettings.forEach(async (element) => {
                          if (element.role === _currentRole) {
                            let _role = _currentRole.replace(
                              `${localStorage.getItem("_appName")}_`,
                              ""
                            );
                            localStorage.setItem("_user_role", _role);

                            if (element.enableCart) {
                              localStorage.setItem(
                                "EnableCart",
                                element.enableCart
                              );
                              this.props.setEnableCart(element.enableCart);
                            } else {
                              localStorage.removeItem("EnableCart");
                            }
                            // Get TenentID from Extendend Class
                            localStorage.setItem(
                              "extended_class",
                              element.extended_class
                            );
                            localStorage.setItem(
                              "userpointer",
                              element.userpointer
                            );

                            const extendedClass = Parse.Object.extend(
                              element.extended_class
                            );
                            let query = new Parse.Query(extendedClass);
                            query.equalTo("UserId", Parse.User.current());
                            query.include("TenantId");
                            await query.find().then(
                              (results) => {
                                let tenentInfo = [];
                                if (results) {
                                  let extendedInfo_stringify =
                                    JSON.stringify(results);
                                  let extendedInfo = JSON.parse(
                                    extendedInfo_stringify
                                  );
                                  if (extendedInfo.length > 1) {
                                    extendedInfo.forEach((x) => {
                                      if (x.TenantId) {
                                        let obj = {
                                          tenentId: x.TenantId.objectId,
                                          tenentName:
                                            x.TenantId.TenantName || "",
                                        };
                                        tenentInfo.push(obj);
                                      }
                                    });

                                    localStorage.setItem("showpopup", true);
                                    localStorage.setItem(
                                      "IncludedApps",
                                      JSON.stringify(tenentInfo)
                                    );

                                    localStorage.setItem(
                                      "PageLanding",
                                      element.pageId
                                    );
                                    localStorage.setItem(
                                      "defaultmenuid",
                                      element.menuId
                                    );
                                    localStorage.setItem(
                                      "pageType",
                                      element.pageType
                                    );
                                    this.setState({ loading: false });
                                    window.location.hash = `/login`;
                                  } else {
                                    extendedInfo.forEach((x) => {
                                      if (x.TenantId) {
                                        let obj = {
                                          tenentId: x.TenantId.objectId,
                                          tenentName:
                                            x.TenantId.TenantName || "",
                                        };
                                        localStorage.setItem(
                                          "TenetId",
                                          x.TenantId.objectId
                                        );
                                        tenentInfo.push(obj);
                                      }
                                    });
                                    localStorage.setItem(
                                      "PageLanding",
                                      element.pageId
                                    );
                                    localStorage.setItem(
                                      "defaultmenuid",
                                      element.menuId
                                    );
                                    localStorage.setItem(
                                      "pageType",
                                      element.pageType
                                    );
                                    this.setState({ loading: false });
                                    if (element.pageType === "microapp") {
                                      this.setLocalIframe(element.pageId);
                                    } else {
                                      window.location.hash = `/${element.pageType}/${element.pageId}`;
                                    }
                                  }
                                } else {
                                  localStorage.setItem(
                                    "PageLanding",
                                    element.pageId
                                  );
                                  localStorage.setItem(
                                    "defaultmenuid",
                                    element.menuId
                                  );
                                  localStorage.setItem(
                                    "pageType",
                                    element.pageType
                                  );
                                  this.setState({ loading: false });
                                  if (element.pageType === "microapp") {
                                    this.setLocalIframe(element.pageId);
                                  } else {
                                    window.location.hash = `/${element.pageType}/${element.pageId}`;
                                  }
                                }
                              },
                              (error) => {
                                /*   alert(
                                  "You dont have access to this application."
                                ); */
                                this.setState(
                                  {
                                    loading: false,
                                    toastColor: "#d9534f",
                                    toastDescription: `"You dont have access to this application."`,
                                  },
                                  () => {
                                    var x = document.getElementById("snackbar");
                                    x.className = "show";
                                    setTimeout(function () {
                                      x.className = x.className.replace(
                                        "show",
                                        ""
                                      );
                                    }, 5000);
                                    localStorage.setItem("accesstoken", null);
                                  }
                                );
                              }
                            );
                          }
                        });
                      } else {
                        this.setState({ loading: false });
                        console.log("User Role Not Found.");
                      }
                    });
                }
              } catch (error) {
                this.setState(
                  {
                    loading: false,
                    toastColor: "#d9534f",
                    toastDescription: `${error.message}`,
                  },
                  () => {
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () {
                      x.className = x.className.replace("show", "");
                    }, 5000);
                  }
                );

                // alert(`${error.message}`);

                console.log(error);
              }
            })
            .catch((error) => {});
        }
      } catch (error) {
        // alert("something went wrong.");
        this.setState(
          {
            loading: false,
            toastColor: "#d9534f",
            toastDescription: "something went wrong.",
          },
          () => {
            var x = document.getElementById("snackbar");
            x.className = "show";
            setTimeout(function () {
              x.className = x.className.replace("show", "");
            }, 5000);
          }
        );
        //this.setState({ loading: false });
      }
    } else {
      // alert("The OTP entered is incorrect.");
      this.setState(
        {
          loading: false,
          toastColor: "#d9534f",
          toastDescription: "The OTP entered is incorrect.",
        },
        () => {
          var x = document.getElementById("snackbar");
          x.className = "show";
          setTimeout(function () {
            x.className = x.className.replace("show", "");
          }, 5000);
        }
      );
      // this.setState({ loading: false });
    }
  };

  render() {
    let IncludedApps = null;
    let logintype =
      this.props.appInfo.loginType && this.props.appInfo.loginType;
    if (localStorage.getItem("showpopup")) {
      if (localStorage.getItem("IncludedApps")) {
        IncludedApps = JSON.parse(localStorage.getItem("IncludedApps"));
      }
      $("#myModaluvs").modal("show");
    } else {
      if (
        localStorage.getItem("accesstoken") &&
        localStorage.getItem("pageType")
      ) {
        if (localStorage.getItem("pageType") === "microapp") {
          this.setLocalIframe(localStorage.getItem("PageLanding"));
        } else {
          let _redirect = `/${localStorage.getItem(
            "pageType"
          )}/${localStorage.getItem("PageLanding")}`;
          return <Redirect to={_redirect} />;
        }
      }
    }
    // console.log(localStorage.getItem("accesstoken"));

    const { email, password, mobile, EnterOTP, OTP } = this.state;
    let image = this.props.appInfo.applogo;
    let settings = this.props.appInfo.settings || undefined;
    return (
      <React.Fragment>
        {settings &&
          settings.map((x) => {
            return x.pageType === "microapp" ? (
              <iframe
                key={x.pageId}
                id="def_iframe"
                src={x.pageId}
                height="0px"
                width="0px"
              />
            ) : null;
          })}

        <Title title={"Login Page"} />

        {this.props.isloginVisible && this.props.isloginVisible ? (
          <React.Fragment>
            <div className="container GTRY">
              <div className="main_head">
                <div className="main-logo">
                  {this.state.hideNav ? (
                    <img src={image} width="100%" />
                  ) : (
                    <img src={image} width="100%" />
                  )}
                </div>
              </div>
              {logintype === "mobile-otp" ? (
                !EnterOTP ? (
                  <form>
                    <div className="row">
                      <div className="col-sm-6 KLO">
                        <h2>Welcome Back !</h2>
                        <span className="KNLO">
                          We will send you a One Time Password on your phone
                          number
                        </span>
                        <div className="card card-box">
                          <div className="card-body">
                            {/* <label>Phone Number</label> */}
                            <PhoneInput
                              country={"in"}
                              value={this.state.phone}
                              onChange={(phone) => this.setState({ phone })}
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: true,
                              }}
                            />
                            {/*   <input
                            type="number"
                            className="form-control"
                            name="mobile"
                            value={mobile}
                            onChange={this.handleChange}
                          /> */}
                            <br />
                          </div>
                        </div>
                        <div
                          className="col-md-6 col-sm-6"
                          style={
                            this.state.hideNav
                              ? {
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }
                              : {}
                          }
                        >
                          {this.state.hideNav ? (
                            this.state.loading ? (
                              <button
                                className="btn btn-info login-btn"
                                type="button"
                                style={{ marginBottom: "4px", width: "210px" }}
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Loading...
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-info login-btn"
                                onClick={(e) => this.SendOtp(e)}
                                style={{ marginBottom: "4px", width: "210px" }}
                              >
                                Send OTP
                              </button>
                            )
                          ) : this.state.loading ? (
                            <button
                              className="btn btn-info login-btn"
                              type="button"
                              style={{ marginBottom: "4px", width: "210px" }}
                              disabled
                            >
                              <span
                                className="spinner-border spinner-border-sm "
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Loading...
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={(e) => this.SendOtp(e)}
                              style={{ marginBottom: "4px", width: "210px" }}
                              className="btn btn-info login-btn"
                            >
                              Send OTP
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 KLO1">
                        <img
                          src={localStorage.getItem("branding_image")}
                          width="100%"
                        />
                        {/*  <img src="./assets/img/login_img.svg" width="100%" /> */}
                      </div>
                    </div>
                  </form>
                ) : (
                  <form>
                    <div className="row">
                      <div className="col-sm-6 KLO">
                        <h2>Welcome Back !</h2>
                        <span className="KNLO">You will get a OTP via SMS</span>
                        <div className="card card-box">
                          <div className="card-body">
                            <label>Enter OTP</label>
                            <input
                              type="number"
                              className="form-control"
                              name="OTP"
                              value={OTP}
                              min="4"
                              max="4"
                              onChange={this.handleChange}
                            />

                            <br />
                          </div>
                        </div>
                        <div
                          className="col-md-6 col-sm-6"
                          style={
                            this.state.hideNav ? { textAlign: "center" } : {}
                          }
                        >
                          {this.state.hideNav ? (
                            this.state.loading ? (
                              <button
                                className="btn btn-info btn-block"
                                type="button"
                                style={{ marginBottom: "4px", width: "210px" }}
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Loading...
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-info btn-block"
                                onClick={(e) => this.VerifyOTP(e)}
                                style={{ marginBottom: "4px", width: "210px" }}
                              >
                                Verify
                              </button>
                            )
                          ) : this.state.loading ? (
                            <button
                              className="btn btn-info btn-block"
                              type="button"
                              style={{ marginBottom: "4px", width: "210px" }}
                              disabled
                            >
                              <span
                                className="spinner-border spinner-border-sm "
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Loading...
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={(e) => this.VerifyOTP(e)}
                              className="btn btn-info login-btn"
                            >
                              Verify
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 KLO1">
                        <img
                          src={localStorage.getItem("branding_image")}
                          width="100%"
                        />
                      </div>
                    </div>
                  </form>
                )
              ) : (
                <div>
                  <div className="row">
                    <div className="col-sm-6 KLO">
                      <form onSubmit={this.handleSubmit}>
                        <h2>Welcome Back !</h2>
                        <span className="KNLO">Login to your account</span>
                        <div className="card card-box">
                          <div className="card-body">
                            <label>Username</label>
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              value={email}
                              onChange={this.handleChange}
                              required
                            />
                            <br />
                            <label>Password</label>
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              value={password}
                              onChange={this.handleChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="SYTU">
                          <div className="col-sm-6">
                            <label className="form-check-label">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                value=""
                              />
                              Remember Password
                            </label>
                          </div>
                          <div className="col-sm-6">
                            <span className="MLKI">
                              {/* <a href="/ForgotPassword" >Forgot Password ?</a></span> */}
                              <a
                                onClick={() => {
                                  window.location.hash = "/ForgotPassword";
                                }}
                              >
                                Forgot Password ?
                              </a>
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-md-6 col-sm-6"
                            style={
                              this.state.hideNav
                                ? {
                                    textAlign: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }
                                : {}
                            }
                          >
                            {this.state.hideNav ? (
                              this.state.loading ? (
                                <button
                                  className="btn btn-info login-btn"
                                  type="button"
                                  style={{
                                    marginBottom: "4px",
                                    width: "210px",
                                  }}
                                  disabled
                                >
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  Loading...
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-info"
                                  style={{
                                    marginBottom: "4px",
                                    width: "210px",
                                  }}
                                >
                                  Login
                                </button>
                              )
                            ) : this.state.loading ? (
                              <button
                                className="btn btn-info login-btn"
                                type="button"
                                disabled
                              >
                                <span
                                  className="spinner-border spinner-border-sm "
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Loading...
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className="btn btn-info login-btn"
                              >
                                Login
                              </button>
                            )}
                          </div>
                          <div
                            className="col-md-6 col-sm-6"
                            style={
                              this.state.hideNav ? { textAlign: "center" } : {}
                            }
                          >
                            {this.props.appInfo &&
                            this.props.appInfo.accessType ===
                              "private" ? null : this.state.hideNav ? (
                              <button
                                onClick={() => {
                                  let { history } = this.props;
                                  history.push({
                                    pathname: "signup",
                                  });
                                }}
                                className="btn btn-reg "
                                style={{ marginBottom: "4px" }}
                              >
                                Create Account
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  let { history } = this.props;
                                  history.push({
                                    pathname: "signup",
                                  });
                                }}
                                className="btn btn-reg btn-block"
                              >
                                Create Account
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                      <br />
                      <div
                        className="row"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <hr
                          className={this.state.hideNav ? "" : "col-sm-4"}
                          style={{ color: "grey" }}
                        />
                        <span
                          className={this.state.hideNav ? "" : "col-sm-1"}
                          style={{ color: "grey" }}
                        >
                          {this.state.hideNav
                            ? "------------------------------- OR -------------------------------"
                            : "OR"}
                        </span>
                        <hr
                          className={this.state.hideNav ? "" : "col-sm-4"}
                          style={{ color: "grey" }}
                        />
                      </div>
                      <br />
                      <div
                        className="row"
                        style={{
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {this.props.appInfo.fbAppId &&
                        this.props.appInfo.fbAppId !== "" ? (
                          <LoginFacebook
                            thirdpartyLoginfn={this.thirdpartyLoginfn}
                            thirdpartyLoader={this.state.thirdpartyLoader}
                            setThirdpartyLoader={this.setThirdpartyLoader}
                          />
                        ) : null}
                      </div>
                      <div style={{ margin: "10px 0" }}></div>

                      <div
                        className="row"
                        style={{
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {this.props.appInfo.googleClietId &&
                        this.props.appInfo.googleClietId !== "" ? (
                          <GoogleSignInBtn
                            thirdpartyLoginfn={this.thirdpartyLoginfn}
                            thirdpartyLoader={this.state.thirdpartyLoader}
                            setThirdpartyLoader={this.setThirdpartyLoader}
                          />
                        ) : null}
                      </div>
                    </div>

                    <div className="col-sm-6 KLO1">
                      <img
                        src={
                          localStorage.getItem("branding_image")
                            ? localStorage.getItem("branding_image")
                            : "./assets/img/login_img.svg"
                        }
                        width="100%"
                      />
                    </div>
                    {/* <div className="col-md-7 col-sm-7 offset-md-5 offset-sm-5 MKUY">
                      {this.props.appInfo.fbAppId &&
                      this.props.appInfo.fbAppId !== "" ? (
                        <FacebookLogin
                          appId="1155800517950502"
                          fields="name,email,picture"
                          callback={this.responseFacebook}
                          cssClass="btn btn-info btn-md btn-fb"
                          icon="fa fa-facebook-f"
                        />
                      ) : null}
                      &nbsp;
                      {this.props.appInfo.googleClietId &&
                      this.props.appInfo.googleClietId !== "" ? (
                        <GoogleSignInBtn googleLoginfn={this.googleLoginfn} />
                      ) : // <GoogleLogin
                      //   clientId="918704711393-thhv3re2pfqvve76tgb86ulu1tlpssrk.apps.googleusercontent.com"
                      //   buttonText="LOGIN WITH GOOGLE"
                      //   onSuccess={this.responseGoogle}
                      //   onFailure={this.responseGoogle}
                      // />
                      null}
                    </div> */}
                  </div>
                </div>
              )}
            </div>

            <div id="RoleRequestModal" className="modal fade">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">
                      Request for Access Application
                    </h4>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        $("#RoleRequestModal").modal("hide");
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body">
                    <p>{`Are you sure to send request for access ${localStorage.getItem(
                      "appTitle"
                    )} application.`}</p>
                    <br />
                    <button
                      className="btn btn-info pull-right"
                      onClick={this.sendAddRoleRequest}
                    >
                      Send Request
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div id="myModaluvs" className="modal fade">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Select Organization</h4>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        localStorage.removeItem("showpopup");
                        $("#myModaluvs").modal("hide");
                        let _redirect = `/${localStorage.getItem(
                          "pageType"
                        )}/${localStorage.getItem("PageLanding")}`;
                        window.location.hash = _redirect;
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-hidden="true"
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="uvsradio">
                      {IncludedApps &&
                        IncludedApps.map((x, i) => (
                          <div key={i} className="uvsboxpop">
                            <label className="uvstablepop1">
                              <table className="uvstablepop">
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td>
                                      <input
                                        type="radio"
                                        name="optradio"
                                        value={x.tenentId}
                                        onChange={(e) => {
                                          debugger;
                                          this.setState({
                                            ["AppURL"]: e.target.value,
                                          });
                                        }}
                                      />
                                    </td>
                                    <td>{/*  <img src={""} /> */}</td>
                                    <td>{x.tenentName}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </label>
                          </div>
                        ))}{" "}
                      <button
                        type="button"
                        onClick={(e) => {
                          this.handleTenant(e, IncludedApps);
                        }}
                        className="btn btn-info btn-block"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="snackbar"
              style={{ backgroundColor: this.state.toastColor }}
            >
              {this.state.toastDescription}
            </div>
          </React.Fragment>
        ) : (
          <div
            style={{
              position: "fixed",
              fontSize: "50px",
              color: "#3ac9d6",
              top: "50%",
              left: "45%",
            }}
            className="loader-37"
          ></div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  if (Object.keys(state.appInfo).length !== 0) {
    return { appInfo: state.appInfo, isloginVisible: true };
  } else {
    return { appInfo: state.appInfo, isloginVisible: false };
  }
};

export default connect(mapStateToProps, {
  fetchAppInfo,
  setEnableCart,
  showTenantName,
})(Login);
