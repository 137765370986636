import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { onChangeLevel1Dropdown } from "../actions";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Parse from "parse";
import LabelField from "./LabelField";
import AppendFormInForm from "../components/AppendFormInForm.js";
import "../components/customModal.css";

function ComboBox(props) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, SetOptions] = useState([]);
  const [SearchPtr, setSearchPtr] = useState(true);
  const [toastColor] = useState("#d9534f");
  const [toastDescription, setToastDescription] = useState("");
  const [newDetails, setNewDetails] = useState({});
  const [isModal, setIsModal] = useState(false);
  const $ = window.$;

  // for insert new records
  const getSuggestion = async (formdata) => {
    try {
      let url = "";
      if (typeof formdata === "object") {
        url = `${localStorage.getItem("baseUrl")}classes/${
          formdata.className
        }/${formdata.objectId}`;
      } else {
        url = `${localStorage.getItem("baseUrl")}classes/${
          props.schema.class
        }?where={"${props.schema.displayKey}":"${formdata}"}`;
      }
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
        "X-Parse-Session-Token": localStorage.getItem("accesstoken"),
      };
      await axios
        .get(url, { headers: headers })
        .then((res) => {
          if (res) {
            if (res) {
              let obj = {
                value: res.data[props.schema.valueKey],
                label: res.data[props.schema.displayKey],
              };
              setSelectedOption(obj);
              props.onChangeLevel1Dropdown(obj.value, props.name);
            }
          }
        })
        .catch((error) => {
          setToastDescription(error.message);
          var x = document.getElementById("ComboBoxErr");
          x.className = "show";
          setTimeout(function () {
            x.className = x.className.replace("show", "");
          }, 5000);
        });
    } catch (error) {
      setToastDescription(error.message);
      var x = document.getElementById("ComboBoxErr");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 5000);
    }
  };
  const loadOptions = async (inputbox, callback) => {
    try {
      Parse.serverURL = localStorage.getItem("baseUrl");
      Parse.initialize(localStorage.getItem("parseAppId"));
      const currentUser = Parse.User.current();
      let extendedClass =
        localStorage.getItem("Extand_Class") &&
        JSON.parse(localStorage.getItem("Extand_Class"));

      var reg = /(\#.*?\#)/gi;
      let str = props.schema.query;
      let str1 = str.match(reg);

      if (props.schema.defaultQuery && !inputbox) {
        str = props.schema.defaultQuery;
      }

      if (str1.length > 1) {
        if (str.includes("#queryString#")) {
          str = str.replace("#queryString#", inputbox);
        }
        str1 = str1.filter((x) => x !== "#queryString#");
        str1 = str1.join();
        str1 = str1.substring(1, str1.length - 1);
        str1 = str1.split(".");
        if (str1.length > 1) {
          str = str.replace(reg, extendedClass[0][str1[0]][str1[1]]);
        } else {
          str = str.replace(reg, extendedClass[0][str1[0]]);
        }
      }
      if (str.includes("#userId#")) {
        str = str.replace("#userId#", currentUser.id);
      }

      if (extendedClass && str.includes("#TenantId#")) {
        str = str.replace("#TenantId#", extendedClass[0].TenantId.objectId);
      }
      var test = "";
      if (props.formData) {
        test = str.replace(reg, inputbox);
      } else {
        test = str.replace(reg, inputbox);
      }
      let url = `${localStorage.getItem("baseUrl")}classes/${
        props.schema.class
      }?${test}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
        "X-Parse-Session-Token": localStorage.getItem("accesstoken"),
      };
      let searchResult = await axios.get(url, { headers: headers });
      if (searchResult) {
        let _otpArr = [];
        searchResult.data.results &&
          searchResult.data.results.forEach((element) => {
            let obj = {
              value: element[props.schema.valueKey],
              label: element[props.schema.displayKey],
            };
            _otpArr.push(obj);
          });
        SetOptions(_otpArr);
        if (props.schema.defaultQuery && !inputbox) {
          setTimeout(() => {
            callback(_otpArr);
          }, 1000);
        } else {
          callback(_otpArr);
        }
      }
    } catch (error) {
      setToastDescription(error.message);
      var x = document.getElementById("ComboBoxErr");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
      }, 5000);
    }
  };

  useEffect(() => {
    if (props.formData && SearchPtr) {
      getSuggestion(props.formData);

      setSelectedOption(null);
      if (typeof props.formData === "object") {
        props.onChangeLevel1Dropdown(props.formData.objectId, props.name);
      } else {
        props.onChangeLevel1Dropdown(props.formData, props.name);
      }
    } else {
      props.onChangeLevel1Dropdown("", props.name);
    }

    // eslint-disable-next-line
  }, [props.formData]);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSearchPtr(false);
    if (props.id && props.filterCond) {
      props.onChange(selectedOption.value, props.id, props.filterCond);
    } else {
      props.onChange(selectedOption.value);
    }
    props.onChangeLevel1Dropdown(selectedOption.value, props.name);
  };
  const handleModalCloseClick = () => {
    setIsModal(false);
    $("#autoSuggestModal" + props.schema.quickAddFormId).modal("hide");
  };

  // `handleNewDetails` is used to show new detail added from quick form plus btn and set it as selected options
  const handleNewDetails = (data) => {
    // console.log("data ", data);
    setSelectedOption(data);
    setNewDetails(data);
    handleChange(data);
  };
  return (
    <React.Fragment>
      <LabelField
        Title={props.schema.title}
        Name={props.name}
        Required={props.required}
        HelpBody={props.schema.helpbody}
        HelpLink={props.schema.helplink}
      />
      <div style={{ display: "flex", gap: 5 }}>
        <div style={{ flexWrap: "wrap", width: "100%" }}>
          <AsyncSelect
            placeholder={props.schema.placeholder}
            value={selectedOption}
            onChange={handleChange}
            /*  options={options} */
            defaultOptions
            loadOptions={loadOptions}
            isDisabled={props.uiSchema["ui:disabled"]}
          />
        </div>
        {props.schema.quickAddFormId && (
          <div
            onClick={() => {
              setIsModal(true);
              $("#autoSuggestModal" + props.schema.quickAddFormId).modal(
                "show"
              );
            }}
            style={{
              cursor: "pointer",
              borderRadius: 4,
              border: "1px solid #ccc",
              minHeight: 38,
              minWidth: 48,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i className="fas fa-plus"></i>
          </div>
        )}
      </div>
      <div id="ComboBoxErr" style={{ backgroundColor: toastColor }}>
        {toastDescription}
      </div>
      <div>
        {props.schema.quickAddFormId && (
          <div
            className="modal fade"
            id={"autoSuggestModal" + props.schema.quickAddFormId}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleModalCloseClick}
                  >
                    <i className="fa fa-times-circle" aria-hidden="true"></i>
                  </button>
                </div>
                <div className="modal-body">
                  {isModal && (
                    <AppendFormInForm
                      id={props.schema.quickAddFormId}
                      valueKey={props.schema.valueKey}
                      displayKey={props.schema.displayKey}
                      details={handleNewDetails}
                      closePopup={handleModalCloseClick}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default connect(null, { onChangeLevel1Dropdown })(ComboBox);
