import React from "react";
import MobileNumber from "../CustomeWidget/MobileNumber";
import ComboBox from "./ComboBox";
import "../components/Test.css";
import AutoCapitalise from "./AutoCapitalise";
import ShowPercentSign from "./ShowPercentSign";
import GeoLacation from "./GeoLacation";
import TextBox from "./TextBox";
import TextArea from "./TextArea";
import CurrencyText from "./CurrencyText";
import HtmlEditor from "./HtmlEditor";
import DateTime from "./DateTime";

function AdvanceTextBox(props) {
  switch (props.schema.component) {
    case "MobileNumber":
      return (
        <MobileNumber
          name={props.name}
          formData={props.formData}
          schema={props.schema}
          uiSchema={props.uiSchema}
          required={props.required}
          onChange={props.onChange}
        />
      );
    case "AutoSuggest":
      return (
        <ComboBox
          name={props.name}
          formData={props.formData}
          schema={props.schema}
          uiSchema={props.uiSchema}
          required={props.required}
          onChange={props.onChange}
        />
      );
    case "CurrencyInput":
      return (
        <CurrencyText
          name={props.name}
          formData={props.formData}
          uiSchema={props.uiSchema}
          schema={props.schema}
          required={props.required}
          onChange={props.onChange}
        />
      );
    case "AutoCapitalise":
      return (
        <AutoCapitalise
          name={props.name}
          formData={props.formData}
          uiSchema={props.uiSchema}
          schema={props.schema}
          required={props.required}
          onChange={props.onChange}
        />
      );
    case "HtmlEditor":
      return (
        <HtmlEditor
          name={props.name}
          formData={props.formData}
          uiSchema={props.uiSchema}
          schema={props.schema}
          required={props.required}
          onChange={props.onChange}
        />
      );
    case "TextBox":
      return (
        <TextBox
          name={props.name}
          formData={props.formData}
          uiSchema={props.uiSchema}
          schema={props.schema}
          required={props.required}
          onChange={props.onChange}
        />
      );
    case "TextArea":
      return (
        <TextArea
          name={props.name}
          formData={props.formData}
          uiSchema={props.uiSchema}
          required={props.required}
          schema={props.schema}
          onChange={props.onChange}
        />
      );
    case "ShowPercentSign":
      return (
        <ShowPercentSign
          name={props.name}
          formData={props.formData}
          uiSchema={props.uiSchema}
          schema={props.schema}
          required={props.required}
          onChange={props.onChange}
        />
      );
    case "GeoLocation":
      return (
        <GeoLacation
          name={props.name}
          formData={props.formData}
          uiSchema={props.uiSchema}
          schema={props.schema}
          required={props.required}
          onChange={props.onChange}
        />
      );
    case "DateTime":
      return (
        <DateTime
          name={props.name}
          formData={props.formData}
          uiSchema={props.uiSchema}
          schema={props.schema}
          required={props.required}
          onChange={props.onChange}
        />
      );
    default:
      return null;
  }
}

export default AdvanceTextBox;
