import React, { useState, useEffect } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import Login from "components/Login";
import ForgotPassword from "components/forgotPassword";
import ReportViewer from "components/ReportViewer";
import Dashboard from "components/Dashboard";
import Signup from "components/Signup";
import ViewDetails from "components/ViewDetails";
import FormBuilder from "components/FormBuilder";
import EditForm from "components/EditForm";
import RouteLayout from "Layout/RouteLayout";
import ReportDataAk from "StaticForm/Report/ReportDataAk";
import ReportData from "components/ReportData";
import KanbanBoard from "Kanban/KanbanBoard";
import MainBoard from "Kanban/MainBoard";
import KanbanViewer from "components/KanbanViewer";
import Board from "StaticForm/Board";
import KanbanViewerAk from "StaticForm/KanbanViewerAk";
import KBBoard from "components/KBBoard";
import MemberRegistration from "components/LoginFormComp/MemberRegistration";
import TestComponent from "components/TestComponent";
import EditMemberRegistration from "components/LoginFormComp/EditMemberRegistration";
import Signup_app from "components/Signup_app";
import ConnectorAppComponent from "CustomeWidget/ConnectorAppComponent";
import CustomeHome from "CustomeConfig/CustomeHome";
import Followup from "components/Followup";
import NewFormSave from "components/NewFormSave";
import ChangePassword from "components/LoginFormComp/ChangePassword";
import UserProfile from "components/UserProfile";
import LoyaltySchemeReport from "StaticForm/LoyaltySchemeReport";
import CustomeSaleReport from "StaticForm/CustomeSaleReport";
import CustomeReddem from "StaticForm/CustomeReddem";
import LoyaltySchemeContact from "StaticForm/LoyaltySchemeContact";
import View from "components/View";
import PageNotFound from "components/PageNotFound";
import IframeComponent from "components/IframeComponent";
import Checkout from "components/Cart/Checkout";
import packageJson from "../package.json";
import { getBuildDate } from "utils/utils";
import withClearCache from "ClearCache";
import formIframe from "components/formIframe";
import axios from "axios";
import PgSignUp from "StaticForm/PgSignUp";
const $ = window.$;

function initializeAnalytics() {
  ReactGA.initialize("UA-158711673-1");
  ReactGA.pageview(window.location.href);
}

const tagManagerArgs = {
  gtmId: "GTM-529N7LD",
};

const MainApp = () => {
  const [org, setOrgId] = useState("");
  const [isloading, setIsLoading] = useState(true);
  const url = window.location.host;
  // const url = "crm-defaultssty.qik.ai";
  // const url = "elearning.qik.ai";
  // const url = "elearning-defaultssty.qik.ai";
  // const url = "crm.qik.ai";
  // const url =  "salesforceexams.qik.ai";
  // const url = "sign-defaultssty.qik.ai"; //  "sign.qik.ai";
  // const url = "contracts.nxglabs.in";
  // const url = "sign.nxglabs.in";

  const splitUrl = url.split(".")[0];
  const qikDomain = url.split(".")[1];
  const urlSubName = splitUrl.split("-")[0];
  const orgId = splitUrl.split("-")[1];
  // console.log("splitUrl ", splitUrl);
  // console.log("Qdomain ", qikDomain);
  // console.log("subname ", urlSubName);
  // console.log("orgID ", orgId);

  // const urlSubName = url.split(".")[0];
  // const orgId = url.split(".")[1];
  // const qikDomain = url.split(".")[2];
  // console.log("orgId ", orgId);

  useEffect(() => {
    if (qikDomain !== "qik") {
      getOrgId();
    } else {
      setIsLoading(false);
    }
  }, [qikDomain]);
  // `getOrgId` is used to fetch orgId in suddomain not equal to qik
  async function getOrgId() {
    const subDo = window.location.href; // "https://sign.nxglabs.in";
    const surl = subDo.split(".");
    surl.shift();
    let domainName = surl.join(".");
    if (domainName.indexOf("/") !== -1) {
      domainName = surl.join(".").split("/")[0];
    }
    console.log(domainName);
    const queryParam = JSON.stringify({ domain: domainName });
    const res = await axios.get(
      `https://root.qik.ai/app/classes/domain?where=${queryParam}`,
      {
        headers: {
          "X-Parse-Application-Id": "aprjnnhfcp",
        },
      }
    );
    const orgRes = res.data.results[0].appId;
    // console.log("org ", orgRes);
    setOrgId(orgRes);
    setIsLoading(false);
  }
  try {
    if (orgId) {
      localStorage.setItem("qikDomain", qikDomain);
      localStorage.setItem("BaseUrl12", `https://server3.qik.ai/${orgId}/`);
      localStorage.setItem("AppID12", orgId);
      localStorage.setItem("domain", urlSubName);
    } else {
      localStorage.setItem("qikDomain", qikDomain);
      if (qikDomain === "uat") {
        localStorage.setItem("BaseUrl12", "https://server3.qik.ai/thirdapp/");
        localStorage.setItem("AppID12", "qbKGrKRiIDFxqrYhcIkY");
      } else if (qikDomain === "dev") {
        localStorage.setItem("BaseUrl12", "https://dev-server.qik.ai/app/");
        localStorage.setItem("AppID12", "qik-dev-app");
      } else if (qikDomain === "qik") {
        localStorage.setItem("BaseUrl12", "https://server2.qik.ai/app/");
        localStorage.setItem("AppID12", "shbjnnhfcp");
      } else {
        // console.log("qikDomain ", qikDomain);
        // console.log("urlSubName ", splitUrl);
        localStorage.setItem("qikDomain", org);
        localStorage.setItem("BaseUrl12", `https://server3.qik.ai/${org}/`);
        localStorage.setItem("AppID12", org);
        localStorage.setItem("domain", splitUrl);
      }
      localStorage.setItem("domain", urlSubName);
    }

    // var urlSubName = url.split(".")[0];
    // let qikDomain = url.split(".")[1];
    // console.log("qikDomain ", qikDomain)
    //     console.log("orgName ", orgName)
    // localStorage.setItem("qikDomain", qikDomain);
    // if (qikDomain === "uat") {
    //   localStorage.setItem("BaseUrl12", "https://server3.qik.ai/thirdapp/");
    //   localStorage.setItem("AppID12", "qbKGrKRiIDFxqrYhcIkY");
    // } else if (qikDomain === "dev") {
    //   localStorage.setItem("BaseUrl12", "https://dev-server.qik.ai/app/");
    //   localStorage.setItem("AppID12", "qik-dev-app");
    // } else {
    //   localStorage.setItem("BaseUrl12", "https://server2.qik.ai/app/");
    //   localStorage.setItem("AppID12", "shbjnnhfcp");
    // }
    // localStorage.setItem("domain", urlSubName);
  } catch (error) {}
  initializeAnalytics();
  TagManager.initialize(tagManagerArgs);
  $(window).scrollTop(0);
  window.onmessage = function (e) {
    try {
      if (e.origin === url) {
        return;
      }
      var payload = JSON.parse(e.data);
      switch (payload.method) {
        case "set":
          if (localStorage.getItem("accesstoken")) {
            localStorage.clear();
          }
          for (const [key, value] of Object.entries(payload.data)) {
            localStorage.setItem(`${key}`, `${value}`);
          }
          localStorage.setItem("SwitcherApp", "true");
          break;
        case "get":
          var parent = window.parent;
          var data = localStorage.getItem(payload.key);
          parent.postMessage(data, "*");
          break;
        case "remove":
          localStorage.removeItem(payload.key);
          break;
      }
    } catch (error) {}
  };

  console.log(getBuildDate(packageJson.buildDate));
  return (
    <React.Fragment>
      {isloading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontSize: "45px",
              color: "#3dd3e0",
            }}
            className="loader-37"
          ></div>
        </div>
      ) : (
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/ForgotPassword" component={ForgotPassword} />
            <Route path="/board" component={MainBoard} />
            <Route path="/login" component={Login} />
            <Route path="/signup_app/:id" component={Signup_app} />
            <Route path="/app/:id" component={FormBuilder} />
            <Route path="/form_app/:id" component={FormBuilder} />
            <Route path="/homepage/:id" component={Dashboard} />
            <Route path="/report_app/:id" component={ReportData} />
            <Route path="/dashboard_app/:id" component={Dashboard} />
            <Route path="/kanban_app/:id" component={KanbanViewer} />
            <Route path="/register_app/:id" component={MemberRegistration} />
            <Route path="/edit_app/:id/:formid/:page" component={EditForm} />
            <Route path="/edit_app/:id/:formid" component={EditForm} />
            <Route
              exact
              path="/edit_app/:id/:formid/:fun"
              component={EditForm}
            />
            <Route path="/kboard_app" component={KBBoard} />
            <Route path="/lcontact_app" component={LoyaltySchemeContact} />
            <Route path="/loyrepo_app/offer" component={LoyaltySchemeReport} />
            <Route path="/redeem_app/:id" component={CustomeReddem} />

            <Route
              path="/sale_app/:id/:formid/:fun"
              component={CustomeSaleReport}
            />
            <Route path="/detail_app/:id/:rid" component={View} />
            <Route path="/detail_app/:id/:rid/:page" component={View} />

            <Route
              exact
              path="/connectweb/:pagetype/:pageid/:sessionId/:baseurl/:extendcls"
              component={ConnectorAppComponent}
            />
            <Route
              exact
              path="/connectweb/:pagetype/:pageid/:sessionId/:baseurl"
              component={ConnectorAppComponent}
            />
            <Route
              exact
              path="/connectweb/:pagetype/:pageid/:sessionId"
              component={ConnectorAppComponent}
            />
            <Route path="/view_app/:id/:formid" component={ViewDetails} />
            <Route path="/dynamic" component={TestComponent} />
            <Route path="/signup" component={Signup} />
            <Route exact path="/pgsignup" component={PgSignUp} />
            <RouteLayout path="/kanban/:id" component={KanbanViewerAk} />
            <RouteLayout path="/kanba/:id" component={KanbanViewer} />
            <RouteLayout path="/lcontact" component={LoyaltySchemeContact} />
            <RouteLayout path="/profile" component={UserProfile} />
            <RouteLayout path="/detail/:id/:rid/:page" component={View} />
            <RouteLayout path="/register/:id" component={MemberRegistration} />
            <RouteLayout
              path="/sale/:id/:formid/:fun"
              component={CustomeSaleReport}
            />
            <RouteLayout path="/knbn" component={Board} />
            <RouteLayout
              path="/loyrepo/offer"
              component={LoyaltySchemeReport}
            />
            <RouteLayout path="/redeem/:id" component={CustomeReddem} />
            <RouteLayout path="/microapp/:url" component={IframeComponent} />
            <RouteLayout path="/kboard" component={KBBoard} />
            <RouteLayout path="/rp/:id" component={ReportViewer} />
            <RouteLayout path="/kb/:id" component={KanbanBoard} />
            <RouteLayout path="/followup/:id/:cls" component={Followup} />
            <RouteLayout path="/dashboard/:id" component={Dashboard} />
            <RouteLayout path="/changepassword/" component={ChangePassword} />
            <RouteLayout path="/repo/:id" component={ReportDataAk} />
            <RouteLayout path="/report/:id" component={ReportData} />
            <RouteLayout path="/view/:id/:formid" component={ViewDetails} />
            <RouteLayout exact path="/form/:id" component={FormBuilder} />
            <RouteLayout exact path="/checkout" component={Checkout} />
            <RouteLayout
              exact
              path="/edit/:id/:formid/:fun/:current/:class"
              component={EditForm}
            />
            <RouteLayout
              exact
              path="/edit/:id/:formid/:page/"
              component={EditForm}
            />
            <RouteLayout exact path="/edit/:id/:formid/" component={EditForm} />

            <RouteLayout
              path="/frmnew/:id/:formid/:cls"
              component={NewFormSave}
            />
            <RouteLayout
              path="/_edit/:id/:formid"
              component={EditMemberRegistration}
            />
            <RouteLayout path="/config/:id" component={CustomeHome} />
            <RouteLayout path="/asmicroapp/:url" component={formIframe} />
            <RouteLayout path="*" component={PageNotFound} />
          </Switch>
        </Router>
      )}
    </React.Fragment>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}
export default App;
