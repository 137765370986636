import React, { useState, useEffect, useRef } from "react";
import "./CustomSuggestDropdown.css";

// example dropdownlist
/**
 *  const arr = [
    { value: "a123", label: "car" },
    { value: "b123", label: "bike" },
    { value: "c123", label: "aeroplane" },
    { value: "d123", label: "helicopter" },
    { value: "e123", label: "jet" },
    { value: "f123", label: "train" },
    { value: "g123", label: "bus" },
    { value: "h123", label: "bicycle" },
  ];
 */

const CustomSuggesDropdown = ({ arr = [], onChange, loading }) => {
  const [isDropdown, setIsDropdown] = useState(false);
  const [selected, setSelected] = useState("");

  const [updatedArr, setUpdatedArr] = useState([]);
  const devRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (arr && arr.length > 0) {
      setUpdatedArr(arr);
    }
  }, [arr]);
  useEffect(() => {
    function handleClickOutside(event) {
      const divCondition =
        devRef.current && !devRef.current.contains(event.target);
      const inputCondition =
        inputRef.current && !inputRef.current.contains(event.target);
      if (divCondition && inputCondition) {
        setIsDropdown(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onhandleChange = (e) => {
    // setUpdatedArr(arr);
    const arr2 = arr.filter((x) =>
      x.label.toLowerCase().includes(e.target.value.toLowerCase())
    );
    if (arr2.length > 0) {
      setUpdatedArr(arr2);
    } else {
      setUpdatedArr([]);
    }
    setSelected(e.target.value);
  };
  return (
    <div>
      <div style={{ position: "relative" }}>
        <input
          ref={inputRef}
          type="text"
          className="form-control"
          placeholder={loading ? "loading..." : "--select--"}
          disabled={loading || arr.length === 0}
          value={selected}
          onChange={onhandleChange}
          onFocus={() => {
            if (!isDropdown) {
              setIsDropdown(true);
            }
          }}
        />
        {isDropdown ? (
          <i
            className="fas fa-angle-up"
            style={{
              fontSize: 14,
              position: "absolute",
              top: "30%",
              right: "2%",
            }}
          ></i>
        ) : (
          <i
            className="fas fa-angle-down"
            style={{
              fontSize: 14,
              position: "absolute",
              top: "30%",
              right: "2%",
            }}
          ></i>
        )}
        {isDropdown && (
          <div ref={devRef} className="customdd-content">
            {updatedArr.map((x, i) => {
              return (
                <div
                  className="customdd-element"
                  onClick={() => {
                    if (x.value) {
                      setSelected(x.label);
                      onChange(x.value, x.label);
                    }
                    setIsDropdown(false);
                  }}
                  key={i}
                >
                  {x.label}
                </div>
              );
            })}
            {updatedArr && updatedArr.length === 0 && (
              <div
                className="customdd-noelement"
                style={{ textAlign: "center" }}
              >
                no data found
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSuggesDropdown;
