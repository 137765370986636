import firebase from "firebase";
import '@firebase/messaging';

const config = {
  apiKey: "AIzaSyC8IfQi-YkBWoMZ_RGAxUfUis27u5cjTCw",
    authDomain: "app-creator-11f40.firebaseapp.com",
    databaseURL: "https://app-creator-11f40.firebaseio.com",
    projectId: "app-creator-11f40",
    storageBucket: "app-creator-11f40.appspot.com",
    messagingSenderId: "787078727576",
    appId: "1:787078727576:web:0e6a922e73cfaa67430ded",
    measurementId: "G-DF9LLMS2XX"
};

firebase.initializeApp(config);


export default firebase;
