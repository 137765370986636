import React, { useState, useEffect, useRef, useCallback } from "react";
import { memo } from "react";

const FormIframe = (props) => {
  const [loader, setLoader] = React.useState(true);
  const [url, setUrl] = useState("");
  const iframeRef = useRef(null);

  //   below use effect is used to create correct url of microapp
  useEffect(() => {
    let str = props.location.pathname.replace("/asmicroapp/", "");
    let hashParam = props.location.hash;
    const desiredurl = str + hashParam;
    // console.log("str ", str);
    // console.log("hashParam ", hashParam);
    // console.log("desired url", desiredurl);
    setLoader(false);
    setUrl(desiredurl);
  }, [props]);

  useEffect(() => {
    return () => localStorage.removeItem("rowlevel");
  }, []);

  const setLocalIframe = useCallback(() => {
    // console.log(
    //   "...props.saveDocumentValue ",
    //   localStorage.getItem("rowlevel")
    // );
    try {
      let data = {
        accesstoken: localStorage.getItem("accesstoken"),
        baseUrl: localStorage.getItem("baseUrl"),
        parseAppId: localStorage.getItem("parseAppId"),
        extended_class: localStorage.getItem("extended_class"),
        extend_details: localStorage.getItem("Extand_Class"),
        userSettings: localStorage.getItem("userSettings"),
        username: localStorage.getItem("username"),
        _appName: localStorage.getItem("_appName"),
        TenetId: localStorage.getItem("TenetId"),
        rowlevel: localStorage.getItem("rowlevel"),
      };
      // console.log("data ", data)
      let storage = JSON.stringify({
        key: "storage",
        method: "set",
        data: data,
      });

      //   var iframe = document.getElementById(url);
      //   iframe.contentWindow.postMessage(storage, "*");
      //   setTimeout(() => {
      let iframe = iframeRef.current.contentWindow.postMessage(storage, "*");
      //   console.log("iframe ", iframe);
      //   }, 100);
    } catch (error) {
      console.log(error);
    }
  }, [url]);
  return (
    <React.Fragment>
      {loader ? (
        <div>Loading....</div>
      ) : (
        <div
          style={{
            margin: "10px 0px 20px",
            borderRadius: 6,
            boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.33)",
            backgroundColor: "#ffffff",
          }}
        >
          <iframe
            ref={iframeRef}
            src={url}
            id={url}
            style={{
              width: "100%",
              minHeight: "100vh",
              scroll: "hidden",
              borderRadius: 3,
            }}
            onLoad={setLocalIframe}
            title=""
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default memo(FormIframe);
