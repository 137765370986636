import React, { memo } from "react";
import uniqid from "uniqid";

const $ = window.$;

function HeaderSwitch({ IncludedApps }) {
  return IncludedApps.map((x, i) => (
    <React.Fragment key={uniqid()}>
      {x.AppName !== localStorage.getItem("domain") && (
        <div className="col-md-6" key={i}>
          <a
            title={x.AppTitle}
            onClick={(e) => {
              e.preventDefault();
              let url = `https://${x.AppName}.qik.ai/`;
              // let url ='http://localhost:3001/'
              let data = {
                accesstoken: localStorage.getItem("accesstoken"),
                TenetId: localStorage.getItem("TenetId"),
              };
              let storage = JSON.stringify({
                key: "storage",
                method: "set",
                data: data,
              });
              $("<iframe>") // Creates the element
                .attr("id", "iframeSwitcher")
                .attr("src", url) // Sets the attribute spry:region="myDs"
                .attr("height", 0)
                .attr("width", 0)
                .appendTo("#switchAppIframe");
              var iframe = document.getElementById("iframeSwitcher");
              iframe.onload = () => {
                iframe.contentWindow.postMessage(storage, "*");
                setTimeout(() => {
                  //   window.location.href = "http://localhost:3001/";
                  // window.location.href = url;
                  window.open(url, "_blank");
                }, 2000);
              };
            }}
          >
            <img
              src={x.AppLogo}
              style={{
                padding: "10px",
                height: "60px !important",
                width: "100%",
              }}
            />
          </a>
          <div id="switchAppIframe" style={{ display: "none" }} />
        </div>
      )}
    </React.Fragment>
  ));
}

export default memo(HeaderSwitch);
