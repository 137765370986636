import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { onChangeLevel1Dropdown } from "../actions";
import CurrencyData from "./CurrencyData";
import LabelField from "./LabelField";

const $ = window.$;
function CurrencyText(props) {
  const [currency, setCurrency] = useState("");
  const [ForData, setForData] = useState(true);
  const [isDisabled, setisDisabled] = useState(false);
  let currency_obj;
  for (let [key, value] of Object.entries(CurrencyData[0])) {
    if (props.schema.defaultcurrency && key === props.schema.defaultcurrency) {
      currency_obj = value;
    }
  }

  // Jquery Dependency

  $("input[data-type='currency']").on({
    keyup: function () {
      formatCurrency($(this));
    },
    blur: function () {
      formatCurrency($(this), "blur");
    },
  });

  function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function formatCurrency(input, blur) {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    var input_val = input.val();

    // don't validate empty input
    if (input_val === "") {
      return;
    }

    // original length
    var original_len = input_val.length;

    // initial caret position
    var caret_pos = input.prop("selectionStart");

    // check for decimal
    if (input_val.indexOf(".") >= 0) {
      // get position of first decimal
      // this prevents multiple decimals from
      // being entered
      var decimal_pos = input_val.indexOf(".");

      // split number by decimal point
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);

      // add commas to left side of number
      left_side = formatNumber(left_side);

      // validate right side
      right_side = formatNumber(right_side);

      // On blur make sure 2 numbers after decimal
      if (blur === "blur") {
        right_side += "00";
      }

      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, props.schema.decimalsLimit);

      // join number by .
      input_val = currency_obj.symbol_native + left_side + "." + right_side;
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = formatNumber(input_val);
      input_val = currency_obj.symbol_native + input_val;

      // final formatting
      if (blur === "blur") {
        input_val += ".00";
      }
    }

    // send updated string to input
    input.val(input_val);

    // put caret back in the right position
    var updated_len = input_val.length;
    caret_pos = updated_len - original_len + caret_pos;
    input[0].setSelectionRange(caret_pos, caret_pos);
  }

  const DependandPrice = async (search) => {
    try {
      if (props.schema.parent) {
        setisDisabled(true);
        props.onChange(undefined);
        setCurrency("");
        const headers = {
          "Content-Type": "application/json",
          "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
          "X-Parse-Session-Token": localStorage.getItem("accesstoken"),
        };
        let reg = /(\#.*?\#)/gi;
        let _query = props.schema.data.query.replace(reg, search);
        let url = `${localStorage.getItem("baseUrl")}classes/${
          props.schema.data.class
        }?${_query}`;
        await axios
          .get(url, { headers: headers })
          .then((res) => {
            setisDisabled(false);
            res.data.results.forEach((x) => {
              if (x[props.schema.data.valueKey]) {
                setCurrency(x[props.schema.data.valueKey]);
                props.onChange(Number(x[props.schema.data.valueKey]));
              } else {
                setCurrency("");
                props.onChange(undefined);
              }
            });
          })
          .catch((error) => {
            setisDisabled(false);
          });
      }
    } catch (error) {
      setisDisabled(false);
    }
  };
  useEffect(() => {
    if (
      props.Level1_Dropdown[props.schema.parent] !== undefined &&
      props.Level1_Dropdown[props.schema.parent] !== "undefined" &&
      props.formData !== undefined &&
      ForData
    ) {
      setForData(false);
    } else if (
      props.Level1_Dropdown[props.schema.parent] &&
      props.Level1_Dropdown[props.schema.parent] !== "Select"
    ) {
      DependandPrice(props.Level1_Dropdown[props.schema.parent]);
    }

    // eslint-disable-next-line
  }, [props.Level1_Dropdown[props.schema.parent]]);

  useEffect(() => {
    if (
      props.Level2_Dropdown[props.schema.parent] &&
      props.Level2_Dropdown[props.schema.parent] !== "Select"
    ) {
      DependandPrice(props.Level2_Dropdown[props.schema.parent]);
    }

    // eslint-disable-next-line
  }, [props.Level2_Dropdown[props.schema.parent]]);

  return (
    <React.Fragment>
      <LabelField
        Title={props.schema.title}
        Name={props.name}
        Required={props.required}
        HelpBody={props.schema.helpbody}
        HelpLink={props.schema.helplink}
      />
      <input
        type="text"
        name={props.name}
        className="form-control"
        id={props.name}
        disabled={
          props.uiSchema["ui:disabled"]
            ? props.uiSchema["ui:disabled"]
            : isDisabled
        }
        maxLength={
          props.schema.maxlength && props.schema.maxlength === 7
            ? 9
            : props.schema.maxlength === 9
            ? 12
            : props.schema.maxlength
            ? props.schema.maxlength
            : 10
        }
        pattern={`^\${currency_obj.symbol_native}\d{1,3}(,\d{3})*(\.\d+)?$`}
        value={
          props.formData
            ? `${currency_obj.symbol_native}${props.formData
                .toString()
                .replace(/\D/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
            : currency
        }
        data-type="currency"
        onChange={(e) => {
          let str = e.target.value;
          str = str.replace(currency_obj.symbol_native, "");
          str = str.replace(",", "");
          setCurrency(str);
          props.onChange(Number(str));
        }}
        placeholder={props.schema.placeholder}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    Level1_Dropdown: state.Level1_Dropdown,
    Level2_Dropdown: state.Level2_Dropdown,
  };
};

export default connect(mapStateToProps, {
  onChangeLevel1Dropdown,
})(CurrencyText);
