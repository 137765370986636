import React, { Component } from "react";
import styled from "styled-components";
import { Droppable } from "react-beautiful-dnd";
import Task from "../../StaticForm/Kanban/Task";
import Parse from "parse";


const Container = styled.div`
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 300px;
  display: flex;
  overflow: auto;
  flex-direction: column;
`;
const Title = styled.h5`
  padding: 8px;
  background-color: #3bc9d6;
  color: #fff;
`;
const TaskList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDraggingOver ? "skyblue" : "white")};
  flex-grow: 1;
  min-height: 100px;
`;

export class Column extends Component {
  state = {
    contactData: JSON.parse(localStorage.getItem("ContactData")),
    contact: {},
    parseBaseUrl: localStorage.getItem("baseUrl"),
    parseAppId: localStorage.getItem("parseAppId")
  };

  async getContDetails(contact) {
    Parse.serverURL = this.state.parseBaseUrl;
    Parse.initialize(this.state.parseAppId);
    var _kList = Parse.Object.extend("uat_Contacts");
    const query = new Parse.Query(_kList);
    query.equalTo("cont_person", contact);
    const results = await query.first();
    const resultjson = results.toJSON();
    this.setState({
      contact: resultjson
    });
    let data = JSON.stringify(resultjson);
  }

  render() {
    return (
      <Container style={{ minWidth: "297px", minHeight: "500px" }}>
        <Title>
          {this.props.column.title}
          {this.state.contactData.map(d => {
            if (d.stepName === this.props.column.title) {
              return (
                <button
                  onClick={() => this.getContDetails(d.contact)}
                  className="pull-right"
                  style={{ color: "#fff", fontSize: "12px", paddingTop: "2px" }}
                >
                  {d.contact}
                </button>
              );
            }
          })}
        </Title>
        <Droppable droppableId={this.props.column.id}>
          {(provided, snapshot) => (
            <TaskList
              ref={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {this.props.tasks.map((task, index) => (
                <Task key={task.id} index={index} task={task} />
              ))}
              {provided.placeholder}
            </TaskList>
          )}
        </Droppable>
      </Container>
    );
  }
}

export default Column;
