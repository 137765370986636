import React, { useState } from "react";
import LabelField from "./LabelField";

function TextArea(props) {
  const [capitalise, setCapitalise] = useState("");

  return (
    <React.Fragment>
      <LabelField
        Title={props.schema.title}
        Name={props.name}
        Required={props.required}
        HelpBody={props.schema.helpbody}
        HelpLink={props.schema.helplink}
      />
      <textarea
        type="text"
        className="form-control"
        maxLength={props.schema.maxlength && props.schema.maxlength}
        minLength={props.schema.minlength && props.schema.minlength}
        required={props.required && props.required}
        readOnly={props.readonly && props.readonly}
        disabled={props.disabled && props.disabled}
        value={props.formData && props.formData ? props.formData : capitalise}
        onChange={(e) => {
          setCapitalise(e.target.value);
          props.onChange(e.target.value);
        }}
      ></textarea>
    </React.Fragment>
  );
}

export default TextArea;
