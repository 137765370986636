import React, { useState, useEffect, useRef } from "react";
import Parse from "parse";
import jwtDecode from "jwt-decode";
import { useScript } from "./useScript";

/*
 * `GoogleSignInBtn`as it's name indicates it render google sign in button
 * and in this `useScript` in which we have created for generate google sign button
 * and when user click on sign in with google it will be verify on server side
 * and then generate token
 */
const GoogleSignInBtn = ({
  thirdpartyLoginfn,
  thirdpartyLoader,
  setThirdpartyLoader,
}) => {
  const [isModal, setIsModal] = useState(false);
  const googleBtn = useRef();
  const [userDetails, setUserDetails] = useState({
    Phone: "",
    Company: "",
    Name: "",
    Email: "",
  });
  const [googleDetails, setGoogleDetails] = useState({
    Id: "",
    TokenId: "",
    Gmail: "",
    Name: "",
  });
  useScript("https://accounts.google.com/gsi/client", () => {
    window.google.accounts.id.initialize({
      client_id:
        "918704711393-thhv3re2pfqvve76tgb86ulu1tlpssrk.apps.googleusercontent.com",
      callback: responseGoogle,
      auto_select: false,
    });
    window.google.accounts.id.renderButton(googleBtn.current, {
      theme: "outline",
      size: "large",
      width: "187px",
    });
  });
  const responseGoogle = async (response) => {
    setThirdpartyLoader(true);
    // console.log("response ", response);
    if (response.credential) {
      const data = jwtDecode(response.credential);
      // console.log("data ", data);
      if (data.sub && data.email) {
        const details = {
          Email: data.email,
          Name: data.name,
        };
        setUserDetails({ ...userDetails, ...details });
        const Gdetails = {
          Id: data.sub,
          TokenId: response.credential,
          Gmail: data.email,
          Name: data.name,
        };
        setGoogleDetails({ ...googleDetails, ...Gdetails });
        const res = await checkExtUser(Gdetails);
      }
    }
  };
  const checkExtUser = async (details) => {
    const extUser = new Parse.Query("contracts_Users");
    extUser.equalTo("Email", details.Gmail);
    const extRes = await extUser.first();
    // console.log("extRes ", extRes);
    if (extRes) {
      const params = { ...details, Phone: extRes.get("Phone") };
      const payload = await Parse.Cloud.run("googlesign", params);
      // console.log("payload ", payload);
      if (payload && payload.sessiontoken) {
        // setThirdpartyLoader(true);
        thirdpartyLoginfn(payload.sessiontoken);
      }
      return { msg: "exist" };
    } else {
      setIsModal(true);
      setThirdpartyLoader(false);
      return { msg: "notexist" };
    }
  };
  const handleSubmitbtn = async () => {
    setThirdpartyLoader(true);

    // e.preventDefault()
    // console.log("handelSubmit", userDetails);
    const params = { ...googleDetails, Phone: userDetails.Phone };
    const payload = await Parse.Cloud.run("googlesign", params);

    // console.log("payload ", payload);
    if (payload && payload.sessiontoken) {
      const params = {
        userDetails: {
          name: userDetails.Name,
          email: userDetails.Email,
          // "passsword":userDetails.Phone,
          phone: userDetails.Phone,
          role: "contracts_Admin",
          company: userDetails.Company,
        },
      };
      const userSignUp = await Parse.Cloud.run("usersignup", params);
      // console.log("userSignUp ", userSignUp);
      if (userSignUp && userSignUp.sessionToken) {
        thirdpartyLoginfn(userSignUp.sessionToken);
      } else {
        alert(userSignUp.message);
      }
    } else if (
      payload &&
      payload.message.replace(/ /g, "_") === "Internal_server_err"
    ) {
      alert("Internal server error !");
    }
  };
  return (
    <div style={{ position: "relative" }}>
      {thirdpartyLoader && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.2)",
            top: 0,
            left: 0,
            zIndex: 2,
          }}
        >
          <div
            style={{
              position: "fixed",
              fontSize: "50px",
              color: "#3ac9d6",
              top: "50%",
              left: "45%",
            }}
            className="loader-37"
          ></div>
        </div>
      )}
      <div ref={googleBtn} className="text-sm"></div>

      {isModal && (
        <div
          className="modal fade show"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block", zIndex: 1 }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Sign up form</h5>
                <span>
                  <span></span>
                </span>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="Phone" className="col-form-label">
                      Phone
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="Phone"
                      value={userDetails.Phone}
                      onChange={(e) =>
                        setUserDetails({
                          ...userDetails,
                          Phone: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="Company" className="col-form-label">
                      Company
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Company"
                      value={userDetails.Company}
                      onChange={(e) =>
                        setUserDetails({
                          ...userDetails,
                          Company: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => setIsModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => handleSubmitbtn()}
                      className="btn btn-info"
                    >
                      Sign up
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GoogleSignInBtn;
