import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import Parse from "parse";
import uniqid from "uniqid";
import "./sidebar.css";
import SideBarIframe from "./SideBarIframe";

const Sidebar = (props) => {
  const $ = window.$;
  const [menuList, setmenuList] = useState([]);
  const [username] = useState(localStorage.getItem("username"));
  const [imageUrl] = useState(localStorage.getItem("profileImg"));
  const [_role] = useState(localStorage.getItem("_user_role"));
  const [scriptId, setscriptId] = useState(localStorage.getItem("scriptId"));
  const [hideNav, sethideNav] = useState("");
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const TenantName = useSelector((state) => state.ShowTenant);

  const menuItem = async () => {
    try {
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      var sideMenu = Parse.Object.extend("w_menu");
      var query = new Parse.Query(sideMenu);
      query.equalTo("objectId", localStorage.getItem("defaultmenuid"));
      //	query.equalTo("objectId", "46X9z6lAv2");
      const results = await query.first();
      const resultjson = results.toJSON();
      let result = resultjson;
      setmenuList(result.menuItems);
    } catch (e) {
      console.error("Problem", e);
    }
  };

  const resize = () => {
    let currentHideNav = window.innerWidth <= 760;
    if (currentHideNav !== hideNav) {
      sethideNav(currentHideNav);
      // console.log("here");
      // console.log($("body").hasClass("sidemenu-closed"));
      if ($("body").hasClass("sidemenu-closed")) {
        $(".body").addClass("sidemenu-closed");

        $(".sidemenu").addClass("sidemenu-closed");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize();
    if (localStorage.getItem("accesstoken")) {
      menuItem();
    }
    const script = document.createElement("script");
    script.src = "/assets/js/app.js";
    script.async = true;
    document.body.appendChild(script);
    const script1 = document.createElement("script");
    script1.src = "/assets/js/layout.js";
    script1.async = true;
    document.body.appendChild(script1);

    // eslint-disable-next-line
  }, []);

  const setLocalIframe = (iframeUrl) => {
    try {
      let data = {
        accesstoken: localStorage.getItem("accesstoken"),
        baseUrl: localStorage.getItem("baseUrl"),
        parseAppId: localStorage.getItem("parseAppId"),
        extended_class: localStorage.getItem("extended_class"),
        extend_details: localStorage.getItem("Extand_Class"),
        userSettings: localStorage.getItem("userSettings"),
        username: localStorage.getItem("username"),
        _appName: localStorage.getItem("_appName"),
        TenetId: localStorage.getItem("TenetId"),
      };
      // console.log("data ", data)
      let storage = JSON.stringify({
        key: "storage",
        method: "set",
        data: data,
      });
      var iframe = document.getElementById(iframeUrl);
      iframe.contentWindow.postMessage(storage, "*");
      setTimeout(() => {
        window.location.hash = `/microapp/${iframeUrl}`;
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  let _imgPath = "";
  if (imageUrl === null || imageUrl == "") {
    _imgPath = "./assets/img/dp.png";
  } else {
    _imgPath = imageUrl;
  }

  return (
    <React.Fragment>
      <>{menuList && <SideBarIframe menuList={menuList} />}</>
      {hideNav ? (
        <React.Fragment>
          <div
            className="sidemenu-container navbar-collapse collapse fixed-menu"
            id="accordionExample2"
          >
            <div
              id="remove-scroll MobileMenuDivWrapper"
              className="left-sidemenu"
            >
              <ul
                className="sidemenu"
                data-keep-expanded="false"
                data-auto-scroll="true"
                data-slide-speed="200"
                style={{
                  paddingTop: "20px",
                  height: "751px",
                  overflow: "auto!important",
                }}
              >
                <li className="sidebar-toggler-wrapper hide">
                  <div className="sidebar-toggler">
                    <span></span>
                  </div>
                </li>
                <li className="sidebar-user-panel">
                  <div className="user-panel">
                    <div className="pull-left image">
                      <img
                        src={_imgPath}
                        className="img-circle user-img-circle"
                        alt="User Image"
                      />
                    </div>
                    <div className="pull-left info">
                      <p>
                        <b> {username} </b>
                      </p>
                      <a>
                        <span className="txtOnline">
                          {(TenantName ||
                            localStorage.getItem("TenantName")) && (
                            <span style={{ fontSize: "1.2rem" }}>
                              {TenantName || localStorage.getItem("TenantName")}
                              <br />
                            </span>
                          )}
                          {_role ? _role.toUpperCase() : "Administrator"}
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
                {menuList.map((item, i) =>
                  !item.children ? (
                    <li
                      key={uniqid()}
                      className="nav-item nav-link nav-toggle"
                      data-toggle="collapse"
                      data-target=".navbar-collapse"
                    >
                      {item.pageType === "Setting V Menu" ? (
                        <NavLink to={`/${"config"}/${item.objectId}`}>
                          <i className={item.icon}></i>
                          <span className="title" title={item.description}>
                            {item.title}
                          </span>
                        </NavLink>
                      ) : (
                        <React.Fragment>
                          {item.pageType === "microapp" ? (
                            <a
                              onClick={(e) => {
                                setLocalIframe(item.objectId);
                              }}
                            >
                              <i
                                className={item.icon}
                                style={{ color: "#444" }}
                              ></i>
                              <span className="title" title={item.description}>
                                {item.title}
                              </span>
                            </a>
                          ) : (
                            <NavLink to={`/${item.pageType}/${item.objectId}`}>
                              <i className={item.icon}></i>
                              <span className="title" title={item.description}>
                                {item.title}
                              </span>
                            </NavLink>
                          )}
                        </React.Fragment>
                      )}
                    </li>
                  ) : (
                    <li key={i} className="nav-item">
                      <a className="nav-link nav-toggle">
                        <i className={item.icon}></i>
                        <span className="title" title={item.description}>
                          {item.title}
                        </span>
                        <span className="arrow"></span>
                      </a>
                      <ul className="sub-menu">
                        {item.children &&
                          item.children.map((sub, j) =>
                            !sub.children ? (
                              <li
                                key={uniqid()}
                                className="nav-item"
                                data-toggle="collapse"
                                data-target=".navbar-collapse"
                              >
                                {sub.pageType === "Setting V Menu" ? (
                                  <NavLink
                                    to={`/${"config"}/${sub.objectId}`}
                                    className="nav-link"
                                  >
                                    <i className={sub.icon}></i>
                                    <span
                                      className="title"
                                      title={sub.description}
                                    >
                                      {sub.title}
                                    </span>
                                  </NavLink>
                                ) : (
                                  <React.Fragment>
                                    {sub.pageType === "microapp" ? (
                                      <a
                                        onClick={(e) => {
                                          setLocalIframe(sub.objectId);
                                        }}
                                        className="nav-link"
                                      >
                                        <i
                                          className={sub.icon}
                                          style={{ color: "#444" }}
                                        ></i>
                                        <span
                                          className="title"
                                          title={sub.description}
                                        >
                                          {sub.title}
                                        </span>
                                      </a>
                                    ) : (
                                      <NavLink
                                        to={`/${sub.pageType}/${sub.objectId}`}
                                        className="nav-link"
                                      >
                                        <i className={sub.icon}></i>
                                        <span
                                          className="title"
                                          title={sub.description}
                                        >
                                          {sub.title}
                                        </span>
                                      </NavLink>
                                    )}
                                  </React.Fragment>
                                )}
                              </li>
                            ) : (
                              <li key={sub.title} className="nav-item">
                                <a className="nav-link nav-toggle">
                                  <i className={sub.icon}></i>
                                  <span
                                    className="title"
                                    title={sub.description}
                                  >
                                    {sub.title}
                                  </span>
                                  <span className="arrow "></span>
                                </a>
                                <ul
                                  className="sub-menu"
                                  id={`testv${sub.objectId}`}
                                >
                                  {sub.children &&
                                    sub.children.map((_menu, k) => (
                                      <li
                                        key={uniqid()}
                                        className="nav-item"
                                        data-toggle="collapse"
                                        data-target=".navbar-collapse"
                                      >
                                        {_menu.pageType === "Setting V Menu" ? (
                                          <NavLink
                                            to={`/${"config"}/${
                                              _menu.objectId
                                            }`}
                                            className="nav-link"
                                          >
                                            <i
                                              className={_menu.icon}
                                              title={_menu.description}
                                            ></i>
                                            {_menu.title}
                                          </NavLink>
                                        ) : (
                                          <NavLink
                                            to={`/${_menu.pageType}/${_menu.objectId}`}
                                            className="nav-link"
                                          >
                                            <i
                                              className={_menu.icon}
                                              title={_menu.description}
                                            ></i>
                                            {_menu.title}
                                          </NavLink>
                                        )}
                                      </li>
                                    ))}
                                </ul>
                              </li>
                            )
                          )}
                      </ul>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            className="sidemenu-container navbar-collapse collapse fixed-menu"
            id="accordionExample1"
          >
            <div id="remove-scroll" className="left-sidemenu">
              <ul
                className="sidemenu  page-header-fixed slimscroll-style"
                data-keep-expanded="false"
                data-auto-scroll="true"
                data-slide-speed="200"
                style={{ paddingTop: "20px" }}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <li className="sidebar-toggler-wrapper hide">
                  <div className="sidebar-toggler">
                    <span></span>
                  </div>
                </li>
                <li className="sidebar-user-panel">
                  <div className="user-panel">
                    <div className="pull-left image">
                      <img
                        src={_imgPath}
                        className="img-circle user-img-circle"
                        alt="User Image"
                      />
                    </div>
                    <div className="pull-left info">
                      <p>
                        <b> {username} </b>
                      </p>
                      <a onClick={(e) => e.preventDefault()}>
                        <span className="txtOnline">
                          {(TenantName ||
                            localStorage.getItem("TenantName")) && (
                            <span style={{ fontSize: "1.2rem" }}>
                              {TenantName || localStorage.getItem("TenantName")}
                              <br />
                            </span>
                          )}

                          {_role ? _role.toUpperCase() : " "}
                        </span>
                      </a>
                    </div>
                  </div>
                </li>
                {menuList.map((item, i) =>
                  !item.children ? (
                    <li key={uniqid()} className="nav-item">
                      {item.pageType === "Setting V Menu" ? (
                        <NavLink
                          to={`/${"config"}/${item.objectId}`}
                          className="nav-link nav-toggle"
                        >
                          <i
                            className={item.icon}
                            style={{ color: "#444" }}
                          ></i>
                          <span className="title" title={item.description}>
                            {item.title}{" "}
                          </span>
                        </NavLink>
                      ) : (
                        <React.Fragment>
                          {item.pageType === "microapp" ? (
                            <a
                              onClick={(e) => {
                                setLocalIframe(item.objectId);
                              }}
                              className="nav-link nav-toggle"
                            >
                              <i
                                className={item.icon}
                                style={{ color: "#444" }}
                              ></i>
                              <span className="title" title={item.description}>
                                {item.title}
                              </span>
                            </a>
                          ) : (
                            <NavLink
                              to={`/${item.pageType}/${item.objectId}`}
                              className="nav-link nav-toggle"
                            >
                              <i
                                className={item.icon}
                                style={{ color: "#444" }}
                              ></i>
                              <span className="title" title={item.description}>
                                {item.title}
                              </span>
                            </NavLink>
                          )}
                        </React.Fragment>
                      )}
                    </li>
                  ) : (
                    <li key={i} className="nav-item">
                      <a
                        className="nav-link nav-toggle"
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <i className={item.icon} style={{ color: "#444" }}></i>
                        <span className="title" title={item.description}>
                          {item.title}
                        </span>
                        <span className="arrow"></span>
                      </a>
                      <ul className="sub-menu">
                        {item.children.map((sub, j) =>
                          !sub.children ? (
                            <li key={uniqid()} className="nav-item">
                              {sub.pageType === "Setting V Menu" ? (
                                <NavLink
                                  to={`/${"config"}/${sub.objectId}`}
                                  className="nav-link"
                                >
                                  <i
                                    className={sub.icon}
                                    style={{ color: "#444" }}
                                  ></i>
                                  &nbsp;
                                  <span
                                    className="title"
                                    title={sub.description}
                                  >
                                    {sub.title}
                                  </span>
                                </NavLink>
                              ) : (
                                <React.Fragment>
                                  {sub.pageType === "microapp" ? (
                                    <a
                                      onClick={(e) => {
                                        setLocalIframe(sub.objectId);
                                      }}
                                      className="nav-link"
                                    >
                                      <i
                                        className={sub.icon}
                                        style={{ color: "#444" }}
                                      ></i>
                                      <span
                                        className="title"
                                        title={sub.description}
                                      >
                                        {sub.title}
                                      </span>
                                    </a>
                                  ) : (
                                    <NavLink
                                      to={`/${sub.pageType}/${sub.objectId}`}
                                      className="nav-link"
                                    >
                                      <i
                                        className={sub.icon}
                                        style={{ color: "#444" }}
                                      ></i>
                                      &nbsp;
                                      <span
                                        className="title"
                                        title={sub.description}
                                      >
                                        {sub.title}
                                      </span>
                                    </NavLink>
                                  )}
                                </React.Fragment>
                              )}
                            </li>
                          ) : (
                            <li key={sub.title} className="nav-item">
                              <a
                                className="nav-link nav-toggle"
                                onClick={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <i
                                  className={sub.icon}
                                  style={{ color: "#444" }}
                                ></i>
                                &nbsp;
                                <span className="title" title={sub.description}>
                                  {sub.title}
                                </span>
                                <span className="arrow "></span>
                              </a>
                              <ul className="sub-menu">
                                {sub.children.map((_menu, k) => (
                                  <li key={uniqid()} className="nav-item">
                                    {_menu.pageType === "Setting V Menu" ? (
                                      <NavLink
                                        to={`/${"config"}/${_menu.objectId}`}
                                        className="nav-link"
                                      >
                                        <i
                                          className={_menu.icon}
                                          style={{ color: "#444" }}
                                        ></i>
                                        &nbsp;
                                        {_menu.title}
                                      </NavLink>
                                    ) : (
                                      <NavLink
                                        to={`/${_menu.pageType}/${_menu.objectId}`}
                                        className="nav-link"
                                      >
                                        <i
                                          className={_menu.icon}
                                          style={{ color: "#444" }}
                                        ></i>
                                        &nbsp;
                                        {_menu.title}
                                      </NavLink>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </li>
                          )
                        )}
                      </ul>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
