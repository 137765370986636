export default (state = [], action) => {
  switch (action.type) {
    case "Level3_Dropdown":
      return { ...state, ...action.payload };
    case "Remove_Level3":
      state = {};
      return state;
    default:
      return { ...state };
  }
};
