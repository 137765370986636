import React, { useState, useEffect } from "react";
import "../Spinner/loader.css";

const IframeComponent = (props) => {
  const [state, setstate] = useState("");

  // this will work if microapp url don't have hash router
  // useEffect(() => {
  //   let str = props.location.pathname.replace("/microapp/", "");
  //   setstate(str);
  // }, [props]);

  useEffect(() => {
    let str = props.location.pathname.replace("/microapp/", "");
    let hashParam = props.location.hash;
    const desiredurl = str + hashParam;
    // console.log("hashParam ", hashParam);
    // const url = str.split("/");
    // const urlParam = url.pop();
    // const str2 = url.join("/");
    // const desiredurl = str2 + `/#/${urlParam}`;
    // console.log("original str ", str);
    // console.log("split url", url);
    // console.log("urlParam", urlParam);
    // console.log("str2", str2);
    // console.log("desired url", desiredurl);
    setstate(desiredurl);
  }, [props]);

  if (!state) {
    return (
      <div style={{ height: "300px" }}>
        <div
          style={{
            marginLeft: "45%",
            marginTop: "150px",
            fontSize: "45px",
            color: "#3dd3e0",
          }}
          className="loader-37"
        ></div>
      </div>
    );
  }
  return (
    <div
      style={{
        margin:"10px 0px 20px",
        borderRadius: 6,
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.33)",
        backgroundColor: "#ffffff",
      }}
    >
      <iframe
        src={state}
        style={{ 
          minHeight: "100vh", scroll: "hidden", borderRadius: 3 }}
        width="100%"
        title=""
      ></iframe>
    </div>
  );
};

export default IframeComponent;
