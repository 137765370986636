import { combineReducers } from "redux";
import infoReducer from "./infoReducer";
import loginReducer from "./loginReducer";
import roleReducer from "./roleReducer";
import Level_1 from "./Level_1";
import Level_2 from "./Level_2";
import Level_3 from "./Level_3";
import CartReducer from "./CartReducer";
import CartItemReducer from "./CartItemReducer";
import ShowTenant from "./ShowTenant";
import DependantDD from "reducers/DependantDDReducer";
import TourStepsReducer from "./TourStepsReducer";
export default combineReducers({
  appInfo: infoReducer,
  login: loginReducer,
  role: roleReducer,
  Level1_Dropdown: Level_1,
  Level2_Dropdown: Level_2,
  Level3_Dropdown: Level_3,
  remove: Level_1,
  removeLevel2: Level_2,
  removeLevel3: Level_3,
  enableCart: CartReducer,
  CartData: CartItemReducer,
  ShowTenant,
  DependantDD: DependantDD,
  TourSteps: TourStepsReducer
});
