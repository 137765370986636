export default (state = [], action) => {
  switch (action.type) {
    case "ADD_CART":
      return [...state, action.payload];
    case "MULTI_CART":
      return action.payload;
    case "UPDATE_CART":
      return action.payload;
    case "CLEAR_CART":
      return action.payload;
    case "REMOVE_CART":
      return state.filter((x) => x.objectId !== action.payload);
    default:
      return state;
  }
};
