import React, { useState } from "react";

const Thead = (props) => {
  const [header] = useState(props.header);

  const renderTableHeader = () => {
    return (
      <tr>
        <th>Sr.No</th>
        {header.map((val, i) => (
          <th key={i}>{val.displayValue}</th>
        ))}
        {props.length > 0 ? <th>Action</th> : null}
      </tr>
    );
  };

  return renderTableHeader();
};

export default Thead;
