import React, { Component } from "react";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import "../../StaticForm/Kanban/Task.css";
import ReactTooltip from "react-tooltip";
import Parse from "parse";
const $ = window.$;

const Container = styled.div`
  /*   border:1px solid lightgrey;
    border-radius:2px; */
  padding: 8px;
  margin-bottom: 8px;
  background-color: ${props => (props.isDragging ? "lightgreen" : "white")};
`;
const Cards = styled.div``;

export class Task extends Component {
  state = {
    viewArray: [],
    _viewData: {},
    feedback: "",
    _fdArr: [],
    parseBaseUrl: localStorage.getItem("baseUrl"),
    parseAppId: localStorage.getItem("parseAppId")
  };

  feedbackHandle = e => {
    this.setState({ feedback: e.target.value });
  };

  async submithandle(id) {
    
    Parse.serverURL = this.state.parseBaseUrl;
    Parse.initialize(this.state.parseAppId);
    var _kList = Parse.Object.extend("uat_Invoice");
    const query = new Parse.Query(_kList);
    query.equalTo("objectId", id);
    const results = await query.first();
    const resultjson = results.toJSON();
    let tempArr = [];
    tempArr = resultjson.feedback;
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let d = {
      message: this.state.feedback,
      date: date
    };
    this.setState({
      _fdArr: [d, ...this.state._fdArr],
      feedback: ""
    });
    tempArr.push(...tempArr, d);
    var list = Parse.Object.extend("uat_Invoice");
    const query1 = new Parse.Query(list);
    query1.get(id).then(object => {
      object.set("feedback", this.state._fdArr);
      object.save().then(
        response => {
          if (typeof document !== "undefined")
            console.log("Updated ", response);
          alert("Status updated successfully...");
          this.setState({
            feedback: ""
          });
        },
        error => {
          if (typeof document !== "undefined")
            console.error("Error while updating ", error);
        }
      );
    });
  }

  getCount = title => {
    if (title.length > 20) {
      return title.substr(0, 20) + "....";
    } else {
      return title;
    }
  };

  toolTip = title => {
    if (title.length > 50) {
      return title.substr(0, 50) + "<br/>" + title.substr(51, title.length - 1);
    } else {
      return title;
    }
  };

  render() {
    return (
      <>
        <Draggable draggableId={this.props.task.id} index={this.props.index}>
          {(provided, snapshot) => (
            <Container
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              isDragging={snapshot.isDragging}
            >
              <div className="Rectangle-353">
                <div className="Rectangle-354"></div>
                <div className="in_main">
                  <div className="-Oct-2019">{this.props.task.date}</div>
                  <div className="MHY">
                    <div
                      data-tip="React-tooltip"
                      style={{ left: "289px", top: "161px" }}
                      className="NxgLabs-Invoice"
                    >
                      {" "}
                      {this.getCount(this.props.task.title)}
                    </div>
                    <ReactTooltip place="bottom" type="info" effect="solid">
                      {this.props.task.title}
                    </ReactTooltip>
                    <div className="invoice_no">
                      {this.props.task.invoice_no}
                    </div>
                  </div>
                  <div className="invoice_desc">{this.props.task.content}</div>
                  <div className="LOKI">
                    <div className="invoice_amt">
                      Rs.{this.props.task.amount}
                    </div>
                    <div className="in_edit">
                      <button
                        className="btn btn-info btn-xs"
                        onClick={async () => {
                          try {
                            Parse.serverURL = this.state.parseBaseUrl;
                            Parse.initialize(this.state.parseAppId);
                            var _kList = Parse.Object.extend(
                              localStorage.getItem("KanbanViewClass")
                            );
                            const query = new Parse.Query(_kList);
                            query.equalTo("objectId", this.props.task.id);
                            const response = await query.first();
                            const resultjson = response.toJSON();
                            this.setState({
                              viewArray: Object.keys(resultjson),
                              _viewData: resultjson,
                              _fdArr: resultjson.feedback
                            });
                            if (this.state.viewArray !== null) {
                              $(this.modal).modal("show");
                            }
                          } catch (e) {}
                        }}
                      >
                        <i className="fa fa-eye"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          )}
        </Draggable>

        <div
          className="modal"
          id="myModal"
          style={{ display: "none" }}
          ref={modal => (this.modal = modal)}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title"></h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="table-responsive col-md-6">
                    <table
                      className="table table-bordered"
                      style={{ width: "100%" }}
                    >
                      <tbody>
                        {this.state.viewArray.map(val => {
                          return val === "class" ? null : val ===
                            "createdAt" ? null : val ===
                            "updatedAt" ? null : val ===
                            "objectId" ? null : val === "feedback" ? null : (
                            <tr key={val}>
                              <th>
                                {val === "invoice_no"
                                  ? "Invoice No."
                                  : val === "invoice_date"
                                  ? "Invoice Date"
                                  : val === "title"
                                  ? "Title"
                                  : val === "description"
                                  ? "Description"
                                  : val === "project_name"
                                  ? "Project Name"
                                  : val === "stage"
                                  ? "Current Stage"
                                  : val === "amount"
                                  ? "Amount"
                                  : val === "remark"
                                  ? "Remark"
                                  : val}
                              </th>
                              <td>{this.state._viewData[val]}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6 ">
                    <div>
                      <h5>
                        {" "}
                        <i className="fa fa-bars" aria-hidden="true"></i>{" "}
                        Feedback
                      </h5>
                    </div>

                    <ul
                      className="list-group"
                      style={{
                        overflowY: "scroll",
                        overflowX: "hidden",
                        height: "400px"
                      }}
                    >
                      {this.state._fdArr == null
                        ? null
                        : this.state._fdArr.map(m => {
                            return (
                              <li
                                key={m + "33"}
                                className="list-group-item d-flex justify-content-between align-items-center"
                              >
                                <div>
                                  {m.message}
                                  <br />
                                  <span className="badge badge-primary badge-pill">
                                    {m.date}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                    </ul>
                    <br />

                    <div className="form-group">
                      <textarea
                        value={this.state.feedback}
                        onChange={this.feedbackHandle}
                        placeholder="write a comment..."
                        className="form-control"
                      ></textarea>
                    </div>
                    <button
                      type="button"
                      onClick={() =>
                        this.submithandle(this.state._viewData["objectId"])
                      }
                      className="btn btn-danger"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Task;
