import React, { Component } from "react";

export class ContentData extends Component {
  render() {
    return this.props.Data[0] === "objectId" ||
      this.props.Data[0] === "updatedAt" ||
      this.props.Data[0] === "createdAt" ||
      this.props.Data[0] === "mainformObjId" ||
      this.props.Data[0] === "class" ? null : this.props.Data[0] ===
      "attachment" ? (
      <tr>
        <td width="50%" style={{paddingLeft: 2}}>{this.props.Data[0]}</td>
        <td width="300px">
          <img src={this.props.Data[1]} alt="img" width="100%" height="200px" />
        </td>
      </tr>
    ) : (
      <tr>
        <td width="50%" style={{paddingLeft: 2}}>{this.props.Data[0]}</td>
        <td width="300px">
          {this.props.Data[1] === true
            ? "Yes"
            : this.props.Data[1] === false
            ? "No"
            : this.props.Data[1]}
        </td>
      </tr>
    );
  }
}

export default ContentData;
