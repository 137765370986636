import React, { useEffect, useState } from "react";
import LabelField from "./LabelField";
import Parse from "parse";
import axios from "axios";

const DynamicRadio = (props) => {
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const [state, setState] = useState(undefined);

  const getRadioList = async () => {
    try {
      let response = [];
      let _query = props.schema.data.query;
      let test = "";
      let str = _query.split("#$").join("$");
      str = str.split("#*").join("$");
      str = str.split("_DOT_").join(".");

      if (str.includes("#")) {
        Parse.serverURL = parseBaseUrl;
        Parse.initialize(parseAppId);
        const currentUser = Parse.User.current();
        var reg = /(\#.*?\#)/gi;
        let res;
        if (localStorage.getItem("Extand_Class")) {
          let data = JSON.parse(localStorage.getItem("Extand_Class"));
          res = data[0];
        } else {
          var emp = Parse.Object.extend(localStorage.getItem("extended_class"));
          var q = new Parse.Query(emp);
          q.equalTo("UserId", currentUser);
          res = await q.first();
          if (res) res = res.toJSON();
        }
        if (res) {
          let json = res;
          let output = str.match(reg);
          if (output.length === 1) {
            output = output.join();
            output = output.substring(1, output.length - 1);
            output = output.split(".");
            if (output.length === 1) {
              let out = output[0];
              if (json[out]) {
                if (typeof json[out] === "object") {
                  test = str.replace(reg, JSON.stringify(json[out]));
                } else {
                  test = str.replace(reg, json[out]);
                }
              } else {
                test = str.replace(reg, currentUser.id);
              }
            } else if (output.length === 2) {
              let out1 = json[output[0]][output[1]];
              if (out1) {
                test = str.replace(reg, out1);
              }
            }
          }
        }
      } else {
        test = str;
      }
      let url = `${parseBaseUrl}classes/${props.schema.data.class}?${test}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": parseAppId,
        "X-Parse-Session-Token": localStorage.getItem("accesstoken"),
      };
      await axios.get(url, { headers: headers }).then((res) => {
        let temp = [];
        res.data.results.forEach((x) => {
          let obj = {};
          obj = {
            name: x[props.schema.data.displayKey],
            value: x[props.schema.data.valueKey],
          };
          temp.push(obj);
        });
        response = temp;
        setState({ ...state, [`${props.name}_DD`]: response });
      });
    } catch (error) {}
  };

  const handleCheck = (e) => {
    try {
      props.onChange(e.target.value);
      document.getElementById(e.target.value).checked = "checked";
    } catch (error) {}
  };

  useEffect(() => {
    getRadioList();
  }, []);

  useEffect(() => {
    if (props.formData) {
      if (typeof props.formData === "object") {
        let radio = document.getElementById(props.formData.objectId);
        if (radio) {
          radio.checked = "checked";
        }
      } else {
        let radio = document.getElementById(props.formData);
        if (radio) {
          radio.checked = "checked";
        }
      }
    }
  }, [props.formData, state]);

  return (
    <React.Fragment>
      <LabelField
        Title={props.schema.title}
        Name={props.name}
        Required={props.required}
        HelpBody={props.schema.helpbody}
        HelpLink={props.schema.helplink}
      />
      <div>
        {state &&
        state[`${props.name}_DD`] &&
        state[`${props.name}_DD`].length > 0 ? (
          state[`${props.name}_DD`].map((r, i) => (
            <label
              key={r.value}
              style={{
                display: "inline-flex",
                cursor: "pointer",
                lineHeight: "20px",
                fontSize: "14px",
                margin: "4px",
              }}
            >
              {props.schema.type === "boolean" ? (
                <>
                  {r.value ? (
                    <input
                      type="checkbox"
                      id={r.value}
                      name={props.name}
                      value={r.value}
                      onChange={props.onChange(r.value)}
                      checked
                      style={{ float: "right" }}
                      disabled={props.uiSchema["ui:disabled"]}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      id={r.value}
                      name={props.name}
                      value={r.value}
                      onChange={props.onChange(r.value)}
                      checked={false}
                      style={{ float: "right" }}
                      disabled={props.uiSchema["ui:disabled"]}
                    />
                  )}
                  <span
                    style={{
                      position: "relative",
                      display: "block",
                      marginRight: "10px",
                      marginTop: "-4px",
                      fontSize: "13px",
                      width: "200px",
                      height: "20px",
                      float: "left",
                    }}
                  >
                    {r.name}
                  </span>{" "}
                </>
              ) : (
                <>
                  <input
                    type="radio"
                    id={r.value}
                    name={props.name}
                    value={r.value}
                    onChange={handleCheck}
                    style={{ float: "right" }}
                  />
                  <span
                    style={{
                      position: "relative",
                      display: "block",
                      marginRight: "10px",
                      marginTop: "-4px",
                      fontSize: "13px",
                      width: "200px",
                      height: "20px",
                      float: "left",
                    }}
                  >
                    {r.name}
                  </span>{" "}
                </>
              )}
            </label>
          ))
        ) : (
          <span>No Content</span>
        )}{" "}
      </div>
    </React.Fragment>
  );
};

export default DynamicRadio;
