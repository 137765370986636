import React, { Component } from "react";
import Parse from "parse";
import Imageviewer from "./Imageviewer";
import { Redirect } from "react-router-dom";

class ReportViewer extends Component {
  state = {
    _title: [],
    _data: [],
    _reportId: "",
    parseBaseUrl: localStorage.getItem("baseUrl"),
    parseAppId: localStorage.getItem("parseAppId"),
  };

  async getform(id) {
    try {
      Parse.serverURL = this.state.parseBaseUrl;
      Parse.initialize(this.state.parseAppId);
      var forms = Parse.Object.extend("w_formV3");
      var query = new Parse.Query(forms);
      query.equalTo("objectId", id);
      const results = await query.first();
      const resultjson = results.toJSON();
      let result = resultjson;
      this.setState(async () => {
        var report = Parse.Object.extend(resultjson.class);
        var query = new Parse.Query(report);
        const result = await query.find();
        var tempData = [];
        for (var t in result) {
          tempData.push(result[t].toJSON());
        }
        let title = Object.keys(tempData[0]).map(function (key) {
          return [String(key)];
        });

        this.setState({
          _title: title,
          _data: tempData,
        });
      });
    } catch (e) {
      console.error("Problem", e);
    }
  }

  renderTableRow(item) {
    return this.state._title.map((val, h) => {
      return val === "applogo" ? (
        <Imageviewer image={item[val]} />
      ) : val === "choosefile" ? (
        <Imageviewer image={item[val]} />
      ) : val === "chooseFile" ? (
        <Imageviewer image={item[val]} />
      ) : val === "createdAt" ? null : val === "updatedAt" ? null : val ===
        "objectId" ? null : (
        <td key={item["objectId"]}>{item[val]}</td>
      );
    });
  }

  renderTableData() {
    return this.state._data.map((item, key) => {
      return (
        <tr key={"a" + key}>
          <td>{key + 1}</td>
          {this.renderTableRow(item)}
          <td>
            <button
              onClick={() => {
                let { history } = this.props;
                // localStorage.setItem('objectId', item.objectId);
                history.push({
                  pathname: "/view/" + item.objectId + "/" + item.mainformObjId,
                });
              }}
              className="btn btn-info btn-xs"
            >
              <i className="fa fa-eye" aria-hidden="true"></i>{" "}
            </button>
            <button
              onClick={() => {
                let { history } = this.props;
                history.push({
                  pathname: "/edit/" + item.objectId + "/" + item.mainformObjId,
                });
              }}
              className="btn btn-success btn-xs"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              onClick={() => {
                Parse.serverURL = this.state.parseBaseUrl;
                Parse.initialize(this.state.parseAppId);
                var report = Parse.Object.extend(item.class);
                var query = new Parse.Query(report);
                query.get(item.objectId).then(
                  (x) => {
                    x.destroy().then(
                      (y) => {
                        alert("Record deleted successfully.");
                      },
                      (error) => {
                        //Failed
                      }
                    );
                  },
                  (error) => {
                    // The object was not retrieved successfully.
                  }
                );
              }}
              className="btn btn-danger btn-xs"
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      );
    });
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    this.getform(id);
    // this.getReport()
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      _title: [],
      _data: [],
    });
    let id = newProps.match.params.id;
    this.getform(id);
  }
  render() {
    if (localStorage.getItem("accesstoken") === null) {
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card card-box">
              <div className="card-head">
                <header>ReportViewer</header>
              </div>
              <div className="card-body ">
                <div className="table-wrap">
                  <div className="table-responsive">
                    <table
                      className="table table-striped table-bordered table-hover table-checkable order-column valign-middle"
                      id="example5"
                    >
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          {this.state._title.map((h, i) =>
                            h === "objectId" ? null : h ===
                              "createdAt" ? null : h === "updatedAt" ? null : (
                              <th key={h}>{h}</th>
                            )
                          )}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>{this.renderTableData()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReportViewer;
