import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

function Title(props) {
  return (
    <Helmet>
      <title>{`${
        localStorage.getItem("appTitle") ? localStorage.getItem("appTitle") : ""
      } - ${props.title}`}</title>
      <meta
        name="description"
        content={`${localStorage.getItem("appTitle")} - ${props.title}`}
      />
      <link
        rel="icon"
        type="image/png"
        href={localStorage.getItem("fev_Icon")}
        sizes="40x40"
      />
    </Helmet>
  );
}

Title.propType = {
  title: PropTypes.string.isRequired,
};
export default Title;
