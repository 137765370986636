import React, { useState } from "react";
import LabelField from "./LabelField";

function ShowPercentSign(props) {
  const [percent, setPercent] = useState(props.formData ? props.formData : "");

  return (
    <React.Fragment>
      <LabelField
        Title={props.schema.title}
        Name={props.name}
        Required={props.required}
        HelpBody={props.schema.helpbody}
        HelpLink={props.schema.helplink}
      />
      <div className="input-group mb-3">
        <input
          type="number"
          className="form-control"
          value={percent}
          max="100"
          onChange={(e) => {
            if (e.target.value > 100) {
            } else {
              setPercent(e.target.value);
              props.onChange(percent);
            }
          }}
        />
        <div className="input-group-append">
          <span
            className="input-group-text"
            style={{ fontSize: "16px", width: "100%" }}
          >
            %
          </span>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ShowPercentSign;
