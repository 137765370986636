import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./../Spinner/loader.css";
import { onChangeLevel3Dropdown } from "../actions";
import axios from "axios";
import LabelField from "./LabelField";

const Level3Dropdown = (props) => {
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const [isLoading, setIsLoading] = useState(false);
  const [toastColor, setToastColor] = useState("#d9534f");
  const [toastDescription, setToastDescription] = useState("");
  const [state, setState] = useState({ [`${props.name}_DD`]: [] });
  const [parentId, setParentId] = useState("");

  const Level2DropdownData = async (id) => {
    if (id !== "undefined" && id !== null) {
      setIsLoading(true);
      let response = [];
      if (props.schema.parent) {
        let reg = /(\#.*?\#)/gi;
        let _query = props.schema.data.query.replace(reg, id);
        try {
          let url = `${parseBaseUrl}classes/${props.schema.data.class}?${_query}`;
          const headers = {
            "Content-Type": "application/json",
            "X-Parse-Application-Id": parseAppId,
            "X-Parse-Session-Token": localStorage.getItem("accesstoken"),
          };
          await axios
            .get(url, { headers: headers })
            .then((res) => {
              let temp = [];
              res.data.results.forEach((x) => {
                if (x[props.schema.data.valueKey]) {
                  let opt = "";
                  if (Array.isArray(x[props.schema.data.valueKey])) {
                    x[props.schema.data.valueKey].forEach((tt) => {
                      if (typeof tt === "object") {
                        opt = {
                          objectId: tt[props.schema.data.displayKey],
                          option: tt[props.schema.data.displayKey],
                        };
                      } else {
                        opt = {
                          objectId: tt,
                          option: tt,
                        };
                      }
                      temp.push(opt);
                    });
                  } else if (props.schema.data.isPointer) {
                    if (props.schema.data.valueKey.includes(".")) {
                      let newArr = props.schema.data.valueKey.split(".");
                      if (newArr.length === 2) {
                        opt = {
                          objectId: x[newArr[0]][newArr[1]],
                          option: x[props.schema.data.displayKey],
                        };
                      } else {
                        opt = {
                          objectId: x[newArr[0]],
                          option: x[props.schema.data.displayKey],
                        };
                      }
                    } else {
                      opt = {
                        objectId: x[props.schema.data.valueKey],
                        option: x[props.schema.data.displayKey],
                      };
                    }
                    temp.push(opt);
                  } else {
                    opt = {
                      objectId: x[props.schema.data.valueKey],
                      option: x[props.schema.data.displayKey],
                    };
                    temp.push(opt);
                  }
                }
              });
              response = temp;
              setState({
                [`${props.name}_DD`]: response,
              });
              setIsLoading(false);
              if (response.length === 1) {
                props.onChangeLevel3Dropdown(response[0].objectId, props.name);
                props.onChange(response[0].objectId);
              }
            })
            .catch((err) => {
              setIsLoading(false);
              setToastDescription(err.message);
            });
        } catch (error) {
          console.error("Problem", error);
          setIsLoading(false);
          setToastDescription(error.message);
        }
      }
    }
  };

  useEffect(() => {
    var x = document.getElementById("Level_3");
    x.className = "show";
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 5000);

    // eslint-disable-next-line
  }, [toastDescription]);

  const uniqueParentId = (id) => {
    Level2DropdownData(id);
  };

  useEffect(() => {
    if (
      props.Level2_Dropdown[props.schema.parent] &&
      props.Level2_Dropdown[props.schema.parent] !== "undefined" &&
      props.Level2_Dropdown[props.schema.parent] !== "Select"
    ) {
      uniqueParentId(props.Level2_Dropdown[props.schema.parent]);
    } else if (
      props.Level2_Dropdown[props.schema.parent] === "" ||
      props.Level2_Dropdown[props.schema.parent] === "Select"
    ) {
      props.onChangeLevel3Dropdown("", props.name);
      setState({
        [`${props.name}_DD`]: [],
      });
    }

    // eslint-disable-next-line
  }, [props.Level2_Dropdown[props.schema.parent]]);

  useEffect(() => {
    if (props.formData && props.formData[`objectId`]) {
      props.onChangeLevel3Dropdown(props.formData.objectId, props.name);
    } else if (props.formData) {
      props.onChangeLevel3Dropdown(props.formData, props.name);
    }

    // eslint-disable-next-line
  }, [props.formData]);

  const selectDefault = () => {
    props.onChange(state[`${props.name}_DD`][0].objectId);
    props.onChangeLevel1Dropdown(
      state[`${props.name}_DD`][0].objectId,
      props.name
    );
  };

  return (
    <React.Fragment>
      <LabelField
        Title={props.schema.title}
        Name={props.name}
        Required={props.required}
        HelpBody={props.schema.data.helpbody}
        HelpLink={props.schema.data.helplink}
      />
      {state[`${props.name}_DD`] && state[`${props.name}_DD`].length === 1 ? (
        <select
          className="form-control"
          name={props.name}
          value={props.value}
          required={props.required}
          disabled={props.uiSchema["ui:disabled"]}
          onChange={(e) => {
            props.onChangeLevel3Dropdown(e.target.value, props.name);
            return props.onChange(e.target.value);
          }}
          defaultValue={selectDefault}
        >
          <option value="">Select</option>
          {state[`${props.name}_DD`] &&
            state[`${props.name}_DD`].map((x, i) => (
              <React.Fragment key={i}>
                {props.formData && props.formData.objectId ? (
                  props.formData.objectId === x.objectId ? (
                    <option value={x.objectId} selected>
                      {x.option}
                    </option>
                  ) : (
                    <option value={x.objectId}>{x.option}</option>
                  )
                ) : props.formData && props.formData === x.objectId ? (
                  <option value={x.objectId} selected>
                    {x.option}
                  </option>
                ) : (
                  <option value={x.objectId}>{x.option}</option>
                )}
              </React.Fragment>
            ))}
        </select>
      ) : (
        <select
          className="form-control"
          name={props.name}
          value={props.value}
          required={props.required}
          disabled={props.uiSchema["ui:disabled"]}
          onChange={(e) => {
            props.onChangeLevel3Dropdown(e.target.value, props.name);
            return props.onChange(e.target.value);
          }}
        >
          <option value="">{!isLoading ? "Select" : "Loading..."}</option>
          {state[`${props.name}_DD`] && state[`${props.name}_DD`].length === 1
            ? state[`${props.name}_DD`].map((x, i) => (
                <option value={x.objectId} selected>
                  {x.option}
                </option>
              ))
            : state[`${props.name}_DD`] &&
              state[`${props.name}_DD`].map((x, i) => (
                <React.Fragment key={i}>
                  {props.formData && props.formData.objectId ? (
                    props.formData.objectId === x.objectId ? (
                      <option value={x.objectId} selected>
                        {x.option}
                      </option>
                    ) : (
                      <option value={x.objectId}>{x.option}</option>
                    )
                  ) : props.formData && props.formData === x.objectId ? (
                    <option value={x.objectId} selected>
                      {x.option}
                    </option>
                  ) : (
                    <option value={x.objectId}>{x.option}</option>
                  )}
                </React.Fragment>
              ))}
        </select>
      )}
      <div id="Level_3" style={{ backgroundColor: toastColor }}>
        {toastDescription}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    Level2_Dropdown: state.Level2_Dropdown,
  };
};

export default connect(mapStateToProps, {
  onChangeLevel3Dropdown,
})(Level3Dropdown);
