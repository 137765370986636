import React from "react";
import Package from "../../package.json";

const Footer = (props) => {
  let footer = localStorage.getItem("_appName");
  return (
    <>
      <div className="page-footer-inner">
        {" "}
        All Rights Reserved.&copy; {new Date().getFullYear()} &nbsp;
        {footer && `${footer.charAt(0).toUpperCase()}${footer.slice(1)}`}{" "}
        (version:{" "}
        {localStorage.getItem("appVersion") &&
          `${Package.version}.${localStorage.getItem("appVersion")}`}
        )
      </div>
      <div className="scroll-to-top">
        <i className="icon-arrow-up"></i>
      </div>
    </>
  );
};

export default Footer;
