import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Parse from "parse";
import SubObject from "../components/SubObject";
import ContentData from "../components/ContentData";
import Title from "./Title";

const $ = window.$;

class ViewDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      theader: [],
      title: [],
      _formtitle: "",
      Imageurl: "",
      parseBaseUrl: localStorage.getItem("baseUrl"),
      parseAppId: localStorage.getItem("parseAppId"),
    };
  }

  renderData = (str) => {
    if (typeof str === "object") {
      return <SubObject Data={str} />;
    }
    let r = str && typeof str === "object" && str.constructor === Object;
    if (r) {
      return str.objectId;
    } else {
      return str;
    }
  };

  viewRecord = (item, i, val) => {
    if (val === "User") {
      return null;
    } else if (val === "class") return null;
    else if (val === "mainformObjId") return null;
    else if (val === "createdAt") return null;
    else if (val === "updatedAt") return null;
    else if (val === "objectId") return null;
    else if (val === "CreatedBy") return null;
    return val === "applogo" ? (
      <td>
        <button
          onClick={() => {
            this.setState({
              Imageurl: item[i][1],
            });
            $(this.modal).modal("show");
          }}
        >
          {" "}
          <img src={item[i][1]} alt="" />
        </button>
      </td>
    ) : val === "choosefile" ? (
      <td>
        <button
          onClick={() => {
            this.setState({
              Imageurl: item[i][1],
            });
            $(this.modal).modal("show");
          }}
        >
          {" "}
          <img src={item[i][1]} alt="" />
        </button>
      </td>
    ) : val === "attachment" ? (
      <td>
        <button
          onClick={() => {
            this.setState({
              Imageurl: item[i][1],
            });
            $(this.modal).modal("show");
          }}
        >
          {" "}
          <img
            src={item[i][1]}
            alt=""
            style={{ width: "80%", height: "50%" }}
          />
        </button>
      </td>
    ) : val === "chooseFile" ? (
      <td>
        <button
          onClick={() => {
            this.setState({
              Imageurl: item[i][1],
            });
            $(this.modal).modal("show");
          }}
        >
          {" "}
          <img src={item[i][1]} alt="" />
        </button>
      </td>
    ) : val === "steps" ? null : val === "feedback" ? null : val ===
      "updatedAt" ? (
      <td>
        {new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        }).format(item.updatedAt)}
      </td>
    ) : (
      <td>{this.renderData(item[i][1])}</td>
    );
  };

  renderHeader = (val) => {
    if (val === "User") {
      return null;
    } else if (val === "class") return null;
    else if (val === "mainformObjId") return null;
    else if (val === "isActive") return null;
    else if (val === "createdAt") return null;
    else if (val === "updatedAt") return null;
    else if (val === "objectId") return null;
    else if (val === "CreatedBy") return null;
    else if (val === "projectId") return null;
    else if (val === "employeeId") return null;
    return <th>{val}</th>;
  };

  TableData = (item, i, val) => {
    if (typeof item[i][1] === "object") {
      return <SubObject Data={item} />;
    }
  };

  onDelete = (cls, id) => {
    if (window.confirm(`Are you sure you want to delete this record`)) {
      try {
        Parse.serverURL = this.state.parseBaseUrl;
        Parse.initialize(this.state.parseAppId);
        var deleted = Parse.Object.extend(cls);
        var query = new Parse.Query(deleted);
        query.get(id).then(
          (x) => {
            x.destroy().then(
              (y) => {
                alert("Record deleted successfully.");
                this.props.history.goBack();
              },
              (error) => {
                //Failed
              }
            );
          },
          (error) => {
            // The object was not retrieved successfully.
          }
        );
      } catch (error) {}
    }
  };
  renderRowData(item) {
    return this.state.title.map((val, i) => {
      return (
        <tbody key={i}>
          {typeof item[i][1] === "object" ? (
            <SubObject Data={item[i]} />
          ) : (
            <ContentData Data={item[i]} />
          )}
        </tbody>
      );
    });
  }

  async getform() {
    if (localStorage.getItem("_appName") === "invoicetracker") {
      try {
        Parse.serverURL = this.state.parseBaseUrl;
        Parse.initialize(this.state.parseAppId);
        var _record = Parse.Object.extend(this.props.match.params.formid);
        var query1 = new Parse.Query(_record);
        query1.get(this.props.match.params.id).then(
          (x) => {
            let result = x.toJSON();
            let obj = result;
            let _theader = Object.keys(obj).map(function (key) {
              return [String(key), obj[key]];
            });
            let _title = Object.keys(result).map(function (key) {
              return String(key);
            });
            this.setState({
              theader: _theader,
              title: _title,
              _formtitle: this.props.match.params.formid,
            });

            let theader1 = Object.keys(obj).map(function (key) {
              return [obj[key]];
            });
            let d = theader1;
            let tContains = [];
            for (var l in d) {
              tContains.push(d[l][0]);
            }
            let h = _theader;
            let tHeading = [];
            for (var p in h) {
              tHeading.push(h[p][0]);
            }
          },
          (error) => {
            //Failed
          }
        );
      } catch (e) {
        console.error("Problem", e);
      }
    }
    if (
      this.props.match.params.formid === "expensetracker_AssignProjectEmptbl" ||
      this.props.match.params.formid === "expensetracker_TransactionDetails"
    ) {
      try {
        Parse.serverURL = this.state.parseBaseUrl;
        Parse.initialize(this.state.parseAppId);
        var _record = Parse.Object.extend(this.props.match.params.formid);
        var query1 = new Parse.Query(_record);
        query1.get(this.props.match.params.id).then(
          (x) => {
            let result = x.toJSON();
            let obj = result;
            let _theader = Object.keys(obj).map(function (key) {
              return [String(key), obj[key]];
            });
            let _title = Object.keys(result).map(function (key) {
              return String(key);
            });
            this.setState({
              theader: _theader,
              title: _title,
              _formtitle: this.props.match.params.formid,
            });

            let theader1 = Object.keys(obj).map(function (key) {
              return [obj[key]];
            });
            let d = theader1;
            let tContains = [];
            for (var l in d) {
              tContains.push(d[l][0]);
            }
            let h = _theader;
            let tHeading = [];
            for (var p in h) {
              tHeading.push(h[p][0]);
            }
          },
          (error) => {
            //Failed
          }
        );
      } catch (e) {
        console.error("Problem", e);
      }
    } else {
      try {
        Parse.serverURL = this.state.parseBaseUrl;
        Parse.initialize(this.state.parseAppId);
        var form = Parse.Object.extend("w_formV3");
        var query = new Parse.Query(form);
        query.equalTo("objectId", this.props.match.params.formid);
        const results = await query.first();
        var jsonform = results.toJSON();
        this.setState(async () => {
          var _record = Parse.Object.extend(jsonform.class);
          var query1 = new Parse.Query(_record);
          await query1.get(this.props.match.params.id).then(
            (x) => {
              let result = x.toJSON();
              let obj = result;
              let _theader = Object.keys(obj).map(function (key) {
                return [String(key), obj[key]];
              });
              let _title = Object.keys(result).map(function (key) {
                return String(key);
              });
              this.setState({
                theader: _theader,
                title: _title,
                _formtitle: jsonform.class,
              });

              let theader1 = Object.keys(obj).map(function (key) {
                return [obj[key]];
              });
              let d = theader1;
              let tContains = [];
              for (var l in d) {
                tContains.push(d[l][0]);
              }
              let h = _theader;
              let tHeading = [];
              for (var p in h) {
                tHeading.push(h[p][0]);
              }
            },
            (error) => {
              //Failed
            }
          );
        });
      } catch (e) {
        console.error("Problem", e);
      }
    }
  }

  componentDidMount() {
    this.getform();
  }

  render() {
    return (
      <React.Fragment>
        <Title title={this.state._formtitle} />
        <div className="card-box">
          <div className="card-head">
            <h3 style={{ padding: "5px 10px" }}>{this.state._formtitle}</h3>

            <div className="pull-right" style={{ paddingRight: "10px" }}>
              {/*  <button className="btn btn-info" style={{ marginBottom: '4px' }} onClick={() => {
                                this.props.history.push('/edit/' + this.props.match.params.id + '/' + this.props.match.params.formid)
                            }}>
                                Edit
                            </button>&nbsp; */}
              <button
                className="btn btn-info"
                style={{ marginBottom: "4px" }}
                onClick={() => {
                  this.onDelete(
                    this.state._formtitle,
                    this.props.match.params.id
                  );
                }}
              >
                Delete
              </button>{" "}
              &nbsp;
              <button
                className="btn btn-info"
                style={{ marginBottom: "4px" }}
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                Back
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="table-wrapper-scroll-y my-custom-scrollbar">
              <table
                width="100%"
                className="table table-bordered  mb-0"
              >
                {this.renderRowData(this.state.theader)}
              </table>
            </div>
          </div>
        </div>
        <div
          id="myModal"
          ref={(modal) => (this.modal = modal)}
          className="modal fade"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times-circle" aria-hidden="true"></i>
                </button>
              </div>
              <div className="modal-body">
                <img src={this.state.Imageurl} width="100%" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(ViewDetails);
