import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { onChangeLevel1Dropdown } from "../actions";
import LabelField from "./LabelField";

function TextBox(props) {
  const [capitalise, setCapitalise] = useState("");
  const [ForData, setForData] = useState(true);
  const [isDisabled, setisDisabled] = useState(false);

  const DependandField = async (search) => {
    try {
      if (props.schema.parent) {
        setisDisabled(true);
        const headers = {
          "Content-Type": "application/json",
          "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
          "X-Parse-Session-Token": localStorage.getItem("accesstoken"),
        };
        let reg = /(\#.*?\#)/gi;
        let _query = props.schema.data.query.replace(reg, search);
        let url = `${localStorage.getItem("baseUrl")}classes/${
          props.schema.data.class
        }?${_query}`;
        await axios
          .get(url, { headers: headers })
          .then((res) => {
            setisDisabled(false);
            res.data.results.forEach((x) => {
              if (
                x[props.schema.data.valueKey] === 0 ||
                x[props.schema.data.valueKey]
              ) {
                setCapitalise(
                  props.schema.type === "number"
                    ? Number(x[props.schema.data.valueKey])
                    : x[props.schema.data.valueKey]
                );

                props.onChange(
                  props.schema.type === "number"
                    ? Number(x[props.schema.data.valueKey])
                    : x[props.schema.data.valueKey]
                );
              } else {
                setCapitalise(props.schema.type === "number" ? 0 : "");
                props.onChange(props.schema.type === "number" ? 0 : "");
              }
            });
          })
          .catch((error) => {
            setisDisabled(false);
          });
      }
    } catch (error) {
      setisDisabled(false);
    }
  };

  useEffect(() => {
    if (
      props.Level1_Dropdown[props.schema.parent] !== undefined &&
      props.Level1_Dropdown[props.schema.parent] !== "undefined" &&
      props.formData !== undefined &&
      ForData
    ) {
      setForData(false);
    } else if (
      props.Level1_Dropdown[props.schema.parent] !== undefined &&
      props.Level1_Dropdown[props.schema.parent] !== "undefined"
    ) {
      DependandField(props.Level1_Dropdown[props.schema.parent]);
    }

    // eslint-disable-next-line
  }, [props.Level1_Dropdown[props.schema.parent]]);

  useEffect(() => {
    if (
      props.Level2_Dropdown[props.schema.parent] !== undefined &&
      props.Level2_Dropdown[props.schema.parent] !== "undefined" &&
      props.formData !== undefined &&
      ForData
    ) {
      setForData(false);
    } else if (
      props.Level2_Dropdown[props.schema.parent] !== undefined &&
      props.Level2_Dropdown[props.schema.parent] !== "undefined"
    ) {
      DependandField(props.Level2_Dropdown[props.schema.parent]);
    }

    // eslint-disable-next-line
  }, [props.Level2_Dropdown[props.schema.parent]]);

  useEffect(() => {
    if (
      props.Level3_Dropdown[props.schema.parent] !== undefined &&
      props.Level3_Dropdown[props.schema.parent] !== "undefined" &&
      props.formData !== undefined &&
      ForData
    ) {
      setForData(false);
    } else if (
      props.Level3_Dropdown[props.schema.parent] !== undefined &&
      props.Level3_Dropdown[props.schema.parent] !== "undefined"
    ) {
      DependandField(props.Level3_Dropdown[props.schema.parent]);
    }

    // eslint-disable-next-line
  }, [props.Level3_Dropdown[props.schema.parent]]);

  useEffect(() => {
    if (!props.formData) {
      setCapitalise("");
    }

    // eslint-disable-next-line
  }, [props.formData]);

  useEffect(() => {
    if (!capitalise && !props.formData) {
      props.onChange(undefined);
    }

    // eslint-disable-next-line
  }, [capitalise]);

  return (
    <React.Fragment>
      <LabelField
        Title={props.schema.title}
        Name={props.name}
        Required={props.required}
        HelpBody={props.schema.helpbody}
        HelpLink={props.schema.helplink}
      />
      <input
        type={
          props.schema.type && props.schema.type === "number"
            ? "number"
            : props.schema.type === "date"
            ? "date"
            : "text"
        }
        className="form-control"
        maxLength={props.schema.maxlength && props.schema.maxlength}
        minLength={props.schema.minlength && props.schema.minlength}
        required={props.required && props.required}
        readOnly={props.readonly && props.readonly}
        disabled={
          props.uiSchema["ui:disabled"]
            ? props.uiSchema["ui:disabled"]
            : isDisabled
        }
        value={props.formData && props.formData ? props.formData : capitalise}
        onChange={(e) => {
          setCapitalise(
            props.schema.type === "number"
              ? Number(e.target.value)
              : e.target.value
          );
          props.onChange(
            props.schema.type === "number"
              ? Number(e.target.value)
              : e.target.value
          );
        }}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    Level1_Dropdown: state.Level1_Dropdown,
    Level2_Dropdown: state.Level2_Dropdown,
    Level3_Dropdown: state.Level3_Dropdown,
  };
};

export default connect(mapStateToProps, {
  onChangeLevel1Dropdown,
})(TextBox);
