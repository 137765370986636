export default (state = [], action) => {
  switch (action.type) {
    case "SAVE_DEPENDANTDD":
      return { ...state, ...action.payload };
    case "REMOVE_DEPENDANTDD":
      // state = {};
      return { ...action.payload };
    case "REMOVE_ALLDEPENDANTDD":
      return {};
    default:
      return { ...state };
  }
};
