import React, { useState } from "react";
import LabelField from "./LabelField";

function AutoCapitalise(props) {
  const [capitalise, setCapitalise] = useState("");

  return (
    <React.Fragment>
      <LabelField
        Title={props.schema.title}
        Name={props.name}
        Required={props.required}
        HelpBody={props.schema.helpbody}
        HelpLink={props.schema.helplink}
      />
      <input
        type="text"
        className="form-control"
        maxLength={props.schema.maxlength && props.schema.maxlength}
        minLength={props.schema.minlength && props.schema.minlength}
        required={props.required && props.required}
        readOnly={props.readonly && props.readonly}
        disabled={props.uiSchema["ui:disabled"]}
        placeholder={props.uiSchema["ui:placeholder"]}
        value={props.formData && props.formData ? props.formData : capitalise}
        onChange={(e) => {
          setCapitalise(e.target.value.toUpperCase());
          props.onChange(e.target.value.toUpperCase());
        }}
      />
    </React.Fragment>
  );
}

export default AutoCapitalise;
