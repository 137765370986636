import React, { Component } from 'react'
import Form from 'react-jsonschema-form'
import { withRouter } from 'react-router-dom';
import Parse from 'parse';
import Spinner from "../Spinner/Spinner";
import LayoutField from "react-jsonschema-form-layout-2";
import TimeWidget from "../CustomeWidget/TimeWidget";
import RoleField from "../CustomeWidget/RoleField";
import LayoutGridField from "react-jsonschema-form-layout-grid";
import Level3Dropdown from "../LevelDropDown/Level3Dropdown";
import Level2Dropdown from "../LevelDropDown/Level2Dropdown";
import Level1Dropdown from "../LevelDropDown/Level1Dropdown";
import ImageUpload from "../CustomeWidget/ImageUpload";
import AutoIncrementValue from "../CustomeWidget/AutoIncrementValue";
import HiddenField from "../CustomeWidget/HiddenField"; 
import  MultiSelectField  from "../LevelDropDown/MultiSelectField";



const widget = {
    TimeWidget: TimeWidget
}
const fields = {
    layout: LayoutField,
    layout_grid: LayoutGridField,
    RoleField: RoleField,
    Level1Dropdown: Level1Dropdown,
    Level2Dropdown: Level2Dropdown,
    Level3Dropdown: Level3Dropdown,
    ImageUpload: ImageUpload,
    AutoIncrementValue:AutoIncrementValue,
    HiddenField:HiddenField,
    MultiSelectField:MultiSelectField,
  }
class NewFormSave extends Component {
    constructor(props) {        
        super(props)
        this.state = {
            schema: {},
            ui_schema: {},
            title: "", 
            schemaState: {},
            formData1: {},
            loading: false,
            parseBaseUrl:localStorage.getItem('baseUrl'),
            parseAppId:localStorage.getItem('parseAppId')
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

   
    async getForm() {
        
        this.setState({loading: true})
        try {
            
            Parse.serverURL = this.state.parseBaseUrl;
            Parse.initialize(this.state.parseAppId);
            var forms = Parse.Object.extend("w_formV3");
            var query = new Parse.Query(forms);
            query.equalTo("objectId", this.props.match.params.formid);
            const res = await query.first();
            const resultjson = res.toJSON();
            let results = resultjson;
            this.setState(async()=>{                       
                 var _record = Parse.Object.extend(this.props.match.params.cls);                
                var query1 = new Parse.Query(_record);
                query1.get(this.props.match.params.id)
                .then((x) => {                    
                    let result = x.toJSON();    
                    this.setState({
                        schemaState: resultjson.jsonSchema,
                        formData1: result
                    })              
                }, (error) => {
                    //Failed
                });
                this.setState({
                    schema: results.jsonSchema,
                    ui_schema: results.uiSchema,
                    title: results.class,
                    loading:false
                })
               
            })
        } catch (e) {
            this.setState({loading:false})
            console.error("Problem", e)
        }
    }
 

    async handleSubmit({ formData }) {    
      
      //this.setState({loading:true})
        let RowData = formData;
        let _scanData = this.state.schemaState;let temp=[], ui =this.state.ui_schema;
        Object.keys(_scanData).forEach(function (key) {
            
            let _dd = _scanData[key]
            Object.keys(_dd).forEach(function(k) {
                
                if (_dd[k].prop_key !== undefined) {
                  temp.push(_dd[k].prop_key);
                }
                if (_dd[k].data !== undefined) {
                  if (_dd[k].data[0] !== undefined) {
                    if (_dd[k].data[0].localfield !== undefined) {
                      if (_dd[k].data[0].localfield.startsWith("$")) {
                        let pointer = {};
                        if (_dd[k].ddtype !== undefined) {
                          pointer = {
                            __type: "Pointer",
                            className: _dd[k].data[0].targetclass,
                            objectId: RowData[k]
                          };
                        } else {
                          pointer = {
                            __type: "Pointer",
                            className: _dd[k].data[0].class,
                            objectId: RowData[k]
                          };
                        }
                        RowData[k] = pointer;
                      }
                    }
                  } else if (_dd[k].data.isPointer) {
                    let pointer = {};
                    pointer = {
                      __type: "Pointer",
                      className: _dd[k].data.class,
                      objectId: RowData[k]
                    };
                    RowData[k] = pointer;
                  }
                }
              }); 
        });
        
        try {
            Parse.serverURL = this.state.parseBaseUrl;
            Parse.initialize(this.state.parseAppId);     
            const currentUser = Parse.User.current(); 
            if(temp.length !== 0){              
                let userPointer={
                    __type: 'Pointer',
                     className: '_User',
                    objectId: currentUser.id
                }
                RowData[[temp[0]]] = userPointer;                
            } 
                
            var data = Parse.Object.extend(this.state.title);
            var object = new data();
            delete RowData.objectId;
            delete RowData.CreatedBy;
            delete RowData.createdAt;
            delete RowData.updatedAt;
            object.save(RowData).then(() => {               
                alert("Record updated successfully")                        
            })               
            
        } catch (error) {
            this.setState({loading:false})
        }
    }

    componentDidMount() {
        this.getForm();
       // this.getSingleRecord();
    }
    render() {
        let formview =  <Form schema={this.state.schema} 
        uiSchema={this.state.ui_schema} 
        formData={this.state.formData1} 
        fields={fields} 
        onSubmit={this.handleSubmit} />

        if(this.state.loading){
            formview = <Spinner/>
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body no-padding height-9">
                                <div className="row">
                                    <div className="col-10"></div>
                                    <div className="col-2">
                                        <input type="button" value="Back" onClick={() => {
                                            this.props.history.goBack()
                                        }} className="btn btn-primary" />
                                    </div>
                                </div>
                                {
                                    formview
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(NewFormSave)
