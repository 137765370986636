import React, { useState } from "react";
import parse from "html-react-parser";

function GeoLacation(props) {
  const [location, setLocation] = useState(
    props.formData ? props.formData : ""
  );
  const REQUIRED_FIELD_SYMBOL = "*";
  const getCurrentLocation = (e) => {
    e.preventDefault();
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
    });
  };
  return (
    <React.Fragment>
     <div style={{display:"inline-block"}}>
        <label htmlFor={props.name}>
          {props.schema.title}
          {props.required && (
            <span className="required">{REQUIRED_FIELD_SYMBOL}</span>
          )}
          {props.schema.helpbody ? (
          <div className="dropdown pull-right">
            <i
              className="far fa-question-circle dropdown-toggle hovereffect pull-right"
              aria-hidden="true"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                fontSize: "12px",
                color: "purple",
                cursor: "pointer !important",
                position: "relative",
                bottom: "0px",
                left: "0px",
                paddingBottom: "4px",
                  paddingLeft: "4px"
              }}
            ></i>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
              style={{
                marginleft: "-121px",
                margintop: "-14px",
                position: "absolute",
                padding: "10px",
                top: "102px!important",
              }}
            >
              {parse(`
             ${props.schema.helpbody}
           `)}
              <br />
              {props.schema.helplink ? (
                <a
                  href={props.schema.helplink}
                  target="_blank"
                  className="btn btn-xs btn-primary"
                >
                  Read more..
                </a>
              ) : null}
            </div>
          </div>
        ) : null}
        </label>
        
      </div>

      <div className="input-group">
        <textarea
          type="text"
          className="form-control"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        ></textarea>
        <div className="input-group-btn">
          <button
            className="btn btn-default"
            type="button"
            onClick={getCurrentLocation}
          >
            <i className="glyphicon glyphicon-search"></i>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GeoLacation;
