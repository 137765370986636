import React, { Component } from "react";
import Options from "./Options";
import ActionView from "./ActionView";
import Modal from "../components/Modal";
import axios from "axios";
import KanBanData from "../components/KanBanData";

const $ = window.$;

class KanbanViewDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      textValue: "",
      textData: [],
      _rData: [],
      Imageurl: "",
      ddlValue: "",
      ddlData: [],
      ddlData1: [],
      txtonload: false,
      ddlValue1: "",
      ddlonload: false,
      ddlonload1: false,
      mainload: false,
      propData: this.props.reportData,
      currentPage: 1,
      todosPerPage: 5,
      upperPageBound: 5,
      lowerPageBound: 0,
      isPrevBtnActive: "disabled",
      isNextBtnActive: "",
      pageBound: 5,
      selected: {},
      selectAll: 0,
      dateData: [],
      formDate: "",
      toDate: "",
      text1Value: "",
      textData1: [],
      parseBaseUrl: localStorage.getItem("baseUrl"),
      parseAppId: localStorage.getItem("parseAppId"),
    };
  }

  handleModalShowClick = (e) => {
    e.preventDefault();
    this.setState({
      showModal: true,
    });
  };
  handleModalCloseClick = () => {
    this.setState({
      showModal: false,
    });
  };

  texthandleChange = (col, event) => {
    event.preventDefault();
    this.setState({
      textValue: event.target.value,
      textData: col,
    });
  };

  text1handleChange = (col, event) => {
    event.preventDefault();
    this.setState({
      text1Value: event.target.value,
      textData1: col,
    });
  };

  formDatehandleChange = (event, col) => {
    event.preventDefault();
    this.setState({
      formDate: event.target.value,
      dateData: col,
    });
  };

  toDatehandleChange = (event, col) => {
    event.preventDefault();
    this.setState({
      toDate: event.target.value,
    });
  };

  ddlhandalChange = (col, event) => {
    event.preventDefault();

    this.setState({
      ddlValue: event.target.value,
      ddlData: col,
    });

    try {
      let url = `${this.state.parseBaseUrl}classes/${col[0].class}?where={"${col[0].query}":{"$regex":"${event.target.value}"}}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": this.state.parseAppId,
      };
      axios.get(url, { headers: headers }).then((response) => {
        this.setState({
          _rData: response.data.results,
        });
      });
    } catch (e) {}
  };

  ddlhandalChange1 = (col, event) => {
    event.preventDefault();
    this.setState({
      ddlValue1: event.target.value,
      ddlData1: col,
    });
    try {
      let url = `${this.state.parseBaseUrl}classes/${col[0].class}?where={"${col[0].query}":{"$regex":"${event.target.value}"}}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": this.state.parseAppId,
      };
      axios.get(url, { headers: headers }).then((response) => {
        this.setState({
          _rData: response.data.results,
        });
      });
    } catch (e) {}
  };

  //Search Result Query
  onSearchResult = (col) => {
    let text1 = this.state.textValue;
    let text2 = this.state.text1Value;
    let date1 = this.state.formDate;
    let date2 = this.state.toDate;
    let ddl1 = this.state.ddlValue;
    let ddl2 = this.state.ddlValue1;
    let t1 = "",
      t2 = "",
      d1 = "",
      d2 = "",
      dd1 = "",
      dd2 = "";
    let _query = "";
    let q = "",
      q1 = "",
      q2 = "",
      q3 = "",
      q4 = "",
      q5 = "";
    let query = [];

    if (text1 === "Select" || text1 === "") {
      text1 = null;
    } else {
      let t = { text1: text1 };
      query.push(t);
    }
    if (text2 === "Select" || text2 === "") {
      text1 = null;
    } else {
      let t = { text2: text2 };
      query.push(t);
    }
    if (date1 === "Select" || date1 === "") {
      date1 = null;
    } else {
      let t = { Date1: date1 };
      query.push(t);
    }
    if (date2 === "Select" || date2 === "") {
      date2 = null;
    } else {
      let t = { Date2: date2 };
      query.push(t);
    }
    if (ddl1 === "Select" || ddl1 === "") {
      ddl1 = null;
    } else {
      let t = { ddl1: ddl1 };
      query.push(t);
    }
    if (ddl2 === "Select" || ddl2 === "") {
      ddl2 = null;
    } else {
      let t = { ddl2: ddl2 };
      query.push(t);
    }


    query.forEach((val) => {
      if (val.text1) {
        t1 = val.text1;
        q =
          '"' +
          this.state.textData[0].query +
          '"' +
          ":" +
          "{" +
          '"$regex"' +
          ":" +
          '"' +
          t1 +
          '"' +
          "}";
      }
      if (val.text2) {
        t2 = val.text2;
        q1 =
          '"' +
          this.state.textData1[0].query +
          '"' +
          ":" +
          "{" +
          '"$regex"' +
          ":" +
          '"' +
          t2 +
          '"' +
          "}";
      }
      if (val.Date1) {
        d1 = val.Date1;
        q2 =
          '"' +
          this.state.dateData[0].query +
          '"' +
          ":" +
          "{" +
          '"$regex"' +
          ":" +
          '"' +
          d1 +
          '"' +
          "}";
      }
      if (val.Date2) {
        d2 = val.Date2;
        q3 =
          '"' +
          this.state.dateData[0].query +
          '"' +
          ":" +
          "{" +
          '"$gte"' +
          ":" +
          '"' +
          d1 +
          '"' +
          "," +
          '"$lte"' +
          ":" +
          '"' +
          d2 +
          '"' +
          "}";
      }

      if (val.ddl1) {
        dd1 = val.ddl1;
        q4 =
          '"' +
          this.state.ddlData[0].query +
          '"' +
          ":" +
          "{" +
          '"$regex"' +
          ":" +
          '"' +
          dd1 +
          '"' +
          "}";
      }
      if (val.ddl2) {
        dd2 = val.ddl2;
        q5 =
          '"' +
          this.state.ddlData1[0].query +
          '"' +
          ":" +
          "{" +
          '"$regex"' +
          ":" +
          '"' +
          dd2 +
          '"' +
          "}";
      }
    });
    if (t1 && t2 && d1 && d2 && dd1 && dd2) {
      _query = q + "," + q1 + "," + q2 + "," + q3 + "," + q4 + "," + q5;
    } else if (t1 && t2 && d1 && d2 && dd1) {
      _query = q + "," + q1 + "," + q2 + "," + q3 + "," + q4;
    } else if (t1 && t2 && d1 && d2) {
      _query = q + "," + q1 + "," + q2 + "," + q3;
    } else if (t1 && t2 && dd1 && dd2) {
      _query = q + "," + q1 + "," + q4 + "," + q5;
    } else if (t1 && d1 && d2 && dd1) {
      _query = q + "," + q3 + "," + q4;
    } else if (t1 && d1 && d2) {
      _query = q + "," + q3;
    } else if (t1 && dd1 && dd2) {
      _query = q + "," + q4 + "," + q5;
    } else if (t1 && t2 && dd1) {
      _query = q + "," + q1 + "," + q4;
    } else if (t1 && t2 && d1) {
      _query = q + "," + q1 + "," + q2;
    } else if (t1 && t2) {
      _query = q + "," + q1;
    } else if (d1 && d2) {
      _query = q3;
    } else if (t1) {
      _query = q;
    } else if (t2) {
      _query = q1;
    } else if (d1) {
      _query = q2;
    } else if (dd1) {
      _query = q4;
    } else if (dd2) {
      _query = q5;
    }

    try {
      let url = `${this.state.parseBaseUrl}classes/${col[0].class}?where={${_query}}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": this.state.parseAppId,
      };
      axios.get(url, { headers: headers }).then((response) => {
        this.setState({
          _rData: response.data.results,
        });
      });
    } catch (e) {}
  };

  onRadioChange = (val, e) => {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[val] = !this.state.selected[val];
    this.setState({
      selected: newSelected,
      selectAll: 2,
    });
  };

  // For TextBox Search
  onSubmitText = async (event) => {
    event.preventDefault();
    try {
      let _d = this.state.textData;
      let url = `${this.state.parseBaseUrl}classes/${_d[0].class}?where={"${_d[0].query}":{"$regex":"^${this.state.textValue}"}}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": this.state.parseAppId,
      };
      axios.get(url, { headers: headers }).then((response) => {
        this.setState({
          _rData: response.data.results,
          txtonload: false,
        });
      });
    } catch (e) {}
  };

  onSubmitText1 = async (event) => {
    event.preventDefault();
    try {
      let _d = this.state.textData1;
      let url = `${this.state.parseBaseUrl}classes/${_d[0].class}?where={"${_d[0].query}":{"$regex":"^${this.state.textValue}"}}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": this.state.parseAppId,
      };
      axios.get(url, { headers: headers }).then((response) => {
        this.setState({
          _rData: response.data.results,
          txtonload: false,
        });
      });
    } catch (e) {}
  };

  // For Select Option
  toggleRow = (firstName) => {
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[firstName] = !this.state.selected[firstName];
    this.setState({
      selected: newSelected,
      selectAll: 2,
    });
  };

  toggleSelectAll = (currentTodos) => {
    let newSelected = {};
    if (this.state.selectAll === 0) {
      currentTodos.forEach((x) => {
        newSelected[x.objectId] = true;
      });
    }

    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0,
    });
  };
  // End Select Options

  //For Radio button

  //End of Radio button

  // For Sorting Column
  sortBy = (key, rData) => {
    this.setState({
      _rData: rData.sort((a, b) => {
        const asc = "asc";
        if (a[key] < b[key]) {
          return asc ? -1 : 1;
        } else if (a[key] > b[key]) {
          return asc ? 1 : -1;
        } else {
          return 0;
        }
      }),
    });
  };
  sortByID = (key, rData) => {
    this.setState({
      _rData: rData.sort((a, b) => {
        const asc = "asc";
        return asc === "asc"
          ? parseInt(a[key]) - parseInt(b[key])
          : parseInt(b[key]) - parseInt(a[key]);
      }),
    });
  };

  //End of Sorting Column

  // For Pagination code
  handleClick = (event) => {
    let listid = Number(event.target.id);
    this.setState({
      currentPage: listid,
    });
    $("ul li.active").removeClass("active");
    $("ul li#" + listid).addClass("active");
    this.setPrevAndNextBtnClass(listid);
  };

  setPrevAndNextBtnClass = (listid) => {
    let totalPage = Math.ceil(
      this.state._rData.length / this.state.todosPerPage
    );
    this.setState({ isNextBtnActive: "disabled" });
    this.setState({ isPrevBtnActive: "disabled" });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: "" });
    } else if (listid === 1 && totalPage > 1) {
      this.setState({ isNextBtnActive: "" });
    } else if (totalPage > 1) {
      this.setState({ isNextBtnActive: "" });
      this.setState({ isPrevBtnActive: "" });
    }
  };

  btnIncrementClick = () => {
    this.setState({
      upperPageBound: this.state.upperPageBound + this.state.pageBound,
    });
    this.setState({
      lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
    });
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  };

  btnDecrementClick = () => {
    this.setState({
      upperPageBound: this.state.upperPageBound - this.state.pageBound,
    });
    this.setState({
      lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
    });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  };

  btnPrevClick = () => {
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({
        upperPageBound: this.state.upperPageBound - this.state.pageBound,
      });
      this.setState({
        lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
      });
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  };

  btnNextClick = () => {
    if (this.state.currentPage + 1 > this.state.upperPageBound) {
      this.setState({
        upperPageBound: this.state.upperPageBound + this.state.pageBound,
      });
      this.setState({
        lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
      });
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  };

  // End of Pagination

  getTableRecord() {
    let _temp = [];
    this.state.propData.forEach((val) => {
      val.columns.forEach((col) => {
        if (col.widget.type) {
          _temp = col.widget.data;
        }
      });
    });
    try {
      let url = `${this.state.parseBaseUrl}classes/${_temp[0].class}?${_temp[0].query}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": this.state.parseAppId,
      };
      axios.get(url, { headers: headers }).then((response) => {
        this.setState({
          _rData: response.data.results,
        });
      });
    } catch (e) {}
  }

  // CompentDid
  componentDidUpdate() {
    $("ul li.active").removeClass("active");
    $("ul li#" + this.state.currentPage).addClass("active");
  }
  componentDidMount() {
    this.getTableRecord();
  }

  renderSwitch(col) {
    switch (col.widget.type) {
      case "HeaderTag":
        return (
          <div style={{ padding: "10px" }}>
            <h3>{col.widget.label}</h3>
          </div>
        );
      case "CreateButton":
        return (
          <div style={{ padding: "10px" }}>
            <button
              className="btn btn-primary"
              onClick={this.handleModalShowClick}
            >
              {col.widget.label}
            </button>
          </div>
        );
      case "FilterPanel":
        switch (col.widget.subType) {
          case "TextBox":
            return (
              <div style={{ paddingTop: "10px", paddingLeft: "10px" }}>
                <form onSubmit={this.onSubmitText}>
                  <input
                    type="text"
                    value={this.state.textValue}
                    onChange={(e) => {
                      this.texthandleChange(col.widget.filter, e);
                    }}
                    className="form-control"
                    placeholder={col.widget.placeholder}
                  />
                </form>
              </div>
            );
          case "TextBox1":
            return (
              <div style={{ paddingTop: "10px" }}>
                <form onSubmit={this.onSubmitText1}>
                  <input
                    type="text"
                    value={this.state.text1Value}
                    onChange={(e) => {
                      this.text1handleChange(col.widget.filter, e);
                    }}
                    className="form-control"
                    placeholder={col.widget.placeholder}
                  />
                </form>
              </div>
            );
          case "DropDown":
            return (
              <div style={{ paddingTop: "10px" }}>
                <select
                  className="form-control"
                  name={col.widget.name}
                  value={this.state.ddlValue}
                  onChange={(e) => {
                    this.ddlhandalChange(col.widget.filter, e);
                  }}
                >
                  <option>Select</option>
                  {<Options data={col.widget.data} />}
                </select>
              </div>
            );
          case "DropDown1":
            return (
              <div style={{ paddingTop: "10px" }}>
                <select
                  className="form-control"
                  value={this.state.ddlValue1}
                  onChange={(e) => {
                    this.ddlhandalChange1(col.widget.filter, e);
                  }}
                >
                  <option>Select</option>
                  {<Options data={col.widget.data} />}
                </select>
              </div>
            );
          case "FromDate":
            return (
              <div style={{ paddingTop: "10px" }}>
                <input
                  type="date"
                  value={this.state.formDate}
                  onChange={(e) => {
                    this.formDatehandleChange(e, col.widget.filter);
                  }}
                  className="form-control"
                  placeholder={col.widget.placeholder}
                />
              </div>
            );
          case "ToDate":
            return (
              <div style={{ paddingTop: "10px" }}>
                <input
                  type="date"
                  value={this.state.ToDate}
                  onChange={(e) => {
                    this.toDatehandleChange(e, col.widget.filter);
                  }}
                  className="form-control"
                  placeholder={col.widget.placeholder}
                />
              </div>
            );
          case "Button":
            return (
              <div style={{ paddingTop: "10px" }}>
                <button
                  className="btn btn-info"
                  onClick={() => this.onSearchResult(col.widget.filter)}
                >
                  {col.widget.label}
                </button>
              </div>
            );
          default:
            return null;
        }

      case "Kanban":
        return (
          <>
            <KanBanData data={col.widget} />
          </>
        );
      case "table":
        switch (col.widget.subType) {
          case "basic":
            const {
              _rData,
              currentPage,
              todosPerPage,
              upperPageBound,
              lowerPageBound,
              isPrevBtnActive,
              isNextBtnActive,
            } = this.state;
            // Logic for displaying current todos
            const indexOfLastTodo = currentPage * todosPerPage;
            const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
            const currentTodos = _rData.slice(
              indexOfFirstTodo,
              indexOfLastTodo
            );

            const renderTodos = currentTodos.map((_rData, index) => {
              return <li key={index}>{_rData}</li>;
            });
            // Logic for displaying page numbers
            const pageNumbers = [];
            for (let i = 1; i <= Math.ceil(_rData.length / todosPerPage); i++) {
              pageNumbers.push(i);
            }

            const renderPageNumbers = pageNumbers.map((number) => {
              if (number === 1 && currentPage === 1) {
                return (
                  <li key={number} className="active" id={number}>
                    <a id={number} onClick={this.handleClick}>
                      {number}
                    </a>
                  </li>
                );
              } else if (
                number < upperPageBound + 1 &&
                number > lowerPageBound
              ) {
                return (
                  <li key={number} id={number}>
                    <a id={number} onClick={this.handleClick}>
                      {number}
                    </a>
                  </li>
                );
              }
            });
            let pageIncrementBtn = null;
            if (pageNumbers.length > upperPageBound) {
              pageIncrementBtn = (
                <li className="">
                  <a onClick={this.btnIncrementClick}> &hellip; </a>
                </li>
              );
            }
            let pageDecrementBtn = null;
            if (lowerPageBound >= 1) {
              pageDecrementBtn = (
                <li className="">
                  <a onClick={this.btnDecrementClick}> &hellip; </a>
                </li>
              );
            }
            let renderPrevBtn = null;
            if (isPrevBtnActive === "disabled") {
              renderPrevBtn = (
                <li className={isPrevBtnActive}>
                  <span id="btnPrev"> Prev </span>
                </li>
              );
            } else {
              renderPrevBtn = (
                <li className={isPrevBtnActive}>
                  <a id="btnPrev" onClick={this.btnPrevClick}>
                    {" "}
                    Prev{" "}
                  </a>
                </li>
              );
            }
            let renderNextBtn = null;
            if (isNextBtnActive === "disabled") {
              renderNextBtn = (
                <li className={isNextBtnActive}>
                  <span id="btnNext"> Next </span>
                </li>
              );
            } else {
              renderNextBtn = (
                <li className={isNextBtnActive}>
                  <a id="btnNext" onClick={this.btnNextClick}>
                    {" "}
                    Next{" "}
                  </a>
                </li>
              );
            }
            return (
              <div className="info-box table-responsive">
                <br />
                <h3>{col.widget.label}</h3>
                <table
                  className="table display product-overview mb-30"
                  id="example1"
                  style={{ width: "100%" }}
                >
                  <thead>
                    {
                      <tr>
                        <th>Sr.No</th>
                        {col.widget.actions.map((t, i) =>
                          t.actionType === "Select" ? (
                            <th key={t + i + 1}>
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={this.state.selectAll === 1}
                                ref={(input) => {
                                  if (input) {
                                    input.indeterminate =
                                      this.state.selectAll === 2;
                                  }
                                }}
                                onChange={() =>
                                  this.toggleSelectAll(this.state._rData)
                                }
                              />
                            </th>
                          ) : null
                        )}
                        {col.widget.columnlabels.map((val, i) => (
                          <th key={val + i + 2}>
                            <button
                              onClick={() => {
                                this.sortBy(val, this.state._rData);
                              }}
                            >
                              <b>{val}</b>
                            </button>
                          </th>
                        ))}
                        {col.widget.actions.map((t, i) =>
                          t.actionType === "Attendance" ? (
                            <th key={t + i + 1} colSpan="2">
                              Attendance
                            </th>
                          ) : null
                        )}
                        {col.widget.actions.length > 0 ? <th>Action</th> : null}
                      </tr>
                    }
                  </thead>
                  <tbody>
                    {currentTodos.map((val, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        {col.widget.actions.map((t, i) =>
                          t.actionType === "Select" ? (
                            <td key={t + i + 1}>
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={this.state.selected[val.objectId]}
                                onChange={() => this.toggleRow(val.objectId)}
                              />
                            </td>
                          ) : null
                        )}
                        {col.widget.columnlabels.map((h) =>
                          h === col.widget.imageColumns ? (
                            <td key={h + i + 1}>
                              <button
                                onClick={() => {
                                  this.setState({
                                    Imageurl: val[h],
                                  });
                                  $(this.modal).modal("show");
                                }}
                              >
                                <img
                                  src={val[h]}
                                  alt="img"
                                  width="60px"
                                  height="60px"
                                />
                              </button>
                            </td>
                          ) : (
                            <td key={h + i + 1}>{val[h]}</td>
                          )
                        )}
                        {col.widget.actions.map((t, i) =>
                          t.actionType === "Attendance" ? (
                            <td key={t + i + 1}>
                              <div className="radio">
                                <div className="form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      onChange={(e) => {
                                        this.onRadioChange(val.objectId, e);
                                      }}
                                      name={val.objectId}
                                      value=""
                                      checked={
                                        this.state.selected[val.objectId]
                                      }
                                    />
                                    Present
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      onChange={(e) => {
                                        this.onRadioChange(val.objectId, e);
                                      }}
                                      name={val.objectId}
                                      value=""
                                      checked={
                                        this.state.selected[val.objectId]
                                      }
                                    />
                                    Absent
                                  </label>
                                </div>
                              </div>
                            </td>
                          ) : null
                        )}
                        {col.widget.actions.length > 0 ? (
                          <ActionView
                            objectId={val.objectId}
                            action={col.widget.actions}
                          />
                        ) : null}
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={col.widget.columnlabels.length + 2}>
                        <ul className="pagination">
                          {renderPrevBtn}
                          {pageDecrementBtn}
                          {renderPageNumbers}
                          {pageIncrementBtn}
                          {renderNextBtn}
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
        }
      default:
        return null;
    }
  }
  render() {
    const { showModal } = this.state;

    return (
      <React.Fragment>
        <div style={{ backgroundColor: "white", marginTop: "12px" }}>
          {this.props.reportData.map((val, key) => (
            <div key={"a" + key} className="row">
              {val.columns.map((col, i) => (
                <div
                  key={col.widget.type}
                  className={this.props.classnameArray[key][i]}
                >
                  {this.renderSwitch(col)}
                </div>
              ))}
            </div>
          ))}
        </div>
        {showModal ? (
          <Modal
            data={this.props.reportData}
            handleModalCloseClick={this.handleModalCloseClick}
          />
        ) : null}
        <div
          id="myModal"
          ref={(modal) => (this.modal = modal)}
          className="modal fade"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times-circle" aria-hidden="true"></i>
                </button>
              </div>
              <div className="modal-body">
                <img src={this.state.Imageurl} width="100%" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default KanbanViewDisplay;
