import React, { Component } from "react";
import styled from "styled-components";
import { DragDropContext } from "react-beautiful-dnd";
import "@atlaskit/css-reset";
import Column from "../StaticForm/Kanban/Column";
import Spinner from "../Spinner/Spinner";
import Parse from "parse";
import axios from "axios";


const KanBanContainer = styled.div`
  display: flex;
`;

class KanBanDataAk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      tasks: {},
      columns: {},
      columnOrder: [],
      updateClass: "",
      updateField: "",
      contact: [],
      parseBaseUrl: localStorage.getItem("baseUrl"),
      parseAppId: localStorage.getItem("parseAppId")
    };
  }

  handleData = async () => {
    let _task = {};
    let _maintasks = {};
    let listField = [];
    let arr = [];
    let columnOrder = [];
    let taskArray = [];
    let TL = {};
    let _column = {};
    let done = false;
    let _stepLi = [];
    try {
      Parse.serverURL = this.state.parseBaseUrl;
      Parse.initialize(this.state.parseAppId);
      var _kList = Parse.Object.extend("uat_Projects");
      const query = new Parse.Query(_kList);
      query.equalTo("title", localStorage.getItem("kbID"));
      const response = await query.first();
      const resultjson = response.toJSON();
      let _ddd = resultjson.steps;
      _ddd.forEach(d => {
        _stepLi.push(d.stepName);
      });
    } catch (e) {}
    this.setState(() => {
      let url = `${this.state.parseBaseUrl}classes/${
        this.props.data.class
      }?where={"project_name":"${localStorage.getItem("kbID")}"}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": this.state.parseAppId
      };
      axios.get(url, { headers: headers }).then(res => {
        this.props.data.data.forEach(x => {
          res.data.results.forEach((val, i) => {
            _task = {
              id: val.objectId,
              content: val[x.carddescfield],
              invoice_no: val[x.cardNo],
              title: val[x.cardtitlefield],
              name: val[x.cardrightfooterfield],
              amount: val[x.cardleftfooterfield],
              date: val[x.cardrighttitlefield],
              view: val[x.cardId],
              camparefield: val[this.props.data.listfield[0]]
            };
            listField.push(val[this.props.data.listfield[0]]);
            //  arr = Array.from(new Set(listField));
            _maintasks[val.objectId] = _task;
            let d = val.objectId;
            taskArray.push(d);
          });
        });
        let tm = false;
        for (let j = 0; j < _stepLi.length; j++) {
          TL["task" + j] = [];
          tm = true;
        }
        if (tm) {
          for (var l in _maintasks) {
            for (let j = 0; j < _stepLi.length; j++) {
              if (_maintasks[l].camparefield == _stepLi[j]) {
                TL["task" + j].push(_maintasks[l].id);
              }
            }
          }
        }

        for (let j = 0; j < _stepLi.length; j++) {
          let _c = "column-" + j;
          _column[_c] = {
            id: _c,
            title: _stepLi[j],
            taskIds: TL["task" + j]
          };
          columnOrder.push(_c);
          done = true;
        }
        if (done) {
          this.setState({
            columns: _column,
            tasks: _maintasks,
            columnOrder: columnOrder,
            updateClass: this.props.data.class,
            updateField: this.props.data.listfield[0]
          });
          localStorage.setItem("KanbanViewClass", this.state.updateClass);
        }
      });
    });
  };

  async getContact() {
    Parse.serverURL = this.state.parseBaseUrl;
    Parse.initialize(this.state.parseAppId);
    var _kList = Parse.Object.extend("uat_Projects");
    const query = new Parse.Query(_kList);
    query.equalTo("title", localStorage.getItem("kbID"));
    const results = await query.first();
    const resultjson = results.toJSON();
    console.log("resultjson", resultjson.steps);
    this.setState({
      contact: resultjson.steps
    });
    let data = JSON.stringify(resultjson.steps);
    localStorage.setItem("ContactData", data);
  }

  onDragEnd = result => {
    document.body.style.color = "inherit";
    document.body.style.backgroundColor = "inherit";
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const start = this.state.columns[source.droppableId];
    const finish = this.state.columns[destination.droppableId];
    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds);
      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        taskIds: newTaskIds
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newColumn.id]: newColumn
        }
      };
      this.setState(newState);

      return;
    }

    //Moving from one list to another list
    const startTaskIds = Array.from(start.taskIds);
    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      taskIds: startTaskIds
    };

    const finishTaskIds = Array.from(finish.taskIds);
    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    };
    this.setState(newState);

    Parse.serverURL = this.state.parseBaseUrl;
    Parse.initialize(this.state.parseAppId);
    var _kList = Parse.Object.extend(this.state.updateClass);
    const query = new Parse.Query(_kList);
    query.get(result.draggableId).then(object => {
      object.set(this.state.updateField, newFinish.title);
      object.save().then(
        response => {
          if (typeof document !== "undefined")
            console.log("Updated ", response);
        },
        error => {
          if (typeof document !== "undefined")
            console.error("Error while updating ", error);
        }
      );
    });
  };

  onDragStart = () => {
    document.body.style.color = "orange";
    document.body.style.transition = "background-color 0.2s ease";
  };

  onDragUpdate = update => {
    const { destination } = update;
    const opacity = destination
      ? destination.index / Object.keys(this.state.tasks).length
      : 0;
    document.body.style.backgroundColor = `rgba(153,141,217,${opacity})`;
  };

  componentDidMount() {
    this.handleData();
    this.getContact();
  }

  render() {
    let data = this.state.columnOrder.map(columnId => {
      const column = this.state.columns[columnId];
      const tasks = column.taskIds.map(taskId => this.state.tasks[taskId]);
      return <Column key={column.id} column={column} tasks={tasks} />;
    });
    if (this.state.loading) {
      data = <Spinner />;
    }
    return (
      <>
        <div style={{ padding: "10px" }}>
          <header>
            <h3>{localStorage.getItem("kbID")}</h3>
          </header>
        </div>
        <div style={{ overflow: "auto" }}>
          <KanBanContainer>
            <DragDropContext
              onDragStart={this.onDragStart}
              onDragUpdate={this.onDragUpdate}
              onDragEnd={this.onDragEnd}
            >
              {data}
            </DragDropContext>
          </KanBanContainer>
        </div>
      </>
    );
  }
}

export default KanBanDataAk;
