import React, { Component } from "react";
import Parse from "parse";
import { connect } from "react-redux";
import { fetchAppInfo, forgetPassword } from "./../actions";
import axios from "axios";
import Title from "./Title";

class forgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      hideNav: "",
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  resize = () => {
    let currentHideNav = window.innerWidth <= 760;
    if (currentHideNav !== this.state.hideNav) {
      this.setState({ hideNav: currentHideNav });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    
    localStorage.setItem("appLogo", this.props.appInfo.applogo);
    localStorage.setItem("appName", this.props.appInfo.appname);
    localStorage.setItem("defaultmenuid", this.props.appInfo.defaultmenuid);
    localStorage.setItem("PageLanding", this.props.appInfo.LandingPageId);
    localStorage.setItem(
      "userSettings",
      JSON.stringify(this.props.appInfo.settings)
    );
    const { email } = this.state;
    if (email) {
      this.props.forgetPassword(email);
    }
  };

  componentWillMount() {
    this.props.fetchAppInfo(localStorage.getItem("domain"));
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  render() {
    const { email, password } = this.state;
    let image = this.props.appInfo.applogo;
    return (
      <React.Fragment>
        <Title title="Forgot password page" />
        <div className="container GTRY">
          <div className="main_head">
            <div className="main-logo">
              {this.state.hideNav ? (
                <img src={image} width="80%" />
              ) : (
                <img src={image} width="100%" />
              )}
            </div>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-sm-6 KLO">
                <h2>Welcome Back !</h2>
                <span className="KNLO">Reset Your Password</span>
                <div className="card card-box">
                  <div className="card-body">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                    />
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-md-6 col-sm-6"
                    style={this.state.hideNav ? { textAlign: "center" } : {}}
                  >
                    {this.state.hideNav ? (
                      <button
                        type="submit"
                        className="btn btn-info"
                        style={{ marginBottom: "4px" }}
                      >
                        Submit
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-info btn-block">
                        Submit
                      </button>
                    )}
                  </div>
                  {/* <div className="col-md-6 col-sm-6" style={this.state.hideNav?{ textAlign: 'center' }:{}}>
                  {
                    //  <a onClick={() => {
                    //     window.location.hash = "/";
                    //                     }}>Login </a>
                    <span className="MLKI">
                    <a onClick={() => {
                    window.location.hash = "/";
                                    }}>Login </a>
                    </span>
                  }
                  </div> */}
                  <div
                    className="col-md-6 col-sm-6"
                    style={this.state.hideNav ? { textAlign: "center" } : {}}
                  >
                    {
                      <span className="MLKI" style={{ paddingTop: "10px" }}>
                        <a
                          onClick={() => {
                            window.location.hash = "/";
                          }}
                        >
                          Login{" "}
                        </a>
                      </span>
                    }
                  </div>
                </div>
              </div>
              <div className="col-sm-6 KLO1">
                <img src="./assets/img/login_img.svg" width="100%" />
              </div>
              <div className="col-md-7 col-sm-7 offset-md-5 offset-sm-5 MKUY">
                {/*  <button type="button" className="btn btn-info btn-lg btn-fb"><i className="fa fa-facebook-f"></i> </button> */}
                &nbsp;
                {/*  <button type="button" className="btn btn-danger btn-lg btn-fb"><i className="fa fa-google"></i></button> */}
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { appInfo: state.appInfo, forgotPassword: state.forgotPassword };
};

export default connect(mapStateToProps, { fetchAppInfo, forgetPassword })(
  forgotPassword
);
