import React, { Component } from "react";
import Form from "react-jsonschema-form";
import { withRouter } from "react-router-dom";
import Parse from "parse";
import LayoutField from "react-jsonschema-form-layout-2";
import TimeWidget from "../../CustomeWidget/TimeWidget";
import RoleField from "../../CustomeWidget/RoleField";
import axios from 'axios';

const widget = {
  TimeWidget: TimeWidget
};
const fields = {
  layout: LayoutField,
  RoleField: RoleField,
};
class EditMemberRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schema: {},
      ui_schema: {},
      title: "",
      schemaState: {},
      formData: {},
      parseBaseUrl: localStorage.getItem("baseUrl"),
      parseAppId: localStorage.getItem("parseAppId")
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async getForm() {
    try {
      Parse.serverURL = this.state.parseBaseUrl;
      Parse.initialize(this.state.parseAppId);
      var forms = Parse.Object.extend("w_formV3");
      var query = new Parse.Query(forms);
      query.equalTo("objectId", this.props.match.params.formid);
      const res = await query.first();
      const resultjson = res.toJSON();
      let results = resultjson;
      this.setState(async () => {
        var _record = Parse.Object.extend(results.class);
        var query1 = new Parse.Query(_record);
        query1.include("userId");
        query1.get(this.props.match.params.id).then(
          x => {           
            let result = x.toJSON();
            delete result.objectId;
            delete result.class;
            delete result.CreatedBy;
            delete result.createdAt;
            delete result.mainformObjId;
            delete result.updatedAt;
            let _empDet = {
              designation: result.designation,
              location: result.location,
              remark: result.remark
            };
            let _userDet = {
              email: result.userId.username,
              name: result.userId.name,
              password: "000000",
              phone: result.userId.phone,
              userID: result.userId.objectId
            };
            let _roleDet = result.role;
            let mergeData = {
              users: _userDet,
              fields: _empDet,
              role: _roleDet
            };
            this.setState({
              schemaState: resultjson.jsonSchema,
              formData: mergeData
            });
          },
          error => {
            //Failed
          }
        );
        this.setState({
          schema: results.jsonSchema,
          ui_schema: results.uiSchema,
          title: results.class
        });
      });
    } catch (e) {
      console.error("Problem", e);
    }
  }

  async handleSubmit({ formData }) {
   
    let RowData = formData;
    try {
      var data = Parse.Object.extend(localStorage.getItem('extended_class'));
      var query = new Parse.Query(data);
      let url = `${this.state.parseBaseUrl}functions/MoveUserToRole`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": this.state.parseAppId,
        'sessionToken': localStorage.getItem('accesstoken') 
      };
      let body = {
        appName:localStorage.getItem('domain'),
        roleName: formData.role,
        userId: formData.users.userID
      };
      axios.post(url, body, { headers: headers }).then(res => {          
        query.get(this.props.match.params.id).then(function(object) {
            object.set('role',formData.role)
            object.save(formData.fields).then(() => {
              alert("Record updated Successfully");
            });
          });      
      });
     
    } catch (error) {}
  }

  componentDidMount() {
    this.getForm();
    // this.getSingleRecord();
  }
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body no-padding height-9">
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col-2">
                    <input
                      type="button"
                      value="Back"
                      onClick={() => {
                        this.props.history.goBack();
                      }}
                      className="btn btn-primary"
                    />
                  </div>
                </div>
                <Form
                  schema={this.state.schema}
                  uiSchema={this.state.ui_schema}
                  formData={this.state.formData}
                  fields={fields}
                  onSubmit={this.handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(EditMemberRegistration);
