import React, { Component } from 'react'
import KanbanViewDisplayAk from './KanbanViewDisplayAk';
import Parse from 'parse';
import Spinner from '../Spinner/Spinner';


// const parseBaseUrl = localStorage.getItem('baseUrl');
// const parseAppId = localStorage.getItem('parseAppId');

class KanbanViewerAk extends Component {

    constructor(props) {
        super(props)
        this.state = {
            reportData: [],
            classnameArray: [],
            loading: false,
            parseBaseUrl: localStorage.getItem("baseUrl"),
            parseAppId: localStorage.getItem("parseAppId")
        }
    }
    async getReportUI(id) {
        
        this.setState({
            loading: true
        });
        try {
            Parse.serverURL = this.state.parseBaseUrl;
            Parse.initialize(this.state.parseAppId);
            var forms = Parse.Object.extend("w_KanbanFilter");
            var query = new Parse.Query(forms);
            query.equalTo("objectId", localStorage.getItem('PageLanding'));
            const results = await query.first();
            const resultjson = results.toJSON();
            console.log("resultjson", resultjson)
            let classArray = [];
            resultjson.rows.forEach(x => {
                let col_lg = "", col_md = "", col_xs = "", obj = {};
                let subItem = []; let item = "";
                x.columns.forEach(y => {
                    if (Number(y["collg"]) > 0) {
                        col_lg = 'col-lg-' + y["collg"];
                    }
                    if (Number(y["colmd"]) > 0) {
                        col_md = 'col-md-' + y["colmd"];
                    }
                    if (Number(y["colxs"]) > 0) {
                        col_xs = 'col-xs-' + y["colxs"];
                    }
                    item = col_lg + ' ' + col_md + ' ' + col_xs;
                    subItem.push(item);
                });
                obj = subItem
                classArray.push(obj);
                console.log("ClassArray", classArray)
            })
            this.setState({
                reportData: resultjson.rows,
                classnameArray: classArray,
                loading: false
            })
        }
        catch (e) {
            console.error("Problem", e)
            this.setState({
                loading: false
            })
        }
    }

    componentDidMount() {
       let id = this.props.match.params.id
        this.getReportUI(id);
    }

    componentWillReceiveProps(newprops) {
        let id = newprops.match.params.id
         this.getReportUI(id);
     } 

    render() {
        let _repo = <KanbanViewDisplayAk reportData={this.state.reportData} classnameArray={this.state.classnameArray} />
        if (this.state.loading) {
            _repo = <Spinner />
        }
        return (
            <React.Fragment>
            {_repo}
            </React.Fragment>
            
        )
    }
}

export default KanbanViewerAk
