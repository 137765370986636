import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Parse from "parse";
import axios from "axios";
import Title from "./Title";
import { fetchAppInfo } from "./../actions";
import "./password.css";

//  { history } = props;
// history.push({
// pathname: "signup",
// });

const Signup = (props) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const [company, setCompany] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [state, setState] = useState({
    hideNav: "",
    loading: false,
    toastColor: "#5cb85c",
    toastDescription: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const settings = props.appInfo.settings;
  const image = props.appInfo.applogo;

  const handleSubmit = (event) => {
    setState({ loading: true });
    if (phone.length === 10) {
      try {
        event.preventDefault();
        Parse.serverURL = parseBaseUrl;
        Parse.initialize(parseAppId);
        event.preventDefault();
        var user = new Parse.User();
        user.set("name", name);
        user.set("email", email);
        user.set("password", password);
        user.set("phone", phone);
        user.set("username", email);
        let res = user.save();
        res
          .then(async (r) => {
            if (r) {
              let roleurl = `${parseBaseUrl}functions/AddUserToRole`;
              const headers = {
                "Content-Type": "application/json",
                "X-Parse-Application-Id": parseAppId,
                sessionToken:
                  r.getSessionToken() || localStorage.getItem("accesstoken"),
              };
              let body = {
                appName: props.appInfo.appname,
                roleName: props.appInfo.defaultRole,
                userId: r.id,
              };
              let role = await axios.post(roleurl, body, { headers: headers });

              let roleData = props.appInfo.settings;
              if (roleData && roleData.length > 0) {
                roleData.forEach((rld) => {
                  if (rld.role === props.appInfo.defaultRole) {
                    const extCls = Parse.Object.extend(rld.extended_class);
                    const newObj = new extCls();
                    newObj.set("UserId", {
                      __type: "Pointer",
                      className: "_User",
                      objectId: r.id,
                    });
                    newObj.set("UserRole", props.appInfo.defaultRole);
                    newObj.set("Email", email);
                    newObj.set("Name", name);
                    newObj.set("Phone", phone);

                    // newObj.set("Username", email);
                    // newObj.set("Password", password);
                    newObj.set("Company", company);
                    newObj.set("JobTitle", jobTitle);
                    newObj
                      .save()
                      .then((ex) => {
                        if (ex) {
                          // window.location.hash = "/";
                          handleNavigation(r.getSessionToken());
                        }
                      })
                      .catch((err) => alert(err.message));
                  }
                });
              }
            }
          })
          .catch((err) => alert(err.message));
      } catch (error) {
        console.log("err ", error);
      }
    } else {
      alert("Phone numver should be 10 digit!");
    }
  };

  const setLocalIframe = (iframeUrl) => {
    try {
      let data = {
        accesstoken: localStorage.getItem("accesstoken"),
        baseUrl: localStorage.getItem("baseUrl"),
        parseAppId: localStorage.getItem("parseAppId"),
        extended_class: localStorage.getItem("extended_class"),
        extend_details: localStorage.getItem("Extand_Class"),
        userSettings: localStorage.getItem("userSettings"),
        username: localStorage.getItem("username"),
        _appName: localStorage.getItem("_appName"),
        // tenantId: localStorage.getItem("TenetId"),
        TenetId: localStorage.getItem("TenetId"),
      };
      let storage = JSON.stringify({
        key: "storage",
        method: "set",
        data: data,
      });
      var iframe = document.getElementById("def_iframe");
      iframe.contentWindow.postMessage(storage, "*");
      setTimeout(() => {
        window.location.hash = `/microapp/${iframeUrl}`;
      }, 4000);
    } catch (error) {
      console.log(error);
    }
  };
  const handleNavigation = async (sessionToken) => {
    const baseUrl = localStorage.getItem("BaseUrl12");
    const parseAppId = localStorage.getItem("AppID12");
    const res = await axios.get(baseUrl + "users/me", {
      headers: {
        "X-Parse-Session-Token": sessionToken,
        "X-Parse-Application-Id": parseAppId,
      },
    });
    await Parse.User.become(sessionToken).then(() => {
      window.localStorage.setItem("accesstoken", sessionToken);
    });
    // console.log("me res ", res);
    if (res.data) {
      let _user = res.data;
      localStorage.setItem("UserInformation", JSON.stringify(_user));
      localStorage.setItem("userEmail", _user.email);
      localStorage.setItem("username", _user.name);
      localStorage.setItem("accesstoken", _user.sessionToken);
      localStorage.setItem("scriptId", true);
      if (_user.ProfilePic) {
        localStorage.setItem("profileImg", _user.ProfilePic);
      } else {
        localStorage.setItem("profileImg", "");
      }
      // Check extended class user role and tenentId
      try {
        let userRoles = [];
        if (props.appInfo.settings) {
          let userSettings = props.appInfo.settings;

          //Get Current user roles
          let url = `${baseUrl}functions/UserGroups`;
          const headers = {
            "Content-Type": "application/json",
            "X-Parse-Application-Id": parseAppId,
            sessionToken: _user.sessionToken,
          };

          let body = {
            appname: localStorage.getItem("_appName"),
          };
          await axios
            .post(url, JSON.stringify(body), { headers: headers })
            .then((roles) => {
              if (roles) {
                userRoles = roles.data.result;
                let _currentRole = "";
                if (userRoles.length > 1) {
                  if (
                    userRoles[0] ===
                    `${localStorage.getItem("_appName")}_appeditor`
                  ) {
                    _currentRole = userRoles[1];
                  } else {
                    _currentRole = userRoles[0];
                  }
                } else {
                  _currentRole = userRoles[0];
                }
                if (
                  _currentRole !==
                  `${localStorage.getItem("_appName")}_appeditor`
                ) {
                  userSettings.forEach(async (element) => {
                    if (element.role === _currentRole) {
                      let _role = _currentRole.replace(
                        `${localStorage.getItem("_appName")}_`,
                        ""
                      );
                      localStorage.setItem("_user_role", _role);

                      if (element.enableCart) {
                        localStorage.setItem("EnableCart", element.enableCart);
                        props.setEnableCart(element.enableCart);
                      } else {
                        localStorage.removeItem("EnableCart");
                      }
                      // debugger;
                      // Get TenentID from Extendend Class
                      localStorage.setItem(
                        "extended_class",
                        element.extended_class
                      );
                      localStorage.setItem("userpointer", element.userpointer);

                      const extendedClass = Parse.Object.extend(
                        element.extended_class
                      );
                      let query = new Parse.Query(extendedClass);
                      query.equalTo("UserId", Parse.User.current());
                      query.include("TenantId");
                      await query.find().then(
                        (results) => {
                          let tenentInfo = [];
                          if (results) {
                            let extendedInfo_stringify =
                              JSON.stringify(results);
                            debugger;
                            localStorage.setItem(
                              "Extand_Class",
                              extendedInfo_stringify
                            );
                            let extendedInfo = JSON.parse(
                              extendedInfo_stringify
                            );
                            if (extendedInfo.length > 1) {
                              extendedInfo.forEach((x) => {
                                if (x.TenantId) {
                                  let obj = {
                                    tenentId: x.TenantId.objectId,
                                    tenentName: x.TenantId.TenantName || "",
                                  };
                                  tenentInfo.push(obj);
                                }
                              });
                              if (tenentInfo.length) {
                                props.showTenantName(
                                  tenentInfo[0].tenentName || ""
                                );
                                localStorage.setItem(
                                  "TenantName",
                                  tenentInfo[0].tenentName || ""
                                );
                              }

                              localStorage.setItem("showpopup", true);
                              localStorage.setItem(
                                "IncludedApps",
                                JSON.stringify(tenentInfo)
                              );

                              localStorage.setItem(
                                "PageLanding",
                                element.pageId
                              );
                              localStorage.setItem(
                                "defaultmenuid",
                                element.menuId
                              );
                              localStorage.setItem(
                                "pageType",
                                element.pageType
                              );
                              setState({ loading: false });
                              window.location.hash = `/login`;
                            } else {
                              extendedInfo.forEach((x) => {
                                if (x.TenantId) {
                                  let obj = {
                                    tenentId: x.TenantId.objectId,
                                    tenentName: x.TenantId.TenantName || "",
                                  };
                                  localStorage.setItem(
                                    "TenetId",
                                    x.TenantId.objectId
                                  );
                                  tenentInfo.push(obj);
                                }
                              });
                              if (tenentInfo.length) {
                                props.showTenantName(
                                  tenentInfo[0].tenentName || ""
                                );
                                localStorage.setItem(
                                  "TenantName",
                                  tenentInfo[0].tenentName || ""
                                );
                              }
                              localStorage.setItem(
                                "PageLanding",
                                element.pageId
                              );
                              localStorage.setItem(
                                "defaultmenuid",
                                element.menuId
                              );
                              localStorage.setItem(
                                "pageType",
                                element.pageType
                              );
                              setState({ loading: false });
                              alert("Registered user successfully");
                              if (element.pageType === "microapp") {
                                setLocalIframe(element.pageId);
                              } else {
                                window.location.hash = `/${element.pageType}/${element.pageId}`;
                              }
                            }
                          } else {
                            alert("Registered user successfully");
                            localStorage.setItem("PageLanding", element.pageId);
                            localStorage.setItem(
                              "defaultmenuid",
                              element.menuId
                            );
                            localStorage.setItem("pageType", element.pageType);
                            setState({ loading: false });
                            if (element.pageType === "microapp") {
                              setLocalIframe(element.pageId);
                            } else {
                              window.location.hash = `/${element.pageType}/${element.pageId}`;
                            }
                          }
                        },
                        (error) => {
                          /*   alert(
                          "You dont have access to this application."
                        ); */
                          setState(
                            {
                              loading: false,
                              toastColor: "#d9534f",
                              toastDescription:
                                "You dont have access to this application.",
                            },
                            () => {
                              var x = document.getElementById("snackbar");
                              x.className = "show";
                              setTimeout(function () {
                                x.className = x.className.replace("show", "");
                              }, 5000);
                              localStorage.setItem("accesstoken", null);
                            }
                          );

                          console.error("Error while fetching Follow", error);
                        }
                      );
                    }
                  });
                } else {
                  setState(
                    {
                      loading: false,
                      toastColor: "#d9534f",
                      toastDescription: "User Role Not Found.",
                    },
                    () => {
                      var x = document.getElementById("snackbar");
                      x.className = "show";
                      setTimeout(function () {
                        x.className = x.className.replace("show", "");
                      }, 5000);
                    }
                  );
                }
              } else {
                setState(
                  {
                    loading: false,
                    toastColor: "#d9534f",
                    toastDescription: "User Role Not Found.",
                  },
                  () => {
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () {
                      x.className = x.className.replace("show", "");
                    }, 5000);
                  }
                );
              }
            })
            .catch((err) => {
              setState(
                {
                  loading: false,
                  toastColor: "#d9534f",
                  toastDescription: `Does not have permissions to access this application.`,
                },
                () => {
                  var x = document.getElementById("snackbar");
                  x.className = "show";
                  setTimeout(function () {
                    x.className = x.className.replace("show", "");
                  }, 5000);
                }
              );
            });
        }
      } catch (error) {
        // alert(`${error.message}`);
        setState(
          {
            loading: false,
            toastColor: "#d9534f",
            toastDescription: `${error.message}`,
          },
          () => {
            var x = document.getElementById("snackbar");
            x.className = "show";
            setTimeout(function () {
              x.className = x.className.replace("show", "");
            }, 5000);
          }
        );

        console.log(error);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("resize", resize);
    props.fetchAppInfo(
      localStorage.getItem("domain"),
      localStorage.getItem("BaseUrl12"),
      localStorage.getItem("AppID12")
    );
    return () => {
      window.removeEventListener("resize", resize);
    };
    // eslint-disable-next-line
  }, []);

  const resize = () => {
    let currentHideNav = window.innerWidth <= 760;
    if (currentHideNav !== state.hideNav) {
      setState({ hideNav: currentHideNav });
    }
  };
  return (
    <React.Fragment>
      {state.loading && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.2)",
            top: 0,
            left: 0,
            zIndex: 2,
          }}
        >
          <div
            style={{
              position: "fixed",
              fontSize: "50px",
              color: "#3ac9d6",
              top: "50%",
              left: "45%",
            }}
            className="loader-37"
          ></div>
        </div>
      )}
      {settings &&
        settings.map((x) => {
          return x.pageType === "microapp" ? (
            <iframe
              key={x.pageId}
              id="def_iframe"
              src={x.pageId}
              height="0px"
              width="0px"
            />
          ) : null;
        })}

      <Title title={"Signup page"} />

      {props.isloginVisible && props.isloginVisible ? (
        <React.Fragment>
          <div className="container GTRY">
            <div className="main_head">
              <div className="main-logo">
                {state.hideNav ? (
                  <img src={image} width="100%" />
                ) : (
                  <img src={image} width="100%" />
                )}
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6 KLO">
                  <h2>Create Account !</h2>
                  <div className="card card-box">
                    <div className="card-body">
                      <label>
                        Name{" "}
                        <span style={{ color: "red", fontSize: 13 }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                      <br />
                      <label>
                        Email{" "}
                        <span style={{ color: "red", fontSize: 13 }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <br />
                      <label>
                        Phone{" "}
                        <span style={{ color: "red", fontSize: 13 }}>*</span>
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                      <br />
                      <label>
                        Company{" "}
                        <span style={{ color: "red", fontSize: 13 }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        required
                      />
                      <br />
                      <label>
                        Job Title{" "}
                        <span style={{ color: "red", fontSize: 13 }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                        required
                      />
                      <br />
                      <label>
                        Password{" "}
                        <span style={{ color: "red", fontSize: 13 }}>*</span>
                      </label>
                      <div className="password-input">
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <span
                          className={`password-toggle ${
                            showPassword ? "visible" : ""
                          }`}
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <i className="fa fa-eye-slash" /> // Close eye icon
                          ) : (
                            <i className="fa fa-eye" /> // Open eye icon
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      className="col-md-6 col-sm-6"
                      style={
                        state.hideNav
                          ? {
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }
                          : {}
                      }
                    >
                      {state.hideNav ? (
                        state.loading ? (
                          <button
                            className="btn btn-info login-btn"
                            type="button"
                            style={{ marginBottom: "4px", width: "210px" }}
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-info"
                            style={{ marginBottom: "4px", width: "210px" }}
                          >
                            Register
                          </button>
                        )
                      ) : state.loading ? (
                        <button
                          className="btn btn-info login-btn"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm "
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-info login-btn"
                        >
                          Register
                        </button>
                      )}
                    </div>
                    <div
                      className="col-md-6 col-sm-6"
                      style={state.hideNav ? { textAlign: "center" } : {}}
                    >
                      <button
                        onClick={() => {
                          let { history } = props;
                          history.push({
                            pathname: "/",
                          });
                        }}
                        className="btn btn-reg btn-block"
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 KLO1">
                  <img
                    src={
                      localStorage.getItem("branding_image")
                        ? localStorage.getItem("branding_image")
                        : "./assets/img/login_img.svg"
                    }
                    width="100%"
                  />
                </div>
              </div>
            </form>
          </div>

          <div id="snackbar" style={{ backgroundColor: state.toastColor }}>
            {state.toastDescription}
          </div>
        </React.Fragment>
      ) : (
        <div
          style={{
            position: "fixed",
            fontSize: "50px",
            color: "#3ac9d6",
            top: "50%",
            left: "45%",
          }}
          className="loader-37"
        ></div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  if (Object.keys(state.appInfo).length !== 0) {
    return { appInfo: state.appInfo, isloginVisible: true };
  } else {
    return { appInfo: state.appInfo, isloginVisible: false };
  }
};

export default connect(mapStateToProps, { fetchAppInfo })(Signup);
