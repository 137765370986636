import React, { Component } from "react";
import Form from "react-jsonschema-form";
import Parse from "parse";
import axios from "axios";
import Title from "./Title";

export class Signup_app extends Component {
  state = {
    schema: {},
    ui_schema: {},
    formData: {},
    title: "",
    active: true,
    schemaState: {},
    loading: false,
    parseBaseUrl: localStorage.getItem("baseUrl"),
    parseAppId: localStorage.getItem("parseAppId"),
  };

  async getForm(id) {
    this.setState({
      loading: true,
    });
    try {
      Parse.serverURL = this.state.parseBaseUrl;
      Parse.initialize(this.state.parseAppId);
      var forms = Parse.Object.extend("w_formV3");
      var query = new Parse.Query(forms);
      query.equalTo("objectId", id);
      const results = await query.first();
      const resultjson = results.toJSON();
      this.setState({
        schemaState: resultjson.jsonSchema,
        ui_schema: resultjson.uiSchema,
        title: resultjson.class,
        loading: false,
      });
    } catch (e) {
      console.error("Problem", e);
      this.setState({
        loading: false,
      });
    }
  }

  handleSubmit = ({ formData }) => {
    let RowData = formData;
    try {
      Parse.serverURL = this.state.parseBaseUrl;
      Parse.initialize(this.state.parseAppId);
      let _fname = this.state.title;
      var _users = Parse.Object.extend("User");
      var _user = new _users();
      _user.set("username", formData.email);
      _user.save(formData).then(
        (u) => {
          try {
            let url = `${this.state.parseBaseUrl}functions/AddUserToRole`;
            const headers = {
              "Content-Type": "application/json",
              "X-Parse-Application-Id": this.state.parseAppId,
            };
            const domainurl = window.location.host;
            var urlSubName = url.split(".")[0];
            //   var urlSubName = 'dummypublic';

            let body = {
              appName: urlSubName,
              roleName: `${urlSubName}_public`,
              userId: u.id,
            };
            axios.post(url, body, { headers: headers }).then((res) => {
              alert("Record inserted Successfully");
              this.setState({
                active: true,
              });
            });
          } catch (e) {
            console.error("Problem", e);
            this.setState({
              active: true,
            });
          }
        },
        async (error) => {
          console.log("error", error);
          try {
            Parse.serverURL = this.state.parseBaseUrl;
            Parse.initialize(this.state.parseAppId);
            var _users = Parse.Object.extend("_User");
            var query = new Parse.Query(_users);
            query.equalTo("email", formData.users.email);
            const results = await query.first();
            let response = results.toJSON();
            let _emp = {
              __type: "Pointer",
              className: "_User",
              objectId: results.id,
            };
            let url = `${this.state.parseBaseUrl}functions/AddUserToRole`;
            const headers = {
              "Content-Type": "application/json",
              "X-Parse-Application-Id": this.state.parseAppId,
              sessionToken: localStorage.getItem("accesstoken"),
            };
            let body = {
              appName: localStorage.getItem("_appName"),
              roleName: formData.role,
              userId: results.id,
            };
            axios.post(url, body, { headers: headers }).then((res) => {

              let rowdata = formData.fields;
              const users = Parse.Object.extend(_fname);
              const User1 = new users();
              User1.set("class", _fname);
              User1.set("mainformObjId", this.props.match.params.id);
              User1.set("userId", _emp);
              User1.set("role", formData.role);
              User1.save(rowdata).then((res1) => {
                alert("Record inserted Successfully");
                this.setState({
                  active: true,
                });
              });
            });
          } catch (error) {
            alert("Something went wrong", error);
          }
        }
      );
    } catch (e) {}
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    this.getForm(id);
  }

  componentWillReceiveProps(newProps) {
    let id = newProps.match.params.id;
    this.getForm(id);
  }

  render() {
    return (
      <React.Fragment>
        <Title title={this.state.schemaState.title} />
        <Form
          schema={this.state.schemaState}
          uiSchema={this.state.ui_schema}
          formData={this.formData}
          onSubmit={this.handleSubmit}
        >
          <div>
            {this.state.active ? (
              <button className="btn btn-info" type="submit">
                Submit
              </button>
            ) : (
              <button className="btn btn-info" type="submit" disabled>
                Submit
              </button>
            )}
            &nbsp;&nbsp;
            <button
              className="btn btn-primary"
              onClick={() => {
                this.setState({
                  formData: {},
                });
              }}
              type="button"
            >
              Reset
            </button>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}

export default Signup_app;
