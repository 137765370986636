import React, { useState, Fragment, useEffect } from "react";
import Parse from "parse";
import Spinner from "../Spinner/Spinner";

function CustomeSaleReport(props) {
  const [offer, setOffer] = useState("");
  const [sale, setSale] = useState("");
  const [parseBaseUrl] = useState(
    localStorage.getItem("baseUrl")
  );
  const [parseAppId] = useState(
    localStorage.getItem("parseAppId")
  );
  const [loader, setLoder] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [offerData, setOfferData] = useState(null);
  const [stateOffer, setStateOffer] = useState("");
  const [districtOffer, setDistrictOffer] = useState("");

  // GetCustomerData method
  const GetCustomerData = async () => {
    try {
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      const Omar = Parse.Object.extend("loyaltyscheme_Customer");
      const query = new Parse.Query(Omar);
      query.equalTo("objectId", props.match.params.id);
      await query.first().then(
        (result) => {
          if (result) {
            let cust = result.toJSON();
            setCustomer(cust);
            setDistrictOffer(cust.District_group);
            setStateOffer(cust.State_group);
          }
        },
        (error) => {
          console.error("Error while fetching Omar", error);
        }
      );
    } catch (error) {}
  };

  // useEffect for get Customer Details
  useEffect(() => {
    GetCustomerData();
  }, [props.match.params.id]);

  // GetStateOffer method
  const GetStateOffer = async (stateoffer, districtOffer) => {
    try {
      if (stateOffer) {
        let date = new Date();
        Parse.serverURL = parseBaseUrl;
        Parse.initialize(parseAppId);
        const Omar = Parse.Object.extend("loyaltyscheme_AddOffer");
        const query = new Parse.Query(Omar);
        query.equalTo("State_Group", stateoffer);
        query.equalTo("Is_Special", true);
        query.equalTo("District_Group", districtOffer);
        query.equalTo("Expiry_Date", {
          $gte: { __type: "Date", iso: date.toISOString() },
        });
        query.include("ItemName");
        await query.find().then(
          (result) => {
            if (result) {
              let offr = JSON.stringify(result);
              let _offr = JSON.parse(offr);
              setOfferData(_offr);
            }
          },
          (error) => {
            console.error("Error while fetching", error);
          }
        );
      }
    } catch (error) {}
  };
  //useEffect for get State Special offer
  useEffect(() => {
    GetStateOffer(stateOffer, districtOffer);
  }, [stateOffer]);

  // on submit button
  const onSubmitHandel = async (e) => {
    e.preventDefault();
    setLoder(true);
    try {
      if (offer) {
        let _offerData = offerData.filter(
          (x) => x.objectId === offer && x.Offer_Points <= Number(sale)
        );
        if (_offerData.length > 0) {
          try {
            let d = new Date();

            let time =
              ("0" + d.getHours()).slice(-2) +
              ":" +
              ("0" + d.getMinutes()).slice(-2);

            const currentUser = Parse.User.current();
            const Agent = Parse.Object.extend("loyaltyscheme_Staff");
            const query = new Parse.Query(Agent);
            query.equalTo("UserId", currentUser);
            await query.first().then(
              async (results) => {
                if (results) {
                  let _res = results.toJSON();
                  const MyCustomClass = Parse.Object.extend(
                    "loyaltyscheme_Sale"
                  );
                  const myNewObject = new MyCustomClass();

                  myNewObject.set("Staff_Id", {
                    __type: "Pointer",
                    className: "loyaltyscheme_Staff",
                    objectId: results.id,
                  });
                  myNewObject.set("CustomerId", {
                    __type: "Pointer",
                    className: "loyaltyscheme_Customer",
                    objectId: props.match.params.id,
                  });
                  myNewObject.set("Sale_Date", new Date());
                  myNewObject.set("Sale_Time", time);
                  myNewObject.set("Sale", Number(sale));
                  myNewObject.set("Branch", _res.Branch);

                  return myNewObject.save().then(
                    async (result) => {
                      try {
                        const MyCustomClass = Parse.Object.extend(
                          "loyaltyscheme_Customer"
                        );
                        const query1 = new Parse.Query(MyCustomClass);
                        return await query1
                          .get(props.match.params.id)
                          .then((object) => {
                            if (customer.PointsTable !== undefined) {
                              object.set(
                                "PointsTable",
                                customer.PointsTable + Number(sale)
                              );
                            } else {
                              object.set("PointsTable", Number(sale));
                            }
                            if (customer.TotalSale !== undefined) {
                              object.set(
                                "TotalSale",
                                customer.TotalSale + Number(sale)
                              );
                            } else {
                              object.set("TotalSale", Number(sale));
                            }
                            object.set("LastSaleAmt", Number(sale));
                            object.set("Branch", _res.Branch);

                            object.set("LastSaleDate", new Date());
                            return object.save().then(
                              async (rr) => {
                                if (rr) {
                                  try {
                                    const redeem = Parse.Object.extend(
                                      "loyaltyscheme_RedeemOffers"
                                    );
                                    const _red = new redeem();
                                    _red.set(
                                      "Offer_Name",
                                      _offerData[0].Offer_Title
                                    );
                                    _red.set("Redeem_Date", new Date());
                                    _red.set("Redeem_Time", time);
                                    _red.set(
                                      "Offer_Points",
                                      _offerData[0].Offer_Points
                                    );
                                    _red.set("Redeem_Quantity", 1);
                                    _red.set(
                                      "Redeem_Total",
                                      1 * _offerData[0].Offer_Points
                                    );
                                    _red.set("CustomerId", {
                                      __type: "Pointer",
                                      className: "loyaltyscheme_Customer",
                                      objectId: props.match.params.id,
                                    });
                                    _red.set("OfferId", {
                                      __type: "Pointer",
                                      className: "loyaltyscheme_AddOffer",
                                      objectId: offer,
                                    });
                                    _red.set("Staffid", {
                                      __type: "Pointer",
                                      className: "loyaltyscheme_Staff",
                                      objectId: results.id,
                                    });
                                    _red.save().then(async (rdeem) => {
                                      if (rdeem) {
                                        try {
                                          const aaaa = Parse.Object.extend(
                                            "loyaltyscheme_Customer"
                                          );
                                          const query5 = new Parse.Query(aaaa);
                                          // here you put the objectId that you want to update
                                          await query5
                                            .get(props.match.params.id)
                                            .then((object) => {
                                              object.set(
                                                "PointsTable",
                                                object.attributes.PointsTable -
                                                  _offerData[0].Offer_Points * 1
                                              );
                                              object.save().then(
                                                async (response) => {
                                                  //get Inventary Item
                                                  const cls = Parse.Object.extend(
                                                    "loyaltyscheme_InventntoryItems"
                                                  );
                                                  const query11 = new Parse.Query(
                                                    cls
                                                  );
                                                  query11.equalTo(
                                                    "objectId",
                                                    _offerData[0].ItemName
                                                      .objectId
                                                  );
                                                  await query11.first().then(
                                                    async (results) => {
                                                      let _item = results.toJSON();

                                                      //update Inventarory Item
                                                      await query11
                                                        .get(_item.objectId)
                                                        .then((object) => {
                                                          object.set(
                                                            "Available_Stock",
                                                            _item.Available_Stock -
                                                              1
                                                          );
                                                          if (
                                                            _item.Minimum_Stock >=
                                                            _item.Available_Stock -
                                                              1
                                                          ) {
                                                            object.set(
                                                              "LMStock",
                                                              -1
                                                            );
                                                          }
                                                          object.save().then(
                                                            (response) => {
                                                              alert(
                                                                `Offer redeemed successfully. Please provide 1 ${_offerData[0]["ItemName"].S_K_U} ${_offerData[0]["ItemName"].Item_name} for ${_offerData[0].Offer_Points} points.`
                                                              );
                                                              window.location.hash =
                                                                "/";
                                                              // setLoder(false);

                                                              
                                                            },
                                                            (error) => {
                                                              let msg = {
                                                                message:
                                                                  "Something went wrong.",
                                                              };
                                                              setLoder(false);
                                                            }
                                                          );
                                                        });
                                                    },
                                                    (error) => {
                                                      alert(`${error.massage}`);
                                                      setLoder(false);
                                                    }
                                                  );
                                                },
                                                (error) => {
                                                  alert(`${error.massage}`);
                                                  setLoder(false);
                                                  console.error(
                                                    "Error while updating aaaa",
                                                    error
                                                  );
                                                }
                                              );
                                            });
                                        } catch (error) {}
                                      }
                                    });
                                  } catch (error) {}
                                }
                              },
                              (error) => {
                                alert(`${error.massage}`);
                                setLoder(false);
                              }
                            );
                          });
                      } catch {}

                      //return result;

                    },
                    (error) => {
                      // return error;
                      alert(`${error.massage}`);
                      setLoder(false);
                      console.error(
                        "Error while creating ParseObject: ",
                        error
                      );
                    }
                  );
                }
              },
              (error) => {
                alert(`${error.massage}`);
                setLoder(false);
                console.error("Error while fetching Agent", error);
              }
            );
          } catch {}
        } else {
          alert("You don't have enough points to redeem this offer");
          setLoder(false);
        }
      } else {
        try {
          let d = new Date();

          let time =
            ("0" + d.getHours()).slice(-2) +
            ":" +
            ("0" + d.getMinutes()).slice(-2);

          const currentUser = Parse.User.current();
          const Agent = Parse.Object.extend("loyaltyscheme_Staff");
          const query = new Parse.Query(Agent);
          query.equalTo("UserId", currentUser);
          await query.first().then(
            async (results) => {
              if (results) {
                let _res = results.toJSON();
                const MyCustomClass = Parse.Object.extend("loyaltyscheme_Sale");
                const myNewObject = new MyCustomClass();

                myNewObject.set("Staff_Id", {
                  __type: "Pointer",
                  className: "loyaltyscheme_Staff",
                  objectId: results.id,
                });
                myNewObject.set("CustomerId", {
                  __type: "Pointer",
                  className: "loyaltyscheme_Customer",
                  objectId: props.match.params.id,
                });
                myNewObject.set("Sale_Date", new Date());
                myNewObject.set("Sale_Time", time);
                myNewObject.set("Sale", Number(sale));
                myNewObject.set("Branch", _res.Branch);

                return myNewObject.save().then(
                  async (result) => {
                    try {
                      const MyCustomClass = Parse.Object.extend(
                        "loyaltyscheme_Customer"
                      );
                      const query1 = new Parse.Query(MyCustomClass);
                      return await query1
                        .get(props.match.params.id)
                        .then((object) => {
                          if (object.attributes.PointsTable !== undefined) {
                            object.set(
                              "PointsTable",
                              object.attributes.PointsTable + Number(sale)
                            );
                          } else {
                            object.set("PointsTable", Number(sale));
                          }
                          if (customer.TotalSale !== undefined) {
                            object.set(
                              "TotalSale",
                              customer.TotalSale + Number(sale)
                            );
                          } else {
                            object.set("TotalSale", Number(sale));
                          }
                          object.set("LastSaleAmt", Number(sale));
                          object.set("Branch", _res.Branch);

                          object.set("LastSaleDate", new Date());
                          return object.save().then(
                            (response) => {
                              alert("Record updated Seccessfully.");
                              window.location.hash = "/";
                              // setLoder(false);
                            },
                            (error) => {
                              alert(`${error.massage}`);
                              setLoder(false);
                            }
                          );
                        });
                    } catch {}

                    //return result;

                  },
                  (error) => {
                    alert(`${error.massage}`);
                    setLoder(false);
                  }
                );
              }
            },
            (error) => {
              alert(`${error.massage}`);
              setLoder(false);
              console.error("Error while fetching Agent", error);
            }
          );
        } catch {}
      }
    } catch (error) {}
  };
  let viewer = <Spinner />;
  if (loader) {
    return viewer;
  } else {
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-head">
                <header>Sale Entry</header>
              </div>
              <div className="card-body no-padding height-9">
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col-2"></div>
                </div>
                <form onSubmit={onSubmitHandel}>
                  <div className="form-group">
                    <label htmlFor="offers">Special Offers</label>
                    <br />
                    {offerData && offerData.length > 0
                      ? offerData.map((x, i) => (
                          <label
                            className="radio-inline"
                            style={{ padding: "5px" }}
                          >
                            <input
                              type="radio"
                              name="offers"
                              value={x.objectId}
                              checked={offer === x.objectId}
                              onChange={(e) => setOffer(e.target.value)}
                              required
                            />
                            {x.Offer_Title}
                          </label>
                        ))
                      : "Currently Not any Special Offers"}
                  </div>
                  <br />
                  <div className="form-group">
                    <label htmlFor="sale">Sale (L)</label>
                    <input
                      type="number"
                      name="sale"
                      value={sale}
                      className="form-control"
                      onChange={(e) => setSale(e.target.value)}
                    />
                  </div>
                  <button type="submit" className="btn btn-info">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CustomeSaleReport;
