import React from "react";
import "./loyalty.css";

function LoyaltySchemeContact() {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="panel-body text-left">
            <div className="row">
              <div className="col-md-4">
                {" "}
                <div className="uvs-tests">
                  <table>
                    <tr>
                      <td colSpan="2" align="center">
                        {" "}
                        <img
                          src="./assets/img/iol.png"
                          width="100% !important"
                        />{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        {" "}
                        <h2>G. N. Agrawal</h2>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fas fa-map-marker"></i>
                      </td>
                      <td>
                        Indian Oil Petrol Pump
                        <br />
                        NH-06, Khamgaon - Akola Road,
                        <br />
                        Tembhurna Village Khamgaon
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fa fa-phone"></i>
                      </td>{" "}
                      <td> 9049458811 </td>
                    </tr>
                  </table>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7465.261764737925!2d76.61221537864903!3d20.684590901687464!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9925f0d16fe0bb9b!2sG%20N%20Agrawal%20Petrol%20Pump%20IndianOil!5e0!3m2!1sen!2sin!4v1589960035644!5m2!1sen!2sin"
                    width="100%"
                    height="150"
                    frameborder="0"
                    border="0"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>{" "}
              </div>
              <div className="col-md-4">
                <div className="uvs-tests">
                  <table>
                    {" "}
                    <tr>
                      <td colSpan="2" align="center">
                        {" "}
                        <img
                          src="./assets/img/bp.png"
                          width="100% !important"
                        />{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        {" "}
                        <h2>Rajendra Singh Minhas</h2>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fas fa-map-marker"></i>
                      </td>
                      <td>
                        Bharat Petroleum Petrol Pump.
                        <br />
                        NH-06 , Khamgaon - Akola Road .<br />
                        Tembhurna Village , Khamgaon
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <i className="fa fa-phone"></i>
                      </td>
                      <td> 9922929511 </td>
                    </tr>
                  </table>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14931.391543223222!2d76.616638!3d20.6757667!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf12e7b9ff4587288!2sBPCL%20Petrol%20Pump!5e0!3m2!1sen!2sin!4v1589968410649!5m2!1sen!2sin"
                    width="100%"
                    height="150"
                    frameborder="0"
                    border="0"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>{" "}
              </div>
              <div className="col-md-4">
                <div className="uvs-tests">
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td colSpan="2" align="center">
                          {" "}
                          <img
                            src="./assets/img/hpcl.png"
                            width="100% !important"
                          />{" "}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          {" "}
                          <h2>Jhunjhunwala Traders</h2>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fas fa-map-marker"></i>
                        </td>
                        <td>
                          HPCL Petrol Pump
                          <br />
                          NH-06 Khamgaon - Akola Road
                          <br />
                          Kolori village . Khamgaon
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <i className="fa fa-phone"></i>
                        </td>
                        <td> 9730904611</td>
                      </tr>
                    </tbody>
                  </table>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14931.894905679515!2d76.66224896621522!3d20.67064788233752!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd74e773d9d3e2b%3A0xf3d9d30c0a54488a!2sJhunjhunwala%20Traders%2C%20Hindustan%20Petroleum!5e0!3m2!1sen!2sin!4v1590670539600!5m2!1sen!2sin"
                    width="100%"
                    height="150"
                    frameborder="0"
                    border="0"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  ></iframe>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoyaltySchemeContact;
