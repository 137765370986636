import React, { Component } from 'react';
import Parse from 'parse';


//const parseBaseUrl = localStorage.getItem('baseUrl');
//const parseAppId = localStorage.getItem('parseAppId');

class Board extends Component {

    state = { boardData: [],
        parseBaseUrl: localStorage.getItem("baseUrl"),
        parseAppId: localStorage.getItem("parseAppId")
     }


    async getBoardData() {
        try {
            let res = '', res1 = [];
            Parse.serverURL = this.state.parseBaseUrl;
            Parse.initialize(this.state.parseAppId);
            var forms = Parse.Object.extend("uat_Projects");
            const query = new Parse.Query(forms);
            await query.find().then(results => {
                if (typeof document !== 'undefined')
                    res = JSON.stringify(results);
                res1 = JSON.parse(res)
                this.setState({
                    boardData: res1
                })
            });

        } catch (e) {
            console.error("Problem", e)
        }
    }

    getTaskBoard(id) {
        
        let { history } = this.props; 
        history.push({
            pathname: '/kanban/' + id
        });
    }
    componentDidMount() {
        this.getBoardData();
    }

    render() {
        const _board = this.state.boardData.map((val) =>
            <div className="card text-center col-md-3"
                style={{ margin: "5px" }} key={val.title}>
                <div className="card-body">
                    <a onClick={() => {
                        localStorage.setItem("kbID", val.title)
                        this.getTaskBoard(val.objectId)
                    }}>
                        <h5 className="card-title">{val.title}</h5>
                        <p className="card-text">{val.description}</p>
                        <p className="card-text"><small className="text-muted">{val.createdAt}</small></p>
                    </a>
                </div>
            </div>
        )
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="card-box">
                        <div className="card-head">
                            <header>Project Status</header>
                        </div>
                        <div className="card-body row">
                            {_board}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Board
