import React, { useEffect, useState } from "react";
import "../CustomeConfig/CustomeHome.css";
import Parse from "parse";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  Redirect,
} from "react-router-dom";
import FormBuilder from "../components/FormBuilder";
import Dashboard from "../components/Dashboard";
import ReportData from "../components/ReportData";
import CustomeHorizantal from "./CustomeHorizantal";
import CustomeEdit from "./CustomeEdit";

const CustomeHome = (props) => {
  const [menuList, setmenuList] = useState([]);
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const [loading, setLoading] = useState(true);
  const [pageType, setPageType] = useState("");
  const [objectId, setObjejectId] = useState("");
  const getMenuList = async (id) => {
    try {
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      var sideMenu = Parse.Object.extend("w_menu");
      var query = new Parse.Query(sideMenu);
      query.equalTo("objectId", id);
      //	query.equalTo("objectId", "46X9z6lAv2");
      const results = await query.first();
      const resultjson = results.toJSON();
      let result = resultjson;
      setmenuList(result.menuItems);
    } catch (e) {
      console.error("Problem", e);
    }
  };

  useEffect(() => {
    getMenuList(props.match.params.id);

    // eslint-disable-next-line
  }, [props.match.params.id]);

  useEffect(() => {
    renderDefault();

    // eslint-disable-next-line
  }, [menuList]);
  const renderDefault = () => {
    if (
      menuList.length > 0 &&
      menuList[0].pageType !== undefined &&
      menuList[0].objectId !== undefined
    ) {
      if (menuList[0].children === undefined) {
        setPageType(menuList[0].pageType);
        setObjejectId(menuList[0].objectId);
      } else {
        setPageType(menuList[0].children[0].pageType);
        setObjejectId(menuList[0].children[0].objectId);
      }
    }
  };

  return (
    <div className="row">
      <div className="col-md-12 col-12">
        <div className="profile-sidebar">
          <div className="card card-topline-aqua">
            <div className="card-body no-padding height-9">
              <nav className="navigation">
                <ul className="mainmenu">
                  {menuList &&
                    menuList.map((item, i) =>
                      item.children === undefined ? (
                        <li key={i}>
                          {item.pageType === "Settings H Menu" || item.pageType === "Setting H Menu" ? (
                            <NavLink
                              to={`${props.match.url}/${"h"}/${item.objectId}`}
                            >
                              <i
                                className={item.icon}
                                style={{ color: "#444" }}
                              ></i>
                              <span className="title">{item.title}</span>
                            </NavLink>
                          ) : item.pageType === "Settings Form" ? (
                            <NavLink
                              to={`${props.match.url}/${"_editform"}/${item.objectId}`}
                            >
                              <i
                                className={item.icon}
                                style={{ color: "#444" }}
                              ></i>
                              <span className="title">{item.title}</span>
                            </NavLink>
                          ) : (
                            <NavLink
                              to={`${props.match.url}/${item.pageType}/${item.objectId}`}
                            >
                              <i
                                className={item.icon}
                                style={{ color: "#444" }}
                              ></i>
                              <span className="title">{item.title}</span>
                            </NavLink>
                          )}
                        </li>
                      ) : (
                        <li key={i}>
                          <a
                            data-toggle="collapse"
                            onclick={(e) => e.preventDefault()}
                          >
                            <i
                              className={item.icon}
                              style={{ color: "#444" }}
                            ></i>
                            <span className="title">{item.title}</span>
                            <span className="arrow"></span>
                          </a>
                          <ul className="submenu">
                            {item.children.map((sub, k) =>
                              sub.children === undefined ? (
                                <li key={sub.pageType}>
                                  {sub.pageType === "Setting H Menu" || sub.pageType === "Settings H Menu" ? (
                                    <NavLink
                                      to={`${props.match.url}/${"h"}/${
                                        sub.objectId
                                      }`}
                                    >
                                      <i
                                        className={sub.icon}
                                        style={{ color: "#444" }}
                                      ></i>
                                      <span className="title">{sub.title}</span>
                                    </NavLink>
                                  ) : sub.pageType === "Settings Form" ? (
                                    <NavLink
                                      to={`${props.match.url}/${"_editform"}/${sub.objectId}`}
                                    >
                                      <i
                                        className={sub.icon}
                                        style={{ color: "#444" }}
                                      ></i>
                                      <span className="title">{sub.title}</span>
                                    </NavLink>
                                  ): (
                                    <NavLink
                                      to={`${props.match.url}/${sub.pageType}/${sub.objectId}`}
                                    >
                                      <i
                                        className={sub.icon}
                                        style={{ color: "#444" }}
                                      ></i>
                                      <span className="title">{sub.title}</span>
                                    </NavLink>
                                  )}
                                </li>
                              ) : null
                            )}
                          </ul>
                        </li>
                      )
                    )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <div className="profile-content">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-head card-topline-aqua">
                  <div className="card-body no-padding height-9">
                    <hr className="col-12" />
                    <div className="col-12" style={{ overflow: "auto" }}>
                      <Switch>
                        <Route
                          path={`${props.match.path}/form/:id`}
                          component={FormBuilder}
                        /> 
                        <Route
                          path={`${props.match.path}/report/:id`}
                          component={ReportData}
                        />
                        <Route
                          path={`${props.match.path}/dashboard/:id`}
                          component={Dashboard}
                        />
                        <Route
                          path={`${props.match.path}/h/:id`}
                          component={CustomeHorizantal}
                        />
                         <Route
                          path={`${props.match.path}/_editform/:id`}
                          component={CustomeEdit}
                        />
                      </Switch>
                      {menuList.length > 0 ? (
                        pageType === "Setting H Menu" ? (
                          <Redirect
                            to={`${props.match.url}/h/${pageType}/${objectId}`}
                          />
                        ) : (
                          <Redirect
                            to={`${props.match.url}/${pageType}/${objectId}`}
                          />
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomeHome;
