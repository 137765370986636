import React, { Component } from "react";
import { connect } from "react-redux";
import { removeState, removeLevel2State, removeLevel3State } from "../actions";
import Engine from "json-rules-engine-simplified";
import applyRules from "react-jsonschema-form-conditionals";
import Form from "react-jsonschema-form";
import Parse from "parse";
import parse from "html-react-parser";
import LayoutField from "react-jsonschema-form-layout-2";
import "../Spinner/loader.css";
import TimeWidget from "../CustomeWidget/TimeWidget";
import MultiSelectField from "../LevelDropDown/MultiSelectField";
import RoleField from "../CustomeWidget/RoleField";
import axios from "axios";
import LayoutGridField from "react-jsonschema-form-layout-grid";
import Level3Dropdown from "../LevelDropDown/Level3Dropdown";
import Level2Dropdown from "../LevelDropDown/Level2Dropdown";
import Level1Dropdown from "../LevelDropDown/Level1Dropdown";
import ImageUpload from "../CustomeWidget/ImageUpload";
import "../components/modal.css";
import HiddenField from "../CustomeWidget/HiddenField";
import AutoIncrementValue from "../CustomeWidget/AutoIncrementValue";
import MobileNumber from "../CustomeWidget/MobileNumber";
import AdvanceTextBox from "../LevelDropDown/AdvanceTextBox";
import FileUpload from "../LevelDropDown/FileUpload";
import TreeWidget from "../LevelDropDown/TreeWidget";
import "./toast.css";
import DynamicRadio from "../LevelDropDown/DynamicRadio";
const $ = window.$;

const widget = {
  TimeWidget: TimeWidget,
};
const fields = {
  MultiSelectField: MultiSelectField,
  DynamicRadio: DynamicRadio,
  FileUpload: FileUpload,
  AdvanceTextBox: AdvanceTextBox,
  MobileNumber: MobileNumber,
  layout: LayoutField,
  layout_grid: LayoutGridField,
  RoleField: RoleField,
  Level1Dropdown: Level1Dropdown,
  Level2Dropdown: Level2Dropdown,
  Level3Dropdown: Level3Dropdown,
  FolderComponent: TreeWidget,
  ImageUpload: ImageUpload,
  HiddenField: HiddenField,
  AutoIncrementValue: AutoIncrementValue,
};
class Model extends Component {
  state = {
    schema: {},
    jsonSchema: {},
    extraActions: undefined,
    rules: [],
    ui_schema: {},
    formData: {},
    ClassName: "",
    isAppRequest: false,
    persistentFields: [],
    successMassage: "Record inserted successfully.",
    title: "",
    loading: false,
    Data: "",
    _validate: null,
    noValidate: false,
    liveValidate: false,
    redirect_type: "",
    redirect_id: "",
    toastColor: "#5cb85c",
    toastDescription: "",
    buttons: {},
    parseBaseUrl: localStorage.getItem("baseUrl"),
    parseAppId: localStorage.getItem("parseAppId"),
  };

  wrap = (s) => "{ return " + s + " };";

  dynamicValidate = (formData, errors) => {
    try {
      let body = atob(this.state._validate);
      let res = new Function(this.wrap(body))
        .call(null)
        .call(null, formData, errors);
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  setExtraActions = (actions) => {
    try {
      let result = {};
      Object.entries(actions).forEach(([key, value]) => {
        let body = atob(value);
        let res = new Function(this.wrap(body)).call(null);
        result[key] = res;
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  async GetForm() {
    let Data = [];
    if (typeof this.props.data === "string") {
      this.setState({ Data: this.props.data, loading: true });
    } else {
      this.props.data.forEach((val) => {
        val.columns.map((col) => {
          if (col.widget.type === "CreateButton") {
            Data = col.widget.data;
          }
          return 1;
        });
      });

      this.setState({
        loading: true,
        Data: Data[0].formid,
      });
    }

    try {
      Parse.serverURL = this.state.parseBaseUrl;
      Parse.initialize(this.state.parseAppId);
      var forms = Parse.Object.extend("w_formV3");
      var query = new Parse.Query(forms);
      if (typeof this.props.data === "string") {
        query.equalTo("objectId", this.props.data);
      } else {
        query.equalTo("objectId", Data[0].formid);
      }
      /*  if (typeof this.props.data === "string") {
        query.equalTo("objectId", "krF9y85XmA");
      } else {
        query.equalTo("objectId", "krF9y85XmA");
      } */
      const results = await query.first();
      const resultjson = results.toJSON();
      let result = resultjson;
      for (let [key, value] of Object.entries(result.jsonSchema.properties)) {
        if (typeof value === "object") {
          for (let [k, v] of Object.entries(value)) {
            if (k === "format" && v === "date") {
              let today = new Date();
              let date =
                today.getFullYear() +
                "-" +
                ("0" + (today.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + today.getDate()).slice(-2);
              value.default = date;
            }
            if (k === "component" && v === "DateTime") {
              value.default = new Date().toISOString();
            }
          }
        }
      }
      let txt,
        link,
        successMsg,
        _rules = [],
        _extraActions = {};
      if (result.help) {
        if (result.help.htmlbody) {
          txt = result.help.htmlbody;
        }
        if (result.help.link) {
          link = result.help.link;
        }
      }
      if (result.rules) {
        _rules = result.rules;
      }
      if (result.extraActions) {
        _extraActions = this.setExtraActions(result.extraActions);
      }
      if (result.success_message) {
        successMsg = result.success_message;
      } else {
        successMsg = this.state.successMassage;
      }
      let _jsonSchema = JSON.stringify(result.jsonSchema);
      _jsonSchema = _jsonSchema.replace("#$", "$");
      _jsonSchema = _jsonSchema.replace("#*", "$");
      _jsonSchema = _jsonSchema.replace("_DOT_", ".");

      let _replaceJSONSchema = JSON.parse(_jsonSchema);
      this.setState(
        {
          redirect_type: result.success_redirect,
          redirect_id: result.redirect_id,
          buttons: result.buttons.add,
          schema: result.jsonSchema,
          jsonSchema: _replaceJSONSchema,
          ui_schema: result.uiSchema,
          ["FormACL"]: result.formACL,
          ["help"]: txt,
          ["link"]: link,
          successMassage: successMsg,
          rules: _rules,
          extraActions: _extraActions,
          _validate: result.validFunction,
          noValidate: result.noValidate,
          liveValidate: result.liveValidate,
          userSchema: result.userSchema,
          title: result.class,
          ClassName: result.class,
          loading: false,
        },
        () => {
          if (this.props.ObjectId && this.state.ClassName) {
            this.getFormData(this.props.ObjectId);
          }
        }
      );
    } catch (e) {
      console.error("Problem", e);
      this.setState({
        loading: false,
      });
    }
  }

  handleSubmit = async ({ formData }) => {
    this.setState({ active: false, loading: true });

    if (
      this.state.userSchema &&
      Object.entries(this.state.userSchema).length !== 0 &&
      this.state.userSchema.constructor === Object
    ) {
      try {
        let RowData = formData;
        RowData &&
          Object.entries(RowData).forEach(([key, value]) => {
            if (typeof value === "string") {
              RowData[key] = value.trim();
            }
          });
        let UserData = {};
        let RoleField = "";
        let _scanData = this.state.jsonSchema;
        if (_scanData.dependencies) {
          Object.keys(_scanData.dependencies).forEach((key) => {
            if (_scanData.dependencies[key].oneOf) {
              _scanData.dependencies[key].oneOf.forEach((val) => {
                Object.keys(val.properties).forEach((k) => {
                  if (typeof val.properties[k] === "object") {
                    if (val.properties[k].format === "date") {
                      if (RowData[k]) {
                        let newdate = new Date(RowData[k]);
                        RowData[k] = newdate;
                      }
                    }
                    if (val.properties[k].component === "HtmlEditor") {
                      if (RowData[k]) {
                        let newHtml = RowData[k]
                          .replace(/<p[^>]*>/g, "")
                          .replace(/<\/p>/g, " ");
                        RowData[k] = newHtml;
                      }
                    }
                    if (val.properties[k].component === "DateTime") {
                      if (RowData[k]) {
                        let newDate11 = new Date(RowData[k]);
                        RowData[k] = newDate11;
                      }
                    }
                    if (val.properties[k].component === "CurrencyInput") {
                      if (val.properties[k].currencyColumn) {
                        RowData[`${val.properties[k].currencyColumn}`] =
                          val.properties[k].defaultcurrency;
                      }
                    }
                    if (val.properties[k].type === "string") {
                      if (typeof RowData[k] === "string")
                        RowData[k] = RowData[k].trim();
                    }
                    if (val.properties[k].data !== undefined) {
                      if (Array.isArray(val.properties[k].data)) {
                      } else if (val.properties[k].data.isPointer) {
                        let pointer = undefined;
                        if (val.properties[k].data.class) {
                          if (val.properties[k].data.savePointerClass) {
                            if (RowData[k]) {
                              pointer = {
                                __type: "Pointer",
                                className:
                                  val.properties[k].data.savePointerClass,
                                objectId: RowData[k],
                              };
                            }
                          } else if (RowData[k]) {
                            pointer = {
                              __type: "Pointer",
                              className: val.properties[k].data.class,
                              objectId: RowData[k],
                            };
                          }
                        } else {
                          if (RowData[k]) {
                            pointer = {
                              __type: "Pointer",
                              className: localStorage.getItem("extended_class"),
                              objectId: RowData[k],
                            };
                          }
                        }
                        RowData[k] = pointer;
                      }
                      if (val.properties[k].data.FolderTypeValue) {
                        if (RowData[k]) {
                          let obj = {
                            __type: "Pointer",
                            className: val.properties[k].data.ClassName,
                            objectId: RowData[k],
                          };
                          RowData[k] = obj;
                        }
                      }
                    }
                  }
                });
              });
            }
          });
        }
        let _userScheama = this.state.userSchema;
        let temp = [];
        Object.keys(_scanData).forEach(function (key) {
          let _dd = _scanData[key];
          Object.keys(_dd).forEach(function (k) {
            if (_dd[k].type === "array" && _dd[k].items) {
              let _prop = _dd[k].items.properties;
              if (_prop && Array.isArray(RowData[k])) {
                let newRow = [];
                RowData[k].forEach((t) => {
                  let _newObj = t;
                  if (typeof t === "object") {
                    Object.keys(_prop).forEach(function (l) {
                      if (_prop[l].data && _prop[l].data.isPointer) {
                        if (typeof t[l] === "object") {
                          let obj = {
                            __type: "Pointer",
                            className: _prop[l].data.class,
                            objectId: t[l].objectId,
                          };
                          _newObj = { ..._newObj, [l]: obj };
                        } else {
                          let obj = {
                            __type: "Pointer",
                            className: _prop[l].data.class,
                            objectId: t[l],
                          };
                          _newObj = { ..._newObj, [l]: obj };
                        }
                      }
                    });
                  }
                  newRow.push(_newObj);
                });
                RowData[k] = newRow;
              }
            }

            if (_dd[k].format === "date") {
              let newdate = new Date(RowData[k]);
              RowData[k] = newdate;
            }
            if (_dd[k].component === "HtmlEditor") {
              if (RowData[k]) {
                let newHtml = RowData[k]
                  .replace(/<p[^>]*>/g, "")
                  .replace(/<\/p>/g, " ");
                RowData[k] = newHtml;
              }
            }
            if (_dd[k].component === "DateTime") {
              let newDate;
              if ((RowData[k] = "")) {
                newDate = new Date();
              } else {
                newDate = new Date(RowData[k]);
              }
              RowData[k] = newDate;
            }
            if (_dd[k].component === "CurrencyInput") {
              if (_dd[k].currencyColumn) {
                RowData[`${_dd[k].currencyColumn}`] = _dd[k].defaultcurrency;
              }
            }
            if (_dd[k].data !== undefined) {
              if (Array.isArray(_dd[k].data)) {
              } else if (_dd[k].data.isPointer) {
                let pointer = undefined;
                if (_dd[k].data.class) {
                  if (_dd[k].data.savePointerClass) {
                    if (RowData[k]) {
                      pointer = {
                        __type: "Pointer",
                        className: _dd[k].data.savePointerClass,
                        objectId: RowData[k],
                      };
                    }
                  } else if (RowData[k]) {
                    pointer = {
                      __type: "Pointer",
                      className: _dd[k].data.class,
                      objectId: RowData[k],
                    };
                  }
                } else {
                  if (RowData[k]) {
                    pointer = {
                      __type: "Pointer",
                      className: localStorage.getItem("extended_class"),
                      objectId: RowData[k],
                    };
                  }
                }
                RowData[k] = pointer;
              }
              if (_dd[k].data.FolderTypeValue) {
                if (RowData[k]) {
                  let obj = {
                    __type: "Pointer",
                    className: _dd[k].data.ClassName,
                    objectId: RowData[k],
                  };
                  RowData[k] = obj;
                }
              }
            }
            if (_dd[k].type === "string") {
              if (typeof RowData[k] === "string")
                RowData[k] = RowData[k].trim();
            }
          });
        });

        Object.keys(_userScheama).forEach(function (kkey) {
          let _userKey = _userScheama[kkey];
          Object.keys(RowData).forEach(function (_k) {
            if (_userScheama[kkey].startsWith("$")) {
              let _uuu = _userScheama[kkey].replace("$", "");
              if (kkey === "Role" || kkey === "role") {
                if (RowData[_uuu] === RowData[_k]) {
                  RoleField = RowData[_uuu];
                }
              } else if (_uuu === _k) {
                UserData[kkey] = RowData[_k];
              }
              if (kkey === "phone") {
                if (RowData[_uuu] === RowData[_k]) {
                  RowData[_uuu] = RowData[_uuu].toString();
                }
              }
            } else {
              RoleField = _userScheama[kkey];
            }
          });
        });
        Parse.serverURL = this.state.parseBaseUrl;
        Parse.initialize(this.state.parseAppId);
        var _users = Parse.Object.extend("_User");
        var _user = new _users();
        _user.set("name", UserData.name);
        if (UserData.username) {
          _user.set("username", UserData.username.toString().trim());
          if (UserData.email) {
            _user.set("email", UserData.email);
          }
        } else if (UserData.email) {
          _user.set("email", UserData.email);
          _user.set("username", UserData.email);
        } else {
          _user.set("username", UserData.phone.toString());
        }
        _user.set("phone", UserData.phone.toString());
        _user.set("password", UserData.password);
        _user.save().then(
          async (u) => {
            let roleurl = `${this.state.parseBaseUrl}functions/AddUserToRole`;
            const headers = {
              "Content-Type": "application/json",
              "X-Parse-Application-Id": this.state.parseAppId,
              sessionToken: localStorage.getItem("accesstoken"),
            };
            let _role = "";
            if (RoleField) {
              _role = RoleField;
            } else {
              _role = UserData.Role;
            }
            let body = {
              appName: localStorage.getItem("_appName"),
              roleName: _role,
              userId: u.id,
            };
            axios.post(roleurl, body, { headers: headers }).then((res) => {
              const currentUser = Parse.User.current();
              let _fname = this.state.title;
              var forms = Parse.Object.extend(_fname);
              var form = new forms();
              form.set(
                "CreatedBy",
                Parse.User.createWithoutData(currentUser.id)
              );
              if (localStorage.getItem("TenetId")) {
                form.set("TenantId", {
                  __type: "Pointer",
                  className: "partners_Tenant",
                  objectId: localStorage.getItem("TenetId"),
                });
              }
              form.set("UserId", u);
              form.set("UserRole", _role);
              if (this.state["FormACL"]) {
                let ACL = {};
                for (let [key, value] of Object.entries(
                  this.state["FormACL"]
                )) {
                  if (key === "*") {
                    ACL[key] = value;
                  }
                  if (key === "#currentUser#") {
                    ACL[Parse.User.current().id] = value;
                  }
                  if (key.startsWith("role")) {
                    ACL[key] = value;
                  }
                }
                form.setACL(new Parse.ACL(ACL));
              }
              form
                .save(RowData)
                .then(
                  () => {
                    let filtered = {};
                    if (this.state.redirect_type === "clearData") {
                      if (
                        this.state.persistentFields &&
                        this.state.persistentFields.length
                      ) {
                        filtered = Object.keys(RowData)
                          .filter((key) =>
                            this.state.persistentFields.includes(key)
                          )
                          .reduce((obj, key) => {
                            obj[key] = RowData[key];
                            return obj;
                          }, {});
                      }
                    } else {
                      RowData = {};
                    }
                    this.setState(
                      {
                        formData: filtered,
                        active: true,
                        loading: false,
                        toastColor: "#5cb85c",
                        toastDescription: this.state.successMassage,
                      },
                      function () {
                        let redirect_type = this.state.redirect_type;
                        let redirect_id = this.state.redirect_id;
                        let isApp = this.state.isAppRequest;
                        this.props.removeState();
                        this.props.removeLevel2State();
                        this.props.removeLevel3State();
                        var x = document.getElementById("snackbar10");
                        x.className = "show";
                        if (redirect_type && redirect_id) {
                          setTimeout(() => {
                            x.className = x.className.replace("show", "");
                            this.props.GetMethod(this.props.data);
                            $("#createFormModal").modal("hide");
                            if (redirect_type === "Form") {
                              isApp
                                ? (window.location.hash = `/form_app/${redirect_id}`)
                                : (window.location.hash = `/form/${redirect_id}`);
                            } else if (redirect_type === "Report") {
                              isApp
                                ? (window.location.hash = `/report_app/${redirect_id}`)
                                : (window.location.hash = `/report/${redirect_id}`);
                            } else if (redirect_type === "Dashboard") {
                              isApp
                                ? (window.location.hash = `/dashboard_app/${redirect_id}`)
                                : (window.location.hash = `/dashboard/${redirect_id}`);
                            } else if (redirect_type === "Url") {
                              window.location = redirect_id;
                            }
                          }, 2000);
                        } else {
                          setTimeout(function () {
                            x.className = x.className.replace("show", "");
                            $("#createFormModal").modal("hide");
                          }, 1000);
                          this.props.GetMethod(this.props.data);
                        }
                      }
                    );
                  },
                  (error) => {
                    this.setState(
                      {
                        loading: false,
                        toastColor: "#d9534f",
                        toastDescription: `${error.message}`,
                      },
                      () => {
                        var x = document.getElementById("snackbar10");
                        x.className = "show";
                        setTimeout(function () {
                          x.className = x.className.replace("show", "");
                        }, 5000);
                      }
                    );
                  }
                )
                .catch((error) => {
                  this.setState(
                    {
                      loading: false,
                      toastColor: "#d9534f",
                      toastDescription: `${error.message}`,
                    },
                    () => {
                      var x = document.getElementById("snackbar10");
                      x.className = "show";
                      setTimeout(function () {
                        x.className = x.className.replace("show", "");
                      }, 5000);
                    }
                  );
                });
            });
          },
          async (error) => {
            console.log(error);
            try {
              Parse.serverURL = this.state.parseBaseUrl;
              Parse.initialize(this.state.parseAppId);
              var _users = Parse.Object.extend("_User");
              var query = new Parse.Query(_users);
              if (UserData.username) {
                query.equalTo("username", UserData.username);
              } else if (UserData.email) {
                query.equalTo("email", UserData.email);
              } else {
                query.equalTo("username", UserData.phone);
              }
              const results = await query.first();
              if (results) {
                let response = results.toJSON();
                let _emp = {
                  __type: "Pointer",
                  className: "_User",
                  objectId: results.id,
                };
                let roleurl = `${this.state.parseBaseUrl}functions/AddUserToRole`;
                const headers = {
                  "Content-Type": "application/json",
                  "X-Parse-Application-Id": this.state.parseAppId,
                  sessionToken: localStorage.getItem("accesstoken"),
                };
                let _role_f = "";
                if (RoleField) {
                  _role_f = RoleField;
                } else {
                  _role_f = UserData.Role;
                }

                let body = {
                  appName: localStorage.getItem("_appName"),
                  roleName: _role_f,
                  userId: results.id,
                };
                axios
                  .post(roleurl, body, { headers: headers })
                  .then((res) => {
                    const currentUser = Parse.User.current();
                    let _fname = this.state.title;
                    var forms = Parse.Object.extend(_fname);
                    var form = new forms();
                    form.set(
                      "CreatedBy",
                      Parse.User.createWithoutData(currentUser.id)
                    );
                    form.set("UserId", _emp);
                    form.set("UserRole", RoleField);
                    if (localStorage.getItem("TenetId")) {
                      form.set("TenantId", {
                        __type: "Pointer",
                        className: "partners_Tenant",
                        objectId: localStorage.getItem("TenetId"),
                      });
                    }
                    if (this.state["FormACL"]) {
                      let ACL = {};
                      for (let [key, value] of Object.entries(
                        this.state["FormACL"]
                      )) {
                        if (key === "*") {
                          ACL[key] = value;
                        }
                        if (key === "#currentUser#") {
                          ACL[Parse.User.current().id] = value;
                        }
                        if (key.startsWith("role")) {
                          ACL[key] = value;
                        }
                      }
                      form.setACL(new Parse.ACL(ACL));
                    }
                    form.save(RowData).then(
                      () => {
                        let filtered = {};
                        if (this.state.redirect_type === "clearData") {
                          if (
                            this.state.persistentFields &&
                            this.state.persistentFields.length
                          ) {
                            filtered = Object.keys(RowData)
                              .filter((key) =>
                                this.state.persistentFields.includes(key)
                              )
                              .reduce((obj, key) => {
                                obj[key] = RowData[key];
                                return obj;
                              }, {});
                          }
                        } else {
                          RowData = {};
                        }
                        this.setState(
                          {
                            formData: filtered,
                            active: true,
                            loading: false,
                            toastColor: "#5cb85c",
                            toastDescription: this.state.successMassage,
                          },
                          function () {
                            let redirect_type = this.state.redirect_type;
                            let redirect_id = this.state.redirect_id;
                            let isApp = this.state.isAppRequest;
                            this.props.removeState();
                            this.props.removeLevel2State();
                            this.props.removeLevel3State();

                            var x = document.getElementById("snackbar10");
                            x.className = "show";
                            if (redirect_type && redirect_id) {
                              setTimeout(() => {
                                x.className = x.className.replace("show", "");
                                this.props.GetMethod(this.props.data);
                                redirect_type === "closePopup" && redirect_id
                                  ? $("#createFormModal").modal("show")
                                  : $("#createFormModal").modal("hide");

                                if (redirect_type === "Form") {
                                  isApp
                                    ? (window.location.hash = `/form_app/${redirect_id}`)
                                    : (window.location.hash = `/form/${redirect_id}`);
                                } else if (redirect_type === "Report") {
                                  isApp
                                    ? (window.location.hash = `/report_app/${redirect_id}`)
                                    : (window.location.hash = `/report/${redirect_id}`);
                                } else if (redirect_type === "Dashboard") {
                                  isApp
                                    ? (window.location.hash = `/dashboard_app/${redirect_id}`)
                                    : (window.location.hash = `/dashboard/${redirect_id}`);
                                } else if (redirect_type === "Url") {
                                  window.location = redirect_id;
                                }
                              }, 2000);
                            } else {
                              setTimeout(function () {
                                x.className = x.className.replace("show", "");
                                $("#createFormModal").modal("hide");
                              }, 1000);
                              this.props.GetMethod(this.props.data);
                            }
                          }
                        );
                      },
                      (error) => {
                        console.log("error", error.message);

                        this.setState(
                          {
                            loading: false,
                            toastColor: "#d9534f",
                            toastDescription: `${error.message}`,
                          },
                          () => {
                            var x = document.getElementById("snackbar10");
                            x.className = "show";
                            setTimeout(function () {
                              x.className = x.className.replace("show", "");
                            }, 5000);
                          }
                        );
                      }
                    );
                  })
                  .catch((error) => {
                    this.setState(
                      {
                        loading: false,
                        toastColor: "#d9534f",
                        toastDescription: `${error.message}`,
                      },
                      () => {
                        var x = document.getElementById("snackbar10");
                        x.className = "show";
                        setTimeout(function () {
                          x.className = x.className.replace("show", "");
                        }, 5000);
                      }
                    );
                  });
              } else {
                this.setState(
                  {
                    loading: false,
                    toastColor: "#d9534f",
                    toastDescription: `${error.message}`,
                  },
                  () => {
                    var x = document.getElementById("snackbar10");
                    x.className = "show";
                    setTimeout(function () {
                      x.className = x.className.replace("show", "");
                    }, 5000);
                  }
                );
              }
            } catch (error) {
              this.setState(
                {
                  loading: false,
                  toastColor: "#d9534f",
                  toastDescription: `${error.message}`,
                },
                () => {
                  var x = document.getElementById("snackbar1010");
                  x.className = "show";
                  setTimeout(function () {
                    x.className = x.className.replace("show", "");
                  }, 5000);
                }
              );
            }
          }
        );
      } catch (e) {
        console.error("Problem", e);
      }
    } else {
      try {
        let RowData = formData;
        let _scanData = this.state.jsonSchema;
        if (_scanData.dependencies) {
          Object.keys(_scanData.dependencies).forEach((key) => {
            if (_scanData.dependencies[key].oneOf) {
              _scanData.dependencies[key].oneOf.forEach((val) => {
                Object.keys(val.properties).forEach((k) => {
                  if (typeof val.properties[k] === "object") {
                    if (val.properties[k].format === "date") {
                      if (RowData[k]) {
                        let newdate = new Date(RowData[k]);
                        RowData[k] = newdate;
                      }
                    }
                    if (val.properties[k].component === "HtmlEditor") {
                      if (RowData[k]) {
                        let newHtml = RowData[k]
                          .replace(/<p[^>]*>/g, "")
                          .replace(/<\/p>/g, " ");
                        RowData[k] = newHtml;
                      }
                    }
                    if (val.properties[k].component === "DateTime") {
                      if (RowData[k]) {
                        let newDate11 = new Date(RowData[k]);
                        RowData[k] = newDate11;
                      }
                    }
                    if (val.properties[k].component === "CurrencyInput") {
                      if (val.properties[k].currencyColumn) {
                        RowData[`${val.properties[k].currencyColumn}`] =
                          val.properties[k].defaultcurrency;
                      }
                    }
                    if (val.properties[k].type === "string") {
                      if (typeof RowData[k] === "string")
                        RowData[k] = RowData[k].trim();
                    }
                    if (val.properties[k].data !== undefined) {
                      if (Array.isArray(val.properties[k].data)) {
                      } else if (val.properties[k].data.isPointer) {
                        let pointer = undefined;
                        if (val.properties[k].data.class) {
                          if (val.properties[k].data.savePointerClass) {
                            if (RowData[k]) {
                              pointer = {
                                __type: "Pointer",
                                className:
                                  val.properties[k].data.savePointerClass,
                                objectId: RowData[k],
                              };
                            }
                          } else if (RowData[k]) {
                            pointer = {
                              __type: "Pointer",
                              className: val.properties[k].data.class,
                              objectId: RowData[k],
                            };
                          }
                        } else {
                          if (RowData[k]) {
                            pointer = {
                              __type: "Pointer",
                              className: localStorage.getItem("extended_class"),
                              objectId: RowData[k],
                            };
                          }
                        }
                        RowData[k] = pointer;
                      }
                      if (val.properties[k].data.FolderTypeValue) {
                        if (RowData[k]) {
                          let obj = {
                            __type: "Pointer",
                            className: val.properties[k].data.ClassName,
                            objectId: RowData[k],
                          };
                          RowData[k] = obj;
                        }
                      }
                    }
                  }
                });
              });
            }
          });
        }
        let temp = [];
        Object.keys(_scanData).forEach(function (key) {
          let _dd = _scanData[key];

          Object.keys(_dd).forEach(function (k) {
            if (_dd[k].type === "array" && _dd[k].items) {
              let _prop = _dd[k].items.properties;
              if (_prop && Array.isArray(RowData[k])) {
                let newRow = [];
                RowData[k].forEach((t) => {
                  let _newObj = t;
                  if (typeof t === "object") {
                    Object.keys(_prop).forEach(function (l) {
                      if (_prop[l].data && _prop[l].data.isPointer) {
                        if (typeof t[l] === "object") {
                          let obj = {
                            __type: "Pointer",
                            className: _prop[l].data.class,
                            objectId: t[l].objectId,
                          };
                          _newObj = { ..._newObj, [l]: obj };
                        } else {
                          let obj = {
                            __type: "Pointer",
                            className: _prop[l].data.class,
                            objectId: t[l],
                          };
                          _newObj = { ..._newObj, [l]: obj };
                        }
                      }
                    });
                  }
                  newRow.push(_newObj);
                });
                RowData[k] = newRow;
              }
            }

            if (_dd[k].component === "AutoSuggest" && _dd[k].isPointer) {
              if (RowData[k]) {
                let pointer = {
                  __type: "Pointer",
                  className: _dd[k].class,
                  objectId: RowData[k],
                };
                RowData[k] = pointer;
              }
            }
            if (_dd[k].format === "date") {
              let newdate = new Date(RowData[k]);
              RowData[k] = newdate;
            }
            if (_dd[k].component === "HtmlEditor") {
              if (RowData[k]) {
                let newHtml = RowData[k]
                  .replace(/<p[^>]*>/g, "")
                  .replace(/<\/p>/g, " ");
                RowData[k] = newHtml;
              }
            }
            if (_dd[k].component === "DateTime") {
              let newDate;
              if (!RowData[k]) {
                newDate = new Date();
              } else {
                newDate = new Date(RowData[k]);
              }
              RowData[k] = newDate;
            }
            if (_dd[k].component === "CurrencyInput") {
              RowData[`${_dd[k].currencyColumn}`] = _dd[k].defaultcurrency;
            }
            if (_dd[k].data !== undefined) {
              if (_dd[k].data[0] !== undefined) {
              } else if (_dd[k].data.isPointer) {
                if (_dd[k].type === "array") {
                  let pointer = [];
                  RowData[k] &&
                    RowData[k].forEach((a) => {
                      let _kk = {};
                      if (_dd[k].data.savePointerClass) {
                        _kk = {
                          __type: "Pointer",
                          className: _dd[k].data.savePointerClass,
                          objectId: a,
                        };
                      } else {
                        _kk = {
                          __type: "Pointer",
                          className: _dd[k].data.class,
                          objectId: a,
                        };
                      }

                      pointer.push(_kk);
                    });
                  RowData[k] = pointer;
                } else if (RowData[k]) {
                  if (_dd[k].data.savePointerClass) {
                    let pointer = {
                      __type: "Pointer",
                      className: _dd[k].data.savePointerClass,
                      objectId: RowData[k],
                    };
                    RowData[k] = pointer;
                  } else {
                    let pointer = undefined;
                    if (_dd[k].data.class) {
                      pointer = {
                        __type: "Pointer",
                        className: _dd[k].data.class,
                        objectId: RowData[k],
                      };
                    } else {
                      pointer = {
                        __type: "Pointer",
                        className: localStorage.getItem("extended_class"),
                        objectId: RowData[k],
                      };
                    }

                    RowData[k] = pointer;
                  }
                }
              }
              if (_dd[k].data.FolderTypeValue) {
                if (RowData[k]) {
                  let obj = {
                    __type: "Pointer",
                    className: _dd[k].data.ClassName,
                    objectId: RowData[k],
                  };
                  RowData[k] = obj;
                }
              }
            }
            if (_dd[k].type === "string") {
              if (typeof RowData[k] === "string")
                RowData[k] = RowData[k].trim();
            }
          });
        });
        Parse.serverURL = this.state.parseBaseUrl;
        Parse.initialize(this.state.parseAppId);
        const currentUser = Parse.User.current();
        let _fname = this.state.title;
        var forms = Parse.Object.extend(_fname);

        var form = new forms();

        form.set("CreatedBy", Parse.User.createWithoutData(currentUser.id));

        if (this.state["FormACL"]) {
          let ACL = {};
          for (let [key, value] of Object.entries(this.state["FormACL"])) {
            if (key === "*") {
              ACL[key] = value;
            }
            if (key === "#currentUser#") {
              ACL[Parse.User.current().id] = value;
            }
            if (key.startsWith("role")) {
              ACL[key] = value;
            }
          }
          form.setACL(new Parse.ACL(ACL));
        }
        form
          .save(RowData)
          .then(
            () => {
              let filtered = {};
              if (this.state.redirect_type === "clearData") {
                if (
                  this.state.persistentFields &&
                  this.state.persistentFields.length
                ) {
                  filtered = Object.keys(RowData)
                    .filter((key) => this.state.persistentFields.includes(key))
                    .reduce((obj, key) => {
                      obj[key] = RowData[key];
                      return obj;
                    }, {});
                }
              } else {
                RowData = {};
              }
              this.setState(
                {
                  formData: filtered,
                  active: true,
                  loading: false,
                  toastColor: "#5cb85c",
                  toastDescription: this.state.successMassage,
                },
                function () {
                  let redirect_type = this.state.redirect_type;
                  let redirect_id = this.state.redirect_id;
                  let isApp = this.state.isAppRequest;
                  this.props.removeState();
                  this.props.removeLevel2State();
                  this.props.removeLevel3State();

                  var x = document.getElementById("snackbar10");
                  x.className = "show";
                  if (redirect_type && redirect_id) {
                    setTimeout(() => {
                      x.className = x.className.replace("show", "");
                      this.props.GetMethod(this.props.data);
                      $("#createFormModal").modal("hide");
                      if (redirect_type === "Form") {
                        isApp
                          ? (window.location.hash = `/form_app/${redirect_id}`)
                          : (window.location.hash = `/form/${redirect_id}`);
                      } else if (redirect_type === "Report") {
                        isApp
                          ? (window.location.hash = `/report_app/${redirect_id}`)
                          : (window.location.hash = `/report/${redirect_id}`);
                      } else if (redirect_type === "Dashboard") {
                        isApp
                          ? (window.location.hash = `/dashboard_app/${redirect_id}`)
                          : (window.location.hash = `/dashboard/${redirect_id}`);
                      } else if (redirect_type === "Url") {
                        window.location = redirect_id;
                      }
                    }, 2000);
                  } else {
                    setTimeout(function () {
                      x.className = x.className.replace("show", "");
                      $("#createFormModal").modal("hide");
                    }, 1000);
                    this.props.GetMethod(this.props.data);
                  }
                }
              );

              // this.props.GetMethod(this.props.PropsData);
            },
            (error) => {
              this.setState(
                {
                  loading: false,
                  toastColor: "#d9534f",
                  toastDescription: `${error.message}`,
                },
                () => {
                  var x = document.getElementById("snackbar10");
                  x.className = "show";
                  setTimeout(function () {
                    x.className = x.className.replace("show", "");
                  }, 5000);
                }
              );
            }
          )
          .catch((error) => {
            this.setState(
              {
                toastColor: "#d9534f",
                toastDescription: `${error.message}`,
              },
              () => {
                var x = document.getElementById("snackbar10");
                x.className = "show";
                setTimeout(function () {
                  x.className = x.className.replace("show", "");
                }, 5000);
              }
            );
          });
      } catch (e) {
        this.setState(
          {
            toastColor: "#d9534f",
            toastDescription: `${e.message}`,
          },
          () => {
            var x = document.getElementById("snackbar10");
            x.className = "show";
            setTimeout(function () {
              x.className = x.className.replace("show", "");
            }, 5000);
          }
        );
      }
    }
  };

  getFormData(id) {
    const MyCustomClass = Parse.Object.extend(this.state.ClassName);
    const query = new Parse.Query(MyCustomClass);
    query.equalTo("objectId", id);
    query.first().then(
      (results) => {},
      (error) => {
        if (typeof document !== "undefined")
          document.write(
            `Error while fetching ParseObjects: ${JSON.stringify(error)}`
          );
        console.error("Error while fetching ParseObjects", error);
      }
    );
  }
  componentDidMount() {
    const { handleModalCloseClick } = this.props;
    let url = window.location.hash;
    if (url.includes("_app")) {
      this.setState({ isAppRequest: true });
    }
    this.GetForm();
    $(this.modal).modal("show");
    $(this.modal).on("hidden.bs.modal", handleModalCloseClick);
  }

  handleCloseClick = (e) => {
    const { handleModalCloseClick } = this.props;
    $(this.modal).modal("hide");
    handleModalCloseClick();
  };

  render() {
    let schema = this.state.schema;
    let uiSchema = this.state.ui_schema;
    let rules = this.state.rules;
    let extraActions = this.state.extraActions;
    let FormToDisplay = applyRules(
      schema,
      uiSchema,
      rules,
      Engine,
      extraActions
    )(Form);

    let formView = (
      <React.Fragment>
        {this.state.help ? (
          <div className="dropdown" style={{ marginTop: "-50px" }}>
            <i
              className="far fa-question-circle dropdown-toggle hovereffect"
              aria-hidden="true"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{
                fontSize: "18px",
                color: "purple",
                cursor: "pointer !important",
                position: "relative",
                top: "40px",
                left: "98%",
              }}
            ></i>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuButton"
              style={{
                marginleft: "-121px",
                margintop: "-14px",
                position: "absolute",
                padding: "10px",
                width: "300px",
                top: "102px!important",
              }}
            >
              {parse(`
             ${this.state.help}
           `)}
              <br />
              {this.state.link ? (
                <a
                  href={this.state.link}
                  target="_blank"
                  className="btn btn-xs btn-primary"
                  rel="noopener noreferrer"
                >
                  Read more..
                </a>
              ) : null}
            </div>
          </div>
        ) : null}
        <FormToDisplay
          widgets={widget}
          showErrorList={false}
          fields={fields}
          formData={this.formData}
          validate={this.state.noValidate && this.dynamicValidate}
          liveValidate={this.state.liveValidate}
          onSubmit={this.handleSubmit}
        >
          <div>
            {this.state.buttons.submitText && (
              <button className="btn btn-info" type="submit">
                {this.state.buttons.submitText}
              </button>
            )}{" "}
            {this.state.buttons.resetText && (
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ formData: {} });
                }}
                type="button"
              >
                {this.state.buttons.resetText}
              </button>
            )}
          </div>
        </FormToDisplay>
        {/*  <Form
          schema={this.state.schema}
          uiSchema={this.state.ui_schema}
          widgets={widget}
          showErrorList={false}
          fields={fields}
          formData={this.formData}
          validate={this.state.noValidate && this.dynamicValidate}
          liveValidate={this.state.liveValidate}
          onSubmit={this.handleSubmit}
        >
          <div>
            {this.state.buttons.submitText && (
              <button className="btn btn-info" type="submit">
                {this.state.buttons.submitText}
              </button>
            )}{" "}
            {this.state.buttons.resetText && (
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ formData: {} });
                }}
                type="button"
              >
                {this.state.buttons.resetText}
              </button>
            )}
          </div>
        </Form>
      */}
      </React.Fragment>
    );

    if (this.state.loading) {
      formView = (
        <div style={{ height: "300px" }}>
          <div
            style={{
              marginLeft: "45%",
              marginTop: "150px",
              fontSize: "45px",
              color: "#3dd3e0",
            }}
            className="loader-37"
          ></div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div
          className="modal fade"
          ref={(modal) => (this.modal = modal)}
          id="createFormModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa fa-times-circle" aria-hidden="true"></i>
                </button>
              </div>
              <div className="modal-body">{formView}</div>
            </div>
          </div>
        </div>
        <div id="snackbar10" style={{ backgroundColor: this.state.toastColor }}>
          {this.state.toastDescription}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null, {
  removeState,
  removeLevel2State,
  removeLevel3State,
})(Model);
