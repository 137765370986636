import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Parse from "parse";
import axios from "axios";
import "./Checkout.css";
import CartFormBuilder from "./CartFormBuilder";
import { clearCartData } from "../../actions";

function Checkout() {
  const CartData = useSelector((state) => state.CartData);
  const [billAddress, setBillAddress] = useState("");
  const [shipAddress, setShipAddress] = useState("");
  const [UpdateForm, setUpdateForm] = useState("");
  const [CartSettings, setCartSettings] = useState(undefined);
  const [formLoader, setFormLoader] = useState(true);
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();

  const currentDate = () => {
    let today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;

    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return `${dd}/${mm}/${yyyy}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsActive(true);
    try {
      let clsName = CartSettings.OrderClass;
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      const currentUser = Parse.User.current();
      let appId = `${localStorage.getItem("_app_objectId")}` || "";
      const MyCustomClass = Parse.Object.extend(clsName);
      const query = new Parse.Query(MyCustomClass);
      query.get(UpdateForm).then((object) => {
        object.set("OrderDetails", CartData);
        object.set("UserPtr", {
          __type: "Pointer",
          className: "_User",
          objectId: currentUser.id,
        });
        object.set("AppId", {
          __type: "Pointer",
          className: "w_appinfo",
          objectId: appId,
        });
        object.set(
          "SubTotal",
          Number(
            (
              CartData && CartData.reduce((a, b) => a + (b["Amount"] || 0), 0)
            ).toFixed(2)
          )
        );
        object.set(
          "GSTTotal",
          Number(
            (
              CartData &&
              CartData.reduce((a, b) => a + (b["Amount"] || 0), 0) * (18 / 100)
            ).toFixed(2)
          )
        );
        object.set(
          "TotalAmount",
          Number(
            (
              CartData &&
              CartData.reduce((a, b) => a + (b["Amount"] || 0), 0) +
                CartData.reduce((a, b) => a + (b["Rate"] || 0), 0) * (18 / 100)
            ).toFixed(2)
          )
        );
        object.save().then(
          async (response) => {
            if (response) {
              try {
                Parse.serverURL = parseBaseUrl;
                Parse.initialize(parseAppId);
                const currentUser = Parse.User.current();
                let url = `${parseBaseUrl}classes/w_Cart?where={"AppId":{"__type":"Pointer","className":"w_appinfo","objectId":"${appId}"},"UserId":{"__type":"Pointer","className":"_User","objectId":"${currentUser.id}"}}`;
                const headers = {
                  "Content-Type": "application/json",
                  "X-Parse-Application-Id": parseAppId,
                  sessionToken: localStorage.getItem("accesstoken"),
                };
                await axios.get(url, { headers }).then((res) => {
                  if (res) {
                    if (res.data.results.length > 0) {
                      let searchObjectId = res.data.results[0].objectId;
                      const MyCustomClass = Parse.Object.extend("w_Cart");
                      const query = new Parse.Query(MyCustomClass);
                      // here you put the objectId that you want to delete
                      query.get(searchObjectId).then((object) => {
                        object.destroy().then(
                          () => {
                            alert("Order placed successfully");
                            setFormLoader(true);
                            dispatch(clearCartData());
                            setIsActive(false);
                          },
                          (error) => {
                            console.error(
                              "Error while deleting ParseObject",
                              error
                            );
                            setIsActive(false);
                          }
                        );
                      });
                    }
                  }
                });
              } catch (error) {}
            }
          },
          (error) => {
            alert("Something went wrong.");
            console.error("Error while updating ", error);
          }
        );
      });
    } catch (error) {
      setIsActive(false);
    }
  };

  const getCartSettings = async () => {
    try {
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      let appId = `${localStorage.getItem("_app_objectId")}` || "";
      let role =
        `${localStorage.getItem("_appName")}_${localStorage.getItem(
          "_user_role"
        )}` || "";
      let url = `${parseBaseUrl}classes/w_CartSettings?where={"AppId":{"__type":"Pointer","className":"w_appinfo","objectId":"${appId}"},"UserRole":"${role}"}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": parseAppId,
        sessionToken: localStorage.getItem("accesstoken"),
      };
      await axios.get(url, { headers }).then((res) => {
        if (res) {
          if (res.data.results.length > 0) {
            setCartSettings(res.data.results[0]);
          }
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    getCartSettings();
  }, []);

  return (
    <div className="checkout_container">
      <div className="checkout_header">
        <span>
          <i className="fab fa-opencart"></i>
        </span>
        Checkout details
      </div>
      {formLoader ? (
        <div className="form_loader">
          {CartSettings && CartData.length > 0 ? (
            <CartFormBuilder
              FormId={CartSettings.FormId}
              UpdateFormObjectId={(val) => setUpdateForm(val)}
              IsFormLoader={(val) => setFormLoader(false)}
            />
          ) : (
            <div className="cat_no_data">
              <span>
                <i className="fab fa-opencart"></i>
              </span>
              Cart is Empty
            </div>
          )}
        </div>
      ) : (
        <React.Fragment>
          <div className="checkout_details">
            <div className="checkout_details_header">Cart Item</div>
            <div className="checkout_details_item">
              {CartData.length > 0 && (
                <div className="grid_table">
                  <span className="th_head">Photo</span>
                  <span className="th_head">Quantity</span>
                  <span className="th_head">Item</span>
                  <span className="th_head">Rate</span>
                  <span className="th_head">Amount</span>
                  {CartData.map((x) => (
                    <React.Fragment key={x.objectId}>
                      <span>
                        {" "}
                        <img src={x.Icon} className="thumb" height="70px" />
                      </span>
                      <span>{x.Quantity}</span>
                      <span>{x.Title}</span>
                      <span>{x.Rate}</span>
                      <span>{x.Amount}</span>
                    </React.Fragment>
                  ))}
                </div>
              )}
            </div>
            {CartSettings && CartSettings.EnableAddressEntry && (
              <div className="shipping_address">
                <div className="address_header">Address</div>
                <div className="billing_address">
                  <div className="billing_address_header">Billing Address</div>
                  <textarea
                    name="billAdd"
                    className="form-control"
                    value={billAddress}
                    onChange={(e) => setBillAddress(e.target.value)}
                    rows="5"
                    style={{ marginTop: "5px", borderRadius: "0px" }}
                    cols="50"
                  ></textarea>
                </div>
                <div className="ship_address">
                  <div className="ship_address_header">Shipping Address</div>
                  <textarea
                    name="ship_add"
                    className="form-control"
                    value={shipAddress}
                    onChange={(e) => setShipAddress(e.target.value)}
                    style={{ marginTop: "5px", borderRadius: "0px" }}
                    rows="5"
                    cols="50"
                  ></textarea>
                </div>
              </div>
            )}
          </div>
          <div className="checkout_total">
            <div className="checkout_total_header">Your Order</div>
            <div className="total_body">
              <span>
                Date <span className="_span_right">{currentDate()}</span>
              </span>
            </div>
            <div className="total_body">
              <span>
                Sub Total{" "}
                <span className="_span_right">
                  {CartData &&
                    CartData.reduce((a, b) => a + (b["Amount"] || 0), 0)}
                </span>
              </span>
              <span>
                GST (18%)
                <span className="_span_right">
                  {(
                    CartData &&
                    CartData.reduce((a, b) => a + (b["Amount"] || 0), 0) *
                      (18 / 100)
                  ).toFixed(2)}
                </span>
              </span>
            </div>
            <div className="total_body">
              <span>
                Total{" "}
                <span className="_span_right">
                  {" "}
                  {(
                    CartData &&
                    CartData.reduce((a, b) => a + (b["Amount"] || 0), 0) +
                      CartData.reduce((a, b) => a + (b["Rate"] || 0), 0) *
                        (18 / 100)
                  ).toFixed(2)}
                </span>
              </span>
            </div>
            <div className="place_order">
              <button
                className="btn btn-primary"
                onClick={handleSubmit}
                disabled={isActive}
              >
                Place order
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default Checkout;
