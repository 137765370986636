import React, { useState, useEffect } from "react";
import "./date.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LabelField from "./LabelField";

function DateTime(props) {
  const [startDate, setStartDate] = useState(new Date());

  const getMinDate = () => {
    if (props.schema.minDate) {
      if (props.schema.minDate === 0 || props.schema.minDate === "0") {
        return new Date();
      }
      if (props.schema.minDate) {
        let num = Number(props.schema.minDate);
        var d = new Date();
        return d.setDate(d.getDate() + num);
      }
    } else {
      return;
    }
  };

  const getMaxDate = () => {
    if (props.schema.maxDate) {
      if (props.schema.maxDate === 0 || props.schema.maxDate === "0") {
        return new Date();
      }
      if (props.schema.maxDate) {
        let num = Number(props.schema.maxDate);
        var d = new Date();
        return d.setDate(d.getDate() + num);
      }
    } else {
      return;
    }
  };

  const onChangeDate = (date) => {
    setStartDate(date);
    props.onChange(date.toISOString());
  };

  useEffect(() => {
    if (props.formData === undefined) {
      setStartDate(new Date());
      props.onChange(new Date().toISOString());
    }

    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      <LabelField
        Title={props.schema.title}
        Name={props.name}
        Required={props.required}
        HelpBody={props.schema.helpbody}
        HelpLink={props.schema.helplink}
      />
      <br />
      <DatePicker
        selected={props.formData ? new Date(props.formData) : startDate}
        className="form-control"
        showTimeSelect={props.schema.showTimeSelect}
        showTimeSelectOnly={props.schema.showTimeSelectOnly}
        timeFormat={props.schema.timeFormat}
        timeIntervals={props.schema.timeIntervals}
        minDate={getMinDate()}
        maxDate={getMaxDate()}
        timeCaption={props.schema.timeCaption}
        showDisabledMonthNavigation={false}
        dateFormat={props.schema.dateFormat}
        onChange={(date) => onChangeDate(date)}
        readOnly={props.schema.readOnly}
        disabled={props.uiSchema["ui:disabled"]}
      />
    </React.Fragment>
  );
}

export default DateTime;
