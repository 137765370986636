import React, { useState, useEffect, lazy, Suspense } from "react";
import Parse from "parse";
import "../Spinner/loader.css";
import { Redirect } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import Title from "./Title";
const GetReportDisplay = lazy(() => import("../components/GetReportDisplay"));

const ReportData = (props) => {
  const [reportData, setreportData] = useState([]);
  const [classnameArray, setclassnameArray] = useState([]);
  const [loading, setloading] = useState(false);
  const [hideActionsAfter, sethideActionsAfter] = useState(3);
  const [allowExcelExport, setallowExcelExport] = useState(false);
  const [allowSelectAll, setallowSelectAll] = useState(false);
  const [showSerialNos, setshowSerialNos] = useState(false);
  const [VerticalViewOnMobile, setVerticalViewOnMobile] = useState(false);
  const [isFilterCollapsible, setisFilterCollapsible] = useState(false);
  const [RepoTitle, setRepoTitle] = useState("Report");
  const [parseBaseUrl] = useState(
    typeof window.localStorage !== "undefined"
      ? localStorage.getItem("baseUrl")
      : ""
  );
  const [parseAppId] = useState(
    typeof window.localStorage !== "undefined"
      ? localStorage.getItem("parseAppId")
      : ""
  );

  const getReportUI = async (id) => {
    setloading(true);
    try {
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      var forms = Parse.Object.extend("w_Filter");
      var query = new Parse.Query(forms);
      query.equalTo("objectId", id);
      const results = await query.first();
      const resultjson = results.toJSON();
      let classArray = [];
      resultjson.rows.forEach((x) => {
        let col_lg = "",
          col_md = "",
          col_xs = "",
          col_sm = "",
          col_ = "col-12",
          obj = {};
        let subItem = [];
        let item = "";
        x.columns.forEach((y) => {
          if (Number(y["collg"]) > 0) {
            col_lg = "col-lg-" + y["collg"];
            if (col_lg) {
              if (item === "") {
                item = col_lg;
              } else {
                item = item + " " + col_lg;
              }
            }
          }
          if (Number(y["colmd"]) > 0) {
            col_md = "col-md-" + y["colmd"];
            if (col_md) {
              if (item === "") {
                item = col_md;
              } else {
                item = item + " " + col_md;
              }
            }
          }
          if (Number(y["colxs"]) > 0) {
            col_xs = "col-xs-" + y["colxs"];
            if (col_xs) {
              if (item === "") {
                item = col_xs;
              } else {
                item = item + " " + col_xs;
              }
            }
          }
          if (Number(y["colsm"]) > 0) {
            col_sm = "col-sm-" + y["colsm"];
            if (col_sm) {
              if (item === "") {
                item = col_sm;
              } else {
                item = item + " " + col_sm;
              }
            }
          }

          item = item + " " + col_;
          // item = col_lg + " " + col_md + " " + col_xs + " " + col_;
          subItem.push(item);
          item = "";
        });
        obj = subItem;
        classArray.push(obj);
      });
      let _hide = 3;
      if (resultjson.hideActionsAfter) {
        _hide = resultjson.hideActionsAfter;
      }
      setreportData(resultjson.rows);
      setclassnameArray(classArray);
      setRepoTitle(resultjson.rpTitle);
      sethideActionsAfter(_hide);
      setallowExcelExport(resultjson.allowExcelExport);
      setisFilterCollapsible(resultjson.isFilterCollapsible);
      setshowSerialNos(resultjson.showSerialNos);
      setallowSelectAll(resultjson.allowSelectAll);
      if (resultjson.VerticalViewOnMobile) {
        setVerticalViewOnMobile(resultjson.VerticalViewOnMobile);
      }
      setloading(false);
    } catch (e) {
      console.error("Problem", e);
      setloading(false);
    }
  };

  useEffect(() => {
    let id = props.match.params.id;
    getReportUI(id);
  }, [props.match.params.id]);

  try {
    if (
      typeof window.localStorage === "undefined" &&
      !localStorage.getItem("accesstoken")
    ) {
      return <Redirect to="/" />;
    }
  } catch (error) {}

  return (
    <React.Fragment>
      <Title title={RepoTitle} />
      <Suspense
        fallback={
          <div
            style={{ marginLeft: "45%", fontSize: "45px", color: "#3dd3e0" }}
            className="loader-37"
          ></div>
        }
      >
        <ErrorBoundary>
          {!loading ? (
            <GetReportDisplay
              reportData={reportData}
              hideActionsAfter={hideActionsAfter}
              isFilterCollapsible={isFilterCollapsible}
              allowExcelExport={allowExcelExport}
              classnameArray={classnameArray}
              allowSelectAll={allowSelectAll}
              showSerialNos={showSerialNos}
              VerticalViewOnMobile={VerticalViewOnMobile}
            />
          ) : (
            <div style={{ height: "300px", backgroundColor: "#fff" }}>
              <div
                style={{
                  marginLeft: "45%",
                  fontSize: "45px",
                  marginTop: "150px",
                  color: "#3dd3e0",
                }}
                className="loader-37"
              ></div>
            </div>
          )}
        </ErrorBoundary>
      </Suspense>
    </React.Fragment>
  );
};

export default ReportData;
