export default (state = [], action) => {
  switch (action.type) {
    case "Level2_Dropdown":
      return { ...state, ...action.payload };
    case "Remove_Level2":
      state = {};
      return state;
    default:
      return { ...state };
  }
};
