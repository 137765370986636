import React from 'react'

const Imageviewer = (props) => {

	const { image } = props;

	let _img = image;

	return (
		<td>
			<img src={_img} width="150px" alt="img" />
		</td>
	);
}

export default Imageviewer
