import React, { useEffect, useState } from "react";
import Parse from "parse";
import parse from "html-react-parser";
import "Spinner/loader.css";
import axios from "axios";
import Title from "components/Title";
import { linkURL } from "helper";

function View(props) {
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const [Columns, SetCoulumns] = useState([]);
  const [Rows, SetRows] = useState([]);
  const [Keys, SetKeys] = useState([]);
  const [Actions, SetActions] = useState([]);
  const [DisplayRecord, SetDisplayRecord] = useState(null);
  const [HeaderColumn, SetHeaderColumn] = useState(null);
  const [allowExcelExport, SetallowExcelExport] = useState(false);
  const [allowPrint, SetallowPrint] = useState(false);
  const [loading, setloading] = useState(false);
  const [IsActive, setIsActive] = useState(false);
  const [ClassName, setClassName] = useState("");

  //Method for get View layout stracture
  const GetViewLayout = async () => {
    try {
      setloading(true);
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      let filter = Parse.Object.extend("w_Filter");
      let query = new Parse.Query(filter);
      query.equalTo("objectId", props.match.params.rid);
      const res = await query.first();
      if (res) {
        let ColumnArr = [];
        let result = res.toJSON();
        SetRows(result.rows);
        let className = result["classname"];
        SetallowExcelExport(result.allowExcelExport);
        SetallowPrint(result.allowPrint);
        result.rows &&
          result.rows.forEach((ele) => {
            if (ele.type === "BodyPanel") {
              SetCoulumns(ele.columns[0].widget.columns);
              ele.columns[0].widget.columns.forEach((col) => {
                if (col.columnName.includes("#")) {
                  let str = col.columnName;
                  let splitArray = str.split(" ");
                  splitArray.forEach((x) => {
                    if (x.startsWith("#")) {
                      ColumnArr.push(x.slice(1));
                    }
                  });
                } else {
                  ColumnArr.push(col.columnName);
                }
              });
              // below filter is used to remove "" (empty string) entry from array
              const keyArr = ColumnArr.filter((x) => x !== "");
              SetKeys(keyArr);
              SetActions(ele.columns[0].widget.actions);
              setClassName(className);
              GetViewRecord(keyArr, className);
            }
            if (ele.type === "HeaderPanel") {
              SetHeaderColumn(ele.columns);
            }
          });
      }
    } catch (error) {
      setloading(false);
    }
  };

  // Get objectId ralated record from table
  const GetViewRecord = async (keys, className) => {
    try {
      let url = `${parseBaseUrl}classes/${className}?where={"objectId":"${
        props.match.params.id
      }"}&keys=${keys.join(",")}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": parseAppId,
        "X-Parse-Session-Token": localStorage.getItem("accesstoken"),
      };

      await axios
        .get(url, { headers })
        .then((response) => {
          if (response) {
            SetDisplayRecord(response.data.results[0]);
            setloading(false);
          }
        })
        .catch((error) => {
          console.log(error.message);
          setloading(false);
        });
    } catch (error) {
      console.log(error.message);
      setloading(false);
    }
  };

  //Delete Link
  const handleDelete = async (e, h) => {
    try {
      e.preventDefault();
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      const query = new Parse.Query(ClassName);
      const object = await query.get(props.match.params.id);
      object.set(h, "");
      try {
        const response = await object.save();
        GetViewLayout();
      } catch (error) {
        console.error("Error while updating ", error);
      }
    } catch (error) {}
  };

  // Reder Array
  const renderArray = (arr) => {
    if (arr) {
      if (typeof arr[0] === "object") {
        return (
          <table className="table table-bordered table-sm">
            <tr key={"header"}>
              {Object.keys(arr[0]).map((key) => {
                return key === "Id" ||
                  key === "Image" ||
                  key === "objectId" ||
                  key === "Icon" ||
                  key === "Weight" ||
                  key === "Size" ? null : (
                  <th key={key}>{key}</th>
                );
              })}
            </tr>
            {arr.map((item) => (
              <tr key={item.Id}>
                {Object.entries(item).map(([key, val]) => {
                  return key === "Id" ||
                    key === "Image" ||
                    key === "objectId" ||
                    key === "Icon" ||
                    key === "Weight" ||
                    key === "Size" ? null : (
                    <td>
                      {typeof val === "boolean" ? (
                        val ? (
                          <input type="checkbox" checked />
                        ) : (
                          <input type="checkbox" />
                        )
                      ) : (
                        val
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </table>
        );
      }
    }
    return arr && arr.join(", ");
  };

  // Field formating
  const FieldDecoration = (_format, val) => {
    try {
      if (_format.format === "static") {
        return parse(`
        ${_format.htmlBody}
      `);
      }
      if (_format.format === "report") {
        return <div>defds</div>;
      }
      if (_format.format === "html") {
        let h = _format.columnName;
        if (h.includes(".")) {
          try {
            let str = h.split(".");
            if (val[str[0]]) {
              let l = val[str[0]];
              return parse(`${l[str[1]]}`);
            } else {
              return "N/A";
            }
          } catch (error) {}
        }
        return parse(`
      ${val[h]}
    `);
      }
      if (_format.columnName.includes("#")) {
        try {
          let ChangeArray = [];
          let str = _format.columnName;
          let splitArray = str.split(" ");
          splitArray.forEach((x) => {
            if (x.startsWith("#")) {
              ChangeArray.push(x.slice(1));
            }
          });
          if (ChangeArray.length > 0) {
            ChangeArray.forEach((x) => {
              let nameSplit = x.split(".");
              if (nameSplit.length === 1) {
                let reg = "#" + x;
                if (val[x] === undefined) {
                  str = str.replace(reg, "N/A");
                } else {
                  str = str.replace(reg, val[x]);
                }
              } else {
                let reg = "#" + x;
                if (val[nameSplit[0][nameSplit[1]]] === undefined) {
                  str = str.replace(reg, "N/A");
                } else {
                  str = str.replace(reg, val[nameSplit[0][nameSplit[1]]]);
                }
              }
            });
          }
          return (
            <p
              className="card-text"
              style={{
                fontSize: `${_format.fontsize}px`,
                fontStyle: _format.italic ? "italic" : null,
                fontWeight: _format.bold ? "bold" : null,
                backgroundColor: _format.bgcolor,
                color: _format.fontcolor,
              }}
            >
              {str || "N/A"}
            </p>
          );
        } catch (error) {}
      } else {
        try {
          var format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
          let h = _format.columnName;
          if (format.test(h)) {
            var nameSplit = h.split(".");
            let value = "";
            if (nameSplit[0] === undefined) {
              value = "";
            } else if (nameSplit.length === 2) {
              try {
                if (Array.isArray(val[nameSplit[0]])) {
                  let tempA = [];
                  val[nameSplit[0]].forEach((x) => {
                    tempA.push(x[nameSplit[1]]);
                  });
                  value = tempA;
                } else {
                  value = val[nameSplit[0]][nameSplit[1]];
                }
              } catch (error) {}
            } else if (nameSplit.length === 3) {
              try {
                if (Array.isArray(val[nameSplit[0]])) {
                  let tempA = [];
                  val[nameSplit[0]].forEach((x) => {
                    tempA.push(x[nameSplit[1]][nameSplit[2]]);
                  });
                  value = tempA;
                } else {
                  value = val[nameSplit[0]][nameSplit[1]][nameSplit[2]];
                }
              } catch (error) {}
            }
            switch (_format.format) {
              case "onedecimal":
                return (
                  <p
                    className="card-text"
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {" "}
                    {value.toFixed(1) || "N/A"}
                  </p>
                );
              case "twodecimal":
                return (
                  <p
                    className="card-text"
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {value.toFixed(2) || "N/A"}
                  </p>
                );
              case "boolean":
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {value.toString() === "true"
                      ? _format.displayValue["true"]
                      : value.toString() === "false"
                      ? _format.displayValue["false"]
                      : value.toString() || " N/A"}
                  </p>
                );
              case "isoString":
                return (
                  <p
                    className="card-text"
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {value.iso || "N/A"}
                  </p>
                );
              case "percent":
                return (
                  <p
                    className="card-text"
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {`${value}%` || " N/A"}
                  </p>
                );
              case "INR":
                return (
                  <p
                    className="card-text"
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {value ? `₹ ${value}` : `₹ 0`}
                  </p>
                );
              case "USD":
                return (
                  <p
                    className="card-text"
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >{`$ ${value || " N/A"}`}</p>
                );
              case "link":
                return value ? (
                  <React.Fragment>
                    <a
                      /*  href={value} */
                      target="_self"
                      rel="noopener noreferrer"
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = value;
                        alert("File downloaded successfully.");
                      }}
                      download
                    >
                      {(value && linkURL(value)) || "N/A"}
                    </a>
                    &nbsp;&nbsp;
                    {/* <button
                      className="btn btn-danger btn-xs pull-right"
                      onClick={(e) => handleDelete(e, _format.columnName)}
                    >
                      <i className="fa fa-trash"></i>
                    </button> */}
                  </React.Fragment>
                ) : (
                  " N/A"
                );
              case "image":
                return value ? (
                  value !== "" ? (
                    <a>
                      <img src={value} alt="Smile" height="100" />
                    </a>
                  ) : (
                    <img
                      src="https://nxgcreator.s3.amazonaws.com/unnamed.png"
                      alt="img"
                      height="60px"
                    />
                  )
                ) : (
                  " N/A"
                );
              case "12hrtime":
                var ts = value;
                var H = +ts.substr(0, 2);
                var hh = H % 12 || 12;
                hh = hh < 10 ? "0" + hh : hh; // leading 0 at the left for 1 digit hours
                var ampm = H < 12 ? " AM" : " PM";
                ts = hh + ts.substr(2, 3) + ampm;
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {ts}
                  </p>
                );
              case "mmddyy":
                let todayTime = new Date(value.iso);
                var month = String(todayTime.getMonth() + 1);
                var day = String(todayTime.getDate());
                var year = String(todayTime.getFullYear());
                if (month.length < 2) month = "0" + month;
                if (day.length < 2) day = "0" + day;
                let date = month + "/" + day + "/" + year;
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {date || "N/A"}
                  </p>
                );
              case "ddmmyy":
                let todayTime1 = new Date(value.iso);
                var month = String(todayTime1.getMonth() + 1);
                var day = String(todayTime1.getDate());
                var year = String(todayTime1.getFullYear());
                if (month.length < 2) month = "0" + month;
                if (day.length < 2) day = "0" + day;
                let date1 = day + "/" + month + "/" + year;
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {date1 || "N/A"}
                  </p>
                );
              case "string":
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {value.toString() || "N/A"}
                  </p>
                );
              default:
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {typeof value === "boolean"
                      ? value.toString()
                      : typeof value === "number"
                      ? Number(value)
                      : value || "N/A"}
                  </p>
                );
            }
          } else {
            switch (_format.format) {
              case "onedecimal":
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {val[h].toFixed(1) || "N/A"}
                  </p>
                );
              case "twodecimal":
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {val[h].toFixed(2) || "N/A"}
                  </p>
                );
              case "isoString":
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {val[h].iso || "N/A"}
                  </p>
                );
              case "boolean":
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {val[h].toString() === "true"
                      ? _format.displayValue["true"]
                      : val[h].toString() === "false"
                      ? _format.displayValue["false"]
                      : val[h].toString() || "N/A"}
                  </p>
                );
              case "percent":
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {val[h] ? `${val[h]}%` : "N/A"}
                  </p>
                );
              case "INR":
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {val[h] ? `₹ ${val[h]}` : `NA`}
                  </p>
                );
              case "USD":
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >{`$ ${val[h]}`}</p>
                );
              case "link":
                return val[h] ? (
                  <React.Fragment>
                    <a
                      /*  href={val[h]} */
                      target="_self"
                      rel="noopener noreferrer"
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = val[h];
                        alert("File downloaded successfully.");
                      }}
                      download
                    >
                      {(val[h] && linkURL(val[h])) || "N/A"}
                    </a>
                    &nbsp;&nbsp;
                    {/*<button
                      className="btn btn-danger btn-xs pull-right"
                      onClick={(e) => handleDelete(e, h)}
                    >
                      <i className="fa fa-trash"></i>
                    </button> */}
                  </React.Fragment>
                ) : (
                  "N/A"
                );
              case "image":
                return val[h] && val[h] !== "" ? (
                  <img
                    src={val[h]}
                    onClick={() => {
                      /*  this.setState({
                        Imageurl: val[h],
                      });
                      $(this.modal).modal("show"); */
                    }}
                    alt="Smile"
                    height="60"
                  />
                ) : (
                  <img
                    src="https://nxgcreator.s3.amazonaws.com/unnamed.png"
                    alt="img"
                    width="60px"
                    height="60px"
                  />
                );
              case "12hrtime":
                var ts = val[h];
                var H = +ts.substr(0, 2);
                var hh = H % 12 || 12;
                hh = hh < 10 ? "0" + hh : hh; // leading 0 at the left for 1 digit hours
                var ampm = H < 12 ? " AM" : " PM";
                ts = hh + ts.substr(2, 3) + ampm;
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {ts}
                  </p>
                );
              case "mmddyy":
                let todayTime = new Date(val[h].iso);
                var month = String(todayTime.getMonth() + 1);
                var day = String(todayTime.getDate());
                var year = String(todayTime.getFullYear());
                if (month.length < 2) month = "0" + month;
                if (day.length < 2) day = "0" + day;
                let date = month + "/" + day + "/" + year;

                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {date || "N/A"}
                  </p>
                );
              case "ddmmyy":
                let todayTime1 = new Date(val[h].iso);
                var month = String(todayTime1.getMonth() + 1);
                var day = String(todayTime1.getDate());
                var year = String(todayTime1.getFullYear());
                if (month.length < 2) month = "0" + month;
                if (day.length < 2) day = "0" + day;

                let date1 = "";
                if (day === "NaN") {
                  date1 = "";
                } else {
                  date1 = day + "/" + month + "/" + year;
                }

                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {date1 || "N/A"}
                  </p>
                );
              case "string":
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {val[h].toString() || "N/A"}
                  </p>
                );
              case "array":
                return (
                  <div
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {renderArray(val[h])}
                  </div>
                );
              default:
                return (
                  <p
                    style={{
                      fontSize: `${_format.fontsize}px`,
                      fontStyle: _format.italic ? "italic" : null,
                      fontWeight: _format.bold ? "bold" : null,
                      backgroundColor: _format.bgcolor,
                      color: _format.fontcolor,
                    }}
                  >
                    {typeof val[h] === "boolean"
                      ? val[h].toString()
                      : typeof val[h] === "number"
                      ? Number(val[h])
                      : typeof val[h] === "object" && val[h].__type === "Date"
                      ? val[h].iso
                      : typeof val[h] === "object"
                      ? "N/A"
                      : val[h]}
                  </p>
                );
            }
          }
        } catch (error) {}
      }
    } catch (error) {}
  };

  // Render record fields on screen
  const RenderRecordField = (col, i) => {
    if (Columns.length - 1 === i) {
      return (
        <div
          className={`${col.class} border`}
          key={i}
          style={{ padding: "0px" }}
        >
          <div
          // className="card"
          // style={{
          //   margin: "0px",
          //   borderColor: "none",
          //   boxShadow: "none",
          //   borderRadius: "0px",
          // }}
          >
            <div className="card-body">
              <h5 className="card-title">{col.displayName}</h5>
              {FieldDecoration(col, DisplayRecord)}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={`${col.class} border`} key={i} style={{ padding: "0px" }}>
        <div
        // className="card"
        // style={{
        //   margin: "0px",
        //   borderColor: "none",
        //   boxShadow: "none",
        //   borderBottom: "0.5px",
        //   borderRadius: "0px",
        // }}
        >
          <div className="card-body" style={{ minHeight: "80px" }}>
            <h5 className="card-title">{col.displayName}</h5>
            {FieldDecoration(col, DisplayRecord)}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    GetViewLayout();

    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Title
        title={HeaderColumn ? HeaderColumn[0].widget.label : "View Detail"}
      />
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="card card-box">
            <div className="card-head">
              <header>{HeaderColumn && HeaderColumn[0].widget.label}</header>
              <input
                type="button"
                style={{ margin: "3px" }}
                title={"Back"}
                value="Back"
                onClick={() => {
                  localStorage.setItem("CurrentPage", props.match.params.page);
                  props.history.goBack();
                }}
                className="btn btn-primary btn-xs pull-right"
              />
              {Actions &&
                Actions.map((action, i) => {
                  return (
                    <a
                      key={i}
                      className={`${action.btnCss} pull-right`}
                      title={action.label ? action.label : action.actionType}
                      style={{ margin: "3px" }}
                      disabled={IsActive}
                      onClick={(e) => {
                        e.preventDefault();
                        try {
                          if (action.actionType === "Edit") {
                            props.history.push(
                              "/edit/" +
                                props.match.params.id +
                                "/" +
                                action.data[0].formId
                            );
                          }
                          if (action.actionType === "Delete") {
                            if (
                              window.confirm(
                                `Are you sure you want to delete this record`
                              )
                            ) {
                              try {
                                setIsActive(true);
                                Parse.serverURL = parseBaseUrl;
                                Parse.initialize(parseAppId);
                                var deleted = Parse.Object.extend(
                                  action.data[0].class
                                );
                                var query = new Parse.Query(deleted);
                                query.get(props.match.params.id).then(
                                  (x) => {
                                    x.destroy().then(
                                      (y) => {
                                        alert("Record deleted successfully.");
                                        setIsActive(false);
                                        props.history.goBack();
                                      },
                                      (error) => {
                                        alert(`${error.message}`);
                                        setIsActive(false);
                                      }
                                    );
                                  },
                                  (error) => {
                                    alert(`${error.message}`);
                                    setIsActive(false);
                                  }
                                );
                              } catch (error) {
                                alert(`${error.message}`);
                                setIsActive(false);
                              }
                            }
                          }
                        } catch (error) {}
                      }}
                    >
                      <i className={action.icon}></i>
                    </a>
                  );
                })}
              {allowPrint && (
                <a
                  className={`btn btn-xs btn-danger pull-right`}
                  title={"Print"}
                  style={{ margin: "3px" }}
                >
                  <i className={"fa fa-print"}></i>
                </a>
              )}
              {allowExcelExport && (
                <a
                  className={`pull-right`}
                  title={"Export to Excel"}
                  style={{ margin: "3px" }}
                >
                  <img src="./assets/img/excel.png" alt="excel" />
                </a>
              )}
            </div>
            <div className="card-body">
              {loading ? (
                <div className="row">
                  <div
                    style={{
                      height: "100px",
                      marginLeft: "45%",
                      marginTop: "7%",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "45px",
                        color: "#3dd3e0",
                      }}
                      className="loader-37"
                    ></div>
                  </div>
                </div>
              ) : (
                <div className="row border">
                  {DisplayRecord &&
                    Columns &&
                    Columns.map((col, i) => RenderRecordField(col, i))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default View;
