import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchRoleEnum } from "./../actions";

const RoleField =(props) => {

  useEffect(() => {
    props.fetchRoleEnum(props.schema.data[0].key);
    
  }, [])
  
    return (
      <>
        <label>{props.schema.data[0].title}</label>
        <select
          value={props.value ? "Select" : props.value}
          className="form-control"
          onChange={event => {
            props.onChange(event.target.value);
          }}
        >
          {" "}
          <option>Select</option>
          {props.formData
            ? props.role.map((opt, i) => {
                if(props.formData === opt){
                    return <option key={i} value={opt} selected>{opt}</option>
                }
                else{
                    return <option key={i} value={opt}>{opt}</option>
                }
              })
            : props.role
            ? props.role.map((opt, i) => {
                return <option key={i}>{opt}</option>;
              })
            : null}
        </select>
      </>
    );
  
}

const mapStateToProps = state => {
  return { role: state.role };
};

export default connect(mapStateToProps, { fetchRoleEnum })(RoleField);
