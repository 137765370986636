import React, { Component } from "react";
import styled from "styled-components";
import { ListGroup, ListGroupItem } from "reactstrap";
import Parse from "parse";
import axios from "axios";


const Header = styled.div`
  height: 80px;
  padding: 0 30px;
  background: #7159c1;
  color: #fff;
  display: flex;
  align-items: center;
`;
const Sidebar = styled.div`
  padding-left: 400px;
`;

const Container = styled.div`
  width: 60%;
`;

export class MainBoard extends Component {
  state = {
    value: "",
    description: "",
    boardData: [],
    Column: {},
    Tasks: {},
    ColumnOrder: [],
    parseBaseUrl: localStorage.getItem("baseUrl"),
    parseAppId: localStorage.getItem("parseAppId")
  };

  handleChange = e => {
    e.preventDefault();
    this.setState({
      value: e.target.value
    });
  };

  handleSubmit = () => {
    // this.handleData();
  };

  handleData = async () => {
    Parse.serverURL = this.state.parseBaseUrl;
    Parse.initialize(this.state.parseAppId);
    var _kList = Parse.Object.extend("w_KanbanList");
    const query = new Parse.Query(_kList);
    query.equalTo("objectId", "3o0JM4BwXP");
    const response = await query.first();
    const resultjson = response.toJSON();
    let _task = {};
    let _maintasks = {};
    let listField = [];
    let arr = [];
    let columnOrder = [];
    let taskArray = [];
    let TL = {};
    let _column = {};
    let done = false;
    this.setState(() => {
      let url = `${this.state.parseBaseUrl}classes/${resultjson.data[0].class}?${resultjson.data[0].query}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": this.state.parseAppId
      };
      axios.get(url, { headers: headers }).then(res => {
        resultjson.data.forEach(x => {
          res.data.results.forEach((val, i) => {
            _task = {
              id: val.objectId,
              content: val[x.carddescfield],
              title: val[x.cardtitlefield],
              name: val[x.carddescfield],
              amount: val[x.cardleftfooterfield],
              date: val[x.cardrighttitlefield],
              view: val[x.cardId],
              camparefield: val[x.listfield[0]]
            };
            listField.push(val[x.listfield[0]]);
            arr = Array.from(new Set(listField));
            _maintasks[val.objectId] = _task;
            let d = val.objectId;
            taskArray.push(d);
          });
        });
        let tm = false;
        for (let j = 0; j < arr.length; j++) {
          TL["task" + j] = [];
          tm = true;
        }
        if (tm) {
          for (var l in _maintasks) {
            for (let j = 0; j < arr.length; j++) {
              if (_maintasks[l].camparefield == arr[j]) {
                console.log("field", _maintasks[l].camparefield, arr[j]);
                TL["task" + j].push(_maintasks[l].id);
              }
            }
          }
        }
        for (let j = 0; j < arr.length; j++) {
          let _c = "column-" + j;
          _column[_c] = {
            id: _c,
            title: arr[j],
            taskIds: TL["task" + j]
          };
          columnOrder.push(_c);
          done = true;
        }
        if (done) {
          this.setState({
            Column: _column,
            Tasks: _maintasks,
            ColumnOrder: columnOrder
          });
          var forms = Parse.Object.extend("w_Board");
          var form = new forms();
          form.set("boardTitle", this.state.value);
          form.set("description", this.state.description);
          form.set("tasks", _maintasks);
          form.set("columns", _column);
          form.set("columnOrder", columnOrder);
          form.save().then(
            form => {
              alert("Record insert Successfully");
              this.getBoardData();
            },
            error => {
              console.log("error", error);
              alert("Something went wrong", error);
            }
          );
        }
      });
    });
  };

  textAreahandleChange = e => {
    e.preventDefault();
    this.setState({
      description: e.target.value
    });
  };

  async getBoardData() {
    try {
      
      let res = "",
        res1 = [];
      Parse.serverURL = this.state.parseBaseUrl;
      Parse.initialize(this.state.parseAppId);
      var forms = Parse.Object.extend("w_KanbanList");
      const query = new Parse.Query(forms);
      await query.find().then(results => {
        if (typeof document !== "undefined") res = JSON.stringify(results);
        res1 = JSON.parse(res);
        this.setState({
          boardData: res1
        });
      });
      console.log("boardData", this.state.boardData);
    } catch (e) {
      console.error("Problem", e);
    }
  }

  getTaskBoard(id) {
    let { history } = this.props;
    history.push({
      pathname: "/kb/" + id
    });
  }
  componentDidMount() {
    this.getBoardData();
  }

  render() {
    const _board = this.state.boardData.map(val => (
      <div
        className="card text-center col-md-4"
        style={{ margin: "5px" }}
        key={val.title}
      >
        <div className="card-body">
          <a
            onClick={() => {
              this.getTaskBoard(val.objectId);
            }}
          >
            <h5 className="card-title">{val.title}</h5>
            <p className="card-text">{val.description}</p>
            <p className="card-text">
              <small className="text-muted">{val.createdAt}</small>
            </p>
          </a>
        </div>
      </div>
    ));
    return (
      <>
        <Header>
          <h3>NXG</h3>
        </Header>
        <div style={{ display: "flex" }}>
          <div className="col-md-4">
            <ListGroup style={{ paddingLeft: "60px", paddingTop: "60px" }}>
              <ListGroupItem tag="a" href="#" action>
                Board
              </ListGroupItem>
              <ListGroupItem tag="a" href="#" action>
                Home
              </ListGroupItem>
              <ListGroupItem tag="a" href="#" action>
                Team
              </ListGroupItem>
            </ListGroup>
          </div>
          <Container
            className="col-md-8"
            style={{ paddingTop: "60px", display: "flex", flexWrap: "wrap" }}
          >
            {_board}

            <div
              className="card text-center col-sm-4"
              style={{ margin: "5px" }}
            >
              <div className="card-body">
                <h5 className="card-title"></h5>
                <div className="card-text">
                  <br />
                  <br />
                  <a
                    href=""
                    className="btn btn-info"
                    data-toggle="modal"
                    data-target="#myModal"
                  >
                    Create New Board
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="modal" id="myModal" style={{ paddingTop: "60px" }}>
          <div className="modal-dialog modal-md">
            <div
              className="modal-content"
              style={{
                backgroundImage:
                  "url('https://s3-ap-south-1.amazonaws.com/av-blog-media/wp-content/uploads/2019/05/hill_top_mountain_sky.jpg')",
                opacity: 0.8,
                backgroundSize: "cover"
              }}
            >
              <div className="modal-body">
                <div
                  className="main"
                  style={{ color: "white", paddingBottom: "5px" }}
                >
                  <input
                    type="text"
                    value={this.state.value}
                    onChange={this.handleChange}
                    className="form-control"
                    placeholder="Add Board Title"
                  />
                  <button
                    type="button"
                    style={{ paddingLeft: "10px", color: "white" }}
                    className="close"
                    data-dismiss="modal"
                  >
                    &times;
                  </button>
                </div>
                <textarea
                  className="form-control"
                  rows="3"
                  style={{ paddingBottom: "5px" }}
                  placeholder="Board Description"
                  value={this.state.description}
                  onChange={this.textAreahandleChange}
                ></textarea>
                <select className="col-md-6 form-control">
                  <option>Select Team</option>
                  <option>Select Team</option>
                  <option>Select Team</option>
                </select>
                <br />
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  className="btn btn-default"
                >
                  Create Board
                </button>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="myModal1" style={{ paddingTop: "60px" }}>
          <div className="modal-dialog modal-md">
            <div
              className="modal-content"
              style={{
                backgroundImage:
                  "url('https://s3-ap-south-1.amazonaws.com/av-blog-media/wp-content/uploads/2019/05/hill_top_mountain_sky.jpg')",
                opacity: 0.8,
                backgroundSize: "cover"
              }}
            >
              <div className="modal-body">
                <div
                  className="main"
                  style={{ color: "white", paddingBottom: "5px" }}
                >
                  <input
                    type="text"
                    value={this.state.value}
                    onChange={this.handleChange}
                    className="form-control"
                    placeholder="Add Board Title"
                  />
                  <button
                    type="button"
                    style={{ paddingLeft: "10px", color: "white" }}
                    className="close"
                    data-dismiss="modal"
                  >
                    &times;
                  </button>
                </div>
                <select className="col-md-6 form-control">
                  <option>Select Team</option>
                  <option>Select Team</option>
                  <option>Select Team</option>
                </select>
                <br />
                <button
                  type="button"
                  onClick={this.handleSubmit}
                  className="btn btn-default"
                >
                  Create Board
                </button>
                <br />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MainBoard;
