import React from "react";

function PageNotFound() {
  return (
    <div
      className="page-wrap d-flex flex-row align-items-center"
      style={{
        backgroundColor: "#fff",
        marginTop: "5px",
        paddingTop: "10%",
        paddingBottom: "10%",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <span className="display-1 d-block">404</span>
            <div className="mb-4 lead" style={{fontSize: "2rem"}}>
              The page you are looking for was not found.
            </div>
           {/*  <a
              onClick={() => (window.location.hash = "/")}
              className="btn btn-link"
              style={{backgroundColor:"skyblue",color:"#fff"}}
            >
              Back to Home
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
