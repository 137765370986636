import React, { useEffect, useState, memo } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { saveDependantDDValue, removeDependantDDValue } from "actions";
import parse from "html-react-parser";
import CustomSuggesDropdown from "./CustomSuggestDropdown";
import Parse from "parse";
const DependantDropdown = (props) => {
  const REQUIRED_FIELD_SYMBOL = "*";
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // jo7FZ8u1ig

  // below used only render for dependant dropdown who don't have parent value
  useEffect(() => {
    if (
      props.uiSchema["ui:field"] === "DependantDropdown" &&
      !props.schema.parentDD
    ) {
      fetchList();
      setSelected("");
    }
  }, []);

  // below used only render for dependant dropdown who have parent value
  useEffect(() => {
    if (
      props.uiSchema["ui:field"] === "DependantDropdown" &&
      props.schema.parentDD &&
      props.DependantDDValue[`${props.schema.parentDD}_dd`]
    ) {
      fetchList();
    } else if (
      // this condition is used set empty after successful submit of form or first dependant dd value change
      props.schema.parentDD &&
      !props.DependantDDValue[`${props.schema.parentDD}_dd`]
    ) {
      props.onChange(undefined)  
      setSelected("");
    }
    
  }, [props.DependantDDValue]);

  // `fetchList` is used to fetch data for dependant dropdown as per condition
  const fetchList = async () => {
    setSelected("");
    setList([]);
    setIsLoading(true);
    const currentUser = Parse.User.current();
    let extendedClass =
      localStorage.getItem("extended_class") &&
      JSON.parse(localStorage.getItem("Extand_Class"));

    let url;
    if (props.DependantDDValue[`${props.schema.parentDD}_dd`]) {
      let str = props.schema.data.query;

      if (str.includes("#ddId#")) {
        str = str.replace(
          "#ddId#",
          props.DependantDDValue[`${props.schema.parentDD}_dd`]
        );
      }
      url = `${localStorage.getItem("baseUrl")}classes/${
        props.schema.data.class
      }?${str}`;

      if (str.includes("#userId#")) {
        str = str.replace("#userId#", currentUser.id);
      }

      if (extendedClass && str.includes("#TenantId#")) {
        str = str.replace("#TenantId#", extendedClass[0].TenantId.objectId);
      }
    } else {
      url = `${localStorage.getItem("baseUrl")}classes/${
        props.schema.data.class
      }?${props.schema.data.query}`;

      if (url.includes("#userId#")) {
        url = url.replace("#userId#", currentUser.id);
      }

      if (extendedClass && url.includes("#TenantId#")) {
        url = url.replace("#TenantId#", extendedClass[0].TenantId.objectId);
      }
    }

    const headers = {
      "Content-Type": "application/json",
      "X-Parse-Application-Id": localStorage.getItem("parseAppId"),
      "X-Parse-Session-Token": localStorage.getItem("accesstoken"),
    };

    await axios.get(url, { headers: headers }).then((res) => {
      if (res) {
        setIsLoading(false);
        const result = res.data.results;
        if (result.length > 0) {
          const arr = result.map((x) => ({
            value: x[props.schema.data.valueKey],
            label: x[props.schema.data.displayKey],
          }));
          setList(arr);
        } else {
          setList([]);
        }
      }
    });
  };
  // `onChange` function is used to pass changed value to form
  const onChange = (event) => {
    setSelected(event.target.value);
    props.onChange(event.target.value);

    // below is used to remove redux state of upcoming all dependantDD which are selected before
    if (
      props.uiSchema["ui:field"] === "DependantDropdown" &&
      !props.schema.parentDD
    ) {
      props.removeDependantDDValue(props.name, event.target.value);
    }
    props.saveDependantDDValue(props.name, event.target.value);
  };

  // `onCustomChange` is used to handle autoSuggest custom dropdown
  const onCustomChange = (value) => {
    setSelected(value);
    props.onChange(value);
    if (
      props.uiSchema["ui:field"] === "DependantDropdown" &&
      !props.schema.parentDD
    ) {
      props.removeDependantDDValue(props.name, value);
    }
    props.saveDependantDDValue(props.name, value);
  };
  return (
    <div className="flex-container flex-column pos-rel">
      <div style={{ display: "inline-block" }}>
        <label htmlFor={`dependantDD_${props.name}`}>
          {props.schema.title}
          {props.required && (
            <span className="required">{REQUIRED_FIELD_SYMBOL}</span>
          )}
          {props.schema.data.helpbody ? (
            <div className="dropdown pull-right">
              <i
                className="far fa-question-circle dropdown-toggle hovereffect pull-right"
                aria-hidden="true"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  fontSize: "12px",
                  color: "purple",
                  cursor: "pointer !important",
                  position: "relative",
                  bottom: "0px",
                  left: "0px",
                  paddingBottom: "4px",
                  paddingLeft: "4px",
                }}
              ></i>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{
                  marginleft: "-121px",
                  margintop: "-14px",
                  position: "absolute",
                  padding: "10px",
                  top: "102px!important",
                }}
              >
                {parse(`
             ${props.schema.data.helpbody}
           `)}
                <br />
                {props.schema.data.helplink ? (
                  <a
                    href={props.schema.data.helplink}
                    target="_blank"
                    className="btn btn-xs btn-primary"
                  >
                    Read more..
                  </a>
                ) : null}
              </div>
            </div>
          ) : null}
        </label>
      </div>

      {props.uiSchema["ui:type"] === "autoSuggest" ? (
        <CustomSuggesDropdown
          arr={list}
          onChange={onCustomChange}
          loading={isLoading}
        />
      ) : (
        <select
          id={`dependantDD_${props.name}`}
          className="form-control"
          value={selected}
          onChange={onChange}
          disabled={
            props.DependantDDValue[`${props.schema.parentDD}_dd`]
              ? false
              : props.schema.parentDD
              ? true
              : false
          }
        >
          <option value="">{isLoading ? "loading..." : "--select--"}</option>
          {list.map((x, i) => {
            return (
              <option key={i} value={x.value}>
                {x.label}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
};

// `comparator` is used to check props value changed or not of parent dropdown
const comparator = (prevProps, nextProps) => {
  if (prevProps.DependantDDValue) {
    // if prevProps and nextProps are same then we are not re-rendering dependantdropdown component else re-render
    if (
      prevProps.DependantDDValue[`${prevProps.schema.parentDD}_dd`] ===
      nextProps.DependantDDValue[`${nextProps.schema.parentDD}_dd`]
    ) {
      // using memo to not re-render
      return true;
    } else {
      // In this component condition component render
      return false;
    }
  }
};

// `mapStateToProps` is used to pass state of dependantdropdown reducer to props
const mapStateToProps = (state) => {
  return {
    DependantDDValue: state.DependantDD,
  };
};

export default connect(mapStateToProps, {
  saveDependantDDValue,
  removeDependantDDValue,
})(memo(DependantDropdown, comparator));
