import firebase from "./pushnotifications/firebase";

export const GetTokenKey = () => {
  try {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(function () {
        console.log("Notification permission granted.");

        // get the token in the form of promise
        return messaging.getToken();
      })
      .then(function (token) {
        localStorage.setItem("fcm-token", token);
        console.log("fcm-token", token);
      })
      .catch(function (err) {
        console.log("Unable to get permission to notify.", err);
      });

    firebase.messaging().onMessage((notification) => {
      console.log("----------------fcm-------")
      alert("Notification received!", notification);
    });
  } catch (error) {
    console.log(error.message);
  }
};
