export default (state = [], action) => {
  switch (action.type) {
    case "Level1_Dropdown":
      return { ...state, ...action.payload };
    case "REMOVE_STATE":
      state = {};
      return state;
    default:
      return { ...state };
  }
};
