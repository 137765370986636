import React, { useState } from "react";
import "./pgsignup.css";
import Parse from "parse";
import axios from "axios";

const PgSignUp = (props) => {
  const [parseBaseUrl] = useState(localStorage.getItem("BaseUrl12"));
  const [parseAppId] = useState(localStorage.getItem("AppID12"));
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [validationMessage, setValidationMessage] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoader(true);
    if (formData.password === formData.confirmPassword) {
      const url = window.location.href;
      let paramString = url.split("?")[1];
      let queryString = new URLSearchParams(paramString);
      // console.log("url ", queryString);

      let obj = { ...formData };
      for (let pair of queryString.entries()) {
        // console.log("Key is: " + pair[0]);
        // console.log("Value is: " + pair[1]);
        obj = { ...obj, [pair[0]]: pair[1] };
      }
      saveUser(obj);
      // console.log("obj ", obj);}
    }
  };
  const saveUser = async (obj) => {
    const domain = localStorage.getItem("domain");
    // console.log("domain", domain)
    try {
      const zohoRes = await axios.post(
        parseBaseUrl + "functions/zohodetails",
        {
          hostedpagesId: obj.hostedpage_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Parse-Application-Id": parseAppId,
          },
        }
      );
      const UserData = {
        name: zohoRes.data.result.name,
        email: zohoRes.data.result.email,
        phone: zohoRes.data.result.phone,
        password: obj.password,
        username: zohoRes.data.result.email,
      };

      const user = new Parse.User();
      user.set("username", zohoRes.data.result.email);
      user.set("password", obj.password);
      user.set("email", zohoRes.data.result.email);
      user.set("phone", zohoRes.data.result.phone);
      user.set("name", zohoRes.data.result.name);

      const res = await user.signUp();
      // console.log("res ", res);

      if (res) {
        let roleurl = `${parseBaseUrl}functions/AddUserToRole`;
        const headers = {
          "Content-Type": "application/json",
          "X-Parse-Application-Id": parseAppId,
          sessionToken: res.getSessionToken(), //localStorage.getItem("accesstoken"),
        };
        let body = {
          appName: domain, //props.appInfo.appname,
          roleName: obj.role, //props.appInfo.defaultRole,
          userId: res.id,
        };
        let role = await axios.post(roleurl, body, { headers: headers });
        // console.log("role ", role);

        const roleData = JSON.parse(localStorage.getItem("userSettings"));
        // console.log("roleData ", roleData)
        if (roleData && roleData.length > 0) {
          roleData.forEach((rld) => {
            if (rld.role === obj.role) {
              // props.appInfo.defaultRole
              const extCls = Parse.Object.extend(rld.extended_class);
              const newObj = new extCls();
              newObj.set("UserId", {
                __type: "Pointer",
                className: "_User",
                objectId: res.id,
              });
              newObj.set("UserRole", obj.role); // props.appInfo.defaultRole
              newObj.set("Email", UserData.email);
              newObj.set("Name", UserData.name);
              newObj.set("Phone", UserData.phone);
              newObj.set(
                "Next_billing_date",
                new Date(zohoRes.data.result.nextBillingDate)
              );
              newObj.set("Company", zohoRes.data.result.company);
              newObj.set("Plan", zohoRes.data.result.plan);
              newObj.set("Customer_id", zohoRes.data.result.customer_id);
              newObj.set(
                "Subscription_id",
                zohoRes.data.result.subscription_id
              );

              newObj
                .save()
                .then((ex) => {
                  if (ex) {
                    alert("Registered user successfully");
                    window.location.hash = "/";
                  }
                })
                .catch((err) => alert(err.message));
            }
          });
        }
      }
      setIsLoader(false);
    } catch (error) {
      console.log("err ", error);
      if (error.message === "Account already exists for this username.") {
        alert("Account already exists!");
        window.location.hash = "/";
      } else {
        setIsLoader(false);
        alert("Something went wrong, please try again later!");
      }
    }
  };

  const handlePassowordChange = (e) => {
    const newPassword = e.target.value;
    setFormData({ ...formData, [e.target.name]: e.target.value });
    validatePasswords(newPassword, formData.confirmPassword);
  };
  const handleConFirmPassowordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setFormData({ ...formData, [e.target.name]: e.target.value });
    validatePasswords(formData.password, newConfirmPassword);
  };
  const validatePasswords = (newPassword, newConfirmPassword) => {
    if (newPassword === newConfirmPassword) {
      setValidationMessage("Passwords match!");
    } else {
      setValidationMessage("Passwords not match!");
    }
  };
  return (
    <div>
      {isLoader ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontSize: "45px",
              color: "#3dd3e0",
            }}
            className="loader-37"
          ></div>
        </div>
      ) : (
        <form id="signup" className="pgsignup-content" onSubmit={handleSubmit}>
          <div className="pgsignup-container">
            <h1>Choose Password</h1>
            <hr className="hrt" />
            <label htmlFor="password">
              <b>Password</b>
            </label>
            <input
              type="password"
              placeholder="Enter Password"
              name="password"
              className="inputt"
              value={formData.password}
              onChange={handlePassowordChange}
              required
            />

            <label htmlFor="confirmPassword">
              <b>Confirm Password</b>
            </label>
            <input
              type="password"
              placeholder="Confirm Password"
              name="confirmPassword"
              className="confirmInputt"
              style={{
                border: validationMessage
                  ? validationMessage === "Passwords match!"
                    ? "2px solid green"
                    : "2px solid red"
                  : "2px solid transparent",
                outline: "none",
              }}
              value={formData.confirmPassword}
              onChange={handleConFirmPassowordChange}
              required
            />
            <div
              className="icon"
              style={{
                margin: validationMessage ? "0 2px 0 2px" : "0 2px 22px 2px",
                color:
                  validationMessage === "Passwords match!" ? "green" : "red",
                fontSize: 11,
              }}
            >
              {validationMessage}
            </div>
            <div className="clearfix">
              <button type="submit" className="signupbtn">
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default PgSignUp;
