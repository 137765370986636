import React, { memo } from "react";
import uniqid from "uniqid";

function SideBarIframe({ menuList }) {
  let renderIframe = menuList.map((x) => {
    if (x.pageType === "microapp") {
      return (
        <iframe
          key={uniqid()}
          id={x.objectId}
          src={x.objectId}
          height="0px"
          width="0px"
          title=""
          style={{ display: "none" }}
        />
      );
    }
    if (x.children) {
      return x.children.map((y) => {
        if (y.pageType === "microapp") {
          return (
            <iframe
              key={uniqid()}
              id={y.objectId}
              src={y.objectId}
              height="0px"
              title=""
            />
          );
        }
      });
    }
  });
  return renderIframe;
}

export default memo(SideBarIframe);
