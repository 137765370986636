import React, { useState, useEffect } from "react";
import Form from "react-jsonschema-form";
import TreeWidget from "../LevelDropDown/TreeWidget";

const fields = { FolderComponent: TreeWidget };
const jsonSchema = {
  title: "Batches",
  description: "",
  type: "object",
  required: [],
  properties: {
    firstName: {
      type: "string",
      title: "First name",
      default: "Chuck",
    },
    Topic: {
      type: "string",
      title: "Topic",
      data: {
        ClassName: "elearning_Videos",
        FolderNameField: "VideoName",
        FolderTypeField: "Type",
        FolderTypeValue: "Folder",
        FolderDescription: "VideoDesc",
        ParentFolderField: "ParentFolder",
        FormId: "RG8F6aXq8z",
        Query:
          'where={"AppId":{"__type": "Pointer", "className": "elearning_BuildConfig", "objectId": "#AppId.objectId#"},"ParentFolder":{"$exists":false},"Type":"Folder"}',
      },
    },
    lastName: {
      type: "string",
      title: "Last name",
    },
  },
};

const uiSchema = {
  Topic: {
    "ui:field": "FolderComponent",
  },
};

const TestComponent = (props) => {
  const [formData, setformData] = useState({
    Topic: {
      __type: "Pointer",
      className: "elearnig_FolderTestClass",
      objectId: "TGTBXsnTBP",
    },
    lastName: "sunil",
  });
  const handleSubmit = ({ formData }) => {
    //console.log(formData);
  };

  const onBlur = (e) => {
    console.log(e);
    // formData.CurrentNum = formData.EmpId;
  };

  useEffect(() => {
    if (props.formData) {
      //  setformData({ ...formData, CurrentNum: props.formData.EmpId });
    }
  }, [props.formData]);

  return (
    <>
      <Form
        schema={jsonSchema}
        uiSchema={uiSchema}
        fields={fields}
        onBlur={onBlur}
        formData={formData}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default TestComponent;
