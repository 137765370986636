import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Parse from "parse";
import uniqid from "uniqid";
import { useSelector, useDispatch } from "react-redux";

import "./header.css";
import "./../Spinner/loader.css";
import {
  addItemsToCart,
  removeFromCart,
  clearCartData,
  setCartUpdateData,
} from "../actions";
import HeaderSwitch from "./HeaderSwitch";

const $ = window.$;

const Header = (props) => {
  const [hideNav, setHideNav] = useState("");
  const [IncludedApps, setIncludedApps] = useState([]);
  const [NotificationsData, setNotificationsData] = useState([]);
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const [tenantId] = useState(localStorage.getItem("TenetId"));
  const [isFetching, setIsFetching] = useState(false);
  const [RowNotification, setRowNotification] = useState(null);
  const [loading, setLoading] = useState(false);
  const [textEdit, setTextEdit] = useState({});
  const [isDropdown, setIsDropdown] = useState(false)
  const CartData = useSelector((state) => state.CartData);
  const enableCart = useSelector((state) => state.enableCart);
  const dispatch = useDispatch();
  const ref = useRef(null);

  const resize = () => {
    let currentHideNav = window.innerWidth <= 760;
    if (currentHideNav !== hideNav) {
      setHideNav(currentHideNav);
    }
  };

    useEffect(() => {
    document.addEventListener('mousedown', Clickout);
    return () => {
      document.removeEventListener('mousedown', Clickout);
    };
  }, []);

  const Clickout = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsDropdown(false)
    }
  };

  const deleteCart = async (e, id) => {
    e.preventDefault();
    try {
      let appId = `${localStorage.getItem("_app_objectId")}` || "";
      let _cartDetails;
      let searchObjectId = "";
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      const currentUser = Parse.User.current();
      let url = `${parseBaseUrl}classes/w_Cart?where={"AppId":{"__type":"Pointer","className":"w_appinfo","objectId":"${appId}"},"UserId":{"__type":"Pointer","className":"_User","objectId":"${currentUser.id}"}}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": parseAppId,
        sessionToken: localStorage.getItem("accesstoken"),
      };
      await axios.get(url, { headers }).then((res) => {
        if (res) {
          if (res.data.results.length > 0) {
            _cartDetails = res.data.results[0].CartDetails;
            searchObjectId = res.data.results[0].objectId;
          }
        }
      });
      if (_cartDetails && searchObjectId) {
        if (_cartDetails.length === 1) {
          const MyCustomClass = Parse.Object.extend("w_Cart");
          const query = new Parse.Query(MyCustomClass);
          query.get(searchObjectId).then((object) => {
            object.destroy().then(
              (response) => {
                dispatch(removeFromCart(id));
              },
              (error) => {
                console.error("Error while deleting ParseObject", error);
              }
            );
          });
        } else {
          let _tempArr = _cartDetails.filter((x) => x.CatalogId !== id);
          const MyCustomClass = Parse.Object.extend("w_Cart");
          const query = new Parse.Query(MyCustomClass);
          query.get(searchObjectId).then((object) => {
            object.set("CartDetails", _tempArr);
            object.save().then(
              (response) => {
                dispatch(removeFromCart(id));
              },
              (error) => {
                console.error("Error while updating ", error);
              }
            );
          });
        }
      }
    } catch (error) {}
  };

  const UpdateQuantity = async (id, qty = 1) => {
    setTextEdit({ ...textEdit, [id]: true });
    let list = CartData.map((i) =>
      i.objectId === id
        ? { ...i, Quantity: Number(qty), Amount: Number(i.Rate) * Number(qty) }
        : i
    );
    try {
      let appId = `${localStorage.getItem("_app_objectId")}` || "";
      let _cartDetails;
      let searchObjectId = "";
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      const currentUser = Parse.User.current();
      let url = `${parseBaseUrl}classes/w_Cart?where={"AppId":{"__type":"Pointer","className":"w_appinfo","objectId":"${appId}"},"UserId":{"__type":"Pointer","className":"_User","objectId":"${currentUser.id}"}}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": parseAppId,
        sessionToken: localStorage.getItem("accesstoken"),
      };
      await axios.get(url, { headers }).then((res) => {
        if (res) {
          if (res.data.results.length > 0) {
            _cartDetails = res.data.results[0].CartDetails;
            searchObjectId = res.data.results[0].objectId;
          }
        }
      });
      if (_cartDetails && searchObjectId) {
        let list1 = _cartDetails.map((i) =>
          i.CatalogId === id ? { ...i, Quantity: Number(qty) } : i
        );
        const MyCustomClass = Parse.Object.extend("w_Cart");
        const query = new Parse.Query(MyCustomClass);
        query.get(searchObjectId).then((object) => {
          object.set("CartDetails", list1);
          object.save().then(
            (response) => {
              dispatch(setCartUpdateData(list));
              setTextEdit({ ...textEdit, [id]: false });
            },
            (error) => {
              console.error("Error while updating ", error);
              setTextEdit({ ...textEdit, [id]: false });
            }
          );
        });
      }
    } catch (error) {
      setTextEdit({ ...textEdit, [id]: false });
    }
  };

  const getSubscriptionData = async () => {
    try {
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      const tour = Parse.Object.extend("partners_Subscriptions");
      const query = new Parse.Query(tour);
      query.equalTo("TenantId", {
        __type: "Pointer",
        className: "partners_Tenant",
        objectId: localStorage.getItem("TenetId"),
      });
      await query.first().then(
        async (results) => {
          if (results) {
            let subscriptiondata = results.toJSON();
            const Agent = Parse.Object.extend("partners_Plans");
            const query1 = new Parse.Query(Agent);
            query1.equalTo("objectId", subscriptiondata.PlansPtr.objectId);
            await query1.first().then(
              (res) => {
                if (res) {
                  let plans = res.toJSON();
                  setIncludedApps(plans.IncludedApps);
                }
              },
              (error) => {
                console.error("Error while fetching Agent", error);
              }
            );
          }
        },
        (error) => {
          console.error("Error while fetching tour", error);
        }
      );
    } catch (error) {
      console.error("Error while fetching tour", error);
    }
  };

  const getNotifications = async () => {
    try {
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      const currentUser = Parse.User.current();
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": parseAppId,
        "X-Parse-Session-Token": localStorage.getItem("accesstoken"),
      };
      let url = `${parseBaseUrl}classes/w_WebNotifications?where={"$or":[{"AppId": {"$inQuery": { "where": {"appname": "${localStorage.getItem(
        "appName"
      )}"},"className": "w_appinfo"}},"RoleName":"${localStorage.getItem(
        "appName"
      )}_${localStorage.getItem(
        "_user_role"
      )}","UserId":{"__type":"Pointer","className":"_User","objectId":"${
        currentUser.id
      }"}},{"AppId": {"$inQuery": { "where": {"appname": "${localStorage.getItem(
        "appName"
      )}"},"className": "w_appinfo"}},"RoleName":"${localStorage.getItem(
        "appName"
      )}_${localStorage.getItem(
        "_user_role"
      )}"},{"AppId": {"$inQuery": { "where": {"appname": "${localStorage.getItem(
        "appName"
      )}"},"className": "w_appinfo"}}}]}&order=-updatedAt&limit=10`;

      await axios.get(url, { headers: headers }).then((result) => {
        if (result) {
          let tempData = result.data.results;
          let newArray = [];
          tempData.forEach((x) => {
            let obj = {
              ...x,
              IsRead: false,
            };
            newArray.push(obj);
          });

          let strArr = JSON.parse(localStorage.getItem("NotificationArray"));
          if (strArr && strArr.length > 0) {
            let parseArray = JSON.parse(
              localStorage.getItem("NotificationArray")
            );

            let campareArray = [];

            let re = true;
            for (var l in newArray) {
              for (var t in parseArray) {
                if (
                  newArray[l].objectId === parseArray[t].objectId &&
                  parseArray[t].objectId
                ) {
                  re = false;
                }
              }
              if (re === true) {
                campareArray.push(newArray[l]);
              }
            }
            let r1 = true;
            for (var l in parseArray) {
              for (var t in campareArray) {
                if (
                  parseArray[l].objectId === campareArray[t].objectId &&
                  campareArray[t].objectId
                ) {
                  r1 = false;
                }
              }
              if (r1 === true) {
                campareArray.push(parseArray[l]);
              }
            }

            setNotificationsData(campareArray);
            localStorage.setItem(
              "NotificationArray",
              JSON.stringify(campareArray)
            );
          } else if (
            localStorage.getItem("NotificationArray") &&
            localStorage.getItem("NotificationArray") ==
              JSON.stringify(newArray)
          ) {
            setNotificationsData(strArr);
          } else {
            setNotificationsData(newArray);
            localStorage.setItem("NotificationArray", JSON.stringify(newArray));
          }
        }
      });
    } catch (error) {
      console.log("error from getNotifications-Header.js", error.message);
    }
  };

  const getUnReadCount = () => {
    let a = 0;
    NotificationsData.forEach((x) => {
      if (!x.IsRead) {
        a++;
      }
    });
    return a;
  };

  const updateReadRecord = async (val, e) => {
    try {
      e.preventDefault();
      let NewArray = [];
      NotificationsData.forEach((x) => {
        if (x.objectId === val.objectId) {
          let _record = {
            ...val,
            IsRead: true,
          };
          NewArray.push(_record);
        } else {
          NewArray.push(x);
        }
      });

      setNotificationsData(NewArray);
      setRowNotification(val);
      localStorage.setItem("NotificationArray", JSON.stringify(NewArray));

      $("#NotificationModal").show();
      /*  const Recenzie = Parse.Object.extend("w_WebNotifications");
      const query = new Parse.Query(Recenzie);
      await query.get(val.objectId).then((object) => {
        object.set("IsRead", true);
        object.save().then(
          (response) => {
            getNotifications();
          },
          (error) => {
            console.error(
              "Error while updating w_WebNotifications",
              error.message
            );
          }
        );
      }); */
    } catch (error) {
      console.error("Error while updating w_WebNotifications", error.message);
    }
  };

  const fetchMoreListItems = () => {
    setTimeout(async () => {
      try {
        Parse.serverURL = parseBaseUrl;
        Parse.initialize(parseAppId);
        const currentUser = Parse.User.current();
        const headers = {
          "Content-Type": "application/json",
          "X-Parse-Application-Id": parseAppId,
          "X-Parse-Session-Token": localStorage.getItem("accesstoken"),
        };
        let url = `${parseBaseUrl}classes/w_WebNotifications?where={"$or":[{"AppId": {"$inQuery": { "where": {"appname": "${localStorage.getItem(
          "appName"
        )}"},"className": "w_appinfo"}},"RoleName":"${localStorage.getItem(
          "appName"
        )}_${localStorage.getItem(
          "_user_role"
        )}","UserId":{"__type":"Pointer","className":"_User","objectId":"${
          currentUser.id
        }"}},{"AppId": {"$inQuery": { "where": {"appname": "${localStorage.getItem(
          "appName"
        )}"},"className": "w_appinfo"}},"RoleName":"${localStorage.getItem(
          "appName"
        )}_${localStorage.getItem(
          "_user_role"
        )}"},{"AppId": {"$inQuery": { "where": {"appname": "${localStorage.getItem(
          "appName"
        )}"},"className": "w_appinfo"}}}]}&order=-updatedAt&skip=${
          NotificationsData.length
        }&limit=10`;

        await axios.get(url, { headers: headers }).then((result) => {
          if (result) {
            let tempData = result.data.results;
            let newArray = [];
            tempData.forEach((x) => {
              let obj = {
                ...x,
                IsRead: false,
              };
              newArray.push(obj);
            });

            let strArr = JSON.parse(localStorage.getItem("NotificationArray"));
            if (strArr && strArr.length > 0) {
              let parseArray = JSON.parse(
                localStorage.getItem("NotificationArray")
              );

              let campareArray = [];

              let re = true;
              for (var l in newArray) {
                for (var t in parseArray) {
                  if (
                    newArray[l].objectId === parseArray[t].objectId &&
                    parseArray[t].objectId
                  ) {
                    re = false;
                  }
                }
                if (re === true) {
                  campareArray.push(newArray[l]);
                }
              }
              let r1 = true;
              for (var l in parseArray) {
                for (var t in campareArray) {
                  if (
                    parseArray[l].objectId === campareArray[t].objectId &&
                    campareArray[t].objectId
                  ) {
                    r1 = false;
                  }
                }
                if (r1 === true) {
                  campareArray.push(parseArray[l]);
                }
              }

              setNotificationsData(campareArray);
              localStorage.setItem(
                "NotificationArray",
                JSON.stringify(campareArray)
              );
            } else if (
              localStorage.getItem("NotificationArray") &&
              localStorage.getItem("NotificationArray") ==
                JSON.stringify(newArray)
            ) {
              setNotificationsData(strArr);
            } else {
              setNotificationsData(newArray);
              localStorage.setItem(
                "NotificationArray",
                JSON.stringify(newArray)
              );
            }
          }
        });
      } catch (error) {
        console.log("error from getNotifications-Header.js", error.message);
      }
      setIsFetching(false);
    }, 2000);
  };

  const getPreviousCartDetails = async () => {
    if (localStorage.getItem("EnableCart")) {
      try {
        let role =
          `${localStorage.getItem("_appName")}_${localStorage.getItem(
            "_user_role"
          )}` || "";
        let appId = `${localStorage.getItem("_app_objectId")}` || "";
        let CartSettings;
        if (role && appId) {
          let url = `${parseBaseUrl}classes/w_CartSettings?where={"AppId":{"__type":"Pointer","className":"w_appinfo","objectId":"${appId}"},"UserRole":"${role}"}`;
          const headers = {
            "Content-Type": "application/json",
            "X-Parse-Application-Id": parseAppId,
            sessionToken: localStorage.getItem("accesstoken"),
          };
          await axios.get(url, { headers }).then((res) => {
            if (res) {
              if (res.data.results.length > 0) {
                CartSettings = res.data.results[0];
              }
            }
          });
          if (CartSettings) {
            let _cartDetails = [];
            let searchClass = "";
            Parse.serverURL = parseBaseUrl;
            Parse.initialize(parseAppId);
            const currentUser = Parse.User.current();
            let url = `${parseBaseUrl}classes/w_Cart?where={"AppId":{"__type":"Pointer","className":"w_appinfo","objectId":"${appId}"},"UserId":{"__type":"Pointer","className":"_User","objectId":"${currentUser.id}"}}`;
            const headers = {
              "Content-Type": "application/json",
              "X-Parse-Application-Id": parseAppId,
              sessionToken: localStorage.getItem("accesstoken"),
            };
            await axios.get(url, { headers }).then((res) => {
              if (res) {
                if (res.data.results.length > 0) {
                  _cartDetails = res.data.results[0].CartDetails;
                  searchClass = res.data.results[0].CatalogClass;
                }
              }
            });
            if (_cartDetails.length > 0 && searchClass) {
              _cartDetails.forEach(async (x) => {
                let url = `${parseBaseUrl}classes/${searchClass}?where={"objectId":"${x.CatalogId}"}`;
                const headers = {
                  "Content-Type": "application/json",
                  "X-Parse-Application-Id": parseAppId,
                  sessionToken: localStorage.getItem("accesstoken"),
                };
                await axios.get(url, { headers }).then((res) => {
                  if (res) {
                    if (res.data.results.length > 0) {
                      let val = res.data.results[0];
                      let obj = {
                        objectId: val["objectId"],
                        Title: val[CartSettings["TitleField"]],
                        Icon: val[CartSettings["IconField"]],
                        Rate: val[CartSettings["RateField"]],
                        Discount: val[CartSettings["DiscountField"]],
                        Quantity: x.Quantity,
                        Amount:
                          Number(val[CartSettings["RateField"]]) *
                          Number(x.Quantity),
                      };
                      dispatch(addItemsToCart(obj));
                    }
                  }
                });
              });
            }
          }
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    getPreviousCartDetails();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener("resize", resize());
    if (localStorage.getItem("enableWebNotification")) {
      getNotifications();
    }
    if (tenantId) {
      getSubscriptionData();
    }
    return () => window.removeEventListener("resize", resize());

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    return fetchMoreListItems();
    // eslint-disable-next-line
  }, [isFetching]);

  function openCloseView() {
    var x = document.getElementById("_cart_view");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  let applogo = localStorage.getItem("appLogo");
  let username = localStorage.getItem("username");
  let imgUrl = localStorage.getItem("profileImg");

  let _imgPath = "./assets/img/dp.png";
  if (imgUrl === null || imgUrl === "") {
    _imgPath = imgUrl;
  }

  const hideImage = !hideNav ? (
    <span className="logo-default">
      <img src={localStorage.getItem("appLogo")} height="auto" width="100%" />
    </span>
  ) : (
    <span className="logo-default">
      <img src={localStorage.getItem("appLogo")} width="100%" />
    </span>
  );

  // `handleSideMenu` is used to show /hide side menu
  const handleSideMenu = (e) => {
    e.preventDefault();
    var body = $("body");
    var sidebar = $(".sidemenu-container");
    var sidebarMenu = $(".sidemenu");
    $(".sidebar-search", sidebar).removeClass("open");

    if (body.hasClass("sidemenu-closed")) {
      body.removeClass("sidemenu-closed");
      sidebarMenu.removeClass("sidemenu-closed");
      if ($.cookie) {
        $.cookie("sidebar_closed", "0");
      }
    } else {
      body.addClass("sidemenu-closed");
      sidebarMenu.addClass("sidemenu-closed");
      if (body.hasClass("sidemenu-container-fixed")) {
        sidebarMenu.trigger("mouseleave");
      }
      if ($.cookie) {
        $.cookie("sidebar_closed", "1");
      }
    }
  };
  return (
    <React.Fragment>
      <ul className="nav navbar-nav navbar-left in">
        <li>
          <a
            onClick={(e) => handleSideMenu(e)}
            className="menu-toggler sidebar-toggler"
          >
            <i className="icon-menu"></i>
          </a>
        </li>
      </ul>
      <a
        onClick={(e) => e.preventDefault()}
        className="menu-toggler responsive-toggler"
        data-toggle="collapse"
        data-target=".navbar-collapse"
      >
        <i className="icon-menu"></i>
        {/* <span></span> */}
      </a>
      <div className="page-logo" style={{ background: "#fff" }}>
        <Link
          to={`/${localStorage.getItem("pageType")}/${localStorage.getItem(
            "PageLanding"
          )}`}
        >
          {hideImage}
        </Link>
      </div>
      <div className="top-menu">
        <ul
          className="nav navbar-nav pull-right"
          style={{
            marginLeft: `${
              hideNav && imgUrl && IncludedApps.length > 1
                ? "-6px"
                : hideNav && imgUrl
                ? "40px"
                : "70px"
            }`,
          }}
        >
          {hideNav ? null : (
            <li>
              <a onClick={(e) => e.preventDefault()} className="fullscreen-btn">
                <i className="fa fa-arrows-alt"></i>
              </a>
            </li>
          )}

          {IncludedApps.length > 0 && (
            <li
              className="dropdown dropdown-extended dropdown-inbox"
              id="header_inbox_bar"
            >
              <a
                onClick={(e) => e.preventDefault()}
                className="dropdown-toggle"
                data-toggle="dropdown"
                data-hover="dropdown"
                data-close-others="true"
                title={"App Switcher"}
              >
                <i
                  className="material-icons"
                  style={
                    !hideNav
                      ? { color: "#4a4242", fontSize: "24px" }
                      : {
                          color: "#4a4242",
                          fontSize: "24px",
                          marginTop: "-8px",
                        }
                  }
                >
                  apps
                </i>
              </a>
              <div className="dropdown-menu" style={{ position: "absolute" }}>
                <div className="row">
                  {IncludedApps.length && (
                    <HeaderSwitch IncludedApps={IncludedApps} />
                  )}
                </div>
              </div>
            </li>
          )}
          {localStorage.getItem("enableWebNotification") && (
            <li
              className="dropdown dropdown-extended dropdown-notification"
              id="header_notification_bar"
            >
              <a
                onClick={(e) => e.preventDefault()}
                className="dropdown-toggle"
                data-toggle="dropdown"
                data-hover="dropdown"
                data-close-others="true"
              >
                <i className="fa fa-bell-o"></i>
                {getUnReadCount() > 0 ? (
                  <span className="badge headerBadgeColor1">
                    {getUnReadCount() >= 10 ? "10+" : getUnReadCount()}
                  </span>
                ) : null}
              </a>
              <ul className="dropdown-menu">
                {NotificationsData.length > 0 ? (
                  <React.Fragment>
                    <li className="external">
                      <h3>
                        <span className="bold">Notifications</span>
                      </h3>
                      <span className="notification-label purple-bgcolor">
                        {getUnReadCount() > 0
                          ? `Unread ${getUnReadCount()}`
                          : null}
                      </span>
                    </li>
                    <li>
                      <ul
                        className="dropdown-menu-list small-slimscroll-style"
                        data-handle-color="#637283"
                      >
                        {NotificationsData &&
                          NotificationsData.map((val, i) => (
                            <li
                              key={uniqid()}
                              style={{
                                backgroundColor: !val.IsRead && "#cbdfdb",
                              }}
                            >
                              <a
                                onClick={(e) => {
                                  updateReadRecord(val, e);
                                }}
                              >
                                <span className="time">
                                  {new Date(val.updatedAt).getFullYear() +
                                    "-" +
                                    (new Date(val.updatedAt).getMonth() + 1) +
                                    "-" +
                                    new Date(val.updatedAt).getDate()}
                                </span>
                                <span className="details">
                                  <span className="notification-icon circle deepPink-bgcolor">
                                    <i className="fa fa-check"></i>
                                  </span>
                                  {val.Title}
                                </span>
                              </a>
                            </li>
                          ))}
                      </ul>
                      <div className="dropdown-menu-footer">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            fetchMoreListItems();
                          }}
                        >
                          {" "}
                          More notifications{" "}
                        </a>
                      </div>
                    </li>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <li className="external">
                      <h3>
                        <span className="bold">Notifications</span>
                      </h3>
                      <span className="notification-label purple-bgcolor"></span>
                    </li>
                    <li>
                      <ul
                        className="dropdown-menu-list small-slimscroll-style"
                        data-handle-color="#637283"
                      >
                        <li>
                          <a onClick={(e) => e.preventDefault()}>
                            <span className="time"></span>
                            <span className="details">
                              <span className="notification-icon circle deepPink-bgcolor">
                                <i className="fa fa-check"></i>
                              </span>
                              No Notifications
                            </span>
                          </a>
                        </li>
                      </ul>
                      <div className="dropdown-menu-footer">
                        <a onClick={(e) => e.preventDefault()}>
                          {" "}
                          All notifications{" "}
                        </a>
                      </div>
                    </li>
                  </React.Fragment>
                )}
              </ul>
            </li>
          )}

          {(enableCart || localStorage.getItem("EnableCart")) && (
            <li
              className="dropdown dropdown-extended dropdown-notification"
              id="header_inbox_bar"
            >
              <a
                onClick={(e) => e.preventDefault()}
                className="dropdown-toggle"
                data-toggle="dropdown"
                data-hover="dropdown"
                data-close-others="true"
              >
                <i className="fab fa-opencart"></i>
                {CartData && CartData.length > 0 && (
                  <span className="badge headerBadgeColor1">
                    {CartData.length}
                  </span>
                )}
              </a>
              <div
                className="dropdown-menu"
                style={{ width: "300px", maxWidth: "300px", padding: "5px" }}
              >
                <header id="title">
                  <h2
                    style={{
                      color: "#fff",
                      textShadow: "1px 2px 0 #7184d8",
                      fontSize: "3.5em",
                      lineHeight: "1.1em",
                      padding: "6px 0",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Cart Item
                  </h2>
                </header>
                <div id="page">
                  <table
                    id="cart"
                    className="table"
                    style={{ overflowY: "scroll", height: "400px" }}
                  >
                    <thead>
                      <tr>
                        <th className="first">Photo</th>
                        <th className="second">Qty</th>
                        <th className="third">Item</th>
                        <th className="forth">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {CartData && CartData.length > 0 ? (
                        CartData.map((x) => (
                          <tr key={uniqid()}>
                            <td>
                              <img src={x.Icon} className="thumb" />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={x.Quantity}
                                min="1"
                                max="99"
                                className="qtyinput"
                                onChange={(e) => {
                                  UpdateQuantity(x.objectId, e.target.value);
                                }}
                                disabled={textEdit[x["objectId"]]}
                              />
                            </td>
                            <td>{x.Title}</td>
                            <td>{x.Amount}</td>
                            <td>
                              <span className="remove">
                                <a
                                  onClick={(e) => {
                                    deleteCart(e, x.objectId);
                                  }}
                                >
                                  <img
                                    src="https://i.imgur.com/h1ldGRr.png"
                                    alt="X"
                                  />
                                </a>
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={5}>No Items</td>
                        </tr>
                      )}

                      <tr className="extracosts">
                        <td className="light">GST</td>
                        <td colSpan="2" className="light"></td>
                        <td>
                          {(
                            CartData &&
                            CartData.reduce(
                              (a, b) => a + (b["Amount"] || 0),
                              0
                            ) *
                              (18 / 100)
                          ).toFixed(2)}
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr className="totalprice">
                        <td className="light">Total:</td>
                        <td colSpan="2">&nbsp;</td>
                        <td colSpan="2">
                          <span className="thick">
                            {(
                              CartData &&
                              CartData.reduce(
                                (a, b) => a + (b["Amount"] || 0),
                                0
                              ) +
                                CartData.reduce(
                                  (a, b) => a + (b["Rate"] || 0),
                                  0
                                ) *
                                  (18 / 100)
                            ).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                      <tr className="checkoutrow">
                        <td colSpan="5" className="checkout">
                          <button
                            id="submitbtn"
                            onClick={() => (window.location.hash = "/checkout")}
                          >
                            Checkout Now!
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </li>
          )}

          <li className="dropdown dropdown-user" data-tut="reactourLast--observe">
            <a
              // href=""
              className="dropdown-toggle"
              // data-toggle="dropdown"
              // data-hover="dropdown"
              // data-close-others="true"
              data-tut="reactourLast"
              onClick={()=> setIsDropdown(!isDropdown)}
            >
              {imgUrl && <img alt="" className="img-circle " src={imgUrl} />}
              <span className="username username-hide-on-mobile">
                {" "}
                {username}{" "}
              </span>
              {!hideNav ? (
                <i className="fa fa-angle-down"></i>
              ) : (
                <i className="fa fa-angle-down"></i>
              )}
            </a>
          {isDropdown &&  <ul ref={ref} className="dropdown-menu dropdown-menu-default show">
              <li>
                <a onClick={(e) => (window.location.hash = "/profile")}>
                  <i className="icon-user"></i> Profile{" "}
                </a>
              </li>
              <li>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setLoading(true);
                    try {
                      let url = "https://admin.qik.ai/";
                      let data = {
                        accesstoken: localStorage.getItem("accesstoken"),
                        auth_token: localStorage.getItem("accesstoken"),
                      };
                      let storage = JSON.stringify({
                        key: "storage",
                        method: "set",
                        data: data,
                      });

                      $("<iframe>") // Creates the element
                        .attr("id", "bill_iframe")
                        .attr("src", url) // Sets the attribute spry:region="myDs"
                        .attr("height", 0)
                        .attr("width", 0)
                        .appendTo("#iframeBilling");
                      var iframe = document.getElementById("bill_iframe");
                      iframe.onload = () => {
                        iframe.contentWindow.postMessage(storage, "*");
                      };

                      setTimeout(() => {
                        // window.location.href = url;
                        setLoading(false);
                        window.open(url, "_blank");
                      }, 5000);
                    } catch (error) {
                      setLoading(false);
                      console.log(error);
                    }
                  }}
                >
                  <i className={loading ? "loader-01" : "fa fa-money"}></i>{" "}
                  Billing{" "}
                </a>
              </li>
              <li>
                <a onClick={() => (window.location.hash = "/changepassword")}>
                  <i className="icon-lock"></i> Change Password
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    try {
                      Parse.serverURL = parseBaseUrl;
                      Parse.initialize(parseAppId);
                      dispatch(clearCartData());
                      Parse.User.logOut();
                      let appdata = localStorage.getItem("userSettings");
                      let applogo = localStorage.getItem("appLogo");
                      let appName = localStorage.getItem("appName");
                      let defaultmenuid = localStorage.getItem("defaultmenuid");
                      let PageLanding = localStorage.getItem("PageLanding");
                      let domain = localStorage.getItem("domain");
                      let _appName = localStorage.getItem("_appName");
                      let baseUrr = localStorage.getItem("BaseUrl12");
                      let appidd = localStorage.getItem("AppID12");

                      localStorage.clear();

                      localStorage.setItem("appLogo", applogo);
                      localStorage.setItem("appName", appName);
                      localStorage.setItem("_appName", _appName);
                      localStorage.setItem("defaultmenuid", defaultmenuid);
                      localStorage.setItem("PageLanding", PageLanding);
                      localStorage.setItem("domain", domain);
                      localStorage.setItem("userSettings", appdata);
                      localStorage.setItem("BaseUrl12", baseUrr);
                      localStorage.setItem("AppID12", appidd);

                      window.location.hash = "/";
                    } catch (error) {}
                  }}
                >
                  <i className="icon-logout"></i>logout
                </a>
              </li>
              <li>
                {" "}
                <div id="iframeBilling" style={{ display: "none" }} />
              </li>
            </ul>}
          </li>
        </ul>
      </div>
      {loading && (
        <React.Fragment>
          <span
            className="loader-02"
            style={{
              color: "skyblue",
              fontSize: "20px",
              left: "30%",
              position: "relative",
              padding: "15px",
              top: "10px",
            }}
          ></span>
          <span
            style={{
              color: "skyblue",
              fontSize: "12px",
              left: "30%",
              position: "relative",
              padding: "12px",
              top: "10px",
            }}
          >
            {" "}
            Please wait...
          </span>
        </React.Fragment>
      )}
      <div className="modal" id="NotificationModal" role="dialog">
        <div
          className="modal-dialog"
          role="document"
          aria-live="polite"
          aria-atomic="true"
          style={{ position: "relative", minHeight: "200px" }}
        >
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: "white" }}>
              <h5 className="modal-title">
                <img
                  src={
                    RowNotification && RowNotification.Logo
                      ? RowNotification.Logo
                      : applogo
                  }
                  alt="logo"
                  height="15px"
                />{" "}
                {RowNotification && RowNotification.Title}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                style={{ width: "auto" }}
                onClick={(e) => {
                  e.preventDefault();
                  $("#NotificationModal").hide();
                }}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {RowNotification && (
                <div className="col-12">
                  <p>{RowNotification.Description}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
