import React, { Component } from "react";
import Parse from "parse";
import axios from "axios";

class Options extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Options: [],
      parseBaseUrl: localStorage.getItem("baseUrl"),
      parseAppId: localStorage.getItem("parseAppId"),
    };
  }

  async dropDownList() {
    try {
      let url = `${this.state.parseBaseUrl}classes/${this.props.data[0].class}?keys=${this.props.data[0].query}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": this.state.parseAppId,
      };
      await axios.get(url, { headers: headers }).then((response) => {
        let _list = [];
        let _arrayList = this.props.data[0].query.split(".");
        if (_arrayList.length === 1) {
          for (var t in response.data.results) {
            let opt = {
              value: response.data.results[t][_arrayList[0]],
              name: response.data.results[t][_arrayList[0]],
            };
            _list.push(opt);
          }
        } else if (_arrayList.length === 2) {
          for (var t in response.data.results) {
            if (this.props.data[0].keyType !== undefined) {
              let opt = {
                value: response.data.results[t].objectId,
                name: response.data.results[t][_arrayList[0]][_arrayList[1]],
              };
              _list.push(opt);
            } else {
              let opt = {
                value: response.data.results[t][_arrayList[0]][_arrayList[1]],
                name: response.data.results[t][_arrayList[0]][_arrayList[1]],
              };
              _list.push(opt);
            }
            //  _list.push(response.data.results[t][_arrayList[0]][_arrayList[1]])
          }
        }
        this.setState({
          Options: _list,
        });
      });
      /* Parse.serverURL = parseBaseUrl;
            Parse.initialize(parseAppId);
            var list = Parse.Object.extend(this.props.data[0].class);
            var query = new Parse.Query(list);
            const result = await query.find();
            var tempData = [];
            for (var t in result) {
                tempData.push(result[t].toJSON())
            }
            let _list = []
            let _dl = this.props.data[0].query
            for (var l in tempData) {
                _list.push(tempData[l][_dl])
            } */
    } catch (e) {}
  }

  componentDidMount() {
    this.dropDownList();
  }
  render() {

    return (
      <React.Fragment>
        {this.state.Options.map((val, i) => (
          <option key={i} value={val["value"]}>
            {val["name"]}
          </option>
        ))}
      </React.Fragment>
    );
  }
}

export default Options;
