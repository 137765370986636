import React, { useState } from "react";
import Parse from "parse";
import { Redirect } from "react-router-dom";

function ChangePassword() {

  const [currentpassword, setCurrentPassword] = useState("");
  const [newpassword, setnewpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      if (newpassword === confirmpassword) {
        Parse.serverURL = localStorage.getItem("baseUrl");
        Parse.initialize(localStorage.getItem("parseAppId"));
        Parse.User.logIn(localStorage.getItem("userEmail"), currentpassword)
          .then(async (user) => {
            if (user) {
              const User = new Parse.User();
              const query = new Parse.Query(User);
              await query.get(user.id).then((user) => {
                // Updates the data we want
                user.set("password", newpassword);
                user
                  .save()
                  .then((response) => {
                    alert("Password updated successfully.");
                  })
                  .catch((error) => {
                    alert("Something went wrong.");
                  });
              });
            } else {
              alert("Your current password is missing or incorrect.");
            }

            console.log("Logged in user", user);
          })
          .catch((error) => {
            alert("Your current password is missing or incorrect.");
            console.error("Error while logging in user", error);
          });
      } else {
        alert("Your password and confirmation password do not match.");
      }
    } catch (error) {}
  };
  if (localStorage.getItem("accesstoken") === null) {
    return <Redirect to="/" />;
  }
  return (
    <div className="card card-box">
      <div className="card-head">
        <header>Change Password</header>
      </div>
      <div className="card-body " id="bar-parent">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label for="simpleFormEmail">Current Password</label>
            <input
              type="password"
              className="form-control"
              name="currentpassword"
              value={currentpassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Current Password"
              required
            />
          </div>
          <div className="form-group">
            <label for="simpleFormPassword">New Password</label>
            <input
              type="password"
              className="form-control"
              name="newpassword"
              value={newpassword}
              onChange={(e) => setnewpassword(e.target.value)}
              placeholder="New Password"
              required
            />
          </div>
          <div className="form-group">
            <label for="simpleFormPassword">Confirm Password</label>
            <input
              type="password"
              className="form-control"
              name="confirmpassword"
              value={confirmpassword}
              onChange={(e) => setconfirmpassword(e.target.value)}
              placeholder="Confirm Password"
              required
            />
          </div>

          <button type="submit" className="btn btn-primary">
            Change Password
          </button>
        </form>
      </div>
    </div>
  );
}

export default ChangePassword;
