import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";

const AutoIncrementValue = (props) => {
  const [state, setState] = useState({
    parseBaseUrl: localStorage.getItem("baseUrl"),
    parseAppId: localStorage.getItem("parseAppId"),
    count: "",
  });
  const [newParent, setNewParent] = useState("");
  const [propsOnchange, setPropsOnchange] = useState("");

  const AutoIncrement = async (id) => {
    try {
      if (props.schema.parent) {
        if (id) {
          let reg = /(\#.*?\#)/gi;
          let _query = props.schema.data.query || "";
          let output = _query.match(reg);
          if (output.length === 2) {
            let res;
            if (localStorage.getItem("Extand_Class")) {
              let data = JSON.parse(localStorage.getItem("Extand_Class"));
              res = data[0];
            }
            output = output.filter((x) => x !== "#queryString#");
            if (output.length === 1) {
              _query = _query.replace("#queryString#", id);
              setNewParent(id);
              output = output.join();
              output = output.substring(1, output.length - 1);
              output = output.split(".");
              if (output.length > 0) {
                _query = _query.replace(reg, res[output[0]][output[1]]);
              } else {
                _query = _query.replace(reg, res[output[0]]);
              }
            }
          } else {
            _query = props.schema.data.query.replace(reg, id);
          }
          let url = `${state.parseBaseUrl}functions/intIncrement`;
          const headers = {
            "Content-Type": "application/json",
            "X-Parse-Application-Id": state.parseAppId,
          };
          let body = {
            className: props.schema.data.class,
            fieldName: props.schema.data.fieldName,
            query: _query,
          };

          await axios.post(url, body, { headers: headers }).then((res) => {
            setState({
              ...state,
              [props.schema.data.fieldName]: res.data.result,
            });
          });
        }
      } else {
        let url = `${state.parseBaseUrl}functions/intIncrement`;
        const headers = {
          "Content-Type": "application/json",
          "X-Parse-Application-Id": state.parseAppId,
        };

        let body = {
          className: props.schema.data.class,
          fieldName: props.schema.data.fieldName,
        };

        await axios.post(url, body, { headers: headers }).then((res) => {
          setState({
            ...state,
            [props.schema.data.fieldName]: res.data.result,
          });
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (props.formData) {
      setState({ ...state, [props.schema.data.fieldName]: props.formData });
    }

    // eslint-disable-next-line
  }, [props.formData]);
  useEffect(() => {
    if (!props.formData) {
      AutoIncrement("");
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      props.Level1_Dropdown[props.schema.parent] &&
      props.Level1_Dropdown[props.schema.parent] !== "Select" &&
      propsOnchange &&
      props.Level1_Dropdown[props.schema.parent] !== propsOnchange
    ) {
      AutoIncrement(props.Level1_Dropdown[props.schema.parent]);
    } else if (props.formData) {
      setPropsOnchange(props.Level1_Dropdown[props.schema.parent]);
    } else {
      setPropsOnchange("queryString");
    }

    // eslint-disable-next-line
  }, [props.Level1_Dropdown[props.schema.parent]]);

  useEffect(() => {
    if (
      props.Level2_Dropdown[props.schema.parent] &&
      props.Level2_Dropdown[props.schema.parent] !== "Select" &&
      propsOnchange &&
      props.Level2_Dropdown[props.schema.parent] !== propsOnchange
    ) {
      AutoIncrement(props.Level2_Dropdown[props.schema.parent]);
    } else if (props.formData) {
      setPropsOnchange(props.Level2_Dropdown[props.schema.parent]);
    } else {
      setPropsOnchange("queryString");
    }
    // eslint-disable-next-line
  }, [props.Level2_Dropdown[props.schema.parent]]);

  useEffect(() => {
    if (
      props.Level3_Dropdown[props.schema.parent] &&
      props.Level3_Dropdown[props.schema.parent] !== "Select" &&
      propsOnchange &&
      props.Level3_Dropdown[props.schema.parent] !== propsOnchange
    ) {
      AutoIncrement(props.Level3_Dropdown[props.schema.parent]);
    } else if (props.formData) {
      setPropsOnchange(props.Level3_Dropdown[props.schema.parent]);
    } else {
      setPropsOnchange("queryString");
    }

    // eslint-disable-next-line
  }, [props.Level3_Dropdown[props.schema.parent]]);

  if (props) {
    return (
      <>
        <label htmlFor={props.schema.title}>{props.schema.title}</label>
        <input
          type="text"
          name={props.name}
          className="form-control"
          onChange={props.onChange(state[props.schema.data.fieldName])}
          value={state[props.schema.data.fieldName]}
          readOnly="readonly"
        />
      </>
    );
  }
  return null;
};
const mapStateToProps = (state) => {
  return {
    Level1_Dropdown: state.Level1_Dropdown,
    Level2_Dropdown: state.Level2_Dropdown,
    Level3_Dropdown: state.Level3_Dropdown,
  };
};
export default connect(mapStateToProps, null)(AutoIncrementValue);
