import React, { useState, useEffect, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import "./image.css";
import axios from "axios";
import parse from "html-react-parser";
import ImgModal from "./ImgModal";
import { SaveFileSize } from "components/SaveFileSize/saveFileSize";
import Parse from 'parse'
const $ = window.$;

const ImageUpload = (props) => {
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const [imageUpload, setimageUpload] = useState("");
  const [imgload, setimgload] = useState(false);
  const [percentage, setpercentage] = useState(1);
  const [ImgCropDisplay, setImgCropDisplay] = useState(false);
  const [enableWebCam] = useState(
    props.schema.enableWebcam ? props.schema.enableWebcam : false
  );
  const [view, setView] = useState(false);
  const [ImgCropModal, setImgCropModal] = useState(false);
  const [ImgCropURL, setImgCropURL] = useState("");
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(
    (e) => {
      e.preventDefault();
      const imageSrc = webcamRef.current.getScreenshot();
      let files = base64StringtoFile(
        imageSrc,
        props.schema.filetypes && props.schema.filetypes.length > 0
          ? `${new Date().toISOString()}.${props.schema.filetypes[0]}`
          : `${new Date().toISOString()}.jpeg`
      );
      if (props.schema.filetypes && props.schema.filetypes.length > 0) {
        var fileName = files.name;
        var fileNameExt = fileName
          .substr(fileName.lastIndexOf(".") + 1)
          .toLowerCase();
        let Extensions = props.schema.filetypes.map((x) => x.toLowerCase());
        let arr = Extensions.filter((x) => x === fileNameExt);
        if (arr.length > 0) {
        } else {
          alert(
            "Only these file types are accepted : " + Extensions.join(", ")
          );
          $("#hashfile").val("");
          return;
        }
      }
      if (props.schema.fileSize) {
        if (Math.round(Number(files.size) / 1024) > props.schema.fileSize) {
          alert(
            `The selected file size is too large. Please select a file less than ${Math.round(
              props.schema.fileSize / 1024
            )} MB`
          );
          $("#hashfile").val("");
          return;
        }
      }
      if (props.schema.exactSize) {
        var reader = new FileReader();
        reader.readAsDataURL(files);
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (
              props.schema.exactSize.height &&
              props.schema.exactSize.width &&
              height === Number(props.schema.exactSize.height) &&
              width === Number(props.schema.exactSize.width)
            ) {
              fileUpload(files);
              setView(false);
              $("#WebCam").hide();
            } else {
              if (
                props.schema.exactSize.width &&
                props.schema.exactSize.height
              ) {
                setImgCropModal(true);
                setImgCropDisplay(true);
                setImgCropURL(files);
              } else {
                alert("Set exactsize property of components.");
                $("#hashfile").val("");
              }
              setView(false);
              $("#WebCam").hide();
              return;
            }
          };
        };
      } else if (props.schema.width) {
        var reader1 = new FileReader();
        reader1.readAsDataURL(files);
        reader1.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var width = this.width;
            if (props.schema.width && width === Number(props.schema.width)) {
              fileUpload(files);
              setView(false);
              $("#WebCam").hide();
            } else {
              alert(
                `The exact size for the width setting is ${props.schema.width}.`
              );
              setView(false);
              $("#WebCam").hide();
              return;
            }
          };
        };
      } else {
        fileUpload(files);
        setView(false);
        $("#WebCam").hide();
        return;
      }
      //  fileUpload(url);
    },
    [webcamRef]
  );

  const onChange = (e) => {
    try {
      let files = e.target.files;
      if (window.app) {
        window.app.getFileConfig(
          `{"fileSize": ${props.schema.fileSize},"exactSize": {"width": ${props.schema.exactSize.width},"height": ${props.schema.exactSize.height}},"width": ${props.schema.width}}`
        );
      }
      if (typeof files[0] !== "undefined") {
        if (props.schema.filetypes && props.schema.filetypes.length > 0) {
          var fileName = files[0].name;
          var fileNameExt = fileName
            .substr(fileName.lastIndexOf(".") + 1)
            .toLowerCase();
          let Extensions = props.schema.filetypes.map((x) => x.toLowerCase());
          let arr = Extensions.filter((x) => x === fileNameExt);
          if (arr.length > 0) {
          } else {
            alert(
              "Only these file types are accepted : " + Extensions.join(", ")
            );
            $("#hashfile").val("");
            return;
          }
        }
        if (props.schema.fileSize) {
          if (
            Math.round(Number(files[0].size) / 1024) > props.schema.fileSize
          ) {
            alert(
              `The selected file size is too large. Please select a file less than ${Math.round(
                props.schema.fileSize / 1024
              )} MB`
            );
            $("#hashfile").val("");
            return;
          }
        }
        if (
          props.schema.exactSize &&
          props.schema.exactSize.height &&
          props.schema.exactSize.width
        ) {
          var reader = new FileReader();
          reader.readAsDataURL(files[0]);
          reader.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
              var height = this.height;
              var width = this.width;
              if (
                props.schema.exactSize.height &&
                props.schema.exactSize.width &&
                height === Number(props.schema.exactSize.height) &&
                width === Number(props.schema.exactSize.width)
              ) {
                fileUpload(files[0]);
              } else {
                if (
                  props.schema.exactSize.width &&
                  props.schema.exactSize.height
                ) {
                  setImgCropModal(true);
                  setImgCropDisplay(true);
                  setImgCropURL(files[0]);
                } else {
                  alert("Set exactsize property of components.");
                  $("#hashfile").val("");
                }
                return true;
              }
            };
          };
        } else if (props.schema.width) {
          var reader1 = new FileReader();
          reader1.readAsDataURL(files[0]);
          reader1.onload = function (e) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
              var width = this.width;
              if (props.schema.width && width === Number(props.schema.width)) {
                fileUpload(files[0]);
              } else {
                alert(
                  `The exact size for the width setting is ${props.schema.width}.`
                );
                return;
              }
            };
          };
        } else {
          fileUpload(files[0]);
          return;
        }
      } else {
        alert("Please select file.");
        $("#hashfile").val("");
        return false;
      }
    } catch (error) {
      alert(`${error.message}`);
      $("#hashfile").val("");
    }
  };

  //function for upload image or file
  const fileUpload = async (file) => {
    if (file) {
      setimgload(true);
      const tenant = localStorage.getItem("TenetId");
      // console.log("parseBaseUrl ", parseBaseUrl);
      const size = file.size;
      // console.log("size", size, file);
      const value = parseBaseUrl.match(/server2/g);
      let image_url;
      if (value !== null) {
        image_url = parseBaseUrl.replace("/app", "");

        const url = `${image_url}image_upload`;
        const formData = new FormData();
        formData.append("file", file);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            "X-Parse-Application-Id": parseAppId,
            tenantId: tenant && tenant,
          },
          onUploadProgress: function (progressEvent) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setpercentage(percentCompleted);
          },
        };
        try {
          const response = await axios
            .post(url, formData, config)
            .then((res) => {
              if (res.data.status === "Error") {
                alert(res.data.message);
              }
              setpercentage(100);

              setTimeout(() => {
                setimageUpload(res.data.imageUrl);
                if (imageUpload) {
                  setpercentage(0);
                }
              }, 1000);
              setimgload(false);
              props.onChange(res.data.imageUrl);
              setImgCropDisplay(false);
              setImgCropModal(false);
              setImgCropURL(undefined);
              setView(false);
              $("#WebCam").hide();
              return res;
            })
            .catch((error) => {
              console.log(error);
              alert(`${error.message}`);
              setpercentage(0);
              setimgload(false);
              setImgCropDisplay(false);
              setImgCropModal(false);
              setImgCropURL(undefined);
            });
          //after getting successful response save image's size on client db class "partners_TenantCredits".
          if (response.data.status === "Success") {
            SaveFileSize(size, response.data.imageUrl, value, tenant);
          }
        } catch (error) {
          alert(error.message);
          setpercentage(0);
          setimgload(false);
          setImgCropDisplay(false);
          setImgCropModal(false);
          setImgCropURL(undefined);
        }
      } else {
        image_url = "https://root.qik.ai/";
        handleFileUpload(file, value);
      }
    }
  };

  const handleFileUpload = async (file, url) => {
    Parse.serverURL = parseBaseUrl;
    Parse.initialize(parseAppId);
    const size = file.size;

    const tenant = localStorage.getItem("TenetId");
    const pdfFile = file;
    const parseFile = new Parse.File(pdfFile.name, pdfFile);

    try {
      const response = await parseFile.save();
      // // The response object will contain information about the uploaded file
      // console.log("File uploaded:", response);

      // You can access the URL of the uploaded file using response.url()
      // console.log("File URL:", response.url());
      if (response.url()) {
        setTimeout(() => {
          setimageUpload(response.url());
          if (imageUpload) {
            setpercentage(0);
          }
        }, 1000);
        setimgload(false);
        props.onChange(response.url());
        setImgCropDisplay(false);
        setImgCropModal(false);
        setImgCropURL(undefined);
        setView(false);
        $("#WebCam").hide();
        SaveFileSize(size, response.url(), url, tenant);

        return response.url();
      }
    } catch (error) {
      alert(error);
      setpercentage(0);
      setimgload(false);
      setImgCropDisplay(false);
      setImgCropModal(false);
      setImgCropURL(undefined);
      console.error("Error uploading file:", error);
    }
  };

  function base64StringtoFile(base64String, filename) {
    var arr = base64String.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const videoConstraints = {
    width: "100%",
    height: "auto",
    facingMode: "user",
  };

  useEffect(() => {
    if (!props.formData) {
      setimageUpload([]);
      $("#hashfile").val("");
      props.onChange(undefined);
    }

    // eslint-disable-next-line
  }, [props.formData]);

  const REQUIRED_FIELD_SYMBOL = "*";

  let imageView =
    props.formData && props.formData ? (
      <a
        onClick={(e) => {
          e.preventDefault();
          document.getElementById("hasimage").src = props.formData;
          $("#ImageModal").show();
        }}
      >
        <img src={props.formData} className="" alt="" height="30px" />
      </a>
    ) : imageUpload === "" ? null : (
      <React.Fragment>
        <a
          onClick={(e) => {
            e.preventDefault();
            document.getElementById("hasimage").src = imageUpload;
            $("#ImageModal").show();
          }}
        >
          <img src={imageUpload} className="" alt="" height="30px" />
        </a>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <div style={{ display: "inline-block", width: `${imgload && "100%"}` }}>
        <label htmlFor={props.name}>
          {props.schema.title}
          {props.required && (
            <span className="required">{REQUIRED_FIELD_SYMBOL}</span>
          )}
          {imgload && (
            <div className="progress pull-right">
              <div
                className="progress__bar"
                style={{ width: `${percentage}%` }}
              ></div>
              <span className="progress__value">{percentage}%</span>
            </div>
          )}
          {props.schema.helpbody ? (
            <div className="dropdown pull-right">
              <i
                className="far fa-question-circle dropdown-toggle hovereffect"
                aria-hidden="true"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  fontSize: "12px",
                  color: "purple",
                  cursor: "pointer !important",
                  position: "relative",
                  bottom: "0px",
                  left: "0px",
                  paddingBottom: "4px",
                  paddingLeft: "4px",
                }}
              ></i>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
                style={{
                  marginleft: "-121px",
                  margintop: "-14px",
                  position: "absolute",
                  padding: "10px",
                  top: "102px!important",
                }}
              >
                {parse(`
             ${props.schema.helpbody}
           `)}
                <br />
                {props.schema.helplink ? (
                  <a
                    href={props.schema.helplink}
                    target="_blank"
                    className="btn btn-xs btn-primary"
                  >
                    Read more..
                  </a>
                ) : null}
              </div>
            </div>
          ) : null}
        </label>
      </div>

      <div className="input-group mb-5">
        <input
          type="file"
          id="hashfile"
          className="form-control"
          accept="image/*;capture=camera"
          onChange={onChange}
        />
        {enableWebCam && (
          <a
            onClick={(e) => {
              e.preventDefault();
              setView(true);
              $("#WebCam").show();
            }}
            style={{
              padding: "5px",
              backgroundColor: "#e9ecef",
              border: "1px solid #e9ecef",
            }}
            title="Webcam"
          >
            <i
              className="fas fa-camera"
              style={{ color: "blue", fontWeight: "bold", fontSize: "15px" }}
            ></i>
          </a>
        )}

        <div className="input-group-append">
          <span className="input-group-text" height="45px">
            {imageView}
          </span>
        </div>
      </div>

      {ImgCropDisplay && (
        <ImgModal
          CloseOpenModal={ImgCropModal}
          ImgPath={ImgCropURL}
          Height={Number(props.schema.exactSize.height)}
          Width={Number(props.schema.exactSize.width)}
          fileUpload={(file) => fileUpload(file)}
        />
      )}

      <div className="modal" id="ImageModal" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-body" width="80%">
              <button
                className="close pull-right"
                style={{ marginTop: "0px", width: "auto" }}
                onClick={(e) => {
                  e.preventDefault();
                  $("#ImageModal").hide();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <br />
              <div style={{ overflow: "auto", textAlign: "center" }}>
                <img
                  id="hasimage"
                  className=""
                  alt=""
                  style={{ maxHeight: "300px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="WebCam" tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <div
              className="modal-body"
              width="80%"
              style={{ overflow: "auto" }}
            >
              {view && (
                <>
                  <Webcam
                    audio={false}
                    height={"auto"}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={"100%"}
                    videoConstraints={videoConstraints}
                  />
                  <div style={{ textAlign: "center" }}>
                    <button
                      style={{
                        width: "auto",
                        backgroundColor: "blue",
                        padding: "5px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        border: "1px solid blue",
                        borderRadius: "3px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                      onClick={capture}
                    >
                      Capture photo
                    </button>{" "}
                    <button
                      style={{
                        width: "auto",
                        backgroundColor: "red",
                        padding: "5px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        border: "1px solid red",
                        borderRadius: "3px",
                        fontWeight: "bold",
                        color: "white",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setView((pre) => (pre = false));
                        $("#WebCam").hide();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ImageUpload;
