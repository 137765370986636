import React, { Component } from "react";
import Parse from "parse";
import "../components/followup.css";
export class Followup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewArray: [],
      _fdArr: [],
      _viewData: {},
      feedback: "",
      parseBaseUrl: localStorage.getItem("baseUrl"),
      parseAppId: localStorage.getItem("parseAppId")
    };
  }

  async getData() {
    try {
      Parse.serverURL = this.state.parseBaseUrl;
      Parse.initialize(this.state.parseAppId);
      var form = Parse.Object.extend(this.props.match.params.cls);
      var query = new Parse.Query(form);
      query.equalTo("objectId", this.props.match.params.id);
      const results = await query.first();
      var jsonform = results.toJSON();
      this.setState({
        viewArray: Object.keys(jsonform),
        _viewData: jsonform
      });
      if (jsonform.FollowupDetails !== undefined) {
        this.setState({
          _fdArr: jsonform.FollowupDetails
        });
      }
    } catch (error) {}
  }

  feedbackHandle = e => {
    this.setState({ feedback: e.target.value });
  };

  async submithandle(id) {
    Parse.serverURL = this.state.parseBaseUrl;
    Parse.initialize(this.state.parseAppId);
    var _kList = Parse.Object.extend(this.props.match.params.cls);
    const query = new Parse.Query(_kList);
    query.equalTo("objectId", id);
    const results = await query.first();
    const resultjson = results.toJSON();
    let tempArr = [];
    if (resultjson.FollowupDetails !== undefined) {
      tempArr = resultjson.FollowupDetails;
    }
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let d = {
      message: this.state.feedback,
      date: date,
      nxtInformDate: this.state.nxtInformDate
    };
    this.setState({
      _fdArr: [d, ...this.state._fdArr],
      feedback: "",
      nxtInformDate: ""
    });
    tempArr.push(...tempArr, d);
    var list = Parse.Object.extend(this.props.match.params.cls);
    const query1 = new Parse.Query(list);
    query1.get(id).then(object => {
      object.set("FollowupDetails", this.state._fdArr);
      object.save().then(
        response => {
          if (typeof document !== "undefined")
          alert("Status updated successfully...");
          this.setState({
            feedback: "",
            nxtInformDate: ""
          });
        },
        error => {
          if (typeof document !== "undefined")
            console.error("Error while updating ", error);
        }
      );
    });
  }

  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="card-box">
            <div className="card-head">
              <header>Followup</header>
            </div>
            <div className="card-body row">
              <div className="table-responsive col-md-6">
                <table
                  className="table table-bordered"
                  style={{ width: "100%" }}
                >
                  <tbody>
                    {this.state.viewArray.map(val => {
                      return val === "class" ? null : val ===
                        "createdAt" ? null : val ===
                        "updatedAt" ? null : val === "objectId" ? null : val ===
                        "CreatedBy" ? null : val === "Course" ? null : val === "Admin" ? null:val === "BranchId" ? null: val ===
                        "FollowupDetails" ? null : val ===
                        "mainformObjId" ? null : val ===
                        "FollowupDetails" ? null :val ===
                        "AssignToUser" ? null : val === "Photo" ? (
                        <tr key={val}>
                          <th>{val}</th>
                          <td>
                            <img
                              src={this.state._viewData[val]}
                              height="150px"
                              alt="ss"
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr key={val}>
                          <th>{val}</th>
                          <td>{this.state._viewData[val]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="col-md-6 ">
                <div>
                  <h5>
                    {" "}
                    <i className="fa fa-bars" aria-hidden="true"></i> Feedback
                  </h5>
                </div>

                <ul
                  className="list-group"
                  style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: "400px"
                  }}
                >
                  {this.state._fdArr == null
                    ? null
                    : this.state._fdArr.map((m, i) => {
                        return (
                          <li
                            key={i}
                            className="list-group-item justify-content-between"
                          >
                            <div>
                              {m.message}
                              <br />
                              <br />
                              <div>
                                <div className="div1">
                                  Submited Date:{" "}
                                  <span className="badge badge-info badge-pill">
                                    {m.date}
                                  </span>
                                </div>
                                <div className="div2">
                                Next Inform Date:{" "}
                                  <span className="badge badge-success badge-pill">
                                    {m.nxtInformDate}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                </ul>
                <br />

                <div className="form-group">
                  <textarea
                    value={this.state.feedback}
                    onChange={this.feedbackHandle}
                    placeholder="write a comment..."
                    className="form-control"
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="nxtInformDate">Next Inform Date</label>
                  <input
                    type="date"
                    name="nxtInformDate"
                    className="form-control"
                    value={this.state.nxtInformDate}
                    onChange={e => {
                      this.setState({ [e.target.name]: e.target.value });
                    }}
                  />
                </div>
                <button
                  type="button"
                  onClick={() =>
                    this.submithandle(this.state._viewData["objectId"])
                  }
                  className="btn btn-danger"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Followup;
