import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import uniqid from "uniqid";
import Swal from "sweetalert2";
import "./../Spinner/loader.css";
import "./action.css";
import "./toast.css";
import Parse from "parse";
import axios from "axios";
import { addItemsToCart, removeFromCart } from "../actions";

//  const $ = window.$;

const ActionView = (props) => {
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const [isloading, setisloading] = useState(false);
  const [isloadingMicro, setisloadingMicro] = useState({});
  const [isUpdateColumn, setisUpdateColumn] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [toastColor, settoastColor] = useState("#5cb85c");
  const [deleteToast, setDeleteToast] = useState("");
  const [softDel, setSoftDel] = useState("");
  const [delUser, setDelUser] = useState("");
  const [rowLevel, setRowLevel] = useState("");
  const [rowlevelMicro, setRowLevelMicro] = useState("");
  const [microUrl, setMicroUrl] = useState("");
  const [ObjectMicro, setObjectMicro] = useState("");
  const [cardLoader, setCardLoader] = useState({});
  const [cartDelete, setCartDelete] = useState({});
  const [isAppRequest] = useState(
    props.isAppRequest ? props.isAppRequest : false
  );
  const [toastDescription, settoastDescription] = useState("");
  const CartData = useSelector((state) => state.CartData);
  const dispatch = useDispatch();

  const ChangeStatus = async (
    cls,
    objectId,
    x,
    field,
    msg,
    Value,
    obj,
    action
  ) => {
    if (action.remarkField) {
      const { value: text } = await Swal.fire({
        input: "textarea",
        inputLabel: action.remarkLabel ? action.remarkLabel : "Remark",
        inputPlaceholder: "Type your remark here...",
        inputAttributes: {
          "aria-label": "Type your remark here",
        },
        confirmButtonText: "Submit",
        confirmButtonColor: "blue",
        showCancelButton: true,
      });

      if (text) {
        if (window.confirm(`${msg}`)) {
          Parse.serverURL = parseBaseUrl;
          Parse.initialize(parseAppId);
          var changed = Parse.Object.extend(cls);
          var query = new Parse.Query(changed);
          query.get(objectId).then((obj) => {
            let _val;
            if (typeof Value[field] === "boolean") {
              if (x === "true") {
                _val = true;
              } else if (x === "false") {
                _val = false;
              } else {
                _val = x;
              }
            } else if (typeof Value[field] === "number") {
              _val = Number(x);
            } else {
              _val = x;
            }
            obj.set(field, _val);
            obj.set(action.remarkField, text);
            obj.save().then((response) => {
              setisUpdateColumn({
                ...isUpdateColumn,
                [`${objectId}_`]: false,
              });

              try {
                alert(`Record updated successfully`);
                props.GetMethod(props.PropsData);
              } catch (error) {
                setisUpdateColumn({
                  ...isUpdateColumn,
                  [`${objectId}_`]: false,
                });
              }
            });
          });
        }
        // Swal.fire(text);
      }
    } else {
      try {
        if (window.confirm(`${msg}`)) {
          setisUpdateColumn({ ...isUpdateColumn, [`${objectId}_`]: true });
          if (obj.functionName) {
            try {
              let url = `${parseBaseUrl}functions/${obj.functionName}`;
              const headers = {
                "Content-Type": "application/json",
                "X-Parse-Application-Id": parseAppId,
                sessionToken: localStorage.getItem("accesstoken"),
              };
              let objData = {
                formData: Value,
              };
              await axios
                .post(url, objData, { headers: headers })
                .then((response) => {
                  if (response) {
                    setisUpdateColumn({
                      ...isUpdateColumn,
                      [`${objectId}_`]: false,
                    });

                    try {
                      if (response.data.result) {
                        try {
                          alert(response.data.result.message);
                          props.GetMethod(props.PropsData);
                        } catch (error) {}
                      } else {
                        try {
                          settoastColor("#5cb85c");
                          settoastDescription(`Record deleted successfully`);
                          var xy = document.getElementById("snackbar");
                          xy.className = "show";
                          setTimeout(function () {
                            xy.className = xy.className.replace("show", "");
                            props.GetMethod(props.PropsData);
                          }, 5000);
                          setisloading(false);
                        } catch (error) {}
                      }
                    } catch (error) {
                      setisloading(false);
                      alert(`${error.message}`);
                    }
                  }
                })
                .catch((err) => {
                  alert(err.response.data.error.message);
                  setisloading(false);
                  setisUpdateColumn({
                    ...isUpdateColumn,
                    [`${objectId}_`]: false,
                  });
                });
            } catch (error) {
              setisloading(false);
              setisUpdateColumn({ ...isUpdateColumn, [`${objectId}_`]: false });
            }
          } else {
            Parse.serverURL = parseBaseUrl;
            Parse.initialize(parseAppId);
            var changed = Parse.Object.extend(cls);
            var query = new Parse.Query(changed);
            query.get(objectId).then((obj) => {
              let _val;
              if (typeof Value[field] === "boolean") {
                if (x === "true") {
                  _val = true;
                } else if (x === "false") {
                  _val = false;
                } else {
                  _val = x;
                }
              } else if (typeof Value[field] === "number") {
                _val = Number(x);
              } else {
                _val = x;
              }
              obj.set(field, _val);
              obj.save().then((response) => {
                setisUpdateColumn({
                  ...isUpdateColumn,
                  [`${objectId}_`]: false,
                });

                try {
                  alert(`Record updated successfully`);
                  props.GetMethod(props.PropsData);
                } catch (error) {
                  setisUpdateColumn({
                    ...isUpdateColumn,
                    [`${objectId}_`]: false,
                  });
                }
              });
            });
          }
        }
      } catch (error) {
        setisUpdateColumn({ ...isUpdateColumn, [`${objectId}_`]: false });
      }
    }
  };
  const UpdateColumnValue = (action, objectId, css, Value) => {
    // let data = Object.keys(action.values);
    let buttons = action.values.map((x) => {
      if (
        Value[action.fieldName] !== undefined ||
        Value[action.fieldName] !== ""
      ) {
        if (Value[action.fieldName] === x.value) {
          return null;
        }
      }
      return (
        <React.Fragment key={uniqid()}>
          <button
            onClick={() => {
              ChangeStatus(
                action.class,
                objectId,
                x.value,
                action.fieldName,
                x["confirmMessage"],
                Value,
                x,
                action
              );
            }}
            className={`${x["btnCss"]} ${css}`}
            style={{ margin: "4px" }}
            disabled={!isUpdateColumn[`${objectId}_`] ? x.disabled : true}
            title={x.label}
          >
            <i
              className={!isUpdateColumn[`${objectId}_`] ? x.icon : "loader-01"}
              aria-hidden="true"
            ></i>{" "}
            {x.label}
          </button>
          <div id="UpdateColumnValue" style={{ backgroundColor: toastColor }}>
            {toastDescription}
          </div>
        </React.Fragment>
      );
    });
    return buttons;
  };

  const customeFunction = async (functionName, Value, msg) => {
    if (window.confirm(`${msg ? msg : "Are you sure perform this action"}`)) {
      setisloading(true);
      try {
        let url = `${parseBaseUrl}functions/${functionName}`;
        const headers = {
          "Content-Type": "application/json",
          "X-Parse-Application-Id": parseAppId,
          sessionToken: localStorage.getItem("accesstoken"),
        };
        let obj = {
          formData: Value,
        };
        await axios
          .post(url, obj, { headers: headers })
          .then((response) => {
            setisloading(false);
            if (response) {
              try {
                if (response.data.result) {
                  try {
                    alert(response.data.result.message);
                    props.GetMethod(props.PropsData);
                  } catch (error) {}
                } else {
                  try {
                    settoastColor("#5cb85c");
                    settoastDescription(`Record deleted successfully`);
                    var x = document.getElementById("snackbar");
                    x.className = "show";
                    setTimeout(function () {
                      x.className = x.className.replace("show", "");
                      props.GetMethod(props.PropsData);
                    }, 5000);
                    setisloading(false);
                  } catch (error) {}
                }
              } catch (error) {
                setisloading(false);
                alert(`${error.message}`);
              }
            }
          })
          .catch((err) => {
            alert(err.response.data.error.message);
            setisloading(false);
          });
      } catch (error) {
        setisloading(false);
      }
    }
  };

  const DeleteUserById = async (val, objectId) => {
    if (window.confirm(`Are you sure you want to delete this user`)) {
      try {
        setIsActive(true);
        Parse.serverURL = parseBaseUrl;
        Parse.initialize(parseAppId);
        var deleted = Parse.Object.extend(val.data[0].class);
        var query = new Parse.Query(deleted);
        await query.get(objectId).then(
          async (x) => {
            let userDetails = x.toJSON();
            let url = `${parseBaseUrl}functions/DeleteUserFromRole`;
            const headers = {
              "Content-Type": "application/json",
              "X-Parse-Application-Id": parseAppId,
              sessionToken: localStorage.getItem("accesstoken"),
            };
            let body = {};
            if (userDetails.userId !== undefined) {
              body = {
                appName: localStorage.getItem("_appName"),
                roleName: userDetails.role,
                userId: userDetails.userId.objectId,
              };
            } else {
              body = {
                appName: localStorage.getItem("_appName"),
                roleName: userDetails.UserRole,
                userId: userDetails.UserId.objectId,
              };
            }
            await axios
              .post(url, body, { headers: headers })
              .then((res) => {
                x.destroy().then(
                  (response) => {
                    if (response) {
                      settoastColor("#5cb85c");
                      setDelUser(`Record deleted successfully`);
                    }
                  },
                  (error) => {
                    settoastColor("#d9534f");
                    setDelUser(`${error.message}`);
                  }
                );
              })
              .catch(async (error) => {
                try {
                  await x.destroy().then(
                    (response) => {
                      if (response) {
                        settoastColor("#5cb85c");
                        setDelUser(
                          "User did not exist in the role. Record deleted successfully."
                        );
                      }
                    },
                    (error) => {
                      settoastColor("#d9534f");
                      setDelUser(`${error.message}`);
                    }
                  );
                } catch (error) {
                  settoastColor("#d9534f");
                  setDelUser(`${error.message}`);
                }
              });
          },
          (error) => {
            settoastColor("#d9534f");
            setDelUser(`${error.message}`);
          }
        );
      } catch (error) {
        settoastColor("#d9534f");
        setDelUser(`${error.message}`);
      }
    }
  };

  const DeleteRecord = async (val, objectId) => {
    try {
      if (window.confirm(`Are you sure you want to delete this record`)) {
        try {
          setIsActive(true);
          Parse.serverURL = parseBaseUrl;
          Parse.initialize(parseAppId);
          var deleted = Parse.Object.extend(val.data[0].class);
          var query = new Parse.Query(deleted);
          await query.get(objectId).then(
            (del) => {
              del.destroy().then(
                (y) => {
                  if (y) {
                    let temp = props.Data.filter(
                      (x) => x.objectId !== objectId
                    );
                    setIsActive(false);
                    setTimeout(() => {
                      props.RefreshData(temp, props.CurrentPage);
                    }, 2000);
                    props.toastColor("#5cb85c", "Record deleted successfully");
                  }
                },
                (error) => {
                  //Failed
                  props.toastColor("#d9534f", error.message);
                }
              );
            },
            (error) => {
              // The object was not retrieved successfully.
              settoastColor("#d9534f");
              setDeleteToast(error.message);
            }
          );
        } catch (error) {
          settoastColor("#d9534f");
          setDeleteToast(error.message);
        }
      }
    } catch (error) {}
  };

  const onSoftDelete = async (val, objectId) => {
    if (window.confirm(`Are you sure you want to delete this record`)) {
      try {
        setIsActive(true);
        Parse.serverURL = parseBaseUrl;
        Parse.initialize(parseAppId);
        var deleted = Parse.Object.extend(val.data[0].class);
        var query = new Parse.Query(deleted);
        await query
          .get(objectId)
          .then((del) => {
            del.set("IsDeleted", true);
            del.save().then((res) => {
              if (res) {
                settoastColor("#5cb85c");
                setSoftDel(`Record deleted successfully`);
              }
            });
          })
          .catch((error) => {
            settoastColor("#d9534f");
            setSoftDel(`${error.message}`);
          });
      } catch (error) {
        settoastColor("#d9534f");
        setSoftDel(`${error.message}`);
      }
    }
  };

  const addCart = async (val) => {
    setCardLoader({ ...cardLoader, [val["objectId"]]: true });
    try {
      let role =
        `${localStorage.getItem("_appName")}_${localStorage.getItem(
          "_user_role"
        )}` || "";
      let appId = `${localStorage.getItem("_app_objectId")}` || "";
      let CartSettings;
      if (role && appId) {
        let url = `${parseBaseUrl}classes/w_CartSettings?where={"AppId":{"__type":"Pointer","className":"w_appinfo","objectId":"${appId}"},"UserRole":"${role}"}`;
        const headers = {
          "Content-Type": "application/json",
          "X-Parse-Application-Id": parseAppId,
          sessionToken: localStorage.getItem("accesstoken"),
        };
        await axios.get(url, { headers }).then((res) => {
          if (res) {
            if (res.data.results.length > 0) {
              CartSettings = res.data.results[0];
            }
          }
        });
      }
      if (CartSettings) {
        let obj = {
          objectId: val["objectId"],
          Icon: val[CartSettings["IconField"]],
          Rate: val[CartSettings["RateField"]],
          Discount: val[CartSettings["DiscountField"]],
          Title: val[CartSettings["TitleField"]],
          Quantity: 1,
          Amount: Number(val[CartSettings["RateField"]]) * 1,
        };
        try {
          let _cartDetails = [];
          let searchId = "";
          Parse.serverURL = parseBaseUrl;
          Parse.initialize(parseAppId);
          const currentUser = Parse.User.current();
          let url = `${parseBaseUrl}classes/w_Cart?where={"AppId":{"__type":"Pointer","className":"w_appinfo","objectId":"${appId}"},"UserId":{"__type":"Pointer","className":"_User","objectId":"${currentUser.id}"}}`;
          const headers = {
            "Content-Type": "application/json",
            "X-Parse-Application-Id": parseAppId,
            sessionToken: localStorage.getItem("accesstoken"),
          };
          await axios.get(url, { headers }).then((res) => {
            if (res) {
              if (res.data.results.length > 0) {
                _cartDetails = res.data.results[0].CartDetails;
                searchId = res.data.results[0].objectId;
              }
            }
          });
          if (_cartDetails.length > 0) {
            let obj1 = {
              CatalogId: val["objectId"],
              Quantity: 1,
            };
            let checkAlreadyAdded = _cartDetails.filter(
              (x) => x.CatalogId === val["objectId"]
            );
            if (checkAlreadyAdded.length > 0) {
              setCardLoader({ ...cardLoader, [val["objectId"]]: false });
              setCartDelete({ ...cartDelete, [val["objectId"]]: true });
            } else {
              _cartDetails.push(obj1);
              const MyCustomClass = Parse.Object.extend("w_Cart");
              const query = new Parse.Query(MyCustomClass);
              query.get(searchId).then((object) => {
                object.set("CartDetails", _cartDetails);
                object.save().then(
                  (response) => {
                    if (response) {
                      dispatch(addItemsToCart(obj));

                      setCardLoader({
                        ...cardLoader,
                        [val["objectId"]]: false,
                      });
                      setCartDelete({ ...cartDelete, [val["objectId"]]: true });
                    }
                  },
                  (error) => {
                    setCardLoader({ ...cardLoader, [val["objectId"]]: false });
                    setCartDelete({ ...cartDelete, [val["objectId"]]: true });
                    console.error("Error while updating ", error);
                  }
                );
              });
            }
          } else {
            let obj1 = {
              CatalogId: val["objectId"],
              Quantity: 1,
            };
            _cartDetails.push(obj1);
            Parse.serverURL = parseBaseUrl;
            Parse.initialize(parseAppId);
            const currentUser = Parse.User.current();
            const MyCustomClass = Parse.Object.extend("w_Cart");
            const myNewObject = new MyCustomClass();
            myNewObject.set("UserId", currentUser);
            myNewObject.set("CartDetails", _cartDetails);
            myNewObject.set("CatalogClass", CartSettings["CatalogClass"]);
            myNewObject.set("AppId", {
              __type: "Pointer",
              className: "w_appinfo",
              objectId: appId,
            });
            myNewObject
              .save()
              .then((res) => {
                dispatch(addItemsToCart(obj));
                setCardLoader({ ...cardLoader, [val["objectId"]]: false });
                setCartDelete({ ...cartDelete, [val["objectId"]]: true });
              })
              .catch((err) => {
                console.log(err);
                setCardLoader({ ...cardLoader, [val["objectId"]]: false });
                setCartDelete({ ...cartDelete, [val["objectId"]]: true });
              });
          }
        } catch (error) {
          setCardLoader({ ...cardLoader, [val["objectId"]]: false });
          setCartDelete({ ...cartDelete, [val["objectId"]]: true });
        }
      }
    } catch (error) {
      setCardLoader({ ...cardLoader, [val["objectId"]]: false });
      setCartDelete({ ...cartDelete, [val["objectId"]]: true });
    }
  };
  const removeCart = async (id) => {
    setCardLoader({ ...cardLoader, [id]: true });
    try {
      let appId = `${localStorage.getItem("_app_objectId")}` || "";
      let _cartDetails;
      let searchObjectId = "";
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      const currentUser = Parse.User.current();
      let url = `${parseBaseUrl}classes/w_Cart?where={"AppId":{"__type":"Pointer","className":"w_appinfo","objectId":"${appId}"},"UserId":{"__type":"Pointer","className":"_User","objectId":"${currentUser.id}"}}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": parseAppId,
        sessionToken: localStorage.getItem("accesstoken"),
      };
      await axios.get(url, { headers }).then((res) => {
        if (res) {
          if (res.data.results.length > 0) {
            _cartDetails = res.data.results[0].CartDetails;
            searchObjectId = res.data.results[0].objectId;
          } else {
            setCardLoader({ ...cardLoader, [id]: false });
            setCartDelete({ ...cartDelete, [id]: false });
          }
        }
      });
      if (_cartDetails && searchObjectId) {
        if (_cartDetails.length === 1) {
          const MyCustomClass = Parse.Object.extend("w_Cart");
          const query = new Parse.Query(MyCustomClass);
          query.get(searchObjectId).then((object) => {
            object.destroy().then(
              (response) => {
                dispatch(removeFromCart(id));
                setCardLoader({ ...cardLoader, [id]: false });
                setCartDelete({ ...cartDelete, [id]: false });
              },
              (error) => {
                console.error("Error while deleting ParseObject", error);
                setCardLoader({ ...cardLoader, [id]: false });
                setCartDelete({ ...cartDelete, [id]: false });
              }
            );
          });
        } else {
          let _tempArr = _cartDetails.filter((x) => x.CatalogId !== id);
          const MyCustomClass = Parse.Object.extend("w_Cart");
          const query = new Parse.Query(MyCustomClass);
          query.get(searchObjectId).then((object) => {
            object.set("CartDetails", _tempArr);
            object.save().then(
              (response) => {
                dispatch(removeFromCart(id));
                setCardLoader({ ...cardLoader, [id]: false });
                setCartDelete({ ...cartDelete, [id]: false });
              },
              (error) => {
                setCardLoader({ ...cardLoader, [id]: false });
                setCartDelete({ ...cartDelete, [id]: false });
              }
            );
          });
        }
      }
    } catch (error) {
      setCardLoader({ ...cardLoader, [id]: false });
      setCartDelete({ ...cartDelete, [id]: false });
    }
  };
  useEffect(() => {
    if (delUser) {
      let delU1 = document.getElementById("DeleteUser");
      delU1.className = "show";
      setTimeout(() => {
        delU1.className = delU1.className.replace("show", "");
        if (toastColor === "#5cb85c") {
          let temp = props.Data.filter((xm) => xm.objectId !== objectId);
          props.RefreshData(temp, props.CurrentPage);
        }
        setIsActive(false);
      }, 5000);
    }

    // eslint-disable-next-line
  }, [delUser]);

  useEffect(() => {
    if (softDel) {
      let x = document.getElementById("deleteObj");
      x.className = "show";
      setTimeout(function () {
        x.className = x.className.replace("show", "");
        if (toastColor === "#5cb85c") {
          let temp = props.Data.filter((x) => x.objectId !== objectId);
          props.RefreshData(temp, props.CurrentPage);
        }
        setIsActive(false);
      }, 2000);
    }
    // eslint-disable-next-line
  }, [softDel]);

  useEffect(() => {
    if (rowlevelMicro) {
      try {
        let data = {
          TenetId: localStorage.getItem("TenetId"),
          accesstoken: localStorage.getItem("accesstoken"),
          baseUrl: localStorage.getItem("baseUrl"),
          parseAppId: localStorage.getItem("parseAppId"),
          extended_class: localStorage.getItem("extended_class"),
          userSettings: localStorage.getItem("userSettings"),
          username: localStorage.getItem("username"),
          _appName: localStorage.getItem("_appName"),
          rowlevel: rowLevel,
          rowlevelMicro: rowlevelMicro,
        };
        let storage = JSON.stringify({
          key: "storage",
          method: "set",
          data: data,
        });
        var iframe = document.getElementById(microUrl);
        iframe.contentWindow.postMessage(storage, "*");
        setTimeout(() => {
          props.MicroUrl(microUrl);
          setisloadingMicro({
            ...isloadingMicro,
            [ObjectMicro]: false,
          });
          props.ShowHideMicroApp(true, microUrl);
        }, 5000);
      } catch (error) {}
    }
    // eslint-disable-next-line
  }, [rowLevel]);

  const { action, objectId, Length, Value } = props;
  let css = Length > 3 ? "dropdown-item" : "";

  const result = action.map((val, key) => {
    switch (val.actionType) {
      case "UpdateColumnValue":
        return (
          <React.Fragment key={uniqid()}>
            {UpdateColumnValue(val, objectId, css, Value)}
          </React.Fragment>
        );
      case "Update":
        return (
          <button
            key={uniqid()}
            className={`${val.btnCss} ${css}`}
            title={val.label ? val.label : ""}
            style={{ margin: "4px" }}
            onClick={() => {
              localStorage.setItem("upObj", objectId);
              window.location.hash = `/${val.data[0].formId}/${objectId}`;
            }}
          >
            <i className={val.icon} aria-hidden="true"></i>
            {val.label}
          </button>
        );
      case "View":
        return (
          <button
            key={uniqid()}
            title={val.label ? val.label : "View"}
            className={`${val.btnCss} ${css}`}
            style={{ margin: "4px" }}
            onClick={() => {
              isAppRequest
                ? props.history.push(
                    "/view_app/" + objectId + "/" + val.data[0].formId
                  )
                : props.history.push(
                    "/view/" + objectId + "/" + val.data[0].formId
                  );
            }}
          >
            <i className={val.icon} aria-hidden="true"></i>
            {val.label}
          </button>
        );
      case "Detail":
        return (
          <button
            key={uniqid()}
            title={val.label ? val.label : "View"}
            className={`${val.btnCss} ${css}`}
            style={{ margin: "4px" }}
            onClick={() => {
              isAppRequest
                ? props.history.push(
                    "/detail_app/" +
                      objectId +
                      "/" +
                      val.data[0].viewId +
                      "/" +
                      props.CurrentPage
                  )
                : props.history.push(
                    "/detail/" +
                      objectId +
                      "/" +
                      val.data[0].viewId +
                      "/" +
                      props.CurrentPage
                  );
            }}
          >
            <i className={val.icon} aria-hidden="true"></i>
            {val.label}
          </button>
        );
      case "_Edit":
        return (
          <button
            key={uniqid()}
            className={`${val.btnCss} ${css}`}
            title={val.label ? val.label : "Edit"}
            style={{ margin: "4px" }}
            onClick={() => {
              props.history.push(
                "/_edit/" + objectId + "/" + val.data[0].formId
              );
            }}
          >
            <i className={val.icon} aria-hidden="true"></i>
            {val.label}
          </button>
        );
      case "Edit":
        return (
          <button
            key={uniqid()}
            className={`${val.btnCss} ${css}`}
            title={val.label ? val.label : "Edit"}
            style={{ margin: "4px" }}
            onClick={() => {
              isAppRequest
                ? props.history.push(
                    "/edit_app/" +
                      objectId +
                      "/" +
                      val.data[0].formId +
                      "/" +
                      props.CurrentPage
                  )
                : props.history.push(
                    "/edit/" +
                      objectId +
                      "/" +
                      val.data[0].formId +
                      "/" +
                      props.CurrentPage
                  );
            }}
          >
            <i className={val.icon} aria-hidden="true"></i>
            {val.label}
          </button>
        );
      case "EditPointer":
        let _value = "";

        try {
          let str = val.data[0].pointerField.split(".");
          if (str.length > 1) {
            _value = Value[str[0]][str[1]];
          } else {
            _value = Value[val.key];
          }
        } catch (error) {
          console.log(error.message);
        }

        return (
          <button
            key={uniqid()}
            className={`${val.btnCss} ${css}`}
            title={val.label ? val.label : "Edit"}
            style={{ margin: "4px" }}
            onClick={() => {
              isAppRequest
                ? props.history.push(
                    "/edit_app/" +
                      _value +
                      "/" +
                      val.data[0].formId +
                      "/" +
                      props.CurrentPage
                  )
                : props.history.push(
                    "/edit/" +
                      _value +
                      "/" +
                      val.data[0].formId +
                      "/" +
                      props.CurrentPage
                  );
            }}
          >
            <i className={val.icon} aria-hidden="true"></i>
            {val.label}
          </button>
        );
      case "AddToCard":
        return (
          <button
            key={uniqid()}
            className={`${
              !cartDelete[Value["objectId"]]
                ? val.btnCss
                : "btn btn-danger btn-xs"
            } ${css}`}
            style={{ margin: "4px" }}
            disabled={cardLoader[Value["objectId"]]}
            onClick={() => {
              if (!cartDelete[Value["objectId"]]) {
                addCart(Value);
              } else {
                removeCart(Value["objectId"]);
              }
            }}
          >
            <i
              className={
                cardLoader && !cardLoader[Value["objectId"]]
                  ? !cartDelete[Value["objectId"]]
                    ? val.icon
                    : "fa fa-trash"
                  : "loader-01"
              }
              aria-hidden="true"
            ></i>{" "}
            {!cartDelete[Value["objectId"]] ? val.label : "Remove"}
          </button>
        );
      case "AddToCart":
        return (
          <button
            key={uniqid()}
            className={`${
              !cartDelete[Value["objectId"]]
                ? val.btnCss
                : "btn btn-danger btn-xs"
            } ${css}`}
            style={{ margin: "4px" }}
            disabled={cardLoader[Value["objectId"]]}
            onClick={() => {
              if (!cartDelete[Value["objectId"]]) {
                addCart(Value);
              } else {
                removeCart(Value["objectId"]);
              }
            }}
          >
            <i
              className={
                cardLoader && !cardLoader[Value["objectId"]]
                  ? !cartDelete[Value["objectId"]]
                    ? val.icon
                    : "fa fa-trash"
                  : "loader-01"
              }
              aria-hidden="true"
            ></i>{" "}
            {!cartDelete[Value["objectId"]] ? val.label : "Remove"}
          </button>
        );

      case "Delete":
        return (
          <React.Fragment key={uniqid()}>
            <button
              title={val.label ? val.label : "Delete"}
              className={`${val.btnCss} ${css}`}
              style={{ margin: "4px" }}
              disabled={isActive}
              onClick={() => DeleteRecord(val, objectId)}
            >
              <i
                className={!isActive ? val.icon : "loader-01"}
                aria-hidden="true"
              ></i>
              {val.label}
            </button>
            {deleteToast && (
              <div id="_DeleteRecord" style={{ backgroundColor: toastColor }}>
                {deleteToast}
              </div>
            )}
          </React.Fragment>
        );
      case "SoftDelete":
        return (
          <React.Fragment key={uniqid()}>
            <button
              title={val.label ? val.label : "Delete"}
              className={`${val.btnCss} ${css}`}
              style={{ margin: "4px" }}
              disabled={isActive}
              onClick={() => onSoftDelete(val, objectId)}
            >
              <i
                className={!isActive ? val.icon : "loader-01"}
                aria-hidden="true"
              ></i>
              {val.label}
            </button>
            {softDel && (
              <div id="deleteObj" style={{ backgroundColor: toastColor }}>
                {softDel}
              </div>
            )}
          </React.Fragment>
        );
      case "DeleteUser":
        return (
          <React.Fragment key={uniqid()}>
            <button
              title={val.label ? val.label : "Delete"}
              className={`${val.btnCss} ${css}`}
              style={{ margin: "4px" }}
              onClick={() => DeleteUserById(val, objectId)}
            >
              {" "}
              <i
                className={!isActive ? val.icon : "loader-01"}
                aria-hidden="true"
              ></i>
              {val.label}
            </button>
            {delUser && (
              <div id="DeleteUser" style={{ backgroundColor: toastColor }}>
                {delUser}
              </div>
            )}
          </React.Fragment>
        );
      case "Custome":
        return (
          <button
            key={uniqid()}
            className={`${val.btnCss} ${css}`}
            style={{ margin: "4px" }}
            onClick={() => {
              alert(val.label);
            }}
          >
            <i className={val.icon} aria-hidden="true"></i>
            {val.label}
          </button>
        );
      case "GetForm":
        return (
          <button
            key={uniqid()}
            className={`${val.btnCss} ${css}`}
            style={{ margin: "4px" }}
            onClick={() => {
              props.history.push(`/repo/${val.data[0].formId}`);
            }}
          >
            <i className={val.icon} aria-hidden="true"></i>
            {val.label}
          </button>
        );
      case "UIAction":
        return (
          <React.Fragment key={uniqid()}>
            {val.isRowLevel && val.isRowLevel ? (
              val.componentType === "form" ? (
                <button
                  className={`${val.btnCss} ${css}`}
                  title={val.label}
                  style={{ margin: "4px" }}
                  onClick={() => {
                    isAppRequest
                      ? props.history.push("/form_app/" + val.componentId)
                      : props.history.push("/form/" + val.componentId);
                  }}
                >
                  <i className={val.icon} aria-hidden="true"></i>
                  {val.label}
                </button>
              ) : val.componentType === "edit" ? (
                <button
                  title={val.label}
                  className={`${val.btnCss} ${css}`}
                  style={{ margin: "4px" }}
                  onClick={() => {
                    if (val.functionName) {
                      localStorage.setItem("functionName", val.functionName);
                      localStorage.setItem("funclass", val.class);
                      isAppRequest
                        ? props.history.push(
                            "/edit_app/" +
                              objectId +
                              "/" +
                              val.componentId +
                              "/" +
                              val.functionName +
                              "/" +
                              props.CurrentPage +
                              "/" +
                              val.class
                          )
                        : props.history.push(
                            "/edit/" +
                              objectId +
                              "/" +
                              val.componentId +
                              "/" +
                              val.functionName +
                              "/" +
                              props.CurrentPage +
                              "/" +
                              val.class
                          );
                    } else if (val.class) {
                      localStorage.setItem("funclass", val.class);
                      isAppRequest
                        ? props.history.push(
                            "/edit_app/" +
                              objectId +
                              "/" +
                              val.componentId +
                              "/" +
                              props.CurrentPage
                          )
                        : props.history.push(
                            "/edit/" +
                              objectId +
                              "/" +
                              val.componentId +
                              "/" +
                              props.CurrentPage
                          );
                    } else {
                      isAppRequest
                        ? props.history.push(
                            "/edit_app/" +
                              objectId +
                              "/" +
                              val.componentId +
                              "/" +
                              props.CurrentPage
                          )
                        : props.history.push(
                            "/edit/" +
                              objectId +
                              "/" +
                              val.componentId +
                              "/" +
                              props.CurrentPage
                          );
                    }
                  }}
                >
                  <i
                    className={val.icon}
                    aria-hidden="true"
                    title={val.label}
                  ></i>
                  {val.label}
                </button>
              ) : val.componentType === "sale" ? (
                <button
                  title={val.label}
                  className={`${val.btnCss} ${css}`}
                  style={{ margin: "4px" }}
                  onClick={() => {
                    if (val.functionName) {
                      localStorage.setItem("functionName", val.functionName);
                      localStorage.setItem("funclass", val.class);
                      isAppRequest
                        ? props.history.push(
                            "/sale_app/" +
                              objectId +
                              "/" +
                              val.componentId +
                              "/" +
                              val.functionName
                          )
                        : props.history.push(
                            "/sale/" +
                              objectId +
                              "/" +
                              val.componentId +
                              "/" +
                              val.functionName
                          );
                    } else {
                      isAppRequest
                        ? props.history.push(
                            "/sale_app/" + objectId + "/" + val.componentId
                          )
                        : props.history.push(
                            "/sale/" + objectId + "/" + val.componentId
                          );
                    }
                  }}
                >
                  <i
                    className={val.icon}
                    aria-hidden="true"
                    title={val.label}
                  ></i>
                  {val.label}
                </button>
              ) : val.componentType === "redeem" ? (
                <button
                  title={val.label}
                  className={`${val.btnCss} ${css}`}
                  style={{ margin: "4px" }}
                  onClick={() => {
                    if (val.functionName) {
                      isAppRequest
                        ? props.history.push("/redeem_app/" + objectId)
                        : props.history.push("/redeem/" + objectId);
                    } else {
                      /*  props.history.push(
                          "/sale/" + objectId + "/" + val.componentId
                        ); */
                    }
                  }}
                >
                  <i
                    className={val.icon}
                    aria-hidden="true"
                    title={val.label}
                  ></i>
                  {val.label}
                </button>
              ) : val.componentType === "frmnew" ? (
                <button
                  title={val.label}
                  className={`${val.btnCss} ${css}`}
                  style={{ margin: "4px" }}
                  onClick={() => {
                    isAppRequest
                      ? props.history.push(
                          "/frmnew_app/" +
                            objectId +
                            "/" +
                            val.componentId +
                            "/" +
                            val.class
                        )
                      : props.history.push(
                          "/frmnew/" +
                            objectId +
                            "/" +
                            val.componentId +
                            "/" +
                            val.class
                        );
                  }}
                >
                  <i
                    className={val.icon}
                    aria-hidden="true"
                    title={val.label}
                  ></i>
                  {val.label}
                </button>
              ) : val.componentType === "report" ? (
                <button
                  title={val.label}
                  className={`${val.btnCss} ${css}`}
                  style={{ margin: "4px" }}
                  onClick={() => {
                    localStorage.setItem("uiReport", JSON.stringify(Value));
                    isAppRequest
                      ? props.history.push("/report_app/" + val.componentId)
                      : props.history.push("/report/" + val.componentId);
                  }}
                >
                  <i
                    className={val.icon}
                    aria-hidden="true"
                    title={val.label}
                  ></i>
                  {val.label}
                </button>
              ) : val.componentType === "followup" ? (
                <button
                  title={val.label}
                  className={`${val.btnCss} ${css}`}
                  onClick={async () => {
                    isAppRequest
                      ? props.history.push(
                          "/followup_app/" + objectId + "/" + val.class
                        )
                      : props.history.push(
                          "/followup/" + objectId + "/" + val.class
                        );
                  }}
                >
                  <i className={val.icon} aria-hidden="true"></i>
                  {val.label}
                </button>
              ) : val.componentType === "microapp" ? (
                <React.Fragment>
                  <button
                    title={val.label}
                    className={`${val.btnCss} ${css}`}
                    disabled={isloadingMicro[`${val.componentId}${objectId}`]}
                    onClick={() => {
                      try {
                        setisloadingMicro({
                          ...isloadingMicro,
                          [`${val.componentId}${objectId}`]: true,
                        });
                        setRowLevel(JSON.stringify(Value));
                        setRowLevelMicro(JSON.stringify(val));
                        setMicroUrl(val.componentId);
                        setObjectMicro(objectId);
                      } catch (error) {
                        setisloadingMicro({
                          ...isloadingMicro,
                          [`${val.componentId}${objectId}`]: false,
                        });
                      }
                    }}
                  >
                    <i
                      className={
                        !isloadingMicro[`${val.componentId}${objectId}`]
                          ? val.icon
                          : "loader-01"
                      }
                      aria-hidden="true"
                    ></i>
                    {val.label}
                  </button>
                </React.Fragment>
              ) : null
            ) : null}
          </React.Fragment>
        );
      case "CustomFunctions":
        return (
          <button
            key={uniqid()}
            className={`${val.btnCss} ${css}`}
            style={{ margin: "4px" }}
            disabled={isloading}
            onClick={() => {
              customeFunction(val.functionName, Value, val.confirmMessage);
            }}
          >
            <i
              className={!isloading ? val.icon : "loader-01"}
              aria-hidden="true"
            ></i>{" "}
            {val.label}
          </button>
        );
      default:
        return null;
    }
  });
  return <React.Fragment>{result}</React.Fragment>;
};

export default withRouter(ActionView);
