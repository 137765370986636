import React, { useEffect, useState, useRef } from "react";
import "./image.css";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";

const $ = window.$;

function ImgModal(props) {
  const [imageDestination, setImageDestination] = useState("");
  const [_fileName, setFileName] = useState("");
  const [_fileType, setFileType] = useState("");
  const [imgSrc, setImgSrc] = useState("");
  const imageElement = useRef(null);

  const CloseOpenModal = (modal) => {
    $("#ImgModal").hide();
    if (modal) {
      $("#ImgModal").show();
    } else {
      $("#ImgModal").hide();
    }
  };

  function base64StringtoFile(base64String, filename) {
    var arr = base64String.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  function getCroppedImg(e) {
    try {
      e.preventDefault();
      const base64Image = imageDestination;
      const file = base64StringtoFile(base64Image, _fileName);
      props.fileUpload(file);
      setImgSrc("");
      $("#ImgModal").hide();
    } catch (error) {}
  }
  useEffect(() => {
    if (props.CloseOpenModal !== undefined) {
      CloseOpenModal(props.CloseOpenModal);
    }

    // eslint-disable-next-line
  }, [props.CloseOpenModal, props.ImgPath]);

  useEffect(() => {
    if (props.ImgPath && props.CloseOpenModal) {
      setFileName(props.ImgPath.name);
      setFileType(props.ImgPath.type);
      setImgSrc(URL.createObjectURL(props.ImgPath));
    }

    // eslint-disable-next-line
  }, [props.ImgPath]);

  useEffect(() => {
    if (imgSrc) {
      const cropper = new Cropper(imageElement.current, {
        dragMode: 3,
        aspectRatio: 1,
        autoCropArea: 0,
        restore: false,
        guides: false,
        center: true,
        highlight: false,
        cropBoxMovable: true,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
        ready: function () {
          cropper.setData({
            canvasData: {
              ...cropper.canvasData,
              height: props.Height,
              width: props.Width,
            },
            containerData: {
              height: props.Height,
              width: props.Width,
            },
            cropBoxData: {
              ...cropper.cropBoxData,
              height: props.Height,
              width: props.Width,
            },
            width: props.Width,
            height: props.Height,
          });
          console.log(cropper);
        },
        crop: () => {
          const canvas = cropper.getCroppedCanvas({});
          setImageDestination(canvas.toDataURL(`${_fileType}`));
        },
      });
    }

    // eslint-disable-next-line
  }, [imgSrc]);

  return (
    <div className="modal" id="ImgModal">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              className="close pull-right"
              style={{
                width:"auto",
                top: "8px",
                position: "relative",
                fontSize: "12px",
                padding: "4px",
                color: "white",
                backgroundColor: "red",
                borderRadius: "12px",
              }}
              onClick={(e) => {
                e.preventDefault();
                setImgSrc("");
                $("#ImgModal").hide();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            className="modal-body"
            width="100%"
            style={{
              height: "400px",
              overflowY: "scroll",
              overflowX: "scroll",
            }}
          >
            <div
              className="row"
              style={{
                textAlign: "center",
              }}
            >
              {imgSrc && (
                <div className="" style={{ overflow: "scroll",width:"100%",height:"auto" }}>
                  <img ref={imageElement} src={imgSrc} alt="source" />
                  <br />

                  <button
                    className="btn btn-danger"
                    style={{ width: "auto", marginBottom: "10px" }}
                    onClick={getCroppedImg}
                  >
                    crop and upload
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImgModal;
