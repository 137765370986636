import React, { useEffect, useState } from "react";
import Engine from "json-rules-engine-simplified";
import applyRules from "react-jsonschema-form-conditionals";
import Form from "react-jsonschema-form";
import "./form.css";
import "./toast.css";
import { withRouter } from "react-router-dom";
import Parse from "parse";
import FileUpload from "../LevelDropDown/FileUpload";
import "../Spinner/loader.css";
import LayoutField from "react-jsonschema-form-layout-2";
import TimeWidget from "../CustomeWidget/TimeWidget";
import axios from "axios";
import LayoutGridField from "react-jsonschema-form-layout-grid";
import Level3Dropdown from "../LevelDropDown/Level3Dropdown";
import Level2Dropdown from "../LevelDropDown/Level2Dropdown";
import Level1Dropdown from "../LevelDropDown/Level1Dropdown";
import ImageUpload from "../CustomeWidget/ImageUpload";
import AutoIncrementValue from "../CustomeWidget/AutoIncrementValue";
import HiddenField from "../CustomeWidget/HiddenField";
import MultiSelectField from "../LevelDropDown/MultiSelectField";
import ErrorBoundary from "./ErrorBoundary";
import MobileNumber from "../CustomeWidget/MobileNumber";
import AdvanceTextBox from "../LevelDropDown/AdvanceTextBox";
import TreeWidget from "../LevelDropDown/TreeWidget";

import Title from "./Title";
import DynamicRadio from "../LevelDropDown/DynamicRadio";

const widget = {
  TimeWidget: TimeWidget,
};
const fields = {
  FileUpload: FileUpload,
  DynamicRadio: DynamicRadio,
  AdvanceTextBox: AdvanceTextBox,
  MobileNumber: MobileNumber,
  layout: LayoutField,
  FolderComponent: TreeWidget,
  layout_grid: LayoutGridField,
  Level1Dropdown: Level1Dropdown,
  Level2Dropdown: Level2Dropdown,
  Level3Dropdown: Level3Dropdown,
  ImageUpload: ImageUpload,
  AutoIncrementValue: AutoIncrementValue,
  HiddenField: HiddenField,
  MultiSelectField: MultiSelectField,
};
const EditForm = (props) => {
  const [schema, setschema] = useState({});
  const [extraActions, setExtraActions] = useState(undefined);
  const [rules, setRuleData] = useState([]);
  const [ui_schema, setui_schema] = useState({});
  const [title, settitle] = useState("");
  const [schemaState, setschemaState] = useState({});
  const [redirect_type, setredirect_type] = useState("");
  const [redirect_id, setredirect_id] = useState("");
  const [formData, setformData] = useState({});
  const [buttons, setbuttons] = useState({});
  const [active, setactive] = useState(true);
  const [loading, setloading] = useState(false);
  const [_validate, set_validate] = useState(null);
  const [noValidate, setnoValidate] = useState(false);
  const [liveValidate, setliveValidate] = useState(false);
  const [FormACL, setFormACL] = useState(null);
  const [userSchema, setuserSchema] = useState({});
  const [isAppRequest, setisAppRequest] = useState(false);
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const [toastColor, settoastColor] = useState("#5cb85c");
  const [toastDescription, settoastDescription] = useState("");

  const getForm = async () => {
    setloading(true);
    try {
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      var forms = Parse.Object.extend("w_formV3");
      var query = new Parse.Query(forms);
      query.equalTo("objectId", props.match.params.formid);
      const res = await query.first();
      const resultjson = res.toJSON();
      let results = resultjson;
      try {
        var _record = "";
        if (props.match.params.fun) {
          _record = Parse.Object.extend(props.match.params.class);
        } else {
          if (props.match.params.class) {
            _record = Parse.Object.extend(props.match.params.class);
          } else {
            _record = Parse.Object.extend(results.class);
          }
        }
        var query1 = new Parse.Query(_record);
        await query1.get(props.match.params.id).then(
          (x) => {
            try {
              if (x) {
                let result = x.toJSON();
                let new_result = result;
                for (let [key, value] of Object.entries(result)) {
                  if (value) {
                    if (value["__type"] === "Date") {
                      let todayTime1 = new Date(value.iso);
                      var month = String(todayTime1.getMonth() + 1);
                      var day = String(todayTime1.getDate());
                      var year = String(todayTime1.getFullYear());
                      if (month.length < 2) month = "0" + month;
                      if (day.length < 2) day = "0" + day;
                      let date1 = year + "-" + month + "-" + day;
                      let bindVar = date1;

                      if (
                        resultjson.jsonSchema.properties[key] &&
                        !resultjson.jsonSchema.properties[key].format
                      ) {
                        bindVar = todayTime1.toISOString();
                      }
                      new_result[key] = bindVar;
                    }
                  }
                }
                setschemaState(resultjson.jsonSchema);
                setformData(new_result);
                setuserSchema(resultjson.userSchema);
              }
            } catch (error) {
              //alert(error.message);
              settoastColor("#d9534f");
              settoastDescription(`${error.message}`);
              var x = document.getElementById("_editsnackbar");
              x.className = "show";
              setTimeout(function () {
                x.className = x.className.replace("show", "");
              }, 5000);
              setloading(false);
            }
          },
          (error) => {
            // alert(error.message);
            settoastColor("#d9534f");
            settoastDescription(`${error.message}`);
            var x = document.getElementById("_editsnackbar");
            x.className = "show";
            setTimeout(function () {
              x.className = x.className.replace("show", "");
            }, 5000);
            setloading(false);
          }
        );
        let _rules = [],
          _extraActions = {};
        if (results.rules) {
          _rules = results.rules;
        }
        if (results.extraActions) {
          _extraActions = setExtraAction(results.extraActions);
        }
        let _jsonSchema = JSON.stringify(results.jsonSchema);
        _jsonSchema = _jsonSchema.replace("#$", "$");
        _jsonSchema = _jsonSchema.replace("#*", "$")
        _jsonSchema = _jsonSchema.replace("_DOT_", ".")

        let _replaceJSONSchema = JSON.parse(_jsonSchema);
        setbuttons(results.buttons.edit);
        setredirect_type(results.success_redirect);
        setredirect_id(results.redirect_id);
        setFormACL(results.formACL);
        setschema(_replaceJSONSchema);
        setui_schema(results.uiSchema);
        set_validate(results.validFunction);
        setnoValidate(results.noValidate);
        setliveValidate(results.liveValidate && results.liveValidate);
        settitle(results.class);
        setRuleData(_rules);
        setExtraActions(_extraActions);
        setloading(false);
        setactive(true);
      } catch (error) {
        settoastColor("#d9534f");
        settoastDescription(`${error.message}`);
        var x = document.getElementById("_editsnackbar");

        x.className = "show";
        setTimeout(function () {
          x.className = x.className.replace("show", "");
        }, 5000);

        // alert(error.message);
        setloading(false);
      }
    } catch (e) {
      // alert(e.message);
      settoastColor("#d9534f");
      settoastDescription(`${e.message}`);
      var x = document.getElementById("_editsnackbar") || null;
      if (x) {
        x.className = "show";
        setTimeout(function () {
          x.className = x.className.replace("show", "");
        }, 5000);
      }

      setloading(false);
      console.error("Problem", e);
    }
  };

  const wrap = (s) => "{ return " + s + " };";

  // func = new Function(wrap(body));

  const dynamicValidate = (formData, errors) => {
    try {
      let body = atob(_validate);
      let res = new Function(wrap(body))
        .call(null)
        .call(null, formData, errors);
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const setExtraAction = (actions) => {
    try {
      let result = {};
      Object.entries(actions).forEach(([key, value]) => {
        let body = atob(value);
        let res = new Function(wrap(body)).call(null);
        result[key] = res;
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async ({ formData }) => {
    if (props.match.params.fun) {
      setloading(true);
      setactive(false);
      let url = `${parseBaseUrl}functions/${props.match.params.fun}`;
      const headers = {
        "Content-Type": "application/json",
        "X-Parse-Application-Id": parseAppId,
        sessionToken: localStorage.getItem("accesstoken"),
      };
      let obj = {
        formData: formData,
      };
      await axios.post(url, obj, { headers: headers }).then((response) => {
        if (response) {
          try {
            if (response.data.result.message !== undefined)
              // alert(`${response.data.result.message}`);
              settoastColor("#5cb85c");
            settoastDescription(`${response.data.result.message}`);
            var x = document.getElementById("_editsnackbar");
            x.className = "show";
            setTimeout(function () {
              x.className = x.className.replace("show", "");
              // props.RefreshData( props.Data, props.CurrentPage);
              props.history.goBack();
            }, 2000);
            setloading(false);
            setactive(true);
            setformData({});
          } catch (error) {}
        } else {
          setloading(false);
          setactive(true);
        }
      });
    } else {
      setactive(false);
      setloading(true);
      let RowData = formData;
      RowData &&
        Object.entries(RowData).forEach(([key, value]) => {
          if (typeof value === "string") {
            RowData[key] = value.trim();
          }
        });
      let _scanData = schemaState;
      let allowed = [];
      if (_scanData.dependencies) {
        Object.keys(_scanData.dependencies).forEach((key) => {
          if (_scanData.dependencies[key].oneOf) {
            _scanData.dependencies[key].oneOf.forEach((val) => {
              Object.keys(val.properties).forEach((k) => {
                allowed.push(k);
                if (typeof val.properties[k] === "object") {
                  if (val.properties[k].format === "date") {
                    if (RowData[k]) {
                      let newdate = new Date(RowData[k]);
                      RowData[k] = newdate;
                    }
                  }
                  if (val.properties[k].component === "HtmlEditor") {
                    if (RowData[k]) {
                      let newHtml = RowData[k]
                        .replace(/<p[^>]*>/g, "")
                        .replace(/<\/p>/g, " ");
                      RowData[k] = newHtml;
                    }
                  }
                  if (val.properties[k].component === "DateTime") {
                    if (RowData[k]) {
                      let newDate11 = new Date(RowData[k]);
                      RowData[k] = newDate11;
                    }
                  }
                  if (val.properties[k].component === "CurrencyInput") {
                    if (val.properties[k].currencyColumn) {
                      RowData[`${val.properties[k].currencyColumn}`] =
                        val.properties[k].defaultcurrency;
                    }
                  }
                  if (val.properties[k].type === "string") {
                    if (typeof RowData[k] === "string")
                      RowData[k] = RowData[k].trim();
                  }
                  if (val.properties[k].data !== undefined) {
                    if (Array.isArray(val.properties[k].data)) {
                    } else if (val.properties[k].data.isPointer) {
                      let pointer = undefined;
                      if (val.properties[k].data.class) {
                        if (RowData[k]) {
                          if (val.properties[k].data.savePointerClass) {
                            pointer = {
                              __type: "Pointer",
                              className:
                                val.properties[k].data.savePointerClass,
                              objectId: RowData[k],
                            };
                          } else {
                            pointer = {
                              __type: "Pointer",
                              className: val.properties[k].data.class,
                              objectId: RowData[k],
                            };
                          }
                        }
                      } else {
                        if (RowData[k]) {
                          pointer = {
                            __type: "Pointer",
                            className: localStorage.getItem("extended_class"),
                            objectId: RowData[k],
                          };
                        }
                      }
                      RowData[k] = pointer;
                    }
                    if (val.properties[k].data.FolderTypeValue) {
                      if (RowData[k]) {
                        let obj = {
                          __type: "Pointer",
                          className: val.properties[k].data.ClassName,
                          objectId: RowData[k],
                        };
                        RowData[k] = obj;
                      } else {
                        RowData[k] = null;
                      }
                    }
                  }
                }
              });
            });
          }
        });
      }
      let temp = [],
        ui = ui_schema;
      Object.keys(_scanData).forEach(function (key) {
        let _dd = _scanData[key];
        Object.keys(_dd).forEach(function (k) {
          allowed.push(k);
          if (_dd[k].type === "array" && _dd[k].items) {
            let _prop = _dd[k].items.properties;
            if (_prop && Array.isArray(RowData[k])) {
              let newRow = [];
              RowData[k].forEach((t) => {
                let _newObj = t;
                if (typeof t === "object") {
                  Object.keys(_prop).forEach(function (l) {
                    if (_prop[l].data && _prop[l].data.isPointer) {
                      if (typeof t[l] === "object") {
                        let obj = {
                          __type: "Pointer",
                          className: _prop[l].data.class,
                          objectId: t[l].objectId,
                        };
                        _newObj = { ..._newObj, [l]: obj };
                      } else {
                        let obj = {
                          __type: "Pointer",
                          className: _prop[l].data.class,
                          objectId: t[l],
                        };
                        _newObj = { ..._newObj, [l]: obj };
                      }
                    }
                  });
                }
                newRow.push(_newObj);
              });
              RowData[k] = newRow;
            }
          }
          if (_dd[k].component === "AutoSuggest" && _dd[k].isPointer) {
            if (typeof RowData[k] === "object") {
              if (RowData[k]) {
                if (RowData[k].objectId !== "Select") RowData[k] = RowData[k];
              }
            } else {
              if (RowData[k]) {
                if (_dd[k].savePointerClass) {
                  let pointer = {
                    __type: "Pointer",
                    className: _dd[k].savePointerClass,
                    objectId: RowData[k],
                  };
                  RowData[k] = pointer;
                } else {
                  let pointer = {
                    __type: "Pointer",
                    className: _dd[k].class,
                    objectId: RowData[k],
                  };
                  RowData[k] = pointer;
                }
              }
            }
          }
          if (_dd[k].format === "date") {
            if (RowData[k]) {
              let newdate = new Date(RowData[k]);
              if (!isNaN(newdate.getTime())) {
                RowData[k] = newdate;
              }
            }
          }
          if (_dd[k].component === "HtmlEditor") {
            if (RowData[k]) {
              let newHtml = RowData[k]
                .replace(/<p[^>]*>/g, "")
                .replace(/<\/p>/g, " ");
              RowData[k] = newHtml;
            }
          }
          if (_dd[k].component === "DateTime") {
            if (RowData[k]) {
              let newdate = new Date(RowData[k]);
              if (!isNaN(newdate.getTime())) {
                RowData[k] = newdate;
              }
            }
          }
          if (_dd[k].format === "date-time") {
            let newdate = new Date(RowData[k]);
            RowData[k] = newdate;
          }
          if (_dd[k].component === "CurrencyInput") {
            if (_dd[k].currencyColumn) {
              RowData[`${_dd[k].currencyColumn}`] = _dd[k].defaultcurrency;
            }
          }
          if (_dd[k].data !== undefined) {
            if (_dd[k].data[0] !== undefined) {
            } else if (_dd[k].data.isPointer) {
              let pointer = undefined;
              if (typeof RowData[k] === "object") {
                if (RowData[k]) {
                  if (Array.isArray(RowData[k])) {
                    pointer = [];
                    RowData[k] &&
                      RowData[k].forEach((a) => {
                        let _kk = {};
                        if (_dd[k].data.savePointerClass) {
                          _kk = {
                            __type: "Pointer",
                            className: _dd[k].data.savePointerClass,
                            objectId: a,
                          };
                        } else {
                          _kk = {
                            __type: "Pointer",
                            className: _dd[k].data.class,
                            objectId: a,
                          };
                        }

                        pointer.push(_kk);
                      });
                  } else if (RowData[k].objectId !== "Select") {
                    let obj = {
                      __type: "Pointer",
                      className: RowData[k].className,
                      objectId: RowData[k].objectId,
                    };
                    pointer = obj;
                  }
                }
              } else if (_dd[k].data.class) {
                if (RowData[k]) {
                  if (_dd[k].data.savePointerClass) {
                    pointer = {
                      __type: "Pointer",
                      className: _dd[k].data.savePointerClass,
                      objectId: RowData[k],
                    };
                  } else {
                    pointer = {
                      __type: "Pointer",
                      className: _dd[k].data.class,
                      objectId: RowData[k],
                    };
                  }
                }
              } else {
                if (RowData[k]) {
                  pointer = {
                    __type: "Pointer",
                    className: localStorage.getItem("extended_class"),
                    objectId: RowData[k],
                  };
                }
              }

              RowData[k] = pointer;
            }
            if (_dd[k].data.FolderTypeValue) {
              if (RowData[k]) {
                let obj = {
                  __type: "Pointer",
                  className: _dd[k].data.ClassName,
                  objectId: RowData[k],
                };
                RowData[k] = obj;
              } else {
                RowData[k] = null;
              }
            }
          }
          if (_dd[k].type === "string") {
            if (typeof RowData[k] === "string") RowData[k] = RowData[k].trim();
          }
        });
      });
      try {
        Parse.serverURL = parseBaseUrl;
        Parse.initialize(parseAppId);
        const currentUser = Parse.User.current();
        if (temp.length !== 0) {
          let userPointer = {
            __type: "Pointer",
            className: "_User",
            objectId: currentUser.id,
          };
          RowData[[temp[0]]] = userPointer;
        }
        var data = Parse.Object.extend(title);
        var query = new Parse.Query(data);
        query.get(props.match.params.id).then(async (object) => {
          if (userSchema && Object.keys(userSchema).length !== 0) {
            let url = `${parseBaseUrl}functions/MoveUserToRole`;
            const headers = {
              "Content-Type": "application/json",
              "X-Parse-Application-Id": parseAppId,
              sessionToken: localStorage.getItem("accesstoken"),
            };
            let role = "";
            if (userSchema.role.startsWith("$")) {
              let _r = userSchema.role.substring(1);
              role = RowData[_r];
            } else {
              role = userSchema.role;
            }
            let body;
            if (RowData.UserId) {
              body = {
                appName: localStorage.getItem("_appName"),
                roleName: role,
                userId: RowData.UserId.objectId,
              };

              await axios
                .post(url, body, { headers: headers })
                .then((res) => {
                  delete RowData.UserId;
                  delete RowData.CreatedBy;
                  delete RowData.createdAt;
                  delete RowData.updatedAt;
                  delete RowData.ACL;
                  const filtered = Object.keys(RowData)
                    .filter((key) => allowed.includes(key))
                    .reduce((obj, key) => {
                      obj[key] = RowData[key];
                      return obj;
                    }, {});
                  object.save(filtered).then(() => {
                    // alert("Record updated successfully.");
                    settoastColor("#5cb85c");
                    settoastDescription("Record updated successfully");
                    var x = document.getElementById("_editsnackbar");
                    x.className = "show";
                    setTimeout(function () {
                      x.className = x.className.replace("show", "");
                      if (redirect_type === "Form") {
                        isAppRequest
                          ? (window.location.hash = `/form_app/${redirect_id}`)
                          : (window.location.hash = `/form/${redirect_id}`);
                      } else if (redirect_type === "Report") {
                        if (props.match.params.current) {
                          localStorage.setItem(
                            "CurrentPage",
                            props.match.params.current
                          );
                        } else {
                          localStorage.setItem(
                            "CurrentPage",
                            props.match.params.page
                          );
                        }
                        isAppRequest
                          ? (window.location.hash = `/report_app/${redirect_id}`)
                          : (window.location.hash = `/report/${redirect_id}`);
                      } else if (redirect_type === "Dashboard") {
                        isAppRequest
                          ? (window.location.hash = `/dashboard_app/${redirect_id}`)
                          : (window.location.hash = `/dashboard/${redirect_id}`);
                      } else if (redirect_type === "Url") {
                        window.location = redirect_id;
                      } else {
                        getForm();
                      }
                    }, 1000);
                    setloading(false);
                    setactive(true);

                    // alert("Record updated successfully");
                  });
                })
                .catch((e) => {
                  console.log(e.message);
                  settoastColor("#CA0B00");
                  settoastDescription(`${e.message}`);
                  let x = document.getElementById("_editsnackbar");
                  x.className = "show";
                  setTimeout(function () {
                    x.className = x.className.replace("show", "");
                  }, 2000);
                  //  alert(`${e.message}`);
                  setactive(true);
                  setloading(false);
                });
            } else {
              settoastColor("#CA0B00");
              settoastDescription(`UserId not found in extended class`);
              let x = document.getElementById("_editsnackbar");
              x.className = "show";
              setTimeout(function () {
                x.className = x.className.replace("show", "");
              }, 2000);
              //  alert(`${e.message}`);
              setactive(true);
              setloading(false);
            }
          } else {
            const filtered = Object.keys(RowData)
              .filter((key) => allowed.includes(key))
              .reduce((obj, key) => {
                obj[key] = RowData[key];
                return obj;
              }, {});
            object.save(filtered).then(
              () => {
                try {
                  settoastColor("#5cb85c");
                  settoastDescription("Record updated successfully");
                  var x = document.getElementById("_editsnackbar");
                  x.className = "show";
                  setTimeout(function () {
                    x.className = x.className.replace("show", "");

                    if (redirect_type === "Form") {
                      isAppRequest
                        ? (window.location.hash = `/form_app/${redirect_id}`)
                        : (window.location.hash = `/form/${redirect_id}`);
                    } else if (redirect_type === "Report") {
                      if (props.match.params.current) {
                        localStorage.setItem(
                          "CurrentPage",
                          props.match.params.current
                        );
                      } else {
                        localStorage.setItem(
                          "CurrentPage",
                          props.match.params.page
                        );
                      }
                      isAppRequest
                        ? (window.location.hash = `/report_app/${redirect_id}`)
                        : (window.location.hash = `/report/${redirect_id}`);
                    } else if (redirect_type === "Dashboard") {
                      isAppRequest
                        ? (window.location.hash = `/dashboard_app/${redirect_id}`)
                        : (window.location.hash = `/dashboard/${redirect_id}`);
                    } else if (redirect_type === "Url") {
                      window.location = redirect_id;
                    } else {
                      getForm();
                    }
                    // setformData(filtered);
                    setloading(false);
                    setactive(true);
                  }, 2000);
                } catch (error) {}
              },
              (error) => {
                settoastColor("#d9534f");
                settoastDescription(`${error.message}`);
                var x = document.getElementById("_editsnackbar");
                x.className = "show";
                setTimeout(function () {
                  x.className = x.className.replace("show", "");
                }, 2000);
                setloading(false);
                setactive(true);
              }
            );
          }
        });
      } catch (error) {
        alert(error.message);
        setloading(false);
        setactive(true);
      }
    }
  };

  useEffect(() => {
    let url = window.location.hash;
    if (url.includes("_app")) {
      setisAppRequest(true);
    }
    getForm();

    // eslint-disable-next-line
  }, []);

  let FormToDisplay = applyRules(
    schema,
    ui_schema,
    [],
    Engine,
    extraActions
  )(Form);
  if (rules.length > 0 && extraActions) {
    FormToDisplay = applyRules(
      schema,
      ui_schema,
      rules,
      Engine,
      extraActions
    )(Form);
  }
  let formview = (
    <FormToDisplay
      formData={formData}
      showErrorList={false}
      widgets={widget}
      fields={fields}
      validate={noValidate && dynamicValidate}
      liveValidate={liveValidate}
      onSubmit={handleSubmit}
    >
      <div>
        {active && buttons.submitText ? (
          <button className="btn btn-info" type="submit">
            {buttons.submitText}
          </button>
        ) : (
          buttons.submitText && (
            <button className="btn btn-info" type="submit" disabled>
              {buttons.submitText}
            </button>
          )
        )}
        &nbsp;&nbsp;
        {buttons.cancelText && (
          <button
            className="btn btn-primary"
            onClick={() => {
              if (props.match.params.current) {
                localStorage.setItem("CurrentPage", props.match.params.current);
              } else {
                localStorage.setItem("CurrentPage", props.match.params.page);
              }
              props.history.goBack();
            }}
            type="button"
          >
            {buttons.cancelText}
          </button>
        )}
      </div>
    </FormToDisplay>
    /* <Form
      schema={schema}
      uiSchema={ui_schema}
      formData={formData}
      showErrorList={false}
      widgets={widget}
      fields={fields}
      validate={noValidate && dynamicValidate}
      liveValidate={liveValidate}
      onSubmit={handleSubmit}
    >
      <div>
        {active && buttons.submitText ? (
          <button className="btn btn-info" type="submit">
            {buttons.submitText}
          </button>
        ) : (
          buttons.submitText && (
            <button className="btn btn-info" type="submit" disabled>
              {buttons.submitText}
            </button>
          )
        )}
        &nbsp;&nbsp;
        {buttons.cancelText && (
          <button
            className="btn btn-primary"
            onClick={() => {
              if (props.match.params.current) {
                localStorage.setItem("CurrentPage", props.match.params.current);
              } else {
                localStorage.setItem("CurrentPage", props.match.params.page);
              }
              props.history.goBack();
            }}
            type="button"
          >
            {buttons.cancelText}
          </button>
        )}
      </div>
    </Form>
  */
  );

  if (loading && formData) {
    formview = (
      <div style={{ height: "300px" }}>
        <div
          style={{
            marginLeft: "45%",
            marginTop: "150px",
            fontSize: "45px",
            color: "#3dd3e0",
          }}
          className="loader-37"
        ></div>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div id="_editsnackbar" style={{ backgroundColor: toastColor }}>
        {toastDescription}
      </div>
      <Title title={schemaState.title ? schemaState.title : "Edit form"} />
      <ErrorBoundary>
        <div className="row">
          <div className="col-md-12">
            <input
              type="button"
              value="Back"
              onClick={() => {
                if (props.match.params.current) {
                  localStorage.setItem(
                    "CurrentPage",
                    props.match.params.current
                  );
                } else {
                  localStorage.setItem("CurrentPage", props.match.params.page);
                }
                props.history.goBack();
              }}
              className="btn btn-primary btn-xs btnBackCss"
            />
            <div className="card">
              <div className="card-body no-padding height-9">{formview}</div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </React.Fragment>
  );
};

export default withRouter(EditForm);
