import React, { Component } from 'react';
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';
import '@atlaskit/css-reset';
import Column from './Column';
import Spinner from '../Spinner/Spinner';
import Parse from 'parse';
import axios from 'axios';




const Container = styled.div`
  height: 80px;
  padding: 0 30px;
  background: #7159c1;
  color: #FFF;
  display: flex;
  align-items: center;
`;

const KanBanContainer = styled.div`
    display:flex;
`;

export class KanbanBoard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            tasks: {},
            columns: {},
            columnOrder: [],
            updateClass: '',
            updateField: '',
            parseBaseUrl: localStorage.getItem("baseUrl"), 
            parseAppId: localStorage.getItem("parseAppId")
        }

    }

    handleData = async (id) => {
        Parse.serverURL = this.state.parseBaseUrl;
        Parse.initialize(this.state.parseAppId);
        var _kList = Parse.Object.extend("w_KanbanList");
        const query = new Parse.Query(_kList);
        query.equalTo("objectId", id);
        const response = await query.first();
        const resultjson = response.toJSON();
        let _task = {};
        let _maintasks = {};
        let listField = [];
        let arr = []; let columnOrder = [];
        let taskArray = []; let TL = {}; let _column = {}; let done = false;
        this.setState(() => {
            let url = `${this.state.parseBaseUrl}classes/${resultjson.data[0].class}?${resultjson.data[0].query}`;
            const headers = {
                'Content-Type': 'application/json',
                'X-Parse-Application-Id': this.state.parseAppId
            }
            axios.get(url, { headers: headers })
                .then(res => {
                    resultjson.data.forEach(x => {
                        res.data.results.forEach((val, i) => {
                            _task = {
                                id: val.objectId,
                                content: val[x.carddescfield],
                                title: val[x.cardtitlefield],
                                name: val[x.cardrightfooterfield],
                                amount: val[x.cardleftfooterfield],
                                date: val[x.cardrighttitlefield],
                                view: val[x.cardId],
                                camparefield: val[x.listfield[0]]
                            }
                            listField.push(val[x.listfield[0]]);
                            arr = Array.from(new Set(listField));
                            _maintasks[val.objectId] = _task;
                            let d = val.objectId;
                            taskArray.push(d);
                        })
                    });
                    let tm = false;
                    for (let j = 0; j < arr.length; j++) {
                        TL['task' + j] = []
                        tm = true;
                    }
                    if (tm) {
                        for (var l in _maintasks) {
                            for (let j = 0; j < arr.length; j++) {
                                if (_maintasks[l].camparefield === arr[j]) {
                                    TL['task' + j].push(_maintasks[l].id)
                                }
                            }
                        }
                    }
                    for (let j = 0; j < arr.length; j++) {
                        let _c = 'column-' + j;
                        _column[_c] = {
                            id: _c,
                            title: arr[j],
                            taskIds: TL['task' + j]
                        }
                        columnOrder.push(_c);
                        done = true
                    }
                    if (done) {
                        this.setState({
                            columns: _column,
                            tasks: _maintasks,
                            columnOrder: columnOrder,
                            updateClass: resultjson.data[0].class,
                            updateField: resultjson.data[0].listfield[0]
                        });
                        localStorage.setItem('KanbanViewClass', this.state.updateClass)
                    }
                });
        })
    }

    onDragEnd = (result) => {

        document.body.style.color = 'inherit';
        document.body.style.backgroundColor = 'inherit';
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }
        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }
        const start = this.state.columns[source.droppableId];
        const finish = this.state.columns[destination.droppableId];
        if (start === finish) {
            const newTaskIds = Array.from(start.taskIds);
            newTaskIds.splice(source.index, 1);
            newTaskIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...start,
                taskIds: newTaskIds
            };

            const newState = {
                ...this.state,
                columns: {
                    ...this.state.columns,
                    [newColumn.id]: newColumn
                }
            };
            this.setState(newState);
            let tempDate = new Date();
            var laterDate = new Date(); 
            laterDate.setDate(laterDate.getDate() + 4);
            let ldate = laterDate.getFullYear() + '-' + (laterDate.getMonth() + 1) + '-' + laterDate.getDate();
            let date = tempDate.getFullYear() + '-' + (tempDate.getMonth()+1) + '-' + tempDate.getDate();
           

            Parse.serverURL = this.state.parseBaseUrl;
            Parse.initialize(this.state.parseAppId);
            var _kList = Parse.Object.extend(this.state.updateClass);
            const query = new Parse.Query(_kList);
            query.get(result.draggableId).then((object) => {
                object.set(this.state.updateField, newFinish.title);
                object.set("stage_updated_date", date);        
                object.set("SysUpDate", ldate);        
                object.save().then((response) => {
                    if (typeof document !== 'undefined')
                        console.log('Updated ', response);
                }, (error) => {
                    if (typeof document !== 'undefined')
                        console.error('Error while updating ', error);
                });
            });
            return;
        }

        //Moving from one list to another list
        const startTaskIds = Array.from(start.taskIds);
        startTaskIds.splice(source.index, 1);
        const newStart = {
            ...start,
            taskIds: startTaskIds
        };

        const finishTaskIds = Array.from(finish.taskIds);
        finishTaskIds.splice(destination.index, 0, draggableId);
        const newFinish = {
            ...finish,
            taskIds: finishTaskIds
        };

        const newState = {
            ...this.state,
            columns: {
                ...this.state.columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish
            }
        }
        this.setState(newState);
        
        Parse.serverURL = this.state.parseBaseUrl;
        Parse.initialize(this.state.parseAppId);
        var _kList1 = Parse.Object.extend(this.state.updateClass);
        const query = new Parse.Query(_kList1);
        query.get(result.draggableId).then((object) => {
            object.set(this.state.updateField, newFinish.title);
            object.save().then((response) => {
                if (typeof document !== 'undefined')
                    console.log('Updated ', response);
            }, (error) => {
                if (typeof document !== 'undefined')
                    console.error('Error while updating ', error);
            });
        });

    }

    onDragStart = () => {
        document.body.style.color = 'orange';
        document.body.style.transition = 'background-color 0.2s ease';
    }

    onDragUpdate = update => {
        const { destination } = update;
        const opacity = destination
            ? destination.index / Object.keys(this.state.tasks).length
            : 0;
        document.body.style.backgroundColor = `rgba(153,141,217,${opacity})`;
    }



    componentDidMount() {
        this.handleData(this.props.match.params.id);
    }

    render() {
        console.log("this.state.updateClass",this.state.updateClass)
        let data = this.state.columnOrder.map(columnId => {
            const column = this.state.columns[columnId];
            const tasks = column.taskIds.map(taskId => this.state.tasks[taskId]);
            return <Column key={column.id} column={column} tasks={tasks} />
        });

        if (this.state.loading) {
            data = <Spinner />
        }
        return (
            <>
                <Container>
                    <h1>NXG</h1>
                </Container>

                <KanBanContainer>
                    <DragDropContext
                        onDragStart={this.onDragStart}
                        onDragUpdate={this.onDragUpdate}
                        onDragEnd={this.onDragEnd}
                    >
                        {
                            data
                        }
                    </DragDropContext>
                </KanBanContainer>

            </>
        )
    }
}

export default KanbanBoard
