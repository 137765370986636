import React, { useState, useEffect } from "react";
import Parse from "parse";
import axios from "axios";
import Form from "react-jsonschema-form";
import { withRouter } from "react-router-dom";
import FileUpload from "../LevelDropDown/FileUpload";
import Spinner from "../Spinner/Spinner";
import LayoutField from "react-jsonschema-form-layout-2";
import TimeWidget from "../CustomeWidget/TimeWidget";
import LayoutGridField from "react-jsonschema-form-layout-grid";
import Level3Dropdown from "../LevelDropDown/Level3Dropdown";
import Level2Dropdown from "../LevelDropDown/Level2Dropdown";
import Level1Dropdown from "../LevelDropDown/Level1Dropdown";
import ImageUpload from "../CustomeWidget/ImageUpload";
import AutoIncrementValue from "../CustomeWidget/AutoIncrementValue";
import HiddenField from "../CustomeWidget/HiddenField";
import MultiSelectField from "../LevelDropDown/MultiSelectField";
import ErrorBoundary from "../components/ErrorBoundary";
import MobileNumber from "../CustomeWidget/MobileNumber";
import AdvanceTextBox from "../LevelDropDown/AdvanceTextBox";

const widget = {
  TimeWidget: TimeWidget,
};
const fields = {
  FileUpload: FileUpload,
  AdvanceTextBox: AdvanceTextBox,
  MobileNumber: MobileNumber,
  layout: LayoutField,
  layout_grid: LayoutGridField,
  Level1Dropdown: Level1Dropdown,
  Level2Dropdown: Level2Dropdown,
  Level3Dropdown: Level3Dropdown,
  ImageUpload: ImageUpload,
  AutoIncrementValue: AutoIncrementValue,
  HiddenField: HiddenField,
  MultiSelectField: MultiSelectField,
};

function CustomeEdit(props) {
  const [jsonSchema, setjsonSchema] = useState({});
  const [uiSchema, setuiSchema] = useState({});
  const [buttons, setbuttons] = useState(null);
  const [formcls, setformcls] = useState("");
  const [formData, setformData] = useState({});
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const [loading, setloading] = useState(false);
  const [active, setactive] = useState(false);
  let _temp = props.match.params.id;
  _temp = _temp.split(";");

  const getform = async (id) => {
    try {
      setloading(true);
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      var form = Parse.Object.extend("w_formV3");
      var query = new Parse.Query(form);
      query.equalTo("objectId", id);
      const results = await query.first();
      
      const resultjson = results.toJSON();
      if (resultjson) {
        setjsonSchema(resultjson.jsonSchema);
        setuiSchema(resultjson.uiSchema);
        setbuttons(resultjson.buttons.edit);
        setformcls(resultjson.class);
        let url = `${parseBaseUrl}classes/${resultjson.class}?${_temp[1]}`;
        const headers = {
          "Content-Type": "application/json",
          "X-Parse-Application-Id": parseAppId,
          "X-Parse-Session-Token": localStorage.getItem("accesstoken"),
        };
        await axios.get(url, { headers: headers }).then((res) => {
          if (res) {
            setformData(res.data.results[0]);
            setloading(false);
          }
        });
      }
    } catch (error) {
      console.log(error.message);
      setloading(false);
    }
  };

  useEffect(() => {
    getform(_temp[0]);

    // eslint-disable-next-line
  }, []);

  const handleSubmit = async ({ formData }) => {
    console.log(formData);
  };

  
  let formview = (
    <Form
      schema={jsonSchema}
      uiSchema={uiSchema}
      formData={formData}
      widgets={widget}
      fields={fields}
      onSubmit={handleSubmit}
    >
      <div>
        {active ? (
          <button className="btn btn-info" type="submit">
            {buttons && buttons.submitText}
          </button>
        ) : (
          <button className="btn btn-info" type="submit" disabled>
            {buttons && buttons.submitText}
          </button>
        )}
        &nbsp;&nbsp;
        <button
          className="btn btn-primary"
          onClick={() => {
            props.history.goBack();
          }}
          type="button"
        >
          {buttons && buttons.cancelText}
        </button>
      </div>
    </Form>
  );

  if (loading) {
    formview = <Spinner />;
  }
  return (
    <React.Fragment>
      <ErrorBoundary>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body no-padding height-9">
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col-2">
                    {/* <input
                      type="button"
                      value="Back"
                      onClick={() => {
                        props.history.goBack();
                      }}
                      className="btn btn-primary"
                    /> */}
                  </div>
                </div>
                {formview}
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </React.Fragment>
  );
}

export default CustomeEdit;
