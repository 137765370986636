import React, { Component } from "react";
import Parse from "parse";
export class SubObject extends Component {
  state = {
    JSONObject: [],
    parseBaseUrl: localStorage.getItem("baseUrl"),
    parseAppId: localStorage.getItem("parseAppId"),
  };

  async GetData() {
    try {
      if (this.props.Data[0] === "UserId") {
        return null;
      }
      if (this.props.Data[0] === "CreatedBy") {
        return null;
      }
      if (this.props.Data[1]["__type"] === "Date") {
        return (
          <tr>
            <td width="50%" style={{ paddingLeft: 2 }}>
              {this.props.Data[0]}
            </td>
            <td width="300px">{this.props.Data[1]["iso"]}</td>
          </tr>
        );
      }
      // if (this.props.Data[1]["__type"] === "Array") {
      //   return (
      //     <tr>
      //       <td width="50%" style={{paddingLeft: 2}} >{this.props.Data[0]}</td>
      //       <td width="300px">{(this.props.Data[1]).join("")}</td>
      //     </tr>
      //   );
      // }
      /* if (this.props.Data[1].isArray()) {
        
        try {
          return (
            <tr>
              <td width="50%">{this.props.Data[0]}</td>
              <td width="300px">{(this.props.Data[1]).join()}</td>
            </tr>
          );
        } catch (error) {}
      } */
      Parse.serverURL = this.state.parseBaseUrl;
      Parse.initialize(this.state.parseAppId);
      var subObject = Parse.Object.extend(this.props.Data[1].className);
      var query = new Parse.Query(subObject);
      query.equalTo("objectId", this.props.Data[1].objectId);
      let res = await query.first();
      let json = res.toJSON();

      delete json.createdAt;
      delete json.objectId;
      delete json.CreatedBy;
      delete json.UserId;
      delete json.updatedAt;
      delete json.ACL;
      delete json.sessionToken;
      let _theader = Object.keys(json).map(function (key) {
        if (typeof json[key] === "object") {
          delete json[key];
        } else if (key === "class" || key === "mainformObjId") {
          delete json[key];
        }
        return [String(key), json[key]];
      });
      this.setState({
        JSONObject: _theader,
      });
    } catch (error) {}
  }
  componentDidMount() {
    this.GetData();
  }
  render() {
    return this.state.JSONObject.map((x, i) => (
      <>
        {x[1] !== undefined ? (
          <tr>
            <td width="50%" style={{ paddingLeft: 2 }}>
              {x[0]}
            </td>
            <td width="300px">
              {x[1] === true ? "Active" : x[1] === false ? "In-active" : x[1]}
            </td>
          </tr>
        ) : null}
      </>
    ));
  }
}

export default SubObject;
