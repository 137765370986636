import React, { useEffect, useState } from "react";
import Parse from "parse";
import Spinner from "../Spinner/Spinner";

function CustomeReddem(props) {
  const [loader, setLoader] = useState(false);
  const [parseBaseUrl] = useState(localStorage.getItem("baseUrl"));
  const [parseAppId] = useState(localStorage.getItem("parseAppId"));
  const [offerData, setofferData] = useState([]);
  const [Quantity, setQuantity] = useState("");
  const [offer, setOffer] = useState("");
  const [customer, setCustomer] = useState({});

  const GetNormalOffer = async () => {
    try {
      Parse.serverURL = parseBaseUrl;
      Parse.initialize(parseAppId);
      const Omar = Parse.Object.extend("loyaltyscheme_AddOffer");
      const query = new Parse.Query(Omar);
      query.equalTo("Is_Special", false);
      query.include("ItemName");
      await query.find().then(
        (result) => {
          if (result) {
            let offr = JSON.stringify(result);
            let _offr = JSON.parse(offr);
            setofferData(_offr);
          }
        },
        (error) => {
          console.error("Error while fetching", error);
        }
      );
    } catch (error) {}
  };

  //useEffect for get State Special offer
  useEffect(() => {
    GetNormalOffer();
  }, []);

  // onSubmitHandle
  const onSubmitHandel = async (e) => {
    try {
      e.preventDefault();
      setLoader(true);
      if (offer && Quantity) {
        let _offerData = offerData.filter((x) => x.objectId === offer);
        
        Parse.serverURL = parseBaseUrl;
        Parse.initialize(parseAppId);
        const currentUser = Parse.User.current();
        const Agent = Parse.Object.extend("loyaltyscheme_Staff");
        const query34 = new Parse.Query(Agent);
        query34.equalTo("UserId", currentUser);
        await query34.first().then(async (staff) => {
          
          if (staff) {
            const MyCustomClass = Parse.Object.extend("loyaltyscheme_Customer");
            const query = new Parse.Query(MyCustomClass);
            query.equalTo("objectId", props.match.params.id);
            await query.first().then(
              async (cust) => {
                if (cust) {
                  let _customer = cust.toJSON();
                  setCustomer(_customer);
                  let d = new Date();
                  let time =
                    ("0" + d.getHours()).slice(-2) +
                    ":" +
                    ("0" + d.getMinutes()).slice(-2);
                  
                 
                  if (
                    _customer.PointsTable >=
                    _offerData[0].Offer_Points * Number(Quantity)
                  ) {
                    const CustomClass = Parse.Object.extend(
                      "loyaltyscheme_RedeemOffers"
                    );
                    const myNewObject = new CustomClass();
                    
                    myNewObject.set("Offer_Name", _offerData[0].Offer_Title);
                    myNewObject.set("ItemName",  _offerData[0].ItemName.objectId);
                    myNewObject.set("Redeem_Date", new Date());
                    myNewObject.set("Redeem_Time", time);
                    myNewObject.set("Offer_Points", _offerData[0].Offer_Points);
                    myNewObject.set("Redeem_Quantity", Number(Quantity));
                    myNewObject.set(
                      "Redeem_Total",
                      Number(Quantity) * _offerData[0].Offer_Points
                    );
                    myNewObject.set("CustomerId", {
                      __type: "Pointer",
                      className: "loyaltyscheme_Customer",
                      objectId: props.match.params.id,
                    });
                    myNewObject.set("Staffid", {
                      __type: "Pointer",
                      className: "loyaltyscheme_Staff",
                      objectId: staff.id,
                    });
                    myNewObject.save().then(
                      async (result) => {
                        if (result) {
                          await query.get(props.match.params.id).then((obj) => {
                            obj.set(
                              "PointsTable",
                              obj.attributes.PointsTable -
                                _offerData[0].Offer_Points * Number(Quantity)
                            );
                            obj.save().then(
                              async (response) => {
                                //get Inventary Item
                                const cls = Parse.Object.extend(
                                  "loyaltyscheme_InventntoryItems"
                                );
                                const query1 = new Parse.Query(cls);
                                query1.equalTo(
                                  "objectId",
                                  _offerData[0].ItemName.objectId
                                );
                                return await query1.first().then(
                                  async (results) => {
                                    let _item = results.toJSON();

                                    //update Inventarory Item
                                    return await query1
                                      .get(_item.objectId)
                                      .then((object) => {
                                        object.set(
                                          "Available_Stock",
                                          _item.Available_Stock -
                                            Number(Quantity)
                                        );
                                        if (
                                          _item.Minimum_Stock >=
                                          _item.Available_Stock -
                                            Number(Quantity)
                                        ) {
                                          object.set("LMStock", -1);
                                        }
                                        return object.save().then(
                                          (response) => {
                                            if (response) {
                                              alert(
                                                `Offer redeemed successfully. Please provide ${Number(
                                                  Quantity
                                                )} ${
                                                  _offerData[0]["ItemName"]
                                                    .S_K_U
                                                } ${
                                                  _offerData[0]["ItemName"]
                                                    .Item_name
                                                } for ${
                                                  _offerData[0].Offer_Points *
                                                  Number(Quantity)
                                                } points.`
                                              );
                                              window.location.hash = "/";
                                            }
                                          },
                                          (error) => {
                                            alert(error.message);
                                            setLoader("");
                                          }
                                        );
                                      });
                                  },
                                  (error) => {
                                    alert(error.message);
                                    setLoader(false);
                                  }
                                );
                              },
                              (error) => {
                                alert(error.message);
                                setLoader(false);
                              }
                            );
                          });
                        }
                      },
                      (error) => {
                        alert(error.message);
                        setLoader(false);
                      }
                    );
                  } else {
                    alert("You don't have enough points to redeem this offer");
                    setLoader(false);
                  }
                }
              },
              (error) => {
                alert(error.message);
                setLoader(false);
              }
            );
          }
        });
      } else {
        alert("Select offer and no. of quantity. ");
        setLoader(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoader(false);
    }
  };

  let viewer = <Spinner />;
  if (loader) {
    return viewer;
  } else {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-head">
                <header>Redeem Entry</header>
              </div>
              <div className="card-body no-padding height-9">
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col-2"></div>
                </div>
                <form onSubmit={onSubmitHandel}>
                  <div className="form-group">
                    <label htmlFor="offers">Offers</label>
                    <br />
                    {offerData && offerData.length > 0
                      ? offerData.map((x, i) => (
                          <label
                            key={x.objectId}
                            className="radio-inline"
                            style={{ padding: "5px" }}
                          >
                            <input
                              type="radio"
                              name="offers"
                              value={x.objectId}
                              checked={offer === x.objectId}
                              onChange={(e) => setOffer(e.target.value)}
                            />
                            {x.Offer_Title}
                          </label>
                        ))
                      : "Currently Not any Normal Offers"}
                  </div>
                  <br />

                  <br />
                  <div className="form-group">
                    <label htmlFor="Quantity">Quantity</label>
                    <input
                      type="number"
                      name="Quantity"
                      value={Quantity}
                      className="form-control"
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </div>
                  <button type="submit" className="btn btn-info">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CustomeReddem;
